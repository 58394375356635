import React from 'react';
import SearchArea from './SearchArea';
import {useMediaQuery} from "react-responsive";
import Footer2 from "../../Shared/Footer/Footer2";
import CarListing from "./CarListing";

const Search = props => {
    console.log('Search-props', props)
    // first commit to responsive II

    const isBigScreen = useMediaQuery({minWidth: 999})

    return (
        <div id='root-search-div'>
            <div className='container'>
                <div id='search-area-and-car-listing' className='row'>
                    {isBigScreen && <SearchArea {...props}/>}
                    <CarListing {...props}/>
                </div>
            </div>
            <Footer2 searchCount={props.searchCount}/>
        </div>
    );
};
export default Search;

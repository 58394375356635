import React, {useState} from 'react';
import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import StatusMessages, {useMessages} from './StatusMessages';
import './stripe.css';
import PaymentConfirmation from "../PaymentConfirmation";
import {AzureUpgradeOrdersRESTConnector} from "../../../../../../../Integration";


const StripeCheckoutForm2 = props => {

    console.log('StripeCheckoutForm2', props)

    const elements = useElements();
    const stripe = useStripe();

    const [messages, addMessage] = useMessages();
    const [isLoading, setIsLoading] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const upgradeOrdersConnector = new AzureUpgradeOrdersRESTConnector();


    const submitHandler = async e => {
        console.log('submitHandler', e.target)
        e.preventDefault();
        console.warn('createIntent to be fired')

        if (!stripe || !elements) return;
        setIsLoading(true);

        addMessage('creating payment intent...');

        const {clientSecret, error} = await upgradeOrdersConnector.createOrderStripe(props);

        console.log('clientSecret:', clientSecret, '\nerror:', error);
        clientSecret ? addMessage('payment intent created') : addMessage(error);

        if (clientSecret !== undefined) {
            const {error: stripeError, paymentIntent} =
                await stripe.confirmCardPayment(
                    clientSecret,
                    {
                        payment_method: {
                            card: elements.getElement(CardElement),
                        },
                    }
                );

            if (stripeError) {
                // Show error to your customer (e.g., insufficient funds)
                addMessage(stripeError.message);
                setIsLoading(false);
                return;
            }

            setIsLoading(false);
            setIsComplete(true);
            // Show a success message to your customer
            // There's a risk of the customer closing the window before callback
            // execution. Set up a webhook or plugin to listen for the
            // payment_intent.succeeded event that handles any business critical
            // post-payment actions.
            addMessage(`Payment ${paymentIntent.status}: ${paymentIntent.id}`);

        }
    };

    return <>
        {!isComplete && <>
            <form id="payment-form" onSubmit={submitHandler} className='cardForm'>
                <CardElement id="card" options={cardStyle}/>
                <button type="submit" disabled={!elements || !stripe || isLoading}>
            <span id="button-text">
        {isLoading ? <div className="spinner" id="spinner"/> : "Pay now"}</span>
                </button>
            </form>
            <StatusMessages messages={messages}/></>
        }
        {isComplete && <PaymentConfirmation/>}
    </>
}

export default StripeCheckoutForm2;

const cardStyle = {
    style: {
        base: {
            color: "#32325d",
            fontFamily: 'Arial, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#32325d"
            },
            background: 'white'
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a"
        }
    }
};
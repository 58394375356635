import React from 'react';
import _ from 'lodash';
import {Link} from "react-router-dom";
import {useMediaQuery} from "react-responsive";

const MakeAndModel = props => {

    // console.log('MakeAndModel facets:', props.facets)
    const facets = props.facets;

    const isBigScreen = useMediaQuery({minWidth: '700px'});
    let colDiv = 'col-4 d-table justify-content-center mt-4';
    if (isBigScreen) colDiv = 'col-3 d-table justify-content-center'

    const getFromFacet = (make, model) => {

        // console.log('getModelFromFacet', model);

        let count = 0;
        if (model.indexOf('/') === -1) {
            props.facets.model.forEach(e => {
                if (e.value === model.toLowerCase() || e.value === model) {
                    count = e.count;
                }
            });
        } else {
            const models = model.split('/');
            models.forEach(m => {
                const trimmed = _.trim(m, ' ');
                props.facets.model.forEach(e => {
                    if (e.value === trimmed.toLowerCase() || e.value === trimmed) {
                        count += e.count;
                    }
                })
            });
        }

        if (count < 1) {
            return <div className='d-table-row text-nowrap' style={{color: 'rgba(0,0,0,.5)'}}>{model}</div>;
        } else {
            return <div className='d-table-row text-nowrap'>
                <Link to={'search'}>
                    <span onClick={() => props.updateOptions({
                        'make': {value: make, visible: true, title: make},
                        'model': {value: model, visible: true, title: model}
                    })}
                    >{model}{' ('}{count}{')'}</span>
                </Link>
            </div>
        }
    }

    if (facets !== undefined && facets.model !== undefined) {

        return <div id='popular-makes-and-models'
                    style={{fontSize: '0.75rem'}} key='make-model-div'
                    className='container-fluid px-0'>
            <div className='row d-flex justify-content-center mt-4'>
                {isBigScreen && <div className='offset-2'/>}
                <div className={colDiv}>
                    <div className='d-table-row font-weight-bold'>Volkswagen</div>
                    {getFromFacet('Volkswagen', 'Polo')}
                    {getFromFacet('Volkswagen', 'Golf')}
                    {getFromFacet('Volkswagen', 'Passat')}
                    {getFromFacet('Volkswagen', 'Tiguan')}
                    {getFromFacet('Volkswagen', 'Touareg')}
                </div>
                <div className={colDiv}>
                    <div className='d-table-row font-weight-bold'>Audi</div>
                    {getFromFacet('Audi', 'A4')}
                    {getFromFacet('Audi', 'A6')}
                    {getFromFacet('Audi', 'A8/S8')}
                    {getFromFacet('Audi', 'Q5')}
                    {getFromFacet('Audi', 'Q7/SQ7')}
                </div>
                <div className={colDiv}>
                    <div className='d-table-row font-weight-bold'>Mercedes-Benz</div>
                    {getFromFacet('Mercedes-Benz', 'EQC')}
                    {getFromFacet('Mercedes-Benz', 'C-class')}
                    {getFromFacet('Mercedes-Benz', 'S-class')}
                    {getFromFacet('Mercedes-Benz', 'ML-class/GLE')}
                    {getFromFacet('Mercedes-Benz', 'GL-class/GLS')}
                </div>
                {isBigScreen && <div className='offset-2'/>}
            </div>

            <div className='row d-flex justify-content-center mt-4'>
                {isBigScreen && <div className='offset-2'/>}
                <div className={colDiv}>
                    <div className='d-table-row font-weight-bold'>BMW</div>
                    {getFromFacet('BMW', '3 Series')}
                    {getFromFacet('BMW', '5 Series')}
                    {getFromFacet('BMW', '7 Series')}
                    {getFromFacet('BMW', 'IX')}
                    {getFromFacet('BMW', 'X5')}
                </div>
                <div className={colDiv}>
                    <div className='d-table-row font-weight-bold'> Porsche
                    </div>
                    {getFromFacet('Porsche', '911')}
                    {getFromFacet('Porsche', 'Panamera')}
                    {getFromFacet('Porsche', 'Macan')}
                    {getFromFacet('Porsche', 'Cayenne')}
                </div>
                <div className={colDiv}>
                    <div className='d-table-row font-weight-bold'>Lexus</div>
                    {getFromFacet('Lexus', 'GS')}
                    {getFromFacet('Lexus', 'LS')}
                    {getFromFacet('Lexus', 'RX')}
                    {getFromFacet('Lexus', 'LX')}
                </div>
                {isBigScreen && <div className='offset-2'/>}
            </div>

            <div className='row d-flex justify-content-center mt-4'>
                {isBigScreen && <div className='offset-2'/>}
                <div className={colDiv}>
                    <div className='d-table-row font-weight-bold'>Toyota</div>
                    {getFromFacet('Toyota', 'Yaris')}
                    {getFromFacet('Toyota', 'Corolla')}
                    {getFromFacet('Toyota', 'Avensis')}
                    {getFromFacet('Toyota', 'RAV4')}
                    {getFromFacet('Toyota', 'Land Cruiser')}
                </div>
                <div className={colDiv}>
                    <div className='d-table-row font-weight-bold'>Skoda</div>
                    {getFromFacet('Skoda', 'Fabia')}
                    {getFromFacet('Skoda', 'Octavia')}
                    {getFromFacet('Skoda', 'Superb')}
                    {getFromFacet('Skoda', 'Kodiaq')}
                    {getFromFacet('Skoda', 'Enyaq')}
                </div>
                <div className={colDiv}>
                    <div className='d-table-row font-weight-bold'>Peugeot</div>
                    {getFromFacet('Peugeot', '208')}
                    {getFromFacet('Peugeot', '308')}
                    {getFromFacet('Peugeot', '508')}
                    {getFromFacet('Peugeot', '2008')}
                    {getFromFacet('Peugeot', '3008')}
                </div>
                {isBigScreen && <div className='offset-2'/>}
            </div>

            <div className='row d-flex justify-content-center mt-4'>
                {isBigScreen && <div className='offset-2'/>}
                <div className={colDiv}>
                    <div className='d-table-row font-weight-bold'>Nissan</div>
                    {getFromFacet('Nissan', 'Juke')}
                    {getFromFacet('Nissan', 'Leaf')}
                    {getFromFacet('Nissan', 'Qashqai')}
                    {getFromFacet('Nissan', 'X-trail')}
                    {getFromFacet('Nissan', 'Navarra')}
                </div>
                <div className={colDiv}>
                    <div className='d-table-row font-weight-bold'>Ford</div>
                    {getFromFacet('Ford', 'Focus')}
                    {getFromFacet('Ford', 'Kuga')}
                    {getFromFacet('Ford', 'S-max')}
                    {getFromFacet('Ford', 'Ranger')}
                    {getFromFacet('Ford', 'Transit')}
                </div>
                <div className={colDiv}>
                    <div className='d-table-row font-weight-bold'>Volvo</div>
                    {getFromFacet('Volvo', 'S60')}
                    {getFromFacet('Volvo', 'S90')}
                    {getFromFacet('Volvo', 'V70')}
                    {getFromFacet('Volvo', 'XC60')}
                    {getFromFacet('Volvo', 'XC90')}
                </div>
                {isBigScreen && <div className='offset-2'/>}

            </div>

        </div>

    }
    return null;
}

export default MakeAndModel;
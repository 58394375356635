import React from 'react';
import pickup from "./img/toyo240.jpg";
import compact from './img/golf240.jpg'
import suv from './img/suv240.jpg'
import road from './img/road240.jpg'
import LargeTypeCard from "./LargeTypeCard/LargeTypeCard";
import {useTranslation} from "react-i18next";
import {useMediaQuery} from "react-responsive";

const LargeTypeCardList = props => {

    const {t} = useTranslation(['texts', 'data-for-ui'])

    const isBigScreen = useMediaQuery({minWidth: 1200});

    let style = {}
    if (!isBigScreen) style = {display: 'flex', overflowX: 'scroll'};

    let topDivClass = 'row mt-n mb-4 justify-content-center';
    if (!isBigScreen) topDivClass = 'mt-n mb-4 scroll';

    const crossoverOrSuvTitle = t('facets.suv-or-crossover');
    const sedanTitle = t('data-for-ui:bodystyle.sedan.title');
    const pickupTitle = t('home.cards.pickup');
    const compactMultiTitle = t('facets.hatchback-minivan-sportback-liftback');


    return <div id='large-type-cards' className={topDivClass} style={style}>
        <LargeTypeCard image={compact}
                       updateOptions={props.updateOptions}
                       title={t('home.cards.compact')}
                       title1={t('home.cards.up-to-1000')} to1={'/search'} value1={[0, 1000]}
                       title2={t('home.cards.up-to-2500')} to2={'/search'} value2={[1000, 2500]}
                       title3={t('home.cards.up-to-5000')} to3={'/search'} value3={[2500, 5000]}
                       title4={t('home.cards.up-to-10000')} to4={'/search'} value4={[5000, 10000]}
                       title5={t('home.cards.up-to-15000')} to5={'/search'} value5={[10000, 15000]}
                       title6={t('home.cards.up-to-20000')} to6={'/search'} value6={[15000, 20000]}
                       bodystyle='hatchback/minivan/sportback/liftback'
                       categoryTitle={compactMultiTitle}
        />

        <LargeTypeCard image={road}
                       title={t('home.cards.sedans')}
                       updateOptions={props.updateOptions}
                       title1={t('home.cards.up-to-2500')} to1={'/search'} value1={[0, 2500]}
                       title2={t('home.cards.up-to-5000')} to2={'/search'} value2={[2500, 5000]}
                       title3={t('home.cards.up-to-10000')} to3={'/search'} value3={[5000, 10000]}
                       title4={t('home.cards.up-to-20000')} to4={'/search'} value4={[10000, 20000]}
                       title5={t('home.cards.up-to-30000')} to5={'/search'} value5={[20000, 30000]}
                       title6={t('home.cards.up-to-50000')} to6={'/search'} value6={[30000, 50000]}
                       bodystyle='sedan'
                       categoryTitle={sedanTitle}
        />
        <LargeTypeCard image={suv}
                       title={t('home.cards.suv')}
                       updateOptions={props.updateOptions}
                       title1={t('home.cards.up-to-2500')} to1={'/search'} value1={[0, 2500]}
                       title2={t('home.cards.up-to-5000')} to2={'/search'} value2={[2500, 5000]}
                       title3={t('home.cards.up-to-10000')} to3={'/search'} value3={[5000, 10000]}
                       title4={t('home.cards.up-to-20000')} to4={'/search'} value4={[10000, 20000]}
                       title5={t('home.cards.up-to-30000')} to5={'/search'} value5={[20000, 30000]}
                       title6={t('home.cards.up-to-50000')} to6={'/search'} value6={[30000, 50000]}
                       bodystyle='crossover/suv'
                       categoryTitle={crossoverOrSuvTitle}
        />
        <LargeTypeCard image={pickup}
                       title={t('home.cards.pickups')}
                       updateOptions={props.updateOptions}
                       title1={t('home.cards.up-to-2500')} to1={'/search'} value1={[0, 2500]}
                       title2={t('home.cards.up-to-5000')} to2={'/search'} value2={[2500, 5000]}
                       title3={t('home.cards.up-to-10000')} to3={'/search'} value3={[5000, 10000]}
                       title4={t('home.cards.up-to-20000')} to4={'/search'} value4={[10000, 20000]}
                       title5={t('home.cards.up-to-30000')} to5={'/search'} value5={[20000, 30000]}
                       title6={t('home.cards.up-to-50000')} to6={'/search'} value6={[30000, 50000]}
                       bodystyle='pickup'
                       categoryTitle={pickupTitle}
        />
    </div>
}

export default LargeTypeCardList;
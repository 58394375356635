import React from 'react';
import Heading from "../../../Search/SearchArea/Heading";
import {Input} from "../../Input";

const InputFieldModification = props => {

    let numberClass = 'row mt-2 numbered-list-item'
    if (props.state.modification === undefined && props.prevField !== undefined) numberClass += ' yellow';
    else if (props.prevField === undefined) numberClass += ' grey'

    return <div className={numberClass} id='row-modification'>

        <div className='offset-1'/>
        <div className='col-9 pl-0'>
            {/*{console.log('modification simple')}*/}
            <Input
                value={props.state.modification !== undefined ?
                    props.state.modification.name : undefined}
                disabled={props.prevField === undefined}
                handler={props.updateModification}
                placeholder='Type'
                maxLength='35'
            >
                <div style={{marginLeft: '1em'}}>
                    <Heading>{props.title}</Heading>
                </div>
            </Input>
        </div>
    </div>
}

export default InputFieldModification;
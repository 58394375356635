import _ from "lodash";
import React from "react";
import {containerStyle} from "../MyAccount";
import {useTranslation} from "react-i18next";

export const ErrorMessageBlock = props => {

    const {t} = useTranslation(['errors']);

    console.log('errorMessage', props.errorMessage);

    return <div id='error-message-block'
                className='col-12 mt-2'
                style={containerStyle}>{!_.isEmpty(props.errorMessage) &&
        <div className="container mt-4">
            <div className='font-weight-bold my-1 row'> {t('errors:my-account.fix-errors')}</div>

            <div className='row mb-1 mt-0'>
                <ul className='list-unstyled text-danger col pl-0'>{
                    Object.keys(props.errorMessage)
                        .filter(k => props.errorMessage[k] !== 'ok').map(n => {
                        return <li key={n}>{props.errorMessage[n]}</li>
                    })}</ul>
            </div>
        </div>}
    </div>;
}
import React from 'react';
import {CloseButton, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";

const ToastModalPublish = props => {

    const {t} = useTranslation(['texts']);
    return <Modal centered size='sm'
                  onHide={props.handleClose}
                  show={props.show}>
        <Modal.Header>
            <span>{t('sell.car-ad-card.unpublish-to-edit')}</span>
            <CloseButton onClick={() => props.handleClose(false)}/>
        </Modal.Header>
    </Modal>
}

export default ToastModalPublish;
import React from 'react';


const SearchOptionModal2 = props => {
    // console.log('SearchOptionModal2 props', props);

    let title = props.title;
    let type = props.type;
    if (type === 'make-model' && props.options.make.value !== undefined) {
        if (type.indexOf('make') !== -1) {
            const make = props.options.make.value;
            const model = props.options.model.value;
            if (make !== 'any') {
                title = `${make}`;
                if (model !== 'any') title = `${make} ${model}`
            }
        } else {
            title = props.options[props.type].title;
            if (title === 'any') title = '--';
        }
    } else {
        if (props.options[props.type].title !== undefined)
            title = props.options[props.type].title;
    }

    let containerClass = 'container';
    if (props.bsClass !== undefined) containerClass += ' ' + props.bsClass;


    return (<div id={'search-field-m-' + props.type.toLowerCase()} className={containerClass}>
        <div className="row">
            <div className="col d-flex align-items-center"
                 style={style.div}
                 onClick={props.handler}
            >
                <span className='text-uppercase font-weight-bold'>{title}</span>
                <div className='d-flex align-items-center'
                     style={{
                         right: 0,
                         position: 'absolute',
                         marginRight: '8px',
                         color: '#ccc'
                     }}
                >
                    <svg width="20" height="20" aria-hidden="true" color='#ccc' className='arrowIcon'>
                        <path
                            fill="currentColor"
                            d="M4.516 7.548c.436-.446 1.043-.481 1.576 0L10 11.295l3.908-3.747c.533-.481 1.141-.446 1.574 0 .436.445.408 1.197 0 1.615-.406.418-4.695 4.502-4.695 4.502-.217.223-.502.335-.787.335s-.57-.112-.789-.335c0 0-4.287-4.084-4.695-4.502s-.436-1.17 0-1.615z"/>
                    </svg>
                </div>
            </div>
        </div>
    </div>)
};

export default SearchOptionModal2;

const style = {
    div: {
        color: '#212529',
        fontSize: '0.875rem',
        lineHeight: '38px',
        height: '38px',
        border: 'solid 1px rgba(0,0,0,0.2)',
        borderRadius: '4px',
        cursor: 'pointer',
        userSelect: 'none'
    }
};

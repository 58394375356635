const years = [2022, 1990];
const months = {
    1: 'January', 2: 'February', 3: 'March', 4: 'April', 5: 'May', 6: 'June', 7: 'July', 8: 'August',
    9: 'September', 10: 'October', 11: 'November', 12: 'December'
};

const getYears = () => {
    const arr = [];
    for (let i = years[0]; i >= years[1]; i--) {
        arr.push(i);
    }
    return arr;

};

export {getYears, months};

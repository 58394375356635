import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import _ from "lodash";
import {Col, Toast, ToastContainer} from "react-bootstrap";
import {formatWebsiteName, formatWebsiteURL, isVerified} from "./sellerUtils";

import verified from '../media/verified.svg';
import verifiedLong from '../media/verified_long.svg';

const SellerInfo = props => {

    console.log('SellerInfo props:', props.sellerInfo);

    const {t} = useTranslation(['texts']);
    const juridicalStatus_d = t('data-for-ui:juridical-status', {returnObjects: true});

    const [showPhone, setShowPhone] = useState(false);
    const [showVerifiedToast, setShowVerifiedToast] = useState(false);


    return <div id='seller-info-panel' className='container mt-4' style={props.style}>

        {/*Seller Title*/}
        <div className='row d-flex justify-content-between my-3' style={{'borderBottom': '1px solid #eee'}}>
            <div className='col-5 justify-content-start pl-0'>
                    <span className="text-left">
                        {t('texts:vehicle-information.seller-info.seller')}
                    </span>
            </div>
            <div className='col-7  pr-0 d-flex justify-content-end align-items-center'>
                    <span className="text-right font-weight-bold text-capitalize">
                        {props.sellerInfo.name}
                    </span>

                {/**Verified Seller*/}
                {isVerified(props.sellerInfo.sellersRank) &&
                    <>                    <img className='ml-1'
                                               style={{userSelect: 'none', cursor: 'pointer', height: '16px'}}
                                               src={verified}
                                               onClick={() => setShowVerifiedToast(true)}
                    />


                    </>
                }
            </div>
            {isVerified(props.sellerInfo.sellersRank) && <div className='d-flex justify-content-center w-100'>
                <ToastContainer position={'middle-end'}>
                    <Toast onClose={() => setShowVerifiedToast(false)}
                           show={showVerifiedToast}
                           animation={false}>
                        <Toast.Header>
                            <div className='d-flex justify-content-between align-items-center w-100'>
                                <Col className='mx-0 px-0'>
                                    <img
                                        src={verifiedLong}
                                        className="rounded ml-0"
                                        style={{height: '1rem'}}
                                        alt="verified seller logo"
                                    />
                                </Col>
                            </div>
                        </Toast.Header>
                        <Toast.Body>
                            <p>{t('vehicle-information.seller-info.verified-by-caruniverse')}</p>
                            <p>{t('vehicle-information.seller-info.more-about-verification-criteria')}{' '}
                                <a
                                    style={{textDecoration: 'underline'}}
                                    href='/support/how-to-partner'>
                                    {t('vehicle-information.seller-info.here')}
                                </a>
                            </p>
                        </Toast.Body>
                    </Toast>
                </ToastContainer>
            </div>}
        </div>


        {/*Phone*/}
        {props.sellerInfo.phone !== undefined &&
            <div className='row d-flex justify-content-between my-3 mb-2'
                 style={{'borderBottom': '1px solid #eee'}}>
                <div className='col-5 justify-content-start pl-0'>
              <span className="text-left">
                        {t('vehicle-information.seller-info.phone')}
              </span>
                </div>
                <div className='col-7 d-flex justify-content-end pr-0'>
                    {showPhone ? <span
                            className="text-right font-weight-bold text-nowrap">
                    {props.sellerInfo.phone}
                </span> :
                        <div onClick={() => setShowPhone(true)}
                             style={{
                                 padding: '0 10px',
                                 background: 'palegreen',
                                 // color: 'palegreen',
                                 border: '1px solid yellowgreen'
                             }}>
                            {t('vehicle-information.seller-info.show-phone')}
                        </div>}
                </div>
            </div>}


        {/*Juridical status*/}
        <div className='row d-flex justify-content-between my-3' style={{'borderBottom': '1px solid #eee'}}>
            <div className='col-7 justify-content-start pl-0'>
                    <span className="text-left">
                        {t('vehicle-information.seller-info.juridicalStatus')}
                        {/*{t('vehicle-information.seller-info.juridical-status')}*/}
                    </span>
            </div>
            <div className='col-5 d-flex justify-content-end pr-0'>
                     <span className="text-right font-weight-bold text-capitalize">
                         {!_.isEmpty(props.sellerInfo.type)
                             ? juridicalStatus_d[props.sellerInfo.type.toLowerCase()].title
                             : props.sellerInfo.type}
                    </span>
            </div>
        </div>


        {/*Reg. number*/}
        {props.sellerInfo.registrationNumber &&
            <div className='row d-flex justify-content-between my-3' style={{'borderBottom': '1px solid #eee'}}>
                <div className='col-5 justify-content-start pl-0'>
                    <span className="text-left">
                        {t('vehicle-information.seller-info.registrationNumber')}
                        {/*{t('vehicle-information.seller-info.reg-number')}*/}
                    </span>
                </div>
                <div className='col-7 d-flex justify-content-end pr-0'>
                    <span className="text-right font-weight-bold text-capitalize">
                        {props.sellerInfo.registrationNumber}
                    </span>
                </div>
            </div>}

        {/*Company address*/}
        {props.sellerInfo.companyAddress &&
            <div className='row d-flex justify-content-between my-3' style={{'borderBottom': '1px solid #eee'}}>
                <div className='col-5 justify-content-start pl-0'>
                    <span className="text-left">
                        {t('vehicle-information.seller-info.companyAddress')}</span>
                    {/*{t('vehicle-information.seller-info.companyAddress')}</span>*/}
                </div>
                <div className='col-7 d-flex justify-content-end pr-0'>
                    <span className="text-right font-weight-bold text-capitalize">
                        {props.sellerInfo.companyAddress}
                    </span>
                </div>
            </div>}

        {/*Website*/}
        {props.sellerInfo.type === 'company' && !_.isEmpty(props.sellerInfo.website) ?
            <div className='row d-flex justify-content-between mt-3 mb-1'
                // style={{'borderBottom': '1px solid #eee'}}
            >
                <div className='col-5 justify-content-start pl-0'>
                    <span className="text-left">
                        {t('vehicle-information.seller-info.website')}
                    </span>
                </div>
                <div className='col-7 d-flex justify-content-end pr-0'>
                    <a
                        className="text-right font-weight-bold text-nowrap"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={formatWebsiteURL(props.sellerInfo.website)}
                    >
                        {formatWebsiteName(props.sellerInfo.website)}
                    </a>
                </div>

            </div> :
            <div id='website-empty' className='py-2'/>
        }

        {/**Seller logo*/}

        {!_.isEmpty(props.sellerInfo.logo) &&
            <div id='seller-logo-div' className='row d-flex justify-end my-3'>
                <div className='col-12 justify-content-center px-0'>
                    {props.sellerInfo.website ? (
                        <a href={formatWebsiteURL(props.sellerInfo.website)} target="_blank"
                           rel="noopener noreferrer">
                            <Col className='d-flex justify-content-center'
                                 style={{maxHeight: '2rem'}}
                                 dangerouslySetInnerHTML={{__html: props.sellerInfo.logo}}>
                            </Col>
                        </a>
                    ) : (
                        <Col id='logo-div'
                             className='d-flex justify-content-center'
                             style={{maxHeight: '2rem'}}
                             dangerouslySetInnerHTML={{__html: props.sellerInfo.logo}}>
                        </Col>

                    )}
                </div>
            </div>}


    </div>

}
export default SellerInfo;
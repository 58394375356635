import React from 'react';
import OptionSelect from "../../OptionSelect/OptionSelect";
import {getYears} from "../../../../Data";
import Heading from "../../../Search/SearchArea/Heading";

const InputFieldYear = props => {
    console.log('InputFieldYear', props);

    let numberClass = 'row mt-2 numbered-list-item'
    if (props.state.year === undefined && props.prevField !== undefined) numberClass += ' yellow';
    else if (props.prevField === undefined) numberClass += ' grey'

    return <div className={numberClass} id='row-age'>
        {/*<NumberOfStep thisField={props.state.year} prevField={props.prevField}>*/}
        {/*    {props.step}*/}
        {/*</NumberOfStep>*/}
        <div className='offset-1'/>
        <div className='col-9 pl-0'>
            <OptionSelect
                id='select-year'
                selected={props.state.year}
                handler={props.updateYear}
                isDisabled={props.prevField === undefined}
                options={getYears().map(year => {
                    return {label: year, value: year}
                })}>
                <Heading>{props.title}</Heading>
            </OptionSelect>
        </div>
    </div>

}

export default InputFieldYear;
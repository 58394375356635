import React, {useState} from 'react';

export const SellerInfoSelect = ({
                                     sellerInfo,
                                     localInfoFields,
                                     setLocalInfoFields,
                                     setShowCompanyTypeChangeModal,
                                     juridicalStatus_d,
                                     k,
                                     showInput
                                 }) => {

    console.log("SellerInfoSelect sellerInfo", sellerInfo, "localInfoFields", localInfoFields, "k", k);

    const [type, setType] = useState(sellerInfo.type); //added to display the selected value in the select


    const handleSelectChange = e => {
        console.log("SellerInfoSelect handleSelectChange", e.target.value);
        const sellerInfoCopy = {...sellerInfo};
        sellerInfoCopy["type"] = e.target.value;
        if (e.target.value === "private" && sellerInfo.type === "company" && showInput["type"] === true) {
            setShowCompanyTypeChangeModal(true);
        }

        const localInfoFieldsCopy = {...localInfoFields};
        localInfoFieldsCopy.type = e.target.value;
        setLocalInfoFields(localInfoFieldsCopy);
        setType(e.target.value); //added to display the selected value
    };

    return (
        <select className="w-100"
                value={type}
                onChange={e => handleSelectChange(e)}>
            {Object.keys(juridicalStatus_d).map(s => {
                console.log("SellerInfoSelect map", s)
                return <option
                    value={s}
                    key={s}>
                    {juridicalStatus_d[s].title}
                </option>
            })}
        </select>
    );
};
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import Backend from 'i18next-http-backend';
import {selectLanguageFromLocaleMap} from "../Utils/LanguageUtils";
import {updateFontFamily} from "../../Shared/helpers/fontHelper";

function getInitialLanguage() {
    console.log('getInitialLanguage in i18n');
    const userSelectedLanguage = localStorage.getItem('user-selected-language');
    if (userSelectedLanguage) {
        updateFontFamily(userSelectedLanguage);
        return userSelectedLanguage;
    }
    if (userSelectedLanguage) {
        updateFontFamily(userSelectedLanguage);
        return userSelectedLanguage;
    }

    const hostname = window.location.hostname;
    const tld = hostname.split('.').pop();

    let matchingLanguage;
    switch (tld) {
        case 'eu':
            matchingLanguage = 'en';
            break;
        case 'ee':
            matchingLanguage = 'ee';
            break;
        case 'fi':
            matchingLanguage = 'fi';
            break;
        case 'lv':
            matchingLanguage = 'lv';
            break;
        case 'lt':
            matchingLanguage = 'lt';
            break;
        case 'se':
            matchingLanguage = 'se';
            break;
        default:
            const lang = navigator.language;
            matchingLanguage = selectLanguageFromLocaleMap(lang);
            break;
    }

    updateFontFamily(matchingLanguage);
    return matchingLanguage;
}


i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        debug: false,
        lng: getInitialLanguage(),  // set the language on initialization
        // lng: null, //'en'
        fallbackLng: 'en',
        whitelist: ['en', 'ru', 'ee', 'lt', 'lv', 'fi', 'se'],
        ns: ['texts', 'data-for-ui', 'locations', 'characteristics', 'support', 'errors', 'extended-dictionary'],
        defaultNS:
            'texts',
        fallbackNS:
            'texts',

        interpolation:
            {
                escapeValue: false
            }
        ,

        backend: {
            loadPath: '../locales/{{lng}}/{{ns}}.json',
        }
        ,
    })
;

export default i18n;
import React from 'react';
import Heading from "../../../Search/SearchArea/Heading";
import OptionSelectOld from "../../OptionSelect/OptionSelectOld";

const getTitleFromJSON = (json, key) => {
    console.log('getTitleFromJSON', key, json)

    const keys = Object.keys(json);
    // console.log('keys', keys, 'key', key);
    if (key !== undefined) {
        const found = keys.find(k => key.toLowerCase().includes(k.toLowerCase()));
        // console.log('substring found:', found);

        // key.indexOf()
        if (found !== undefined && json[found] !== undefined) {
            // console.log('key.replace(found):', key.replace(found, json[found].title));
            return key.replace(found, json[found].title);
        }
        return key;
    }
}

const InputFieldGeneration = props => {
    console.log('InputFieldGeneration props', props);

    let numberClass = 'row mt-2 numbered-list-item'
    if (props.state.generation === undefined && props.prevField !== undefined) numberClass += ' yellow';
    else if (props.prevField === undefined) numberClass += ' grey'

    return <div className={numberClass} id='row-generation'>

        <div className='offset-1'/>
        <div className='col-9 pl-0'>
            {/*<div style={{marginLeft: '1em'}}>*/}
            <OptionSelectOld
                id='select-generation'
                options={props.state.generations
                    .map(generation => {
                        return {
                            label: getTitleFromJSON(props.generation, generation.generationName),
                            value: generation
                        }
                    })}
                selected={props.state.generation !== undefined && getTitleFromJSON(
                    props.generation, props.state.generation.generationName)
                }
                handler={props.updateGeneration}
                isDisabled={props.prevField === undefined}
            >
                <Heading>{props.title}</Heading>
            </OptionSelectOld>
            {/*</div>*/}
        </div>
    </div>
}

export default InputFieldGeneration;
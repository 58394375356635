import React from 'react';
import {Input} from "../../Input";
import Heading from "../../../Search/SearchArea/Heading";

const InputFieldDisplacement = props => {

    let numberClass = 'row mt-2 numbered-list-item'
    if (props.state.displacement === undefined && props.prevField !== undefined) numberClass += ' yellow';
    else if (props.prevField === undefined) numberClass += ' grey'

    if (props.state.entryMode !== 'automatic' ||
        (props.state.fuel !== undefined && props.state.fuel !== 'electric')) {
        return <div className={numberClass} id='row-displacement'>
            {/*<NumberOfStep thisField={props.state.displacement}*/}
            {/*              prevField={props.prevField}>*/}
            {/*    {props.step}*/}
            {/*</NumberOfStep>*/}
            <div className='offset-1'/>
            <div className='col-9 pl-0'>
                <Input
                    key='input-displacement'
                    value={props.state.displacement}
                    disabled={props.prevField === undefined}
                    handler={props.updateDisplacement}
                    placeholder={'Type'}
                    maxLength='4'
                    minLength='3'
                    type='number'
                >
                    <div style={{marginLeft: '1em'}}>
                        <Heading>{props.title}</Heading>
                    </div>
                </Input>
            </div>
        </div>
    } else return null;
};

export default InputFieldDisplacement;
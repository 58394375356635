import React, {useEffect, useState} from 'react';
import OptionSelect from "../../OptionSelect/OptionSelect";
import Heading from "../../../Search/SearchArea/Heading";
import _ from "lodash";

const InputFieldLocation = props => {
    console.log('InputFieldLocation at Sell', props);

    const countries = props.countries;

    let cities = [];
    if (props.state.country !== undefined) {
        const matchingCountry = countries.find(el => el.Name === props.state.country);
        console.log('matchingCountry.cities', countries, props.state.country);
        cities = matchingCountry.cities.map(c => {
            return {
                Name: c.Name,
                Country: c.PartitionKey,
                Population: c.Population,
                title: c.title
            }
        });
        cities = _.orderBy(cities, 'Population', 'desc');
        console.log('sorted cities', cities);
    }

    const [selectedCountry, setSelectCountry] = useState(undefined);
    const [selectedCity, setSelectCity] = useState(undefined);

    useEffect(() => {
            if (props.state.country !== undefined) {
                const country = _.find(countries, o => o.Name === props.state.country);
                console.log('useEffect location', country, countries, props.state.country);
                setSelectCountry(country.title);
                setSelectCity(undefined);
            }
            if (props.state.city !== undefined) {
                const city = _.find(cities, o => o.Name === props.state.city);
                console.log('useEffect location', city, cities, props.state.city);
                setSelectCity(city.title);
            }
        }


        , [props.state.country, props.state.city]);


    let numberClass = 'row mt-2 numbered-list-item'
    if (props.state.country === undefined && props.prevField !== undefined) numberClass += ' yellow';
    else if (props.prevField === undefined) numberClass += ' grey';
    else if (props.state.city === undefined && props.state.country !== undefined) numberClass += ' halfgreen';

    return <div className={numberClass}>

        <div className='offset-1'/>
        <div className='col-9 pl-0'>
            <div className='col-12 px-0'>
                <OptionSelect
                    className={'Option-Select'}
                    id='select-country'
                    options={countries.map(country => {
                        // console.log('country', country);
                        return {value: country.Name, label: country.title, code: country.IsoCode}
                    })}
                    selected={selectedCountry}
                    handler={props.updateCountry}
                    isDisabled={props.prevField === undefined}
                >
                    <Heading>{props.countryTitle}</Heading>
                </OptionSelect>
            </div>
            <div className='col-12 px-0'>
                <OptionSelect
                    className={'Option-Select'}
                    id='select-city'
                    options={cities.map(city => {
                        return {value: city.Name, label: city.title}
                    })}
                    selected={selectedCity}
                    handler={props.updateCity}
                    isDisabled={props.prevField === undefined}
                >
                    <Heading>{props.cityTitle}</Heading>
                </OptionSelect>
            </div>
        </div>
        {/*<div className='offset-8'></div>*/}
    </div>
};
export default InputFieldLocation;

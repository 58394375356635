import React, {useEffect, useState} from 'react';
import Heading from "../../../Search/SearchArea/Heading";
import {ShowPhotosModal} from "./Modal/ShowPhotosModal";
import {buyButtonStyle} from "../../Pages/MyOffers/functions/offersFunctions";
import _ from "lodash";
import {InputFile2} from "../../Input/FileInput/InputFile2";
import {ErrorMessageBlock} from "../ErrorMessages/ErrorMessageBlock";
import {Row} from "react-bootstrap";
import {translateErrorMessage} from "../../../../Integration/Utils/StorageUtils";
import {useTranslation} from "react-i18next";

const InputFieldImages = props => {

    const {t} = useTranslation('errors');

    const [showModal, setShowModal] = useState(false);
    const handleClose = () => {
        console.log('InputFieldImages handleClose');
        setShowModal(false);
    }

    let numberClass = 'row mt-2 numbered-list-item'
    if (props.state.files.length === 0 && props.prevField !== undefined) numberClass += ' yellow';
    else if (props.prevField === undefined && props.state.files.length === 0) numberClass += ' grey'

    console.log('InputFieldImages props', props);


    const [errorMessage, setErrorMessage] = useState(undefined);

    useEffect(() => {
        if (props.state.errors.files !== undefined) {

            //filter unique status codes
            const uniqueFileErrors = _.uniqWith(props.state.errors.files, _.isEqual);
            console.log('InputFieldImages useEffect unique statusObjects', uniqueFileErrors);
            const translatedErrors =
                _.join(
                    _.map(uniqueFileErrors, fileError => translateErrorMessage(fileError.status, t)),
                    '\n'
                );

            console.log('InputFieldImages useEffect translatedErrors', translatedErrors);

            setErrorMessage(translatedErrors);
            console.log('InputFieldImages useEffect set error message', props.state.errors.files,
                'message', translatedErrors);
        } else {
            setErrorMessage(undefined);
            console.log('InputFieldImages useEffect reset error message', props.state.errors.files);
        }

    }, [props.state.errors.files]);

    return <div>
        <div id='upload-files' className={numberClass}>
            <div className='offset-1'/>
            <div className='col-9 h-100 pl-0'>

                {/**Upload and compress files*/}
                <InputFile2
                    files={props.state.files}
                    disabled={_.isEmpty(props.state.make)}
                    handler={props.updateFileInput}
                    updateSellErrors={props.updateSellErrors}
                    stateErrors={props.state.errors}
                    placeholder={'Type'}>
                    <div style={{marginLeft: '1em'}}>
                        <Heading>{props.title}</Heading>
                    </div>
                </InputFile2>
                {!_.isEmpty(props.state.files) &&
                    <div id='uploaded-files'
                         onClick={() => setShowModal(true)}
                         className='w-100 d-flex justify-content-center align-items-center mr-0'
                         style={{
                             ...buyButtonStyle, color: '#222', border: '1px solid #ccc',
                             height: '32px', borderRadius: '4px'
                         }}>
                <span className='d-flex align-items-center'>
                    {props.message} ({props.state.files.length})
                </span>
                    </div>
                }


                {props.state.files.length > 0 && !_.isEmpty(props.state.errors) &&
                    /**Error Message Block */
                    <Row>
                        <ErrorMessageBlock errorMessage={errorMessage}/>
                    </Row>
                }

            </div>
        </div>

        <ShowPhotosModal handleClose={handleClose}
                         car={props.state}
                         updateFileInput={props.updateFileInput}
                         show={showModal}
                         updateSellErrors={props.updateSellErrors}
                         sellErrors={props.state.errors}

        />
    </div>
};

export default InputFieldImages;

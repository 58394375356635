import React from 'react';
import './toggle.css';

const Toggle = props => {

    console.log('Toggle props', props);

    return <div>
        <label className="switch">
            <input type="checkbox" checked={props.autoComplete}
                   onChange={() => {
                       props.setAutoComplete(!props.autoComplete);
                       props.sellClear();
                       if (!props.autoComplete) {
                           props.updateEntryMode('automatic');
                       } else {
                           props.updateEntryMode('manual');
                       }
                   }}/>
            <span className="slider round"/>
        </label>
        <span className='toggle-label'>
            {props.autoComplete ? props.onMessage : props.offMessage}</span>
    </div>
}

export default Toggle;
import _ from 'lodash';

const rootApiURI = process.env.REACT_APP_ROOT_API_URI;
const metadataApiKey = process.env.REACT_APP_METADATA_API_KEY;
const apiKeyName = process.env.REACT_APP_OCP_API_NAME;

class AzureModelsRESTConnector {

    retrieveModelNames = async makeID => {
        // const response = await _retrieveModelsByStartYear(makeID, 1985);
        const response = await _retrieveModelsSimple(makeID);
        const models = _filterModelNames(response.value);
        console.log('filtered unique model names', models);
        return models;
    }

    retrieveModelsByID = async (makeID, year) => {
        let models = [];
        let response;
        if (year === undefined) { //manual
            // response = await _retrieveModelsByStartYear(makeID, 1985);
            response = await _retrieveModelsSimple(makeID);
            console.log('retrieveModelsByStartYear response value', response.value);
            models = _processModelsSimple(response.value);
            console.log('filtered unique model names', models);
        } else {
            response = await _retrieveModelsWithinYearRange(makeID, year); //automatic search
            if (response.value !== undefined) {
                models = _processModelsDN(response.value);
                if (models.length === 0) {
                    response = await _retrieveModelsSimple(makeID);//not in the DB
                    models = _processModelsDN(response.value);
                }

            } else console.warn('response value is undefined')
        }
        console.log('models', models);
        if (models.length > 0) return models
            .sort((a, b) => a.name.localeCompare(b.name)) //todo sort by rating
            .sort((a, b) => a.rating - b.rating); //todo sort by rating
        return models;
    }

}

const _retrieveModelsSimple = async makeID => {
    console.log('_retrieveModelsSimple');
    const response = await fetch(`${rootApiURI}/metadata/makes/${makeID}/models`, {
        headers: {[apiKeyName]: metadataApiKey}
    })
    return await response.json();
}

const _retrieveModelsByStartYear = async (makeID, year) => { // remove?
    console.log('_retrieveModelsByStartYear', makeID, year);
    // const response = await fetch(`https://api-mgnt-2.azure-api.net/metadata/makes/${makeID}/models?startyear=${year}`, {
    const response = await fetch(`${rootApiURI}/metadata/makes/${makeID}/models/${year}`, {
        headers: {[apiKeyName]: metadataApiKey}
    });
    return await response.json();
}

const _retrieveModelsWithinYearRange = async (makeID, year) => {
    console.log('_retrieveModelsWithinYearRange', makeID, year);
    const response = await fetch(`${rootApiURI}/metadata/makes/${makeID}/models?year=${year}`, {
        headers: {[apiKeyName]: metadataApiKey}
    });
    return await response.json();
}

const _filterModelNames = models => {
    console.log('_filterModelNames\nraw models', models);
    const json = models.map(e => ({name: e.ModelName, rating: e.ModelRating}))
    const g = _.groupBy(json, 'name');
    console.log('groupBy: ', g);
    const sel = _.map(g, el => {
        const sorted = _.sortBy(el, 'name');
        return sorted[0];
    })
    const selected = _.sortBy(sel, 'rating');
    console.log('_filterModelNames sorted: ', selected);
    return selected;
}

const _processModelsSimple = models => {
    return models.map(el => ({
        name: el.ModelName,
        id: el.ModelID,
        rating: el.ModelRating,
        makeID: el.PartitionKey,
        generationID: undefined,
        generationName: undefined,
        yearStart: undefined,
        yearEnd: undefined
    }));
}
const _processModelsDN = models => {

    return models.map(el => ({
        name: el.ModelName,
        id: el.ModelID,
        rating: el.ModelRating,
        makeID: el.PartitionKey,
        generationID: el.RowKey,
        generationName: el.GenerationName,
        yearStart: el.YearStart,
        yearEnd: el.YearEnd
    }));
}

export {AzureModelsRESTConnector}
import React from 'react';
import * as LanguageUtils from '../../../../Integration/Utils/LanguageUtils';
import {calculatePriceWithoutVat, calculateVatFromGross} from "../../../Sell/Fields/Price/priceHelperFunctions";

const getAttribute = (title, value, side, fontSize) => {

    const colClass = 'col-6 d-flex justify-content-between' + (side === 'left' ? ' pl-0' : ' pr-0')
    const FieldSubColClass = 'col-6 justify-content-start' + (side === 'left' ? ' pl-0' : ' pr-0');
    const ValueSubColClass = 'col-6 d-flex justify-content-end pr-0';

    // let font = {fontSize: '1rem'};
    // if (fontSize !== undefined) font = fontSize;

    return <div className={colClass}>
        <div className={FieldSubColClass}>
            <span className="text-left">{title}</span>
        </div>
        <div className={ValueSubColClass}>
            <span className="text-right font-weight-bold text-capitalize">{value}</span>
        </div>
    </div>;
};

const getAttributePrice = (title, priceValue, vatPercentage, netTitle) => {

    const colClass = 'col-6 d-flex justify-content-between pl-0';
    const FieldSubColClass = 'col-3 justify-content-start pl-0';
    const ValueSubColClass = 'col-9 row d-flex justify-content-end pl-0';

    // let font = {fontSize: '1rem'};
    // if (fontSize !== undefined) font = fontSize;

    const netPrice = Number.parseInt(calculatePriceWithoutVat(priceValue, vatPercentage))
        .toLocaleString('ru-RU');

    console.log('getAttibutePrice', priceValue, vatPercentage, 'typeof netPrice', typeof netPrice, netPrice);

    return <div className={colClass}>
        <div className={FieldSubColClass}>
            <span className="text-left">{title}</span>
        </div>
        <div className={ValueSubColClass}>
            <ul className='list-unstyled my-0'>
                <li className="text-right font-weight-bold text-capitalize">
                    {priceValue.toLocaleString('ru-RU')}</li>
                {vatPercentage > 0 && <li className="text-right text-capitalize"
                                          style={{color: '#888'}}>
                    <span style={{marginRight: '.3rem'}}>{netTitle}</span>
                    <span>{netPrice}</span>
                </li>}
            </ul>
        </div>
    </div>;
};

const getAttributeVAT = (title, priceValue, vatPercentage, taxTitle) => {

    const colClass = 'col-6 d-flex justify-content-between pr-0';
    const FieldSubColClass = 'col-3 justify-content-start pr-0';
    const ValueSubColClass = 'col-9 row d-flex justify-content-end pl-0';

    // let font = {fontSize: '1rem'};
    // if (fontSize !== undefined) font = fontSize;

    const vat = Number.parseInt(calculateVatFromGross(priceValue, vatPercentage))
        .toLocaleString('ru-RU');

    console.log('getAttibuteVat', priceValue, vatPercentage, 'typeof netPrice', typeof vat, vat);

    return <div className={colClass}>
        <div className={FieldSubColClass}>
            <span className="text-left">{title}</span>
        </div>
        <div className={ValueSubColClass}>
            <ul className='list-unstyled my-0'>
                <li className="text-right font-weight-bold text-capitalize">
                    {vatPercentage}{'%'}</li>
                {vatPercentage > 0 && <li className="text-right text-capitalize"
                                          style={{color: '#888'}}>
                    <span style={{marginRight: '.3rem'}}>{taxTitle}</span>
                    <span>{vat}</span>
                </li>}
            </ul>
        </div>
    </div>;
};

const getAttributeWide = (title, value) => {
    return <div id={'vehicle-information-' + title.toLowerCase()}
                className="col-12 d-flex justify-content-between px-0"
        // style={{fontSize: '1rem'}}
    >
        <span className="text-left col-4 pl-0 ml-0">{title}</span>
        <span className="text-right font-weight-bold text-capitalize col-8 px-0 mx-0">
                                {value}
                            </span>
    </div>
}

const getAttributeComment = (title, value) => {
    if (value !== undefined && value !== null) return <div className="col-12 px-0"
        // style={{fontSize: '1rem'}}
    >
        <div className="col-6 justify-content-start pl-0 ml-0 font-weight-bold ">{title}</div>
        <div className="col-12 justify-content-start px-0 mx-0 mt-3 pb-2"
             style={{whiteSpace: 'pre-line'}}>
            {value}
        </div>
        <div className='border-bottom' style={{width: '7em'}}/>
    </div>
}

const getAttributeAsList = (title, list, dictionary, list2, side, emptyMessage) => {
    // let length = list.length;
    // if (list2.length > list.length) length = list2.length;
    // const diff = length - list.length;
    // if (side === 'right') {
    //     for (let i = 0; i < diff; i++) {
    //         list.push(String.fromCharCode(155));
    //     }
    // }

    let col0Style = 'col-6 mx-0 ';
    let col1Style = 'row col-12 mx-0 ';
    let col2Style = 'row col-12 mx-0 ';
    if (side === 'left') {
        col0Style += 'pl-0'
        col1Style += 'pl-0';
        col2Style += 'pl-0';
    } else if (side === 'right') {
        col0Style += 'pr-0';
        col1Style += 'pr-0';
        col2Style += 'pr-0';
    }

    return <>
        <div className={col0Style}
            // style={{fontSize: '1rem'}}
        >
            <div className={col1Style}>
                <span className="text-left pl-0 ml-0 font-weight-bold">{title}</span>
            </div>
            <div className={col2Style}>
                <div className="text-left w-100">
                    {list.length > 0 ? <>
                            <ul className='list-unstyled '
                                // style={{listStyleType: 'square'}}
                            >
                                {list.map((item, index) => {
                                    // console.log('list item', item, index);
                                    return <li key={item + index}
                                        // style={{minHeight:'17px',height:'17px',lineHeight:'17px'}}
                                    >
                                        <div className="py-2 w-100 mx-0 px-0">
                                            {LanguageUtils.getTitleFromDictionary(item, dictionary)}
                                        </div>
                                        <div className='border-bottom' style={{width: '7em'}}/>
                                    </li>
                                })}
                            </ul>
                        </>
                        : <div className='py-3' style={{color: 'rgba(0,0,0,.5)'}}>
                            <span className=''>{emptyMessage}</span></div>
                    }
                </div>
            </div>
        </div>
    </>
}
export {
    getAttribute, getAttributeWide, getAttributeAsList, getAttributeComment, getAttributePrice,
    getAttributeVAT
};
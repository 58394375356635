import _ from 'lodash';
import {initialOptions} from "../../Data/State/initialState";

class SearchUtils {
    createSearchString = options => {
        console.log('SearchUtils.createSearchString options', options);

        let strings = [];
        const arrays = [];
        const ranges = [];

        let value = undefined;
        Object.entries(options).map(
            entry => {
                let key = entry[0];
                // console.log('SearchUtils search key', key)

                /**data type 1 (JSON, value: string): transmission: {value: "automatic", visible: true}*/

                if (Array.isArray(entry[1]) && entry[1].length > 0) { //data type 3: arrays - multi-select
                    value = entry[1];
                    value = value.filter(el => el.visible).map(el => {
                        if (key === 'drivetrain') {
                            console.warn('key === drivetrain')
                            if (el.value === 'all wheel drive') el.value = 'full';
                            else if (el.value === 'front wheel drive') el.value = 'front';
                            else if (el.value === 'rear wheel drive') el.value = 'rear';
                        }
                        return el.value;
                    });
                    console.log('SearchUtils createSearchString push to arrays (1)', key, value);

                    arrays.push({[key]: value})
                }
                /**data type 2 (JSON, value: array(1); range) price{value:[0,100],visible:true}*/
                else if (typeof entry[1].value === 'string' && entry[1].visible) { //data type 1 - string
                    value = entry[1].value.toLowerCase();
                    if (key === 'make') key = 'PartitionKey';


                    console.log('SearchUtils createSearchString push to strings (2)', key, value);
                    if (key === 'ignoreSponsored') {
                        console.log('SearchUtils key === ignoreSponsored, skipping', key);
                    } else strings.push({[key]: value});


                }

                /**data type 3 (Array, el => JSON(value,visible) features
                 * [0: {value: "navigation", visible: true} 1: {value: "leather", visible: true}]*/
                else if (typeof entry[1].value === 'object' && entry[1].visible) { //data type 2: range
                    value = entry[1].value;
                    const limits = initialOptions[key].value;

                    if (value[0] !== limits[0] || value[1] !== limits[1]) {
                        ranges.push({[key]: value});
                        console.log('SearchUtils: Range added to ranges', key, value);
                    } else {
                        console.log('SearchUtils: Skipping range', key, value);
                    }


                }

                /**data type 4 (JSON, value: string, model:JSON)*/
                else if (typeof entry[1].value === 'string' //data type 4: nested object
                    && entry[1]?.model?.value !== undefined) {
                    console.log('SearchUtils createSearchString push to strings (4)', key, 'data type is nested object - 4 ', entry[1]);
                    // nested.make = entry[1].value;
                    // nested.model = entry[1].model.value;
                    if (entry[1].value !== undefined && entry[1].value !== 'any') {
                        strings.push({PartitionKey: entry[1].value});//save make
                        // strings.push({make: entry[1].value});//save make
                        if (entry[1].model.visible)
                            strings.push({model: entry[1].model.value.toLowerCase()})//save model
                    }
                }
                // console.log('returning search string:',[entry[0]]);
                return [entry[0]];
            }
        );
        console.log('SearchUtils strings', strings);
        console.log('SearchUtils arrays', arrays);
        console.log('SearchUtils ranges', ranges);
        // console.log('nested', nested);

        const filtersPrefix = '&$filter=';
        const countPrefix = '&$count=true';
        let filters = '';

        filters = this._handleStrings(strings, filters);
        filters = this._handleRanges(ranges, filters);
        filters = this._handleArrays(arrays, filters);


        const filtersApplied = countPrefix + filtersPrefix + filters;
        const noFilters = countPrefix;
        // console.log('SearchUtils >> searchString\n', filters.length > 0 ? filtersApplied : noFilters);
        return filters.length > 0 ? filtersApplied : noFilters;
    };

    _handleStrings(strings, filters) {
        strings.forEach((json, index) => {
                Object.entries(json).forEach(entry => {
                    const key = entry[0];
                    const value = entry[1];

                    //handling transmission
                    if (key === 'transmission') {
                        console.log('SearchUtils: transmission found', key, value);
                        if (value === 'automatic') {
                            filters += `transmission ne 'manual'`;
                            return;
                        } else if (value === 'manual') {
                            filters += `transmission eq 'manual'`;
                            return;
                        }
                    }

                    if (key === 'fuel') {
                        console.log('SearchUtils: fuel found', key, value);
                        if (value === 'gasoline' || value === 'petrol') {
                            filters += `(fuel eq 'gasoline' or fuel eq 'petrol')`;
                            return;
                        }
                        if (value === 'electro' || value === 'electric') {
                            filters += `(fuel eq 'electro' or fuel eq 'electric')`;
                            return;
                        }
                    }

                    //handling drivetrain
                    if (key === 'drivetrain') {
                        console.log('SearchUtils: drivetrain found', key, value);
                        if (value === 'full') {
                            filters += `(drivetrain ne 'front' and drivetrain ne 'front wheel drive' and drivetrain ne 'rear' and drivetrain ne 'rear wheel drive')`;
                            return;
                        } else if (value === 'front') {
                            filters += `(drivetrain eq 'front' or drivetrain eq 'front wheel drive')`;
                            return;
                        } else if (value === 'rear') {
                            filters += `(drivetrain eq 'rear' or drivetrain eq 'rear wheel drive')`;
                            return;
                        }
                    }

                    //handling all other entries
                    if (value.indexOf('/') !== -1) {
                        const values = value.split('/');
                        console.log('multiple values found', values);
                        values.forEach((v, index) => {
                            const trimmed = _.trim(v);
                            if (index === 0) filters += '(';
                            filters += `${key} eq '${trimmed}'`;
                            if (index < values.length - 1) filters += ' or ';
                            if (index === values.length - 1) filters += ') '
                        })
                        // filters += `${key} eq '${value}'`;
                    } else filters += `${key} eq '${value}'`;

                });
                if (strings.length > 1 && index < strings.length - 1) filters += ' and ';
            }
        );
        console.log('SearchUtils: filters created from strings: ', filters);
        return filters;
    }

    _handleRanges(ranges, filters) {
        /**Handle ranges*/
        ranges.forEach((range, rangeIndex) => {
                const array = Object.entries(range)[0];
                const key = array[0];
                const val1 = array[1][0];
                const val2 = array[1][1];
                console.log('SearchUtils: range array\n', array, key, val1, val2, 'rangeIndex', rangeIndex);
                let str = '';

                //compare initialOptions and actual
                // if there is only val1 use gt;
                // if there is only val2 use lt
                // if there are both use gt and le
                const limits = initialOptions[key].value;
                console.log('SearchUtils range limits and values', limits, val1, val2);
                if (val1 === limits[0] && val2 !== limits[1]) { //val1 default, val2 non-default
                    str = `(${key} lt ${val2})`;
                    console.log('SearchUtils range value[0]===limits[0]', val2, limits, str);
                } else if (val1 !== limits[0] && val2 === limits[1]) { //val1 non default, val2 default
                    str = `(${key} gt ${val1})`;
                    console.log('SearchUtils range value[1]===limits[1]', val1, limits, str);
                } else if (val1 !== limits[0] && val2 !== limits[1]) {
                    str = `(${key} ge ${val1} and ${key} le ${val2})`;
                    console.log('SearchUtils range value!==limits, both values within range:', val1, val2, str);
                }

                //if there are filters and this is the first range
                if (filters.length > 0 && rangeIndex === 0) {
                    console.log('SearchUtils: there are filters and this is the first range', ranges, rangeIndex, str);
                    filters += ' and ';
                }

                //if there are more than one range and this is not the last range
                if (ranges.length > 1 && rangeIndex < ranges.length - 1) {
                    str += ' and ';
                    console.log('SearchUtils: are more than one range and this is not the last range\n', 'ranges', ranges,
                        '\nrangeIndex', rangeIndex, '\nstr', str);
                }

                filters += str;
                console.log('SearchUtils: range string created:', str, 'filters:', filters);
            }
        );
        return filters;
    }

    _handleArrays(arrays, filters) {
        console.log('SearchUtils arrays', arrays);
        arrays.forEach((json, index) => {
            const array = Object.entries(json)[0];
            let key = array[0];
            if (key === 'features') key = 'options';
            // console.log('arrays:',array,key)
            const val = array[1];
            // console.log('array:', key, val);
            if (filters.length > 1 && index === 0) filters += ' and ';
            let str = '';

            /** Handling colors */
            if (key === 'color') str = '(';

            val.forEach((el, i) => {
                if (key === 'options' || key === 'history') str += `${key}/any(t: t eq '${el}')`;
                else str += `${key} eq '${el}'`;
                if (el.length > 0 && i < val.length - 1) {
                    if (key === 'options' || key === 'history') str += ' and ';
                    else str += ' or ';
                }
                // console.warn('>< str', str);
            });
            if (arrays.length > 1 && index < arrays.length - 1) str += ' and ';

            /** Handling colors */
            if (key === 'color') str += ')';

            /** Adding string to filter*/
            filters += str;
            console.log('SearchUtils: array string created:', str, '\nfilters:', filters);
        });
        return filters;
    }

    generateTop = items => {
        return `&$top=${items}`;
    };

    generateSkip = (activePageIndex, itemsPerPage) => {
        //active = 0, count =2, items =1: $skip=0
        //active = 1, count 2, items =1: $skip=1 (active*items)=(1*1)
        //active = 0, count = 200; items =10: $skip = ( active*items)=0*10=0
        //active = 4, count = 200; items = 10: $skip = (4*10)=40

        const skip = `&$skip=${activePageIndex * itemsPerPage}`;
        console.log('generate skip', skip);
        if (activePageIndex !== undefined) return skip;
        return '';
    }


    generateSort = (sort, ignoredSponsored) => {
        console.log('SearchUtils.generateSort', 'sort', sort, 'ignoredSponsored', ignoredSponsored);
        //sort ok, ignoreSponsored undefined
        if (sort !== 'undefined' && ignoredSponsored.value !== 'true') {
            const ordering = '&$orderby=rating desc,' + sort.replace('-', ' ');

            console.log('SearchUtils.generateSort 1: sort ok, ignoreSponsored false', ordering);
            return ordering;
        }
        //sort undefined, ignoreSponsored undefined
        else if (sort === 'undefined' && ignoredSponsored !== 'true') {
            console.log('SearchUtils.generateSort 2: sort undefined, ignoreSponsored false');
            return '&$orderby=rating desc'
        }
        //sort ok, ignoreSponsored true
        else if (sort !== 'undefined' && ignoredSponsored.value === 'true') {
            const ordering = '&$orderby=' + sort.replace('-', ' ');
            console.log('SearchUtils.generateSort 3: sort ok, ignoreSponsored true', ordering);
            return ordering;
        }
        //sort undefined, ignoreSponsored true
        else if (sort === 'undefined' && ignoredSponsored.value === 'true') {
            console.log('SearchUtils.generateSort 4: sort undefined, ignoreSponsored true');
            return '';
        }
        //other cases
        else {
            console.log('SearchUtils.generateSort 5: other cases; sort', sort,
                'ignoredSponsored', ignoredSponsored);
            return '';
        }
    }


    generateFacets = query => {
        // console.log('generateFacets');
        if (query.indexOf('&$filter=') === -1)
            return '&facet=city,count:20 &facet=model,count:60';
        return '';

    }
}

export default SearchUtils;
import React, {useEffect, useState} from 'react';
import Heading from "../../../Search/SearchArea/Heading";
import Select from "react-select";
import _ from "lodash";

const InputFieldColor = props => {

    console.log('InputFieldColor', props);

    const colors = props.colors;
    // console.log('extColors', colors)
    // const [colorsOptions, setColorsOptions] = useState({});

    const generateColorsOptions = () => Object.keys(colors).map(key => {
        console.log('setColorsOptions')
        return {
            value: key, label: <div className='d-inline-flex'>
                <div style={{
                    ...colorDivStyle,
                    background: colors[key].color,
                }}/>
                <span style={{marginLeft: '5px'}}>{colors[key].title}</span>
            </div>
        }
    });

    const [colorsOptions, setColorsOptions] = useState(generateColorsOptions());

    /**
     useEffect(() => {
     console.log('useEffect color: colors')
     // setColorsOptions(generateColorsOptions());
     }, []);
     */

    const [colorValue, setColorValue] = useState('');

    useEffect(() => {

        const selected = _.find(colorsOptions, function (o) {
            return o.value === props.state.color;
        });
        console.log('useEffect color', selected);
        if (props.state.color !== undefined) {
            setColorValue(selected);
        }


    }, [props.state.color])


    let numberClass = 'row mt-2 numbered-list-item'
    if (props.state.color === undefined && props.prevField !== undefined) numberClass += ' yellow';
    else if (props.prevField === undefined) numberClass += ' grey'

    return <div className={numberClass} id='row-color'>

        <div className='offset-1'/>
        <div className='col-9 pl-0'>
            <Heading style={{marginLeft: '0px'}}>{props.title}</Heading>
            <Select
                // key={props.state.color}
                className={'text-capitalize'}
                options={colorsOptions}
                defaultMenuIsOpen={false}
                isDisabled={props.prevField === undefined}
                style={{}}
                onChange={e => props.updateColor(e)}
                styles={customSelectStyle}
                value={colorValue !== undefined && colorValue} //?
                placeholder={props.placeholder}
                isSearchable={false}
            >
            </Select>
        </div>
    </div>
}

export default InputFieldColor;
// const style = {}
const colorDivStyle = {
    width: '24px',
    height: '24px',
    border: '1px solid #888',
    // zIndex: 22,
    borderRadius: '4px'
};

const customSelectStyle = {
    singleValue: base => ({
        ...base,
        display: 'flex'
    })
};
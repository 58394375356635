import {verifyMandatoryFields} from "../../../../../Integration/Utils/TableUtils";
import jwtDecode from "jwt-decode";

const getFilenameFromPath = path => path.substring(path.lastIndexOf('/') + 1)

const isReady = (car) => {

    try {
        const token = localStorage.getItem('token');
        const decoded = jwtDecode(token);
        console.log('isReady token decoded', decoded);

        const authenticatedUser = decoded.emails[0];
        console.log('isReady prepare for upload', car);
        const errorMap = new Map();
        const valid = verifyMandatoryFields(car, errorMap);
        if (valid && authenticatedUser === car.userID) {
            console.log('isReady user ok, can proceed', car.userID);
            return true;
        } else {
            console.log('isReady user not ok, cannot proceed', car.userID, 'authenticatedUser', authenticatedUser, 'valid',
                valid);
            return false;
        }
    } catch (e) {
        console.log('isReady error', e);
        return false;
    }
}

export {isReady, getFilenameFromPath};
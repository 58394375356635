import sha1 from 'crypto-js/sha1'

const hashUserID = userID => {
    const hash = sha1(userID);
    console.log('email as hash', hash.toString());
    return hash.toString().toLowerCase();
};

const isLandscapeOrientation = async file => {
    const url = URL.createObjectURL(file);
    try {
        const response = await fetch(url);
        const blob = await response.blob();
        const bitmap = await createImageBitmap(blob);
        return bitmap.width > bitmap.height;
    } finally {
        URL.revokeObjectURL(url);
    }
};

const validateFiles = async files => {
    // let errorMap = [];

    console.log('validateFiles files', files);
    //check if file is valid
    let errorMap = await Promise.all(files.map(async (file) => {
        console.log('validateFiles files.map...', file.name);
        if (file.size > 350 * 1024) {
            console.warn('validateFiles: Invalid file size:', file.name);
            return {
                status: {
                    "code": 413,
                    "message": 'invalid file size'
                }
            };
        }
        if (file.type !== 'image/jpeg') {
            console.warn('putBlob: Invalid file type:', file.name);
            return {
                status: {
                    "code": 415,
                    "message": 'invalid file type'
                }
            };
        }
        const isLandscape = await isLandscapeOrientation(file);
        if (!isLandscape) {
            console.warn('validateFiles: Invalid image orientation:', file.name);
            return {
                status: {
                    "code": 415,
                    "message": 'invalid image orientation'
                }
            };
        }
        //todo add maximum 5 files and overall size of 2MB
        console.log('validateFiles check image orientation: ', isLandscape);
    }));
    // console.log('validateFiles errorMap after validation  : errorMap', errorMap);

    //filter out all files that failed validation
    let filteredErrorMap = errorMap.filter((error) => {
        //if status code not empty and is 413 or 415 return true
        return error !== undefined && (error.status.code === 413 || error.status.code === 415);
    });

    console.log('validateFiles errorMap after filter  : errorMap', errorMap);
    return filteredErrorMap;
}

const translateErrorMessage = (statusObject, t) => {
    console.log('translateErrorMessage', statusObject);
    if (statusObject !== undefined) {
        if (statusObject.code === 413) return t('texts:sell.car-ad-card.edit-images.invalid-file-size');
        else if (statusObject.code === 415 && statusObject.message === 'invalid file type')
            return t('texts:sell.car-ad-card.edit-images.invalid-file-type');
        else if (statusObject.code === 415 && statusObject.message === 'invalid image orientation')
            return t('texts:sell.car-ad-card.edit-images.invalid-image-orientation')
    } else {
        console.log('translateErrorMessage: no errors, statusObject is undefined');
        return '';
    }
}

export {hashUserID, isLandscapeOrientation, validateFiles, translateErrorMessage}
import React, {useEffect, useState} from 'react';
import jwtDecode from "jwt-decode";
import {useMsal, useIsAuthenticated} from "@azure/msal-react";
import {useTranslation} from "react-i18next";
import LoginButton from "./MsalLogin/LoginButton";
import {MyAccount} from "./Pages";
import {initialState, testState} from "./Data/Data";
import {Nav} from "react-bootstrap";
import {
    AzureCharacteristicsRESTConnector, AzureModelsRESTConnector, AzureSellersRESTConnector,
    AzureAdvertisementsRESTConnector
} from "../../Integration";
import MyOffers from "./Pages/MyOffers/MyOffers";
import _ from "lodash";
import AddCar from "./Pages/AddCar/AddCar";
import {
    cleanUpFieldsAfterGeneration,
    cleanUpFieldsAfterMake,
    cleanUpFieldsAfterModel,
    cleanUpFieldsAfterModification,
    cleanUpFieldsAfterYear,
    processCharacteristicsFields, processFieldsOnCharacteristicsError,
    processModifications,
    processTrims,
    combineSellAndAppState
} from "./Utils/sellHelpers";
import {
    isCommentInRange, isDisplacementWithinRange,
    isMileageWithinRange, isModificationSimpleWithinRange, isPowerWithinRange,
    isPriceWithinRange,
    isVinWithinRange,
    isYearWithinRange
} from "./Utils/inputvalidators";
import {capitalize, checkIfNotEmpty, getDistinctElements} from "./Utils/utils";
import {hashUserID} from "../../Integration/Utils/StorageUtils";
import {AzureSeriesModificationRESTConnector} from "../../Integration/Tables/AzureSeriesModificationRESTConnector";
import {LoadingIndicator} from "../../Shared/FetchImage/LoadingIndicator";
import {verifyMandatoryFields, verifyTechnicalFields} from "../../Integration/Utils/TableUtils";


const Sell2 = props => {
    console.log('Sell2 props', props);

    const modelsRESTConnector = new AzureModelsRESTConnector();
    const seriesModificationsRESTConnector = new AzureSeriesModificationRESTConnector();
    const characteristicsRESTConnector = new AzureCharacteristicsRESTConnector();
    const sellersRESTConnector = new AzureSellersRESTConnector();
    const advertisementRESTConnector = new AzureAdvertisementsRESTConnector();
    const {instance, accounts} = useMsal();
    const isAuthenticated = useIsAuthenticated();

    //page selection
    const [selectedPage, setSelectedPage] = useState({page: ''});//myOffers,myAccount,addCar

    //change style of active tab
    const defaultNavStyles = {
        'myaccount': {color: '#888'},
        'myoffers': {color: '#888'},
        'addcar': {color: '#888'}
    }
    const [navStyle, setNavStyle] = useState(defaultNavStyles);

    const changeStyle = selectedTab => {
        const nn = {...defaultNavStyles};
        nn[selectedTab] = {color: 'red', borderBottom: '3px solid #888'}
        setNavStyle(nn);
    }

    const {t, i18n} = useTranslation(['characteristics', 'texts']);

    const [state, setState] = useState(initialState); //initialState //testState
    const [cars, setCars] = useState([]);
    const [sellerInfo, setSellerInfo] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);

    const setDefaultPage = (cars, sellerInfo) => {
        console.log('setDefaultPage', cars, sellerInfo);
        const isEmpty = _.isEmpty(sellerInfo);
        if (isEmpty) {
            setSelectedPage({page: 'myAccount'});
        } else if (cars.length === 0) {
            setSelectedPage({page: 'addCar'});
        } else {
            setSelectedPage({page: 'myOffers'});
        }
    }

    const handleTokenExpiration = async () => {
        console.log('handleTokenExpiration token expired; logging out!');
        window.localStorage.removeItem('token');
        window.sessionStorage.removeItem('sellerInfo');
        await instance.logout();
        window.alert('Your session has expired. Please log in again.');
    };

    useEffect(() => {
        console.log('Sell2: useEffect, acquire token. isAuthenticated', isAuthenticated);
        const fetchAccessToken = async () => {
            try {
                console.log('Sell2: acquireTokenSilent');
                const silentResult = await instance.acquireTokenSilent({
                    account: accounts[0],
                    scopes: [process.env.REACT_APP_MSAL_TOKEN_SCOPE]
                });
                const accessToken = silentResult.accessToken;
                localStorage.setItem('token', accessToken);
                const email = accounts[0].username;
                console.log('Sell2: Access email:', email, 'accessToken', accessToken);
                // props.updateIsLoggedIn(true, email);
                setIsLoading(false);
            } catch (error) {
                console.log('Sell2: Error acquiring token', error.message);
            }
        }

        async function getCarsAndSellerData() {
            console.log('user logged in, fetching cars in useEffect', props);
            const cars = await _fetchCars();
            const sellerInfo = await _fetchSeller(accounts[0].username);
            // console.log('useEffect getSellerAndCarInfo', cars, sellerInfo);
            setDefaultPage(cars, sellerInfo);
        }

        if (isAuthenticated) {
            fetchAccessToken().then(async () => {
                getCarsAndSellerData().then(r => {
                    const combined = combineSellAndAppState(props,
                        {...state, ...{email: accounts[0].username}});
                    setState(combined);
                    setIsLoading(false);
                });
            });
        }
    }, [isAuthenticated]);


    useEffect(() => {
        console.log('Sell2 useEffect: Logout on token expiration [isAuthenticated]', isAuthenticated,
            'localStorage.getItem(token)', localStorage.getItem('token'));
        const token = localStorage.getItem('token');
        setIsLoading(true);
        if (token) {
            const tokenObject = jwtDecode(token);
            const expirationTime = tokenObject.exp * 1000;
            const dateNow = Date.now();
            const timeUntilExpiration = expirationTime - dateNow;
            console.log('expirationTime', expirationTime, 'dateNow', dateNow, 'timeUntilExpiration',
                timeUntilExpiration);
            // const timeoutId = setTimeout(handleTokenExpiration, 5000);//testing logout after 5 seconds
            const timeoutId = setTimeout(handleTokenExpiration, timeUntilExpiration);
            return () => {
                clearTimeout(timeoutId);
            };
        }
        setIsLoading(false);
    }, [isAuthenticated, localStorage.getItem('token')]);

    useEffect(() => {
        console.log('Sell2: useEffect, location.pathname', selectedPage);
    }, [selectedPage]);

    const _fetchCars = async () => {
        const cars = await advertisementRESTConnector.retrieveUserAds(props.email);
        setCars(cars);
        console.log('my fetched cars', cars);
        return cars;
    }

    const _fetchSeller = async id => {
        console.log('_fetchSeller', id);
        const ID = hashUserID(id);
        const sellerInfo = await sellersRESTConnector.retrieveSellerInfo(ID.toString());
        if (!_.isEmpty(sellerInfo)) {
            console.log('window.sessionStorage.setItem', sellerInfo);
            window.sessionStorage.setItem('sellerInfo', JSON.stringify(sellerInfo));
        } else {
            console.log('window.sessionStorage.setItem is empty', sellerInfo);
        }
        return sellerInfo;
    };

    const updateSellMake = make => {
        const updatedState = _.clone(state);
        updatedState.make = {name: make.name, id: make.id};
        cleanUpFieldsAfterMake(updatedState);
        _updateStateMultiple(updatedState);
    }

    const updateSellYear = async year => {
        const updatedState = _.clone(state);
        cleanUpFieldsAfterYear(updatedState);
        if (isYearWithinRange) {
            updatedState.year = parseInt(year);
            updatedState.models = await modelsRESTConnector.retrieveModelsByID(updatedState.make.id, updatedState.year);
            _updateStateMultiple(updatedState);
        }
    }

    const updateSellYearSimple = async year => {
        const updatedState = _.clone(state);
        cleanUpFieldsAfterYear(updatedState);
        if (isYearWithinRange) {
            updatedState.year = parseInt(year);
            updatedState.models = await modelsRESTConnector.retrieveModelsByID(updatedState.make.id);
            _updateStateMultiple(updatedState);
        }
    }

    const updateSellModel = async model => {
        const updatedState = _.clone(state);
        cleanUpFieldsAfterModel(updatedState);
        updatedState.model = model;
        updatedState.generations = updatedState.models.filter(el => el.id === model.id);
        const trimsAndMods = await Promise.all(updatedState.generations.map(async el => {
            return await seriesModificationsRESTConnector.retrieveSeriesAndModification(el.generationID);
        }));
        updatedState.trims_and_modifications = _.flatten(trimsAndMods);

        if (updatedState.generations.length === 1) {
            updatedState.generation = updatedState.generations[0];
            let trims = processTrims(updatedState.trims_and_modifications, updatedState.generation);
            updatedState.trims = getDistinctElements(trims, 'name');
        }

        if (updatedState.trims.length === 1) {
            updatedState.trim = updatedState.trims[0];
            updatedState.modifications = processModifications(updatedState);
        }

        if (updatedState.modifications.length === 1) {
            updatedState.modification = updatedState.modifications[0];
            await processCharacteristics(updatedState.modifications[0], updatedState);
        }

        _updateStateMultiple(updatedState);
    }

    const updateSellGeneration = async generation => {
        const updatedState = _.clone(state);
        cleanUpFieldsAfterGeneration(updatedState);
        updatedState.generation = generation;

        let trims = processTrims(updatedState.trims_and_modifications, updatedState.generation);
        updatedState.trims = getDistinctElements(trims, 'name');

        //if 1 trim
        if (updatedState.trims.length === 1) {
            updatedState.trim = updatedState.trims[0];
            updatedState.modifications = processModifications(updatedState);
        }
        //if 1 mod
        if (updatedState.modifications.length === 1) {
            updatedState.modification = updatedState.modifications[0];
            await processCharacteristics(updatedState.modifications[0], updatedState);
        }

        _updateStateMultiple(updatedState);
    }

    const updateSellTrim = async trim => {
        const updatedState = _.clone(state);
        cleanUpFieldsAfterGeneration(updatedState);
        updatedState.trim = trim;
        updatedState.modifications = processModifications(updatedState);

        //if 1 mod
        if (updatedState.modifications.length === 1) {
            updatedState.modification = updatedState.modifications[0];
            await processCharacteristics(updatedState.modifications[0], updatedState);
        }
        _updateStateMultiple(updatedState);
    }

    const updateSellModification = async modification => {
        const updatedState = _.clone(state);
        cleanUpFieldsAfterModification(updatedState);
        updatedState.modification = modification;
        await processCharacteristics(modification, updatedState);
        _updateStateMultiple(updatedState);
        const errors = []
        verifyTechnicalFields(updatedState, errors);
        if (errors.length > 0) {
            console.log('updateSellModification errors', errors);
            updateCharacteristicsError(errors);
        }
    }

    async function processCharacteristics(modification, updatedState) {
        const response = await characteristicsRESTConnector.retrieveCharacteristicsByLanguage(
            modification.id, i18n.language);

        const translatedAttributes = {
            units: t('characteristics:units', {returnObjects: true}),
            values: t('characteristics:values', {returnObjects: true}),
            suspension: t('characteristics:values', {returnObjects: true})
        }

        console.log('response with characteristics', response);
        const containsData = checkIfNotEmpty(response, 'characteristics');
        if (containsData) {
            await processCharacteristicsFields(response, updatedState, translatedAttributes);
        } else {
            processFieldsOnCharacteristicsError(updatedState);
        }
    }

    const updateSellColor = (e) => {
        _updateState('color', e.value);
    }

    const updateSellMileage = mileage => {
        const number = parseInt(mileage);
        if (isMileageWithinRange(number)) {
            _updateState('mileage', mileage);
        } else if (Number.isNaN(number)) {
            _updateState('mileage', undefined);
        }
    };

    const updateSellPrice = price => {
        const number = parseInt(price);
        if (isPriceWithinRange(number)) {
            _updateState('price', number);
        } else if (Number.isNaN(number)) {
            _updateState('price', undefined);
        }
    };

    const updateSellVIN = vin => {
        if (isVinWithinRange) {
            _updateState('vin', vin.toUpperCase());
        }
    }

    const updateSellCountry = country => {
        _updateStateMultiple({country: country, city: undefined});
    }

    const updateSellCity = city => {
        _updateState('city', city);
    }

    const updateSellFileInput = files => {
        _updateState('files', [...files]);
    }

    const updateSellComment = comment => {
        if (isCommentInRange(comment)) {
            _updateState('comment', comment);
        }
    }

    const updateSellHistory = history => {
        _updateState('history', history);
    }

    const updateSellOptions = options => {
        _updateState('options', options);
    }

    const updateSellBlobPaths = paths => {
        _updateState('blobPaths', paths);
    }

    const updateSellLoadingComplete = isComplete => {
        _updateState('loadingComplete', isComplete);
    }

    const updateSellFuel = fuel => {
        _updateState('fuel', fuel);
    }

    const updateSellPower = power => {
        if (isPowerWithinRange(power)) {
            _updateState('power', power);
        } else if (Number.isNaN(power)) {
            _updateState('power', undefined);
        }
    };


    const updateSellTransmission = transmission => {
        _updateState('transmission', transmission);
    }

    const updateSellDrivetrain = drivetrain => {
        _updateState('drivetrain', drivetrain);
    }

    const updateSellDisplacement = cc => {
        if (isDisplacementWithinRange(cc)) {
            _updateState('displacement', cc);
        }
    }

    const sellClear = () => {
        const appState = props;
        const sellState = _.clone(initialState);
        sellState.email = accounts[0].username;
        const updatedState = combineSellAndAppState(appState, sellState);
        _updateStateMultiple(updatedState);
    }

    const refreshCars = () => {
        console.log('_refresh cars')
        _fetchCars();
    }

    const updateSellModificationSimple = modification => {
        if (isModificationSimpleWithinRange(modification)) {
            const capitalized = capitalize(modification);
            _updateState('modification', {name: capitalized});
        }
    };

    const updateSellBodyStyle = bodyStyle => {
        _updateState('bodyStyle', bodyStyle);
    }

    const updateVatIncluded = isIncluded => {
        _updateState('vatIncluded', isIncluded);
    }

    const updateEntryMode = entryMode => {
        console.log('Sell2 updateEntryMode', entryMode);
        _updateState('entryMode', entryMode);
    }
    const updateSellMakes = makes => {
        _updateState('makes', makes);
    }

    const _updateState = (property, value) => {
        setState(prevState => ({
            ...prevState,
            [property]: value
        }));
    };

    const _updateStateMultiple = (updates) => {
        setState(prevState => ({
            ...prevState,
            ...updates
        }));
    }

    const updateCharacteristicsError = (errors) => {
        console.log('updateCharacteristicsError', errors);
        _updateState('characteristicsError', errors);
    }

    const updateSellErrors = (errors) => {
        console.log('updateSellErrors errors:', errors);
        _updateStateMultiple({errors: errors});
    };


    const sellFunctions = {
        updateSellMake: updateSellMake,
        updateSellYear: updateSellYear,
        updateSellModel: updateSellModel,
        updateSellGeneration: updateSellGeneration,
        updateSellTrim: updateSellTrim,
        updateSellModification: updateSellModification,
        updateSellColor: updateSellColor,
        updateSellMileage: updateSellMileage,
        updateSellPrice: updateSellPrice,
        updateSellVIN: updateSellVIN,
        updateSellCountry: updateSellCountry,
        updateSellCity: updateSellCity,
        updateSellFileInput: updateSellFileInput,
        updateSellComment: updateSellComment,
        updateSellHistory: updateSellHistory,
        updateSellOptions: updateSellOptions,
        updateSellBlobPaths: updateSellBlobPaths,
        updateSellLoadingComplete: updateSellLoadingComplete,
        updateSellFuel: updateSellFuel,
        updateSellPower: updateSellPower,
        updateSellTransmission: updateSellTransmission,
        updateSellDrivetrain: updateSellDrivetrain,
        updateSellDisplacement: updateSellDisplacement,
        setSellSelectedPage: setSelectedPage,
        sellClear: sellClear,
        refreshCars: refreshCars,
        updateSellYearSimple: updateSellYearSimple,
        updateSellModificationSimple: updateSellModificationSimple,
        updateSellBodyStyle: updateSellBodyStyle,
        updateVatIncluded: updateVatIncluded,
        updateEntryMode: updateEntryMode,
        updateSellMakes: updateSellMakes,
        updateSellErrors: updateSellErrors,
        updateCharacteristicsError: updateCharacteristicsError
    }

    return <div id='sell-container' className='container'>
        {!isAuthenticated && <div className='row'>
            <div className='col-12 d-flex justify-content-end'>
                <LoginButton setIsLoading={setIsLoading}/>
            </div>
        </div>}
        {isAuthenticated &&
            <div id='sell-container'>
                <div id='sell-tabs'>
                    <Nav fill activeKey={selectedPage.page} style={{maxWidth: '400px'}}
                         onSelect={key => {
                             setSelectedPage({page: key});
                             // changeStyle(key.toLowerCase());
                         }}>

                        <Nav.Item style={navStyle.addcar}>
                            <Nav.Link eventKey='addCar'>
                                <div>
                                    <span>{t('texts:sell.add-car')}</span>
                                    {selectedPage.page === 'addCar' && <span className='active-span'/>}
                                </div>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item style={navStyle.myoffers}>
                            <Nav.Link eventKey='myOffers'>
                                <div>
                                    {t('texts:sell.my-offers')}
                                    {selectedPage.page === 'myOffers' && <span className='active-span'/>}
                                </div>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item style={navStyle.myaccount}>
                            <Nav.Link eventKey='myAccount'>
                                <div>
                                    {t('texts:sell.my-account')}
                                    {selectedPage.page === 'myAccount' && <span className='active-span'/>}
                                </div>
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>

                {selectedPage.page === 'addCar' && <AddCar
                    state={state}
                    sellFunctions={sellFunctions}
                    sellerInfo={sellerInfo}
                />
                }
                {selectedPage.page === 'myOffers' && <MyOffers cars={cars}
                                                               updateSelectedCar={props.updateSelectedCar}
                                                               sellFunctions={sellFunctions}
                                                               sellerInfo={sellerInfo}
                />
                }

                {selectedPage.page === 'myAccount' &&
                    <MyAccount updateSellerInfo={setSellerInfo}
                               sellerInfo={sellerInfo}/>
                }
            </div>
        }
        {isLoading && <div id='loader'
                           className='col-12 d-flex justify-content-center align-items-center mt-4 h-50'>
            <LoadingIndicator/>
        </div>
        }
    </div>
}

export default Sell2;
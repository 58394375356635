import React from 'react';
import {useTranslation} from "react-i18next";
import {
    getCityFromDictionary,
    getCountryFromDictionary,
    getTitleFromDictionary
} from "../../../../Integration/Utils/LanguageUtils";
import HistoryLabel from "./HistoryLabel/HistoryLabel";
import {truncateWordsToLength} from "./utils/stringUtils";
import {FetchImage} from "../../../../Shared/FetchImage/FetchImage";
import SponsoredLabel from "./SponsoredLabel/SponsoredLabel";
import {useNavigate} from "react-router-dom";
import '../../../Car/Components/css/carcomponents.css';


const CarCardMobilePortrait = props => {

    console.log('CarCardMobilePortrait props', props)

    const navigate = useNavigate();

    const {t} = useTranslation(['texts']);
    const fuel_d = t('extended-dictionary:fuels', {returnObjects: true});
    // const fuel_d = t('data-for-ui:fuel', {returnObjects: true});
    const transmission_d = t('extended-dictionary:transmissions', {returnObjects: true});
    const locations = t('locations:countries', {returnObjects: true});

    const vatElement = getVATBaloon(t('texts:vehicle-information.vat'), props.car.vatIncluded);

    const carLocation = getCityFromDictionary(props.car, locations) + ', ' +
        getCountryFromDictionary(props.car, locations);

    const truncatedModification = truncateWordsToLength(props.car.modification, 33);

    return <div id='car-card-mobile-portrait' className='row cursor-pointer' style={border}
                onClick={() => {
                    navigate('/car/' + props.car.RowKey);//
                    props.selectHandler(props.car);
                }}
    >
        <div id='car-card-mobile-image-block' className='col-12 px-0'>
            <SponsoredLabel rating={props.car.rating}/>
            <FetchImage
                // className="img-thumbnail"
                style={{...thumbnail, borderBottom: '1px solid #ddd'}}
                src={props.image} alt='car image'/>
        </div>
        <div id='car-card-description-block-0' className='col-12 d-table pr-0 mb-2'>
            <div className='d-table-row'>
                <div className='col-12 row justify-content-between align-items-start pr-0 mt-0'>

                    <div className='font-weight-bold mt-2 mb-0 col-7 px-0' style={carHeader}>
                        <span className="text-capitalize">{props.car.year}{' '}</span>
                        <span className="text-capitalize">{props.car.PartitionKey}{' '}</span>
                        <span className="text-capitalize">{props.car.model}</span>
                    </div>

                    <div className='col-5 font-weight-bold mt-2 mb-n1 px-0 text-right d-flex align-items-center
                    justify-content-end' style={carHeader}>
                        €{props.car.price.toLocaleString('ru-RU')}{' '}{vatElement}
                    </div>
                </div>
            </div>

            <div className='d-table-row'>
                <div className='justify-content-between row col-12 pr-0 mt-0'>
                    <div className='col-12 px-0 text-uppercase'>
                        <span style={{fontSize: '.82rem', color: '#555'}}>{truncatedModification}</span>
                    </div>
                </div>
            </div>
            <div className='d-table-row mt-2' style={{fontSize: '.88rem'}}>
                <div className='justify-content-between row col-12 pr-0 my-1'>
                    <div>
                        <span>{props.car.mileage.toLocaleString('ru-RU')}{' '}{t('search.car-card.km')}</span>
                    </div>
                    <div className=''>
                        <span className='text-capitalize'>{getTitleFromDictionary(props.car.fuel, fuel_d)}</span>
                        <span
                            className='text-lowercase'>{', '}{props.car.power}{' '}
                            {t('sell.characteristics.hp')}</span>
                        <span
                            className='text-lowercase'>{' '}{getTitleFromDictionary(props.car.transmission, transmission_d)}</span>
                    </div>
                </div>
            </div>

            <div className='d-table-row' style={{fontSize: '.88rem'}}>
                <div className='justify-content-between row col-12 pr-0 align-items-center'>
                    <div className='text-capitalize'>
                        <span className='justify-content-start'>{carLocation.toString()}</span>
                    </div>
                    <HistoryLabel history={props.car.history}/>
                </div>
            </div>
        </div>
    </div>
}

export default CarCardMobilePortrait;

const thumbnail = {
    width: '100%',
    // maxWidth:'400px',
    height: 'auto',
    // borderRadius: '1rem 0 0 1rem',
}
const border = {
    border: '1.5px solid #ddd',
    boxShadow: '1px 8px 7px -3px rgba(221,221,221,0.7)'
}

export const carHeader = {
    fontSize: '0.955rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    // maxWidth: '70%'
}


export const vatStyle = {
    fontSize: '.5rem', border: '1px solid #bbb',
    padding: '0.1rem',
    borderRadius: '0.3rem',
    background: 'lightyellow', marginLeft: '.25rem', lineHeight: '8px'
}

export const getVATBaloon = (text, vatIncluded) => {
    console.log('getVATBaloon');
    let vatElement = '';
    if (vatIncluded !== null) {
        console.log('vatIncluded not empty', vatIncluded);
        if (vatIncluded === "true") vatElement =
            <span id='vat-span' style={vatStyle}>{text}</span>
    }
    return vatElement;
}
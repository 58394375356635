import React, {useEffect, useState} from 'react';
import _ from "lodash";
import {validatePrivateInfo} from "../utils/utils";
import {useTranslation} from "react-i18next";
import {AzureSellersRESTConnector} from "../../../../../Integration";
import './/myaccount.css';

export const AddPrivateSellerButton = ({
                                           localInfoFields, setLocalInfoFields,
                                           setErrorMessage,
                                           props
                                       }) => {

    const {t} = useTranslation(['texts']);
    const sellersRESTConnector = new AzureSellersRESTConnector();
    const [fieldsOK, setFieldsOK] = useState(false);

    useEffect(() => {
        if (!_.isEmpty(localInfoFields)) {
            setFieldsOK(areFieldsOk(localInfoFields));
        }
    }, [localInfoFields]);

    const areFieldsOk = ({...localInfoFields}) => {
        const isOK = localInfoFields['juridicalType'] === 'private'
            && !_.isEmpty(localInfoFields['name']);
        console.log('AddPrivateSellerButton areFieldsOk', isOK, localInfoFields);
        return isOK
    }

    const handleSubmitAddPrivate = async (localInfoFields, setLocalInfoFields,
                                          setErrorMessage, props) => {
        const {juridicalType, name, phone} = localInfoFields;

        if (!_.isEmpty(name) && !_.isEmpty(juridicalType)) {
            const validationMessage = validatePrivateInfo(juridicalType, name, phone, t);
            console.log('AddPrivateSellerButton handleSubmitAddPrivate validation result', validationMessage);

            setErrorMessage(validationMessage);

            const requestObject = {
                type: juridicalType,
                name,
                ...(phone && {phone}),
            };

            console.log('requestObject', requestObject);

            if (_.isEmpty(validationMessage)) {
                console.log('AddPrivateSellerButton can proceed!', validationMessage);

                const result = await sellersRESTConnector.addSeller(requestObject);
                window.sessionStorage.setItem('sellerInfo', JSON.stringify(result));
                props.updateSellerInfo(result);
                setLocalInfoFields({});
            } else {
                console.warn('AddPrivateSellerButton !can proceed', validationMessage)
            }
        }
    };

    return <div id='submit-private-data-row' className='row mt-4'>
        <div className='col-1 d-flex align-items-center justify-content-center pr-0'/>
        <div className='offset-1'/>

        <button id='submit-button-add-info-private'
                className='submit-button' disabled={!fieldsOK}
                onClick={() => handleSubmitAddPrivate(
                    localInfoFields, setLocalInfoFields, setErrorMessage, props, t)
                }>
                            <span className='d-flex justify-content-center align-items-center h-100'>
                                    {t('texts:sell.my-account-area.save-data')}
                                </span>
        </button>
    </div>
}
//input field validators; submit validators in different file

const isCommentInRange = text => {
    let inRange = true;
    if (text !== undefined && text.length > 2000) inRange = false;
    return inRange;
}

const isPriceWithinRange = price => {
    return !Number.isNaN(price) && Number.isInteger(Number.parseInt(price)) &&
        (parseInt(price) > 0 && parseInt(price) < 999999);
}

const isMileageWithinRange = mileage => {
    return !Number.isNaN(mileage) && Number.isInteger(Number.parseInt(mileage)) &&
        (parseInt(mileage) > 0 && parseInt(mileage) < 1999999);
}

const isPowerWithinRange = power => {
    const isPowerOK = !Number.isNaN(power) && Number.isInteger(Number.parseInt(power)) && (power >= 0 && power <= 1500);
    console.log('isPowerWithinRange', power, isPowerOK);
    return isPowerOK;
}

const isModificationSimpleWithinRange = text => {
    const isOK = text.length <= 100;
    console.log('isModificationSimpleWithinRange', text.length, isOK);
    return isOK
};

const isYearWithinRange = year => {
    const isYearOK = !Number.isNaN(year) && Number.isInteger(Number.parseInt(year))
        && (year >= 0 && year <= new Date().getFullYear());
    console.log('isYearWithinRange', isYearOK, 'getFullYear', new Date().getFullYear)
    return isYearOK;
}

const isDisplacementWithinRange = cc => {
    const isDisplacementOK = !Number.isNaN(cc) && cc <= 12000;
    console.log('isDisplacementWithinRange', cc, isDisplacementOK);
    return isDisplacementOK;
}

const isVinWithinRange = vin => {
    const isVinOK = typeof vin === 'string' && vin.length >= 0 && vin.length <= 17;
    console.log('isVinWithinRange', vin.length, isVinOK);
    return isVinOK;
}

const isOptionsWithinRange = options => {
    return typeof options === 'string' && options.length <= 400;
}

export {
    isCommentInRange, isPriceWithinRange, isMileageWithinRange, isModificationSimpleWithinRange,
    isYearWithinRange, isPowerWithinRange, isDisplacementWithinRange, isVinWithinRange, isOptionsWithinRange
}
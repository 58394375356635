import React, {useState} from 'react';
import {PayPalButtons, PayPalScriptProvider} from "@paypal/react-paypal-js";
import PaymentConfirmation from "../PaymentConfirmation";
import {AzureUpgradeOrdersRESTConnector} from "../../../../../../../Integration";

const upgradeOrdersConnector = new AzureUpgradeOrdersRESTConnector();

const Paypal = props => {

    const [isComplete, setIsComplete] = useState(false);
    const paypalClientID = process.env.REACT_APP_PAYPAL_CLIENT_ID;


    return <div className='col-12 d-flex justify-content-center' id='paypal-payment-div'>
        <div style={{maxWidth: '250px'}}><PayPalScriptProvider
            options={
                {
                    "client-id": paypalClientID,
                    "intent": "capture",
                    "currency": "EUR"
                }}>
            {!isComplete && <PayPalButtons
                style={{layout: "horizontal"}}

                createOrder={
                    async () => {
                        console.log('createOrder props', props);
                        const orderID = await upgradeOrdersConnector.createOrderPayPal(props);
                        console.log('createOrder orderID', orderID);
                        return orderID;

                    }}

                onApprove={
                    async orderData => {
                        console.log('approveOrder', orderData);
                        const json = await upgradeOrdersConnector.approveOrderPayPal(orderData);
                        console.log("approveOrder json", json);
                        setIsComplete(true);
                    }}
            />}
            {isComplete && <PaymentConfirmation/>}
        </PayPalScriptProvider>
        </div>
    </div>

}

export default Paypal;
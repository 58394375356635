import React, {useRef, useState} from "react";
import {useTranslation} from 'react-i18next';
import _ from "lodash";
import {handleFileChangeAndResize} from "./fileUtils";
import {ProgressBar} from "react-bootstrap";
import {validateFiles} from "../../../../Integration/Utils/StorageUtils";

const InputFile2 = ({files, handler, disabled, placeholder, children, updateSellErrors, stateErrors}) => {
    const {t} = useTranslation();
    const fileInput = useRef();

    const [progress, setProgress] = useState(0); // for tracking progress

    const triggerFileSelectPopup = () => fileInput.current.click();


    return (
        <div id='input-file' className='mb-1'>
            {children}
            <button onClick={triggerFileSelectPopup}
                    type="button"
                    style={disabled ? styles.disabledButton : styles.button}>
                {t('sell.select-files')}
            </button>
            <input ref={fileInput}
                   multiple
                   type='file'
                   style={styles.input}
                   accept='image/jpeg,image/jpg'
                   onChange={async event => {
                       const resizedFiles = await handleFileChangeAndResize(event, setProgress);
                       handler(resizedFiles);

                       const errors = await validateFiles(resizedFiles);
                       console.log('InputFile2 file errors', errors);
                       if (errors.length > 0) {
                           updateSellErrors({files: errors});
                       } else {

                           /** Clear error in case of new file upload **/
                           console.log('InputFile2 file checking OK ', errors);
                           const copyStateErrors = {...stateErrors};
                           delete copyStateErrors.files;

                           console.log('InputFile2 file checking OK, copyStateErrors', copyStateErrors);
                           updateSellErrors(copyStateErrors);
                       }

                   }}
                   disabled={disabled}
            />
            <div id='progress indicator' className='my-2'>
                {!_.isEmpty(files) && progress !== 100 &&
                    <ProgressBar
                        now={progress}
                        label={`${progress}%`}
                        variant='success'/>}
            </div>
        </div>
    );
};

const styles = {
    input: {
        fontSize: '0.875rem',
        width: '100%',
        height: '32px',
        borderRadius: '4px',
        display: 'none', // Hide the actual input
        outline: 'none'
    },
    button: {
        fontSize: '0.875rem',
        width: '100%',
        height: '32px',
        borderRadius: '4px',
        border: 'solid 1px #d2d2d2',
    },
    disabledButton: {
        fontSize: '0.875rem',
        width: '100%',
        height: '32px',
        borderRadius: '4px',
        backgroundColor: '#e9ecef',
        border: 'solid 1px #d2d2d2',
        opacity: 0.5
    },

};

export {InputFile2};

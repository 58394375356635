import React from 'react';
import {
    companyKeys,
    populateInfoWithUpdatedValues,
    privateKeys,
    validateCompanyInfo,
    validatePrivateInfo
} from "../utils/utils";
import _ from "lodash";
import {AzureSellersRESTConnector} from "../../../../../Integration";
import {buyButtonStyle} from "../../MyOffers/functions/offersFunctions";
import {useTranslation} from "react-i18next";
import {coralStyle} from "../MyAccount";
import {useMsal} from "@azure/msal-react";

export const UpdateButton = props => {

    const sellerRestConnector = new AzureSellersRESTConnector();
    const {t} = useTranslation(['texts']);
    const {accounts} = useMsal();

    const handleCompanyUpdate = async () => {
        console.log('Update company');
        const companyInfo = populateInfoWithUpdatedValues(
            props.localInfoFields,
            props.sellerInfo,
            companyKeys
        );
        const {
            type,
            name,
            phone,
            registrationNumber,
            companyAddress,
            website
        } = companyInfo;
        console.log('companyInfo', companyInfo);
        const errors = validateCompanyInfo(type, name, phone, registrationNumber, companyAddress, website, t);
        if (!_.isEmpty(errors)) {
            console.log('errors', errors);
            props.setErrorMessage(errors);
            return;
        }
        console.log('MyAccount add new company seller', 'company info', companyInfo);
        const status = await sellerRestConnector.updateSeller(companyInfo);
        if (status === 204) {
            await props.fetchAndUpdate(accounts[0].username);
            window.sessionStorage.setItem('sellerInfo', JSON.stringify(companyInfo));
            props.setShowInput({});
            props.setErrorMessage({});
        } else console.warn('update not 204');
        props.setShowInput({});
    }


    const handlePrivateUpdate = async (infoFields) => {
        console.log('Update private', 'localinfo', infoFields);
        const privateInfo = populateInfoWithUpdatedValues(infoFields, props.sellerInfo, privateKeys);
        const errors = validatePrivateInfo(privateInfo.type, privateInfo.name, privateInfo.phone, t);
        if (!_.isEmpty(errors)) {
            console.log('errors', errors);
            props.setErrorMessage(errors);
            return;
        }
        console.log('MyAccount add new private seller', 'private info', privateInfo);
        const status = await sellerRestConnector.updateSeller(privateInfo);
        if (status === 204) {
            await props.fetchAndUpdate(accounts[0].username);
            window.sessionStorage.setItem('sellerInfo', JSON.stringify(privateInfo));
            props.setShowInput({});
            props.setErrorMessage({});
        } else console.warn('update not 204');
        props.setShowInput({});
    };

    const handleClick = async () => {
        if (_.isEmpty(props.localInfoFields)) {
            console.log('localInfo is empty', props.localInfoFields)
            return;
        }
        const isCompanyType = props.localInfoFields.type === undefined
            ? props.sellerInfo.type === 'company'
            : props.localInfoFields.type === 'company';
        const isPrivateType = props.localInfoFields.type === undefined
            ? props.sellerInfo.type === 'private'
            : props.localInfoFields.type === 'private';
        if (isCompanyType) {
            await handleCompanyUpdate(props.localInfoFields);
        } else if (isPrivateType) {
            await handlePrivateUpdate(props.localInfoFields);
        }
    };

    return (
        <div id='info-submit-button'
             style={{...buyButtonStyle, ...coralStyle}}
             className='px-4 d-flex justify-content-center align-items-center mr-0'
             onClick={handleClick}
        >
        <span className='d-flex align-items-center'>
            {t('texts:sell.car-ad-card.edit-modal.update')}
        </span>
        </div>
    );

}
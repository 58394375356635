import React from 'react';

const SearchOptionSingle = ({children, data, bsClass, options, section, updateHandler}) => {

    console.log('SearchOptionSingle props', data, bsClass, options, section);

    const updateFeature = (e, title, section, optionsSection, updateHandler) => {
        // console.log('SearchOptionSingle updateFeature', section, e.target.name, e.target.checked, e.target.value);
        const newFeatures =
            {
                value: e.target.value,
                visible: e.target.checked,
                title: title !== undefined ? title : e.target.name
            }
        updateHandler(section, newFeatures);
    };

    let value = {};
    let containerClass = 'container multiselect';
    if (bsClass !== undefined) containerClass += ' ' + bsClass;

    const keys = Object.keys(data)
    const divID = 'search-field-s-' + section.toLowerCase();

    return (<div id={divID} className={containerClass}>
            {children}
            {keys.map((key, index) => {

                const entry = data[key][index];
                const title = entry.label;
                const entryValue = entry.value;
                // console.log('single entry', entry, 'title', title, 'value', entryValue);

                return <div className='row' key={key} style={{position: 'relative', cursor: 'pointer'}}>
                    <div className='d-flex justify-content-start align-items-center'>
                        <input
                            checked={options[section].value === entryValue && options[section].visible}
                            name={key}
                            value={entryValue}
                            type='checkbox'
                            style={multiSelectStyles.checkbox}
                            onChange={(e) =>
                                updateFeature(e, title, section, options[section],
                                    updateHandler)}/>
                        <span className='checkmark' style={value}/>
                        <label className='ml-2 my-0 text-capitalize'
                               style={multiSelectStyles.label}>{title}</label>
                    </div>
                </div>
            })}
        </div>
    )
};

export default SearchOptionSingle;


export const multiSelectStyles = {
    div: {
        opacity: 0.1,
        width: '24px',
        height: '24px',
        zIndex: 1
    },
    checkbox: {
        width: '24px',
        height: '24px',
        border: '1px solid black',
        zIndex: 22
        // opacity: 0.2
    },
    input: {
        opacity: 0,
        cursor: 'pointer',
        height: 0,
        width: 0,
    }
    ,
    label: {
        lineHeight: '32px',
        fontSize: '13px',
        userSelect: 'none',
    }
};

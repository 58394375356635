import React from 'react';
import CarMetadata from "./CarMetadata/CarMetadata";
import {FetchImage} from "../../../../Shared/FetchImage/FetchImage";
import {useNavigate} from "react-router-dom";
import '../../../Car/carpages.css'


const CarCardMobileLandscape = props => {

    //todo vertical centering of mileage, gearbox and power
    const navigate = useNavigate();

    return <div id='car-card-mobile-landscape' className='row cursor-pointer'
                onClick={() => {
                    navigate('/car/' + props.car.RowKey);
                    props.selectHandler(props.car);
                }}
                style={border}>
        <div className='col-6 px-0'
             style={{borderRight: '1px solid #eee'}}
            // style={{boxShadow:'rgb(221 221 221 / 40%) 1px 0px 8px 1px'}}
        >
            <FetchImage
                // className="img-thumbnail"
                style={thumbnail}
                src={props.image} alt='car image'/>
        </div>
        <div className='col-6 pt-3 pb-2'>
            <CarMetadata car={props.car}/>
        </div>
    </div>
};

export default CarCardMobileLandscape;

const thumbnail = {
    width: '100%',
    // maxWidth:'400px',
    height: 'auto',
    borderRadius: '0.5rem 0 0 0.5rem',
}
const border = {
    border: '1.5px solid #ddd',
    boxShadow: '1px 8px 7px -3px rgba(221,221,221,0.7)',
    borderRadius: '0.5rem',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    overflow: 'hidden'
}
// const carHeader = {
//     fontSize: '1.1rem',
//     overflow: 'hidden',
//     textOverflow: 'ellipsis',
//     maxWidth: '230px'
// }

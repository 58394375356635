import {
    AzureAdvertisementsRESTConnector,
    AzureStorageRESTConnector,
} from "../../../../../Integration";
import _ from 'lodash';

import {clearFields, flatten, lowerCase, prepareForInsert} from '../../../../../Integration/Utils/TableUtils'
import {getFilenameFromPath, isReady} from "./editHelpers";

const storageConnector = new AzureStorageRESTConnector();
const advertisementConnector = new AzureAdvertisementsRESTConnector();

const saveInfoResults = async props => {
    console.log('save info results', props);
    const copy = {...props};
    console.log('copied props', copy);
    const ready = isReady(copy);
    console.log('ready for update', ready)
    const paths = copy.blobPaths;
    delete copy.blobPaths;
    const cleared = clearFields(copy);
    const lowered = lowerCase(cleared);
    const flat = flatten(lowered);
    flat.blobPaths = JSON.parse(paths);
    const prepared = prepareForInsert(flat);
    console.log('prepare', prepared);
    console.log(copy.make, copy.vin, prepared, JSON.parse(prepared.blobPaths));
    const response = await advertisementConnector.updateAdvertisementRecord(copy.make, copy.vin, prepared);
    console.log('response', response);
}

const deleteCar = async (car, accounts) => {

    if (accounts.length > 0) {
        const loggedIn = accounts[0].username;
        if (loggedIn === car.userID) {
            console.log('can proceed');
            const paths = JSON.parse(car.blobPaths);
            const all = await Promise.all(paths.map(async b => {
                    console.log('blob', getFilenameFromPath(b))
                    const response = await storageConnector.deleteBlob(car.containerID, car.vin, getFilenameFromPath(b))
                    // console.log('response status from storage connector', response.status);
                    return {vin: car.vin, filename: b, status: response.status};
                })
            );
            console.log('all', all);
            const diff = _.difference(car.blobPaths, all.map(n => n.filename));
            console.log('difference', diff, 'car.blobPaths', car.blobPaths, 'all filenames', all.map(n => n.filename))
            if (diff.length === 0) {
                console.log('all files have been deleted');
                const tableResponse = await advertisementConnector.deleteAdvertisementRecord(car.make, car.vin);
                console.log('tableResponse after all delete: ', tableResponse);
            } else {
                car.blobPaths = diff;
                const tableResponse = await advertisementConnector.updateAdvertisementRecord(car.make, car.vin, car);
                console.log('partial success', tableResponse);
            }
        }
    }
}

export {saveInfoResults, deleteCar}
import React, {useState} from 'react';
import {Link, Route, Routes, useLocation} from "react-router-dom";
import './footer-pages.css';

import menu from './img/menu.png';
import menuMake from './img/menu-make.png';
import menuMakeModel from './img/menu-make-model.png';
import menuPrice from './img/menu-price.png';
import menuYear from './img/menu-year.png';
import menuAll from './img/menu-all.png';
import menuDeselect from './img/menu-deselect.png';
import menuDeselected from './img/menu-price-cleared.png';
import cardByType from './img/predefined-search-by-type.png';
import cardByPrice from './img/predefined-search-by-price.png';
import makeModel from './img/predefined-search-by-make-model.png';
import countryCity from './img/predefined-search-by-country-city.png';
import searchControlBrief from './img/search-control-brief.png';
import searchControlFull from './img/search-control-full.png';
import searchCategoriesUI from './img/categories-ui.png';
import searchCategoriesUIclearOne from './img/categories-ui-clear-one-option.png';
import searchCategoriesUIcleared from './img/categories-cleared.png';
import listingElement from './img/listing-element.png';
import loginButton from './img/login-button.png';
import loginModal from './img/log-in-modal.png';
import signupModal from './img/signup-modal.png';
import fillSellerInfo from './img/sell-ui-fill-account.png';
import fillSellerInfoPrivate from './img/sell-ui-fill-account-private.png';
import fillSellerInfoCorp from './img/sell-ui-fill-account-corp.png';
import automaticMode from './img/add-car-automatic-1.png';
import addCarModels from './img/add-car-models.png';
import addCarModification from './img/add-car-modification.png';
import carSpecs from './img/car-specs-car-ui.png';
import addCarCompletedFields from './img/add-car-owner-fields-2.png';
import completedUpload from './img/add-car-completed.png';
import myOffers from './img/my-offers.jpg';
import publishActivated from './img/publish-active.png';
import publishedCar from './img/published-car.jpg';
import carPage from './img/car-page.jpg';
import {useTranslation} from "react-i18next";
import {useMediaQuery} from "react-responsive";
import Footer2 from "../Footer/Footer2";

const Support = props => {

    const {t} = useTranslation(["support"]);
    const isSmallScreen = useMediaQuery({maxWidth: '450px'})

    const initialArrowStyle = {marginLeft: '0.4rem', opacity: 0.5};
    const [arrowStyle, setArrowStyle] = useState(initialArrowStyle);

    const [homeSectionOpen, setHomeSectionOpen] = useState(false);
    const [searchSectionOpen, setSearchSectionOpen] = useState(false);
    const [sellSectionOpen, setSellSectionOpen] = useState(false);

    //home links
    const [typeLinkOpen, setTypeLinkOpen] = useState(false);
    const [priceLinkOpen, setPriceLinkOpen] = useState(false);
    const [makeLinkOpen, setMakeLinkOpen] = useState(false);
    const [cityLinkOpen, setCityLinkOpen] = useState(false);

    //search links
    const [searchControlOpen, setSearchControlOpen] = useState(false);
    const [activeCategoryOpen, setActiveCategoryOpen] = useState(false);
    const [carListingOpen, setCarListingOpen] = useState(false);

    //sell links
    const [loginOpen, setLoginOpen] = useState(false);
    const [sellerInfoOpen, setSellerInfoOpen] = useState(false);
    const [carInfoOpen, setCarInfoOpen] = useState(false);
    const [publishOpen, setPublishOpen] = useState(false);


    console.log('Support props', props);
    const location = useLocation();
    const pathname = location.pathname;


    let supportSideMenu = 'col-2';
    let supportMainTextAreaClass = 'col-8';
    let textColumn = 'col-6';
    const mainTextStyle = {fontSize: '1rem'};
    if (isSmallScreen) {
        supportSideMenu = 'd-none';
        supportMainTextAreaClass = 'col-12';
        textColumn = 'col-12';
        mainTextStyle.fontSize = '0.9rem';
    }

    //TODO Refactor


    return <div id='support' className='root-container-style'>
        <div className='container level-one-container'>
            <h5 style={{fontSize: '0.875rem', fontWeight: 600, textTransform: 'uppercase'}}>
                {t('support.support')}
            </h5>
            <div className='row'>
                <div className={supportSideMenu} id='support-sidemenu'>
                    <ul className='list-unstyled'>
                        {/*<li>Company info</li>*/}
                        {getMenuItem('how-to-search', t('support.search.how-to-search'), location)}
                        {getMenuItem('how-to-sell', t('support.sell.how-to-sell'), location)}
                        {getMenuItem('how-to-partner', t('support.sell.how-to-partner'), location)}
                        {getMenuItem('sitemap', t('support.sell.sitemap'), location)}
                    </ul>
                </div>
                <div className={supportMainTextAreaClass} style={{minHeight: '300px', ...mainTextStyle}}>
                    <Routes>
                        <Route path='how-to-search'
                               element={<div id='how-search-root'>
                                   <p className='header-h3'>{t('support.search.how-to-search')}</p>
                                   <p className='mt-2 mb-3'>{t('support.search.search-is-the-heart')}</p>
                                   <p className='mt-2 mb-4'>{t('support.search.search-can-be-made-from')}</p>
                                   {/*<p className='mt-2 mb-3'>{t('support.search.search-can-be-made-from')}{' '}*/}
                                   {/*    <Link to={'/'}><b>Home</b></Link> page and from the{' '}*/}
                                   {/*    <Link to={'/search'}><b>Search</b></Link>{' page'}.*/}
                                   {/*</p>*/}

                                   {/*<ul style={{listStyleType:'square'}}>*/}
                                   <div className='mt-2 mb-3'>
                                    <span className='header-h3 link'
                                          onClick={() => setHomeSectionOpen(!homeSectionOpen)}
                                    >{t('support.search.search-from-the-home-page')}
                                    </span>
                                       {!homeSectionOpen && <img src="img/angle-down-solid-black.svg"
                                                                 height="21px"
                                                                 alt='arrow down'
                                                                 style={arrowStyle}/>}
                                       {homeSectionOpen && <img src="img/angle-up-solid-black.svg"
                                                                height="21px"
                                                                alt='arrow up'
                                                                style={arrowStyle}/>}

                                   </div>
                                   {homeSectionOpen && <div id='how-to-search-visible'>
                                       {/*<br/>*/}
                                       <h3 className='header-h3 mt-2'>{t('support.search.the-menu')}</h3>
                                       <p className='mt-2'>
                                           {t('support.search.let-look-at-search')}
                                           <br/>
                                       </p>
                                       <p className='mb-3'>
                                           {t('support.search.menu-key-element-for-search')}
                                       </p>
                                       {/*<span className='header'>The Menu</span><br/>*/}
                                       <img className='img-fluid' src={menu} width='630'/><br/>
                                       <i style={{fontSize: '0.75rem', marginTop: '-12px'}}>
                                           {t('support.search.figure-the-menu')}
                                       </i>
                                       <p style={{marginTop: '10px'}}>
                                           {t('support.search.menu-has-4-buttons')}
                                       </p>
                                       <ol className='text-uppercase' style={{fontSize: '0.875rem'}}>
                                           <li className='my-1 pl-2'>{t('texts:search.search-area.make-model')}</li>
                                           <li className='my-1 pl-2'>{t('texts:search.search-area.price-range')}</li>
                                           <li className='my-1 pl-2'>{t('texts:search.search-area.year-range')}</li>
                                           <li className='my-1 pl-2'>{t('texts:nav.search')}</li>
                                       </ol>
                                       <p>{t('support.search.4-button-functions')}
                                       </p>
                                       <p className='mt-2 mb-4'>
                                           <span>{t('support.search.if-any-selected-search-highlighted')}</span>
                                           <img className='img-fluid' src={menuMake} width='630'/><br/>
                                           <i style={{fontSize: '0.75rem', marginTop: '-12px'}}>
                                               {t('support.search.figure-search-active')}
                                           </i>
                                       </p>

                                       <h3 className='header-h3'>{t('support.search.variants-of-setting-the-parameters')}</h3>

                                       <p className='mt-2 mb-1'>{t('support.search.variant-make')}</p>
                                       <div className='mb-3'><img className='img-fluid' src={menuMake} width='630'/>
                                       </div>

                                       <p className='mb-1'>{t('support.search.variant-make-model')}</p>
                                       <div className='mb-3'><img className='img-fluid' src={menuMakeModel}
                                                                  width='630'/>
                                       </div>

                                       <p className='mb-1'>{t('support.search.variant-price')}</p>
                                       <div className='mb-3'><img className='img-fluid' src={menuPrice} width='630'/>
                                       </div>

                                       <p className='mb-1'>{t('support.search.variant-year')}</p>
                                       <div className='mb-3'><img className='img-fluid' src={menuYear} width='630'/>
                                       </div>

                                       <p className='mb-1'>{t('support.search.variant-all')}</p>
                                       <div className='mb-4'><img className='img-fluid' src={menuAll} width='630'/>
                                       </div>

                                       <h3 className='header-h3'>{t('support.search.deselecting-a-parameter')}</h3>
                                       <p className='mt-1 mb-1'>{t('support.search.deselection-cross-appears')}</p>
                                       <div className='mb-3'><img className='img-fluid' src={menuDeselect} width='630'/>
                                       </div>

                                       <p className='mt-1 mb-1'>{t('support.search.click-to-deselect')}</p>
                                       <img className='img-fluid' src={menuDeselected} width='630'/>

                                       <h3 className='header-h3 mt-4'>{t('support.search.running-the-search')}</h3>
                                       <p className='mb-4'>{t('support.search.click-to-search')}</p>

                                       <h3 className='header-h3'>{t('support.search.predefined-search')}</h3>
                                       <p>{t('support.search.predefined-search-sets')}</p>
                                       <p>{t('support.search.using-predefined-sets')}</p>
                                       <ol>
                                           <li>
                                               <div key='type'
                                                    className={`${textColumn} text-nowrap`}
                                                    onMouseEnter={() => setArrowStyle({...arrowStyle, opacity: 0.9})}
                                                    onMouseLeave={() => setArrowStyle(initialArrowStyle)}
                                                    onClick={() => setTypeLinkOpen(!typeLinkOpen)}
                                               >
                                                   <span
                                                       className='link'>{t('support.search.selections-by-type')}</span>
                                                   {!typeLinkOpen &&
                                                       <img src="img/angle-down-solid-black.svg" height="21px"
                                                            alt='arrow down'
                                                            style={arrowStyle}/>}
                                                   {typeLinkOpen && <><img src="img/angle-up-solid-black.svg"
                                                                           height="21px"
                                                                           alt='arrow down' style={arrowStyle}
                                                   />
                                                       <div>
                                                           <img className='img-fluid' src={cardByType} height='120'
                                                                style={{marginBottom: '-7px'}}/>
                                                           <br/>
                                                           <i style={{fontSize: '0.75rem'}}>
                                                               {t('support.search.figure-selections-by-type')}
                                                           </i>
                                                       </div>
                                                   </>}
                                               </div>
                                           </li>
                                           <li>
                                               <div key='type-and-price'
                                                    className={`${textColumn} text-nowrap mt-2`}
                                                    onMouseEnter={() => setArrowStyle({...arrowStyle, opacity: 0.9})}
                                                    onMouseLeave={() => setArrowStyle(initialArrowStyle)}
                                                    onClick={() => setPriceLinkOpen(!priceLinkOpen)}
                                               >
                                                <span
                                                    className='link'>{t('support.search.selections-by-price-and-type')}</span>
                                                   {!priceLinkOpen &&
                                                       <img src="img/angle-down-solid-black.svg" height="21px"
                                                            alt='arrow down'
                                                            style={arrowStyle}
                                                       />}
                                                   {priceLinkOpen && <><img src="img/angle-up-solid-black.svg"
                                                                            alt='arrow down'
                                                                            height="21px" style={arrowStyle}
                                                   />
                                                       <div>
                                                           <img className='img-fluid' src={cardByPrice} height='240'/>
                                                           <br/>
                                                           <i style={{fontSize: '0.75rem'}}>{t('support.search.figure-by-price-and-type')}</i>
                                                       </div>
                                                   </>}
                                               </div>

                                           </li>
                                           <li>
                                               <div key='make-model'
                                                    className={`${textColumn} text-nowrap mt-2`}
                                                    onMouseEnter={() => setArrowStyle({...arrowStyle, opacity: 0.9})}
                                                    onMouseLeave={() => setArrowStyle(initialArrowStyle)}
                                                    onClick={() => setMakeLinkOpen(!makeLinkOpen)}
                                               >
                                                   <span className='link'>{t('support.search.by-make-and-model')}</span>
                                                   {!makeLinkOpen && <img src="img/angle-down-solid-black.svg"
                                                                          height="21px"
                                                                          alt='arrow down'
                                                                          style={arrowStyle}
                                                   />}
                                                   {makeLinkOpen && <><img src="img/angle-up-solid-black.svg"
                                                                           height="21px"
                                                                           alt='arrow down'
                                                                           style={arrowStyle}
                                                   />
                                                       <div>
                                                           <img className='img-fluid' src={makeModel} height='320'/>
                                                           <br/>
                                                           <i style={{fontSize: '0.75rem'}}>
                                                               {t('support.search.figure-by-make-and-model')}
                                                           </i>
                                                       </div>
                                                   </>}
                                               </div>

                                           </li>
                                           <li>
                                               <div key='make-model'
                                                    className={`${textColumn} text-nowrap mt-2`}
                                                    onMouseEnter={() => setArrowStyle({...arrowStyle, opacity: 0.9})}
                                                    onMouseLeave={() => setArrowStyle(initialArrowStyle)}
                                                    onClick={() => setCityLinkOpen(!cityLinkOpen)}
                                               >
                                                   <span
                                                       className='link'>{t('support.search.by-country-and-city')}</span>
                                                   {!cityLinkOpen && <img src="img/angle-down-solid-black.svg"
                                                                          height="21px"
                                                                          alt='arrow down'
                                                                          style={arrowStyle}
                                                   />}
                                                   {cityLinkOpen && <><img src="img/angle-up-solid-black.svg"
                                                                           height="21px"
                                                                           alt='arrow down'
                                                                           style={arrowStyle}
                                                   />
                                                       <div>
                                                           <img className='img-fluid' src={countryCity} height='220'/>
                                                           <br/>
                                                           <i style={{fontSize: '0.75rem'}}>
                                                               {t('support.search.figure-by-country-and-city')}
                                                           </i>
                                                       </div>
                                                   </>}
                                               </div>
                                           </li>
                                       </ol>

                                       <div className='d-flex justify-content-end mr-3 mt-3 mb-4' id='close-div'>
                                    <span className='header pr-2'
                                          onClick={() => setHomeSectionOpen(false)}
                                    >{t('support.search.close-search-from-home-page' +
                                        '')}</span>
                                           <img src="img/angle-up-solid-black.svg"
                                                height="21px"
                                                alt='arrow up'
                                                style={arrowStyle}/>
                                       </div>
                                   </div>
                                   }
                                   <div>
                                    <span className='header-h3 link'
                                          onClick={() => setSearchSectionOpen(!searchSectionOpen)}
                                    >{t('support.search.search-from-the-search-page')}</span>
                                       {!searchSectionOpen && <img src="img/angle-down-solid-black.svg"
                                                                   height="21px"
                                                                   alt='arrow down'
                                                                   style={arrowStyle}/>}
                                       {searchSectionOpen && <img src="img/angle-up-solid-black.svg"
                                                                  height="21px"
                                                                  alt='arrow up'
                                                                  style={arrowStyle}/>}
                                       {searchSectionOpen && <div id='search-section-visible'>
                                           <h3 className='header-h3 mt-3'>{t('support.search.search-page')}</h3>
                                           <p className='my-2'>{t('support.search.search-page-is-available')}</p>
                                           <ul className='list-square'>
                                               <li className='my-2'>{t('support.search.clicking-search-navlink')}</li>
                                               <li className='my-2'>{t('support.search.typing-search')}</li>
                                               <li className='my-2'>{t('support.search.using-home-page-elements')}
                                               </li>
                                           </ul>
                                           <div>
                                               <h3 className='header-h3 mt-4'>{t('support.search.search-page-ui')}</h3>
                                               <span>{t('support.search.search-ui-has-3-elements')}</span>
                                               <ul id='search-ui-ul' className='list-square'>
                                                   <li id='search-control-li' className='my-2'>
                                                       <div key='search control'
                                                            className='col-12 pl-0'
                                                            onMouseEnter={() => setArrowStyle({
                                                                ...arrowStyle,
                                                                opacity: 0.9
                                                            })}
                                                            onMouseLeave={() => setArrowStyle(initialArrowStyle)}
                                                       >
                                    <span className='header-h4 link'
                                          onClick={() => setSearchControlOpen(!searchControlOpen)}
                                    >
                                        {t('support.search.search-control-panel')}
                                    </span>
                                                           {!searchControlOpen &&
                                                               <img src="img/angle-down-solid-black.svg"
                                                                    height="21px"
                                                                    alt='arrow down'
                                                                    style={arrowStyle}
                                                               />}
                                                           {searchControlOpen && <><img
                                                               src="img/angle-up-solid-black.svg"
                                                               height="21px"
                                                               alt='arrow down'
                                                               style={arrowStyle}
                                                           />
                                                               <div id='search-filter-control'>
                                                                   <div className='mt-2 mb-4'>
                                                                       {t('support.search.search-filter-panel')}
                                                                       <ul className='list-square mt-2'>
                                                                           <li className='my-1'>{t('support.search.normal')}</li>
                                                                           <li className='my-1'>{t('support.search.extended')}</li>
                                                                       </ul>
                                                                   </div>
                                                                   <div id='normal-mode-div'>
                                                                       <h5 className='header-h5'>{t('support.search.normal-mode')}</h5>
                                                                       <p className='mt-2 mb-3'>{t('support.search.attributes-in-normal-mode')}</p>
                                                                       <div className='row mt-2 mb-5'>
                                                                           <div className={textColumn}>
                                                                               <img src={searchControlBrief}
                                                                                    className='img-fluid'
                                                                                    width='300'
                                                                                    style={{marginBottom: '-7px'}}/>
                                                                               <br/>
                                                                               <i style={{fontSize: '0.75rem'}}>
                                                                                   {t('support.search.figure-normal-mode')}
                                                                               </i>
                                                                           </div>
                                                                           <div className='col-auto'>
                                                                               <ul className='list-square text-firstletter'>
                                                                                   <li className='my-1'>{t('texts:search.search-area.price-range')}</li>
                                                                                   <li className='my-1'>{t('texts:search.search-area.year-range')}</li>
                                                                                   <li className='my-1'>{t('texts:search.search-area.make-model')}</li>
                                                                                   <li className='my-1'>{t('texts:search.search-area.fuel')}</li>
                                                                                   <li className='my-1'>{t('texts:search.search-area.history')}                                                                                </li>
                                                                                   <li className='my-1'>{t('texts:search.search-area.mileage')}</li>
                                                                               </ul>
                                                                           </div>
                                                                       </div>
                                                                   </div>

                                                                   <div id='extended-mode-div'>
                                                                       <h5 className='header-h5'>{t('support.search.extended-mode')}</h5>
                                                                       <p className='mt-2 mb-3'>
                                                                           {t('support.search.options-of-extended-mode')}
                                                                       </p>
                                                                       <div className='row mt-2 mb-5'>
                                                                           <div className={textColumn}>
                                                                               <img src={searchControlFull}
                                                                                    width='300'
                                                                                    className='img-fluid'
                                                                                    style={{marginBottom: '-7px'}}/>
                                                                               <br/>
                                                                               <i style={{fontSize: '0.75rem'}}>{t('support.search.figure-extended-mode')}</i>
                                                                           </div>
                                                                           <div className='col-auto'>
                                                                               <ul className='list-square mt-2 text-firstletter'>
                                                                                   <li className='my-1'>{t('texts:search.search-area.transmission')}</li>
                                                                                   <li className='my-1'>{t('texts:search.search-area.drivetrain')}</li>
                                                                                   <li className='my-1 '>{t('texts:vehicle-information.power')}</li>
                                                                                   <li className='my-1'>{t('texts:vehicle-information.location')}</li>
                                                                                   <li className='my-1'>{t('texts:search.search-area.body-style')}</li>
                                                                                   <li className='my-1'>{t('texts:vehicle-information.options')}</li>
                                                                                   <li className='my-1'>{t('texts:search.search-area.color')}</li>
                                                                               </ul>
                                                                           </div>
                                                                       </div>
                                                                   </div>
                                                                   <div className='d-flex justify-content-end mr-n3'
                                                                        id='close-div'>
                                    <span className='header pr-2'
                                          onClick={() => setSearchControlOpen(false)}
                                    >{t('support.search.close-search-control')}</span>
                                                                       <img src="img/angle-up-solid-black.svg"
                                                                            height="21px"
                                                                            alt='arrow up'
                                                                            style={arrowStyle}/>
                                                                   </div>
                                                               </div>
                                                           </>}
                                                       </div>
                                                   </li>
                                                   <li id='active-category-li' className='my-2'>
                                                       <div key='search control'
                                                            className='col-12 pl-0'
                                                            onMouseEnter={() => setArrowStyle({
                                                                ...arrowStyle,
                                                                opacity: 0.9
                                                            })}
                                                            onMouseLeave={() => setArrowStyle(initialArrowStyle)}
                                                       >
                                    <span className='header-h4 link'
                                          onClick={() => setActiveCategoryOpen(!activeCategoryOpen)}>
                                        {t('support.search.active-category-area')}
                                    </span>
                                                           {!activeCategoryOpen &&
                                                               <img src="img/angle-down-solid-black.svg"
                                                                    height="21px"
                                                                    alt='arrow down'
                                                                    style={arrowStyle}
                                                               />}
                                                           {activeCategoryOpen && <><img
                                                               src="img/angle-up-solid-black.svg"
                                                               height="21px"
                                                               alt='arrow down'
                                                               style={arrowStyle}
                                                           />
                                                               <div id='search-active-category'>
                                                                   <h5 className='header-h5 mt-3'>{t('support.search.active-category')}</h5>
                                                                   <p className='mt-1 mb-4'>{t('support.search.every-category-is-a-gray-rectangle')}<br/>
                                                                       <img src={searchCategoriesUI} height='110'
                                                                            className='img-fluid'/>
                                                                       <i style={{fontSize: '0.75rem'}}>{t('support.search.figure-active-filters')}</i>
                                                                   </p>
                                                                   <h5 className='header-h5 mt-4 mb-2'>{t('support.search.clear-category')}</h5>
                                                                   <div className='mt-1 mb-4'>
                                                                       <p className='mt-2 mb-2'>
                                                                           {t('support.search.category-ui-clear-options')}
                                                                           <br/>
                                                                           {t('support.search.cross-becomes-red')}
                                                                       </p>
                                                                       <img src={searchCategoriesUIclearOne}
                                                                            height='110' className='img-fluid'/>
                                                                       <br/>
                                                                       <i style={{fontSize: '0.75rem'}}>{t('support.search.figure-3-filter-clear-one')}</i>
                                                                   </div>
                                                                   <h5 className='header-h5 mt-4 mb-2'>{t('support.search.clear-all')}</h5>
                                                                   <div
                                                                       className='mt-2 mb-4'>{t('support.search.clear-by-one-or-at-once')}<span>{' '}</span>
                                                                       <b style={{
                                                                           color: 'red',
                                                                           fontSize: '0.75rem'
                                                                       }}>{t('support.search.all-at-once')}</b>
                                                                       <br/>
                                                                       <span
                                                                           className='mb-2 mt-1'>{t('support.search.cleared-ui')}</span>

                                                                       <img src={searchCategoriesUIcleared}
                                                                            height='56'/>
                                                                       <i style={{fontSize: '0.75rem'}}>{t('support.search.figure-cleared-ui')}</i>
                                                                   </div>
                                                                   <div
                                                                       className='d-flex justify-content-end mr-n3 mt-1 mb-2'
                                                                       id='close-div'>
                                    <span className='header pr-2 mt-2 mb-3'
                                          onClick={() => setActiveCategoryOpen(false)}>
                                        {t('support.search.close-active-category-area')}</span>
                                                                       <img src="img/angle-up-solid-black.svg"
                                                                            height="21px"
                                                                            alt='arrow up'
                                                                            style={arrowStyle}/>
                                                                   </div>
                                                               </div>
                                                           </>}
                                                       </div>
                                                   </li>
                                                   <li id='listing-element-li' className='my-2'>
                                                       <div key='listing element div'
                                                            className='col-12 pl-0'
                                                            onMouseEnter={() => setArrowStyle({
                                                                ...arrowStyle,
                                                                opacity: 0.9
                                                            })}
                                                            onMouseLeave={() => setArrowStyle(initialArrowStyle)}
                                                       >
                                    <span className='header-h4 link'
                                          onClick={() => setCarListingOpen(!carListingOpen)}>
                                        {t('support.search.car-listing-area')}
                                    </span>
                                                           {!carListingOpen &&
                                                               <img src="img/angle-down-solid-black.svg"
                                                                    height="21px"
                                                                    alt='arrow down'
                                                                    style={arrowStyle}
                                                               />}
                                                           {carListingOpen && <><img src="img/angle-up-solid-black.svg"
                                                                                     height="21px"
                                                                                     alt='arrow down'
                                                                                     style={arrowStyle}
                                                           />
                                                               <div id='listing-element-description'>
                                                                   <p className='mt-3'>{t('support.search.car-listing-matching-cars')}</p>
                                                                   <div className='mt-2 mb-4'>
                                                                       {t('support.search.listing-entries')}
                                                                       <ul className='list-square text-firstletter'>
                                                                           <li className='mt-2 mb-1'>{t('texts:search.search-area.year-range')}</li>
                                                                           <li className='my-1'>{t('support.search.make-model-mod')}</li>
                                                                           <li className='my-1'>{t('texts:search.search-area.mileage')}</li>
                                                                           <li className='my-1'>{t('support.search.fuel-power')}</li>
                                                                           <li className='my-1'>{t('texts:search.search-area.transmission')}</li>
                                                                       </ul>
                                                                       <br/>
                                                                       {t('support.search.history-and-location')}
                                                                   </div>
                                                                   <img src={listingElement} height='230'
                                                                        className='img-fluid'/>
                                                                   <br/>
                                                                   <i style={{fontSize: '0.75rem'}}>{t('support.search.figure-matching-car')}</i>
                                                               </div>
                                                               <div className='d-flex justify-content-end mr-n3 mb-2'
                                                                    id='close-div'>
                                    <span className='header pr-2'
                                          onClick={() => setCarListingOpen(false)}
                                    >{t('support.search.close-car-listing')}</span>
                                                                   <img src="img/angle-up-solid-black.svg"
                                                                        height="21px"
                                                                        alt='arrow up'
                                                                        style={arrowStyle}/>
                                                               </div>
                                                           </>}
                                                       </div>
                                                   </li>

                                               </ul>
                                           </div>
                                           <div className='d-flex justify-content-end mt-4' id='close-div'>
                                    <span className='header pr-2'
                                          onClick={() => setSearchSectionOpen(false)}
                                    >{t('support.search.close-search-ui')}</span>
                                               <img src="img/angle-up-solid-black.svg"
                                                    height="21px"
                                                    alt='arrow up'
                                                    style={arrowStyle}/>
                                           </div>
                                       </div>}

                                   </div>
                                   {/*</ul>*/}
                               </div>}>
                        </Route>
                        <Route path='how-to-sell'
                               element={<div id='how-to-sell-root'>
                                   <p className='header-h3'>{t('support.sell.how-to-sell')}</p>

                                   <div className='mt-2 mb-3'>{t('support.sell.add-car-for-sale')}</div>
                                   <ul style={{listStyleType: 'square'}} className='my-1'>
                                       <li id='sell-login' className='my-3'>
                                           <div key='sell-login'
                                                className='col pl-0 mt-3'
                                                onMouseEnter={() => setArrowStyle({...arrowStyle, opacity: 0.9})}
                                                onMouseLeave={() => setArrowStyle(initialArrowStyle)}>
                                    <span className='link header-h3'
                                          onClick={() => setLoginOpen(!loginOpen)}

                                    >{t('support.sell.sign-up-or-login')}</span>
                                               {!loginOpen && <img src="img/angle-down-solid-black.svg"
                                                                   height="21px"
                                                                   alt='arrow down'
                                                                   style={arrowStyle}
                                               />}
                                               {loginOpen && <><img src="img/angle-up-solid-black.svg"
                                                                    height="21px"
                                                                    alt='arrow down'
                                                                    style={arrowStyle}
                                               />

                                                   <div id='sell-login-internal'>
                                                       <div className='mt-3 mb-2'>
                                                           {t('support.sell.click-login-button')}
                                                       </div>
                                                       <div className='mt-2 mb-4'>
                                                           <img src={loginButton} height='80'
                                                                className='support-img-border'
                                                           />
                                                           <br/>
                                                           <i style={{fontSize: '0.75rem', marginTop: '-12px'}}>
                                                               {t('support.sell.figure-login-button')}</i>
                                                       </div>
                                                       <p>{t('support.sell.modal-window-on-login')}</p>
                                                       <div className='mt-2 mb-3'>
                                                           <img src={loginModal} width='350'
                                                                className='support-img-border img-fluid'
                                                           /> <br/>
                                                           <i style={{fontSize: '0.75rem', marginTop: '-12px'}}>
                                                               {t('support.sell.figure-login-authentication')}</i>
                                                       </div>
                                                       <p className='mt-2 mb-3'>{t('support.sell.we-support')}</p>
                                                       <p className='mt-2 mb-4'>
                                                           {t('support.sell.google-facebook-login')}
                                                       </p>

                                                       <h3 className='header-h3 mt-1'>
                                                           {t('support.sell.email-registration')}</h3>
                                                       <p>{t('support.sell.sign-up-email')}
                                                       </p>
                                                       <p>
                                                           <p>
                                                               <img src={signupModal} width='350'
                                                                    className='support-img-border img-fluid'
                                                               /><br/><i
                                                               style={{fontSize: '0.75rem', marginTop: '-12px'}}>
                                                               {t('support.sell.figure-email-confirmation')}</i>
                                                           </p>
                                                       </p>
                                                       <p className='mt-2 mb-4'>{t('support.sell.confirmation-email')}
                                                       </p>
                                                       <div className='d-flex justify-content-end'>
                                    <span className='header pr-2'
                                          onClick={() => setLoginOpen(false)}>
                                        {t('support.sell.close-signup')}
                                    </span><img src="img/angle-up-solid-black.svg"
                                                height="21px"
                                                alt='arrow up'
                                                style={arrowStyle}/>
                                                       </div>
                                                   </div>
                                               </>}
                                           </div>
                                       </li>

                                       <li id='seller-account' className='my-3'>
                                           <div key='seller-account'
                                                className='col pl-0 mt-2'
                                                onMouseEnter={() => setArrowStyle({...arrowStyle, opacity: 0.9})}
                                                onMouseLeave={() => setArrowStyle(initialArrowStyle)}
                                           >
                                    <span className='link header-h3'
                                          onClick={() => setSellerInfoOpen(!sellerInfoOpen)}

                                    >{t('support.sell.fill-in-sellers-info')}</span>
                                               {!sellerInfoOpen && <img src="img/angle-down-solid-black.svg"
                                                                        height="21px"
                                                                        alt='arrow down'
                                                                        style={arrowStyle}
                                               />}
                                               {sellerInfoOpen && <><img src="img/angle-up-solid-black.svg"
                                                                         height="21px"
                                                                         alt='arrow down'
                                                                         style={arrowStyle}
                                               /></>}

                                               {sellerInfoOpen && <div id='seller-info-description'>
                                                   <h3 className='mt-4 mb-3 header-h3'>
                                                       {t('support.sell.sellers-info')}
                                                   </h3>
                                                   <p className='mt-1 mb-4'>
                                                       {t('support.sell.first-sellers-login')}
                                                       <br/>
                                                       {t('support.sell.its-done-once')}
                                                       <br/>
                                                       <br/>
                                                       {t('support.sell.choose-private-or-company')}
                                                       <br/>
                                                       <img src={fillSellerInfo} width='350'
                                                            className='support-img-border mt-2 img-fluid'
                                                       /> <br/><i style={{fontSize: '0.75rem', marginTop: '-12px'}}>
                                                       {t('support.sell.figure-seller-type')}</i>
                                                   </p>
                                                   <h3 className='header-h3 mt-1'>
                                                       {t('texts:vehicle-information.seller-info.private')}</h3>
                                                   <div
                                                       className='mt-2 mb-3'>{t('support.sell.private-data-fields-short')}
                                                   </div>
                                                   <div className='mt-2 mb-4 '>
                                                       {/*<h5>Private person</h5>*/}

                                                       <div className=''>
                                                           <img src={fillSellerInfoPrivate} width='350'
                                                                className='support-img-border mt-1 img-fluid'
                                                           /> <i style={{fontSize: '0.75rem', marginTop: '-12px'}}>
                                                           <br/>
                                                           {t('support.sell.figure-private-seller-form')}</i>
                                                       </div>

                                                   </div>
                                                   <h3 className='header-h3 mt-1'> {t('texts:vehicle-information.seller-info.company')}</h3>
                                                   <p className='mt-2 mb-4'>
                                                       {/*<h5>Company</h5>*/}
                                                       {t('support.sell.company-fields')}
                                                       <br/>
                                                       <ul className='list-square text-firstletter mt-2 mb-2'>
                                                           <li className='my-1'>{t('texts:vehicle-information.seller-info.alias')}</li>
                                                           <li className='my-1'>{t('texts:vehicle-information.seller-info.phone')}</li>
                                                           <li className='my-1'>{t('texts:vehicle-information.seller-info.reg-number')}</li>
                                                           <li className='my-1'>{t('texts:vehicle-information.seller-info.company-address')}</li>
                                                           <li className='my-1'>
                                                               {t('texts:vehicle-information.seller-info.website')}{' '}
                                                               {t('texts:vehicle-information.seller-info.optional')}
                                                           </li>
                                                       </ul>

                                                       <img src={fillSellerInfoCorp} width='350'
                                                            className='support-img-border mt-1 img-fluid'
                                                       /><br/><i style={{fontSize: '0.75rem', marginTop: '-12px'}}>
                                                       {t('support.sell.figure-company-seller-form')}</i>
                                                   </p>
                                                   <div className='d-flex justify-content-end' id='close-div'>
                                    <span className='header pr-2'
                                          onClick={() => setSellerInfoOpen(false)}
                                    >{t('support.sell.close-fill-in-seller-info')}</span>
                                                       <img src="img/angle-up-solid-black.svg"
                                                            height="21px"
                                                            alt='arrow up'
                                                            style={arrowStyle}/>
                                                   </div>
                                               </div>}
                                           </div>
                                       </li>

                                       <li id='add-car' className='my-3'>
                                           <div key='fill-in-car-info'
                                                className='col pl-0 mt-2'
                                                onMouseEnter={() => setArrowStyle({...arrowStyle, opacity: 0.9})}
                                                onMouseLeave={() => setArrowStyle(initialArrowStyle)}
                                           >
                                    <span className='link header-h3'
                                          onClick={() => setCarInfoOpen(!carInfoOpen)}

                                    >{t('support.sell.add-a-car')}</span>
                                               {!carInfoOpen && <img src="img/angle-down-solid-black.svg"
                                                                     height="21px"
                                                                     alt='arrow down'
                                                                     style={arrowStyle}
                                               />}
                                               {carInfoOpen && <><img src="img/angle-up-solid-black.svg"
                                                                      height="21px"
                                                                      alt='arrow down'
                                                                      style={arrowStyle}
                                               /></>}

                                               {carInfoOpen && <div id='car-info-description' className='col pl-0'>
                                                   <p className='my-3'>
                                                       {t('support.sell.adding-car-needs-account-info')}
                                                   </p>
                                                   <p className='mt-2 mb-4'>{t('support.sell.two-ways-of-adding')}
                                                   </p>

                                                   <h3 className='header-h3'>{t('support.sell.automatic-mode')}</h3>
                                                   <p className='mt-2 mb-3'>
                                                       {t('support.sell.automatic-mode-description')}
                                                       <br/>
                                                       <br/>
                                                       {t('support.sell.manual-mode-description')}
                                                   </p>
                                                   <p className='my-3'>
                                                       {t('support.sell.each-step-has-a-number')}
                                                   </p>
                                                   <p className='mt-3 mb-4'>
                                                       {t('support.sell.article-describes-automatic-mode')}
                                                       <img src={automaticMode} width='450px'
                                                            className='support-img-border mt-2 img-fluid'/>
                                                       <br/>
                                                       <i style={{fontSize: '0.75rem', marginTop: '-12px'}}>
                                                           {t('support.sell.figure-automatic-mode-and-steps')}</i>
                                                   </p>
                                                   <h3 className='header-h3'>{t('support.sell.select-make-model-generation')}</h3>
                                                   <p className='mt-2 mb-3'>
                                                       {t('support.sell.choose-make-year-modellist-dynamic')}
                                                       {' '}
                                                       {t('support.sell.same-applies-to-generation-and-modification')}
                                                   </p>
                                                   <p className='mt-2 mb-3'>{t('support.sell.for-instance-audi-a8')}</p>
                                                   <p>
                                                       <img src={addCarModels} width='400px'
                                                            className='support-img-border mt-2 img-fluid'/>
                                                       <br/>
                                                       <i style={{fontSize: '0.75rem', marginTop: '-12px'}}>
                                                           {t('support.sell.figure-automatic-mode-sample')}</i>
                                                   </p>
                                                   <p>{t('support.sell.automatic-preselection-of-generation-and-bodystyle')}</p>
                                                   <p className='mt-2 mb-3'>{t('support.sell.result-of-preselection')}</p>
                                                   <p>
                                                       <img src={addCarModification} width='400px'
                                                            className='support-img-border mt-2 img-fluid'/>
                                                       <br/>
                                                       <i style={{fontSize: '0.75rem', marginTop: '-12px'}}>
                                                           {t('support.sell.figure-a8-modifications')}</i>
                                                   </p>
                                                   <p className='mt-2 mb-3'>
                                                       {t('support.sell.technical-details-are-fetched')}
                                                   </p>
                                                   <p>{t('support.sell.detailed-spec-available-for-visitors')}</p>
                                                   <p className='mt-3 mb-4'>
                                                       <img src={carSpecs} width='400px'
                                                            className='support-img-border mt-2 img-fluid'/>
                                                       <br/>
                                                       <i style={{
                                                           fontSize: '0.75rem',
                                                           marginTop: '-12px'
                                                       }}>{t('support.sell.figure-car-specs-for-visitor')}</i>
                                                   </p>

                                                   <h3 className='header-h3 mt-4'>{t('support.sell.remaining-fields')}</h3>
                                                   <div className='mt-3 mb-4'>
                                                       {t('support.sell.the-remaining-fields-are')}
                                                       <ul className='mt-3 list-square text-firstletter'>
                                                           <li className='my-2'>{t('texts:search.search-area.mileage')}</li>
                                                           <li className='my-1'>{t('texts:vehicle-information.price')}</li>
                                                           <li className='my-1'>{t('texts:vehicle-information.vin')}</li>
                                                           <li className='my-1'>{t('texts:vehicle-information.images')}</li>
                                                           <li className='my-1'>{t('texts:vehicle-information.comment')}{' '}{t('texts:vehicle-information.seller-info.optional')}</li>
                                                           <li className='my-1'>{t('texts:search.search-area.history')}{' '}{t('texts:vehicle-information.seller-info.optional')}</li>
                                                           <li className='my-1'>{t('texts:search.search-area.features')}{' '}{t('texts:vehicle-information.seller-info.optional')}</li>
                                                       </ul>
                                                       <br/>
                                                       {t('support.sell.easy-to-fill-in')}
                                                   </div>
                                                   <p className='mt-1 mb-4'>
                                                       <img src={addCarCompletedFields} width='400'
                                                            className='support-img-border img-fluid'/>
                                                       <br/>
                                                       <i style={{fontSize: '0.75rem', marginTop: '-12px'}}>
                                                           {t('support.sell.figure-remaining')}
                                                       </i>
                                                   </p>
                                                   <h3 className='header-h3'>{t('support.sell.submitting-the-listing')}</h3>
                                                   <p className='mt-2 mb-4'>{t('support.sell.button-becomes-clickable')}</p>
                                                   <p>{t('support.sell.after-click')}</p>
                                                   <p className='my-3'>
                                                       <img src={completedUpload} width='360'
                                                            className='support-img-border img-fluid'/>
                                                       <br/>
                                                       <i style={{fontSize: '0.75rem', marginTop: '-12px'}}>
                                                           {t('support.sell.figure-success-upload')}
                                                       </i>
                                                   </p>
                                                   <p className=''>
                                                       {t('support.sell.add-another-or-view-my-offers')}
                                                   </p>
                                                   <div className='d-flex justify-content-end' id='close-div'>
                                    <span className='header pr-2'
                                          onClick={() => setCarInfoOpen(false)}
                                    >{t('support.sell.close-add-car')}</span>
                                                       <img src="img/angle-up-solid-black.svg"
                                                            height="21px"
                                                            alt='arrow up'
                                                            style={arrowStyle}/>
                                                   </div>
                                               </div>}
                                           </div>
                                       </li>

                                       <li id='publish-car' className='my-3'>
                                           <div key='publish-car'
                                                className='col pl-0 mt-2'
                                                onMouseEnter={() => setArrowStyle({...arrowStyle, opacity: 0.9})}
                                                onMouseLeave={() => setArrowStyle(initialArrowStyle)}
                                           >
                                    <span className='link header-h3'
                                          onClick={() => setPublishOpen(!publishOpen)}

                                    >{t('support.sell.publish-to-search')}</span>
                                               {!publishOpen && <img src="img/angle-down-solid-black.svg"
                                                                     height="21px"
                                                                     alt='arrow down'
                                                                     style={arrowStyle}
                                               />}
                                               {publishOpen && <><img src="img/angle-up-solid-black.svg"
                                                                      height="21px"
                                                                      alt='arrow down'
                                                                      style={arrowStyle}
                                               /></>}
                                               {publishOpen && <div id='publish-car-visible-div'>

                                                   <p className='my-3'>
                                                       {t('support.sell.open-my-offers')}
                                                   </p>
                                                   <p className='mt-2 mb-3'>
                                                       {t('support.sell.my-offers-convenient-control-panel')}
                                                   </p>
                                                   <p>
                                                       <img src={myOffers} width='100%'
                                                            className='support-img-border'/>
                                                       <br/>
                                                       <i style={{fontSize: '0.75rem', marginTop: '-12px'}}>
                                                           {t('support.sell.figure-my-offers')}
                                                       </i>
                                                   </p>
                                                   <p className='mt-2 mb-3'>
                                                       {t('support.sell.publish-first-link-in-my-offers')}
                                                   </p>
                                                   <p className='mt-2 mb-3'>
                                                       {t('support.sell.publish-changes-color')}
                                                       <br/>
                                                       {t('support.sell.unpublish-became-active')}
                                                   </p>
                                                   <p>
                                                       <img src={publishActivated} width='400'
                                                            className='support-img-border img-fluid'/>
                                                       <br/>
                                                       <i style={{fontSize: '0.75rem', marginTop: '-12px'}}>
                                                           {t('support.sell.figure-activated-publish-button')}
                                                       </i>
                                                   </p>
                                                   <p className='mt-2 mb-3'>
                                                       {t('support.sell.car-is-searchable-now')}
                                                   </p>
                                                   <p>
                                                       <img src={publishedCar} width='100%'
                                                            className='support-img-border'/>
                                                       <br/>
                                                       <i style={{fontSize: '0.75rem', marginTop: '-12px'}}>
                                                           {t('support.sell.figure-published-car-in-the-search')}
                                                       </i>
                                                   </p>
                                                   <p className='mt-2 mb-3'>
                                                       {t('support.sell.click-to-get-to-car-page')}
                                                   </p>
                                                   <p>
                                                       <img src={carPage} width='100%'
                                                            className='support-img-border img-fluid'/>
                                                       <br/>
                                                       <i style={{fontSize: '0.75rem', marginTop: '-12px'}}>
                                                           {t('support.sell.figure-car-page')}
                                                       </i>
                                                   </p>
                                                   <div className='d-flex justify-content-end' id='close-div'>
                                    <span className='header pr-2'
                                          onClick={() => setPublishOpen(false)}
                                    >{t('support.sell.close-publish-car')}</span>
                                                       <img src="img/angle-up-solid-black.svg"
                                                            height="21px"
                                                            alt='arrow up'
                                                            style={arrowStyle}/>
                                                   </div>
                                               </div>}
                                           </div>
                                       </li>
                                   </ul>


                               </div>}>
                        </Route>
                        <Route path='how-to-partner'
                               element={<div>
                                   <span className='header-h3'>{t('support.sell.how-to-become-a-partner')}</span>
                                   <ul style={{listStyleType: 'square'}}>
                                       <li className='my-3'>{t('support.sell.use-our-platform-at-your-website')}</li>
                                       <li className='my-3'>{t('support.sell.batch-import-of-your-stock')}</li>
                                       <li className='my-3'>{t('support.sell.you-are-photographer-or-car-expert')}</li>
                                   </ul>
                                   <p className='my-3'>{t('support.sell.we-are-open-for-collaboration')}</p>
                               </div>}>
                        </Route>
                        <Route path='sitemap'
                               element={<div>
                                   {/*<span className='header'>Sitemap</span>*/}
                                   <p className='header-h3'>{t('support.sell.sitemap')}</p>
                                   <p className='mt-2 mb-3'>{t('support.sell.site-from-10k-foot')}</p>
                                   <ul className='sitemap list-square text-firstletter'>
                                       <li><Link to={'/'}>{t('texts:nav.home')}</Link></li>
                                       <li><Link to={'/search'}>{t('texts:nav.search')}</Link></li>
                                       <li><Link to={'/sell'}>{t('texts:nav.sell')}</Link></li>

                                       <li><Link to='/car'>{t('texts:nav.car')}</Link></li>
                                       <br/>
                                       <li>
                                           {t('texts:footer.company')}
                                           <ul className='list-square text-firstletter'>
                                               <li><Link to='/company/about'>{t('texts:footer.about')}</Link></li>
                                               <li><Link to='/company/legal'>{t('texts:footer.legal')}</Link></li>
                                               <li><Link
                                                   to='/company/protection'>{t('texts:footer.data-protection')}</Link>
                                               </li>
                                               <li><Link to='/company/contact'>{t('texts:footer.contact')}</Link></li>
                                           </ul>
                                       </li>
                                       <br/>
                                       <li>
                                           {t('support.support')}
                                           <ul className='list-square text-firstletter'>
                                               <li><Link
                                                   to='/support/how-to-search'>{t('support.search.how-to-search')}</Link>
                                               </li>
                                               <li><Link
                                                   to='/support/how-to-sell'>{t('support.sell.how-to-sell')}</Link>
                                               </li>
                                               <li><Link
                                                   to='/support/how-to-partner'>{t('support.sell.how-to-become-a-partner')}</Link>
                                               </li>
                                               <li><Link to='/support/sitemap'>{t('support.sell.sitemap')}</Link></li>
                                           </ul>
                                       </li>
                                   </ul>
                               </div>}>
                        </Route>
                    </Routes>
                </div>
            </div>
        </div>
        <Footer2/>
    </div>
}

export default Support;

const getMenuItem = (item, title, location) => {
    let style = {
        fontWeight: 300, fontSize: '0.875rem', paddingTop: '2px', paddingBottom: '2px',
        marginLeft: '0rem'
    };

    const itemPath = `/support/${item.toLowerCase()}`;
    if (location.pathname.includes(itemPath)) {
        style = {...style, fontWeight: 600};
    }

    return <li style={style}>
        <Link to={itemPath}>{title !== undefined ? title : item}</Link>
    </li>
}
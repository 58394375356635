import React from 'react';
// import roadImage from './img/beautiful-highway-wallpaper.jpg';
import roadImage from './img/swedish-road-4k4.jpg';
import './css/home.css';
import Hero from './Hero';
import BannerWithMakes from './Banner';
import HR from '../../Shared/HR';
import {useTranslation} from "react-i18next";
import SmallTypeCardList from "./Cards/SmallTypeCardList";
import LargeTypeCardList from "./Cards/LargeTypeCardList";
import CountryAndCity from "./Lists/CountryAndCity/CountryAndCity";
import Footer2 from "../../Shared/Footer/Footer2";
import MakeAndModel from "./Lists/MakeAndModel/MakeAndModel";


const Home = props => {
    console.log('Home props', props);

    const {t} = useTranslation(['texts']);
    // const isHugeScreen = useMediaQuery({minWidth: 1600})

    let backgroundPosition = '50% 30%'
    // if(isHugeScreen) backgroundPosition = '50% 33%'

    return (
        <div id={'hero'}>
            <div className='hero-img ' style={{
                height: '435px', backgroundImage: `url(${roadImage})`,
                backgroundSize: 'cover',
                backgroundPosition: backgroundPosition
            }}>
                <Hero {...props}/>
            </div>
            <div className='container'>
                <HR mt='mt-2'>{t('home.popular-makes')}</HR>
                <BannerWithMakes updateOptions={props.updateMultipleOptionsNoAppend}/>
                {/*<hr style={{width: '44px'}}/>*/}
                <HR>{t('home.popular-vehicle-types')}</HR>
                <SmallTypeCardList updateOptions={props.updateMultipleOptionsNoAppend}/>
                {/*<div className='mt-3'/>*/}
                {/*<hr style={{width: '44px'}}/>*/}
                <HR>{t('home.popular-price-ranges')}</HR>
                <LargeTypeCardList updateOptions={props.updateMultipleOptionsNoAppend}/>
                <HR>{t('home.popular-models')}</HR>
                <MakeAndModel facets={props.searchFacets} updateOptions={props.updateMultipleOptionsNoAppend}/>
                <HR>{t('home.by-country-and-city')}</HR>
                <CountryAndCity facets={props.searchFacets} updateOptions={props.updateMultipleOptionsNoAppend}/>
            </div>

            <Footer2/>
        </div>
    );
};

export default Home;

import React from 'react';
import {
    getCityFromDictionary,
    getCountryFromDictionary,
    getTitleFromDictionary
} from "../../../../../Integration/Utils/LanguageUtils";
import HistoryLabel from "../HistoryLabel/HistoryLabel";
import {useTranslation} from "react-i18next";
import {
    getCompanyVatByCountry
} from "../../../../Sell/Fields/Price/priceHelperFunctions";
import SponsoredLabel from "../SponsoredLabel/SponsoredLabel";

const CarMetadata = props => {

    // console.log('CarMetadata: ', props);

    const {t} = useTranslation(['texts']);
    const fuel_d = t('extended-dictionary:fuels', {returnObjects: true});
    // const fuel_d = t('data-for-ui:fuel', {returnObjects: true});
    const transmission_d = t('extended-dictionary:transmissions', {returnObjects: true});
    const locations = t('locations:countries', {returnObjects: true});

    const inclVAT = t('vehicle-information.including-vat')

    const countryLocalized = getCountryFromDictionary(props.car, locations);
    const carLocation = getCityFromDictionary(props.car, locations) + ', ' +
        countryLocalized;

    let vatByCountry = '';
    if (props.car.vatIncluded === 'true') {
        console.log('country', props.car.country.toLowerCase());
        vatByCountry = getCompanyVatByCountry(props.car.country) + '%';
    }

    let vatString = '';
    if (props.car.vatIncluded === 'true') {
        vatString = ' ' + inclVAT + ' ' + vatByCountry;
    }

    return <div id='car-metadata' className='d-table h-100 w-100'>
        <div className='d-table-row'>
            <div id='car-card-heading-big-screen'
                 className="d-table-cell"
                 style={{...headerTextStyle, maxWidth: '11rem'}}>
                <div style={{
                    maxWidth: '20rem', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'
                }}>
                    <span className="text-nowrap text-capitalize">{props.car.PartitionKey}{' '}</span>
                    <span className="text-nowrap text-capitalize">{props.car.model}</span>
                    <span className="text-nowrap pl-2 text-uppercase"
                          style={{fontSize: '0.92rem', fontWeight: 300, color: 'rgba(0,0,0,1)'}}>
                            {props.car.modification.replace(/ *\([^)]*\) */g, '')}
                        </span>
                </div>
            </div>
            <div className='d-table-cell text-right'>{props.car.year}</div>
        </div>
        <div className='d-table-row' id='metadata-km-fuel-hp'>
            <div className='d-table-cell align-middle'
                 style={{fontSize: '.925rem'}}>
                <div>
                    <span>{props.car.mileage.toLocaleString('ru-RU')}{' '}{t('search.car-card.km')}</span>
                </div>
                <div className=''><span
                    className='text-capitalize'>{getTitleFromDictionary(props.car.fuel, fuel_d)}</span>
                    <span className='text-lowercase'>{', '}{props.car.power}{' '}{t('sell.characteristics.hp')}</span>
                </div>
                <div className='text-capitalize'>
                    <span>{getTitleFromDictionary(props.car.transmission, transmission_d)}</span>
                </div>
            </div>
            <div className='d-table-cell'/>
        </div>
        <div className='d-table-row ' id='metadata-row-history-price' style={{bottom: '0.4rem'}}>
            <div className='d-table-cell align-bottom' id='history'>
                <HistoryLabel history={props.car.history}/>
            </div>
            <div className='d-table-cell align-bottom' id='price'>
                         <span style={{...headerTextStyle, lineHeight: '1rem'}} className='d-block text-right'>
                                {props.car.price.toLocaleString('ru-RU')} EUR
                            </span>
            </div>
            <SponsoredLabel rating={props.car.rating}/>
        </div>

        <div className='d-table-row' id='metadata-row-location-lease'
             style={{fontSize: '0.75rem', fontStyle: 'normal'}}>

            <div className='d-table-cell align-middle' id='location'>
                <span className='justify-content-start'>{carLocation.toString()}</span>
            </div>
            <div className='d-table-cell align-middle' id='vat'>
                          <span className='d-block text-right'>
                                {vatString}
                          </span>
            </div>
        </div>
    </div>

}

export default CarMetadata;

const headerTextStyle = {fontSize: '1.125rem', fontWeight: 'bold'};

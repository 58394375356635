import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {composeMessage, loadRecaptcha, recaptchaKey, submitData} from "../../../../Integration/Email/emailFunctions";
import {getContainerIDfromPath} from "../../Utils/utils";
import './captcha.css';
import './contactForm.css';
import {validateTextRange, validateEmail} from "./helpers";
import {EmailSentStatus} from "../../../../Shared/Pages/EmailSentStatus";

const ContactForm = props => {

    console.log('Contact Form props', props);

    const {t} = useTranslation(['texts']);

    const [showDisclaimer, setShowDisclaimer] = useState(true);
    const [senderEmail, setSenderEmail] = useState('');
    const [senderName, setSenderName] = useState('');
    const [fieldsAreValid, setFieldsAreValid] = useState(false)
    const [emailSent, setEmailSent] = useState(false);
    const [emailStatus, setEmailStatus] = useState('checking');

    // let defaultMsg = t("search.car-card.contact-form.msg-placeholder");
    let defaultMsg = '';
    let preMsg = t("search.car-card.contact-form.msg-pre") + ': ' + senderName + ' (' + senderEmail + ')\n\n';
    let afterMsg = t("search.car-card.contact-form.msg-after");

    const [message, setMessage] = useState(defaultMsg);
    const [emailSubmitStyle, setEmailSubmitStyle] = useState({opacity: .5});


    useEffect(() => {
        if (props.showDisclaimer !== undefined) {
            setShowDisclaimer(props.showDisclaimer);
        }
        // loadRecaptcha();
    }, []);


    const [isDisabled, setIsDisabled] = useState(true);
    if (!isDisabled) {
        console.log('emailSubmitStyle', emailSubmitStyle, isDisabled);
    } else {
        console.log('emailSubmitStyle OK');
    }

    useEffect(() => {
        if (validateTextRange(senderEmail, 5, 60)
            && validateEmail(senderEmail)
            && validateTextRange(senderName, 1, 40)
            && validateTextRange(message, 1, 200)) {

            setFieldsAreValid(true);
            setIsDisabled(false);
            setEmailSubmitStyle({opacity: 1.0});
            console.log('email,name or message length are OK, submit unlocked');
        } else {
            console.log('email,name or message length is not ok');
            setFieldsAreValid(false);
            setIsDisabled(true);
        }
        console.log('useEffect...', senderEmail, ' ', senderName, ' ', message);
    }, [senderEmail, senderName, message]);


    return (
        !emailSent ? <div className='container-fluid' style={props.style} id='contact-form-container'>
            <div className='mb-4'/>
            <div className="row d-flex flex-column" id='contact-form-row'>
                <form id='form-contact-seller' className='col-12 px-0 d-flex flex-column mx-0'>
                    <label className="contact-form-label mb-0" htmlFor="input-full-name">
                        {t('search.car-card.contact-form.full-name')}
                    </label>
                    <input
                        className="contact-form-input"
                        id="input-full-name"
                        autoComplete='off'
                        // autoSave=''
                        style={{...styles.border, ...styles.input}}
                        value={senderName}
                        placeholder={t('search.car-card.contact-form.name-placeholder')}
                        onChange={e => {
                            console.log('INPUT-full-name', e.target.value);
                            // e.preventDefault();
                            setSenderName(e.target.value)
                        }}
                    />
                    <div className='mb-3'/>
                    {/*EMAIL*/}
                    <label className="contact-form-label mb-0" htmlFor="input-email">
                        {t('search.car-card.contact-form.email')}
                    </label>
                    <input className="contact-form-input" id="input-email"
                           autoComplete='off'
                           type='email'
                           value={senderEmail}
                           style={{...styles.border, ...styles.input}}
                           maxLength={50}
                           placeholder={t('search.car-card.contact-form.email-placeholder')}
                           onChange={e => {
                               // e.preventDefault();
                               setSenderEmail(e.target.value)
                           }}
                    />
                    <div className='mb-3 mt-2'/>

                    {/*TEXT AREA*/}
                    <label className="contact-form-label mb-0"
                           htmlFor="input-message">{t('search.car-card.contact-form.message')}
                    </label>
                    <textarea className="d-flex justify-content-start contact-form-input"
                              maxLength={150}
                              style={{...styles.border, height: '90px'}}
                        // height='90px'
                              placeholder={t("search.car-card.contact-form.msg-placeholder")}
                              id="input-message"
                              value={message}
                              onChange={e => {
                                  // e.preventDefault();
                                  const message = e.target.value;
                                  console.log('onChange textarea', message);
                                  setMessage(message);
                              }}>
                </textarea>
                </form>


                {/*SUBMIT BUTTON*/}
                <div className='w-100 d-flex justify-content-start align-items-center mt-3' id='send-message-button'>
                    <div className="email-button d-flex justify-content-center align-items-center"
                         style={emailSubmitStyle}
                         onClick={async () => {

                             if (!isDisabled && fieldsAreValid) {
                                 // console.log('clicked at email submit', props);
                                 setEmailSent(true);

                                 setIsDisabled(true);

                                 // window.grecaptcha.ready(() => {
                                 //     window.grecaptcha.execute(recaptchaKey, {action: 'submit'}).then(async token => {
                                 const containerID = getContainerIDfromPath(props.car);
                                 const vin = props.car.RowKey;
                                 // const response = await submitData(token,
                                 const response = await submitData('temporaryreplacementforatoken', vin,
                                     containerID, senderEmail, senderName,
                                     composeMessage(preMsg, message, afterMsg));
                                 // const json = response.json();
                                 console.log('response from submitData', response);
                                 // const json = await response.json();
                                 if (response.emailStatus === 'sent') {
                                     setEmailStatus('sent');
                                     console.log('email sent');
                                 } else {
                                     setEmailStatus('not sent')
                                     console.log('email  not sent', response.emailStatus);
                                 }
                                 // setEmailStatus(response.emailStatus);
                                 // });
                                 // });

                                 setTimeout(() => {
                                     setIsDisabled(false);
                                 }, 3000);


                             }
                         }}
                    >
                    <span className="mr-2">
                        {t('search.car-card.contact-form.send-email')}
                    </span>
                    </div>
                </div>
                {/*DISCLAIMER*/}
                {/*todo toggle disclaimer*/}

                {showDisclaimer &&
                    <div className="w-100 d-flex justify-content-center my-3 px-4" style={{fontSize: '0.75rem'}}>
                        <span className="text-center">{t('search.car-card.contact-form.consent-of-contact')}</span>
                    </div>}
            </div>
        </div> : <div id='sent-email' style={props.style}
                      className='d-flex justify-content-between my-3 px-0'>
            <div className='col-5 pl-0'>{t('search.car-card.contact-form.sent-status-title')}</div>
            {' '}
            <div className='col-7 pr-0 text-right'>
                <EmailSentStatus emailStatus={emailStatus}/>
            </div>
        </div>
    );
}

export default ContactForm;
const styles = {
    mainMessage: {fontSize: '1.25rem', fontWeight: 'bold'},
    // textArea: {height: '90px', border: 'solid 1px rgba(0,0,0,0.2)',borderRadius:'2px'},
    // inputBorder:{border: 'solid 1px rgba(0,0,0,0.2)',borderRadius:'2px'},
    border: {border: 'solid 1px rgba(0,0,0,0.2)', borderRadius: '2px'},
    input: {height: '2rem'}

};


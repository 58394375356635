import _ from 'lodash';

const validateTextRange = (text, minLength, maxLength) => {
    return !_.isEmpty(text) && text.length > minLength && text.length <= maxLength;
}

const validateEmail = text => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(text)

}

export {validateTextRange, validateEmail};
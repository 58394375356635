import React from 'react';
import CarMetadata from "./CarMetadata/CarMetadata";
import {FetchImage} from "../../../../Shared/FetchImage/FetchImage";
import {useNavigate} from "react-router-dom";

const CarCardBigScreen = props => {

    console.log('CarCardBigScreen', props);

    const navigate = useNavigate();

    return <div id='car-card-big-screen'
                className='my-1 ml-2 row'
                style={imageContainer}
                onClick={() => {
                    navigate('/car/' + props.car.RowKey);
                    props.selectHandler(props.car);
                }}>
        <div className='col-5 d-flex justify-content-center align-items-center px-0' id='car-card-big-left'>
            <FetchImage style={image} src={props.image} alt='car image'/>
        </div>
        <div className='col-7 px-4 pt-3 pb-2' id='car-card-big-right'>
            <CarMetadata car={props.car}/>
        </div>
    </div>
}

export default CarCardBigScreen;

const imageContainer = {
    border: '1.5px solid #ddd',
    boxShadow: '1px 8px 7px -3px rgba(221,221,221,0.7)',
    borderRadius: '0.5rem',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    overflow: 'hidden',
    minWidth: '600px',
    minHeight: '200px',
    cursor: 'pointer'
}
const image = {
    width: '100%',
    height: 'auto',
    borderRight: '1px solid #eee',
    borderRadius: '0.5rem 0 0 0.5rem'
}
import React from 'react';
import {Modal} from "react-bootstrap";
import {companyKeys, populateInfoWithUpdatedValues} from "../utils/utils";
import {useTranslation} from "react-i18next";


const CompanyTypeChangeModal = props => {

    const {t} = useTranslation(["texts"]);

    // console.log('CompanyTypeChangeModal', props);
    return <div>
        <Modal show={props.show} onHide={props.handleClose} animation={false} centered>
            <Modal.Header closeButton>
                <Modal.Title>{t('texts:vehicle-information.seller-info.change-seller-type')}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className='row'>
                    {/*<div className='offset-2'/>*/}
                    <div className='col-5 d-flex justify-content-center'>
                        <span>{t('texts:vehicle-information.seller-info.private-will-remove-fields')}</span>
                    </div>
                    <div className='col-7 d-flex justify-content-center'>
                        <ul>
                            <li>{t('texts:vehicle-information.seller-info.companyAddress')}</li>
                            <li>{t('texts:vehicle-information.seller-info.registrationNumber')}</li>
                            <li>{t('texts:vehicle-information.seller-info.website')}</li>
                        </ul>
                    </div>
                </div>
                <div className='row col mt-3 mb-2'>
                    <b>{t('texts:sell.car-ad-card.delete-modal.are-you-sure')}</b>
                </div>
                <div className='container'>
                    <div className='row d-flex justify-content-between'>
                        <div className='col-3 btn btn-outline-success mr-1 px-1' id='change-juridical-type-yes'
                             style={{borderRadius: 0}}
                             onClick={async () => {
                                 console.log('yes');
                                 const o = populateInfoWithUpdatedValues(props.localInfoFields, props.sellerInfo, companyKeys)
                                 // const o = props.localInfoFields;
                                 delete o['companyAddress'];
                                 delete o['registrationNumber'];
                                 delete o['website'];
                                 console.log('localInfo after update', o);
                                 const {type, name, phone} = o;
                                 const errors = props.validatePrivateInfo(type, name, phone, t);
                                 if (Object.keys(errors).length === 0) {
                                     const status = await props.updateSeller(o);
                                     if (status === 204) {
                                         await props.fetchAndUpdate(props.accounts[0].username);
                                         props.setLocalInfoFields({});
                                     } else console.warn('update not 204')
                                 } else {
                                     console.log('errors', errors);
                                     props.setErrorMessage(errors);
                                 }
                                 props.setShowInput({});
                                 props.handleClose();

                             }}>
                            <span>{t('texts:sell.car-ad-card.delete-modal.yes-update')}</span>
                        </div>
                        <div className='col-3 btn btn-outline-dark' id='change-juridical-type-no'
                             style={{borderRadius: 0}}
                             onClick={() => {
                                 console.log('no');
                                 props.handleClose();
                                 const o = {...props.localInfoFields};
                                 o.type = 'company';
                                 props.setLocalInfoFields(o);
                                 const i = {...props.showInput};
                                 delete i['type'];
                                 props.setShowInput(i);
                                 // props.setFontStyle({});
                             }}><span>{t('texts:sell.car-ad-card.delete-modal.no')}</span>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    </div>

}

export {CompanyTypeChangeModal};

const styles = {
    modalBody: {
        width: '100%',
        height: '44px',
        borderBottom: 'solid 1px rgba(0,0,0,0.75)'
    },
    modalUl: {
        maxHeight: '450px',
        overflow: 'overlay',
        border: 'none'
    },
    modalInput: {
        opacity: 0.8,
        fontSize: '1.125rem',
        width: '320px',
        border: 'none',
        height: '42px',
        marginLeft: '10px'
    },
    carItem: {
        width: '100%',
        height: '55px',
        lineHeight: '52px',
        borderBottom: 'solid 1px rgba(0,0,0,0.25)'
    },
    carTitle: {fontSize: '1.125rem', marginLeft: '5px'}
}
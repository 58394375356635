import React from 'react';
import _ from 'lodash';
import {Link} from "react-router-dom";
import {initialOptions} from "../../../../Data/State/initialState";
import {useTranslation} from "react-i18next";
import {useMediaQuery} from "react-responsive";
import Arrays from "lodash";

const CountryAndCity = props => {

    console.log('CountryAndCity props', props);

    const isBigScreen = useMediaQuery({minWidth: '700px'});
    let colDiv = 'col-4 d-table justify-content-center';
    if (isBigScreen) colDiv = 'col-3 d-table justify-content-center'

    const {t} = useTranslation(['locations']);
    const countries = t('locations:countries', {returnObjects: true});

    if (countries !== undefined && Arrays.isArray(countries)) {
        // console.log('CountryAndCity countries is an array', countries);
        const ee = countries.find(co => co.Name === 'Estonia');
        const lv = countries.find(co => co.Name === 'Latvia');
        const lt = countries.find(co => co.Name === 'Lithuania');
        const se = countries.find(co => co.Name === 'Sweden');
        const fi = countries.find(co => co.Name === 'Finland');
        const no = countries.find(co => co.Name === 'Norway');

        const getCity = (country, cityName) => {
            const city = country.cities.find(city => city.Name.toLowerCase() === cityName.toLowerCase())
            // console.log('getCity', city);
            return city;
        }

        const facets = props.facets;
        const getFromFacet = (country, city) => {
            // console.log('getCityFromFacet ', city);

            if (country !== undefined && city !== undefined && _.isObject(city)) {

                let count = 0;
                props.facets.city.forEach(e => {
                    if (e.value.toLowerCase() === city.Name.toLowerCase()) {
                        count = e.count;
                    }
                });

                if (count < 1) {
                    return <div className='d-table-row' style={{color: 'rgba(0,0,0,.5)'}}>{city.title}</div>;
                } else {
                    return <div className='d-table-row text-capitalize'><Link to={'search'}>
                    <span
                        onClick={() => props.updateOptions({
                            'make': initialOptions.make,
                            'model': initialOptions.model,
                            'country': {value: country.Name, visible: true, title: country.title},
                            'city': {value: city.Name, visible: true, title: city.title}
                        })}
                    >{city.title}{' ('}{count}{')'}</span>
                    </Link></div>

                }
            }
        }

        if (facets !== undefined && facets.city !== undefined) {
            return <div id='CountryAndCity' style={{fontSize: '0.75rem'}} className='container-fluid px-0'>
                <div className='row mt-4 d-flex justify-content-center'>
                    {isBigScreen && <div className='offset-2'/>}
                    <div className={colDiv}>
                        <div className='d-table-row font-weight-bold'>{ee.title}</div>
                        {getFromFacet(ee, getCity(ee, 'Tallinn'))}
                        {getFromFacet(ee, getCity(ee, 'Tartu'))}
                        {getFromFacet(ee, getCity(ee, 'Narva'))}
                        {getFromFacet(ee, getCity(ee, 'Kohtla-Järve'))}
                        {getFromFacet(ee, getCity(ee, 'Pärnu'))}
                    </div>
                    <div className={colDiv}>
                        <div className='d-table-row font-weight-bold'>{lv.title}</div>
                        {getFromFacet(lv, getCity(lv, 'Riga'))}
                        {getFromFacet(lv, getCity(lv, 'Daugavpils'))}
                        {getFromFacet(lv, getCity(lv, 'Liepaja'))}
                        {getFromFacet(lv, getCity(lv, 'Jelgava'))}
                        {getFromFacet(lv, getCity(lv, 'Jurmala'))}
                    </div>
                    <div className={colDiv}>
                        <div className='d-table-row font-weight-bold'>{lt.title}</div>
                        {getFromFacet(lt, getCity(lt, 'Vilnius'))}
                        {getFromFacet(lt, getCity(lt, 'Kaunas'))}
                        {getFromFacet(lt, getCity(lt, 'Klaipeda'))}
                        {getFromFacet(lt, getCity(lt, 'Siauliai'))}
                        {getFromFacet(lt, getCity(lt, 'Panevezys'))}
                    </div>
                    {isBigScreen && <div className='offset-2'/>}
                </div>
                <div className='row mt-4 d-flex justify-content-center'>
                    {isBigScreen && <div className='offset-2'/>}
                    <div className={colDiv}>
                        <div className='d-table-row font-weight-bold'>{se.title}</div>
                        {getFromFacet(se, getCity(se, 'Stockholm'))}
                        {getFromFacet(se, getCity(se, 'Goteborg'))}
                        {getFromFacet(se, getCity(se, 'Malmo'))}
                        {getFromFacet(se, getCity(se, 'Uppsala'))}
                        {getFromFacet(se, getCity(se, 'Upplands Vasby'))}
                    </div>
                    <div className={colDiv}>
                        <div className='d-table-row font-weight-bold'>{fi.title}</div>
                        {getFromFacet(fi, getCity(fi, 'Helsinki'))}
                        {getFromFacet(fi, getCity(fi, 'Tampere'))}
                        {getFromFacet(fi, getCity(fi, 'Turku'))}
                        {getFromFacet(fi, getCity(fi, 'Oulu'))}
                        {getFromFacet(fi, getCity(fi, 'Jyvaskyla'))}
                    </div>
                    <div className={colDiv}>
                        <div className='d-table-row font-weight-bold'>{no.title}</div>
                        {getFromFacet(no, getCity(no, 'Oslo'))}
                        {getFromFacet(no, getCity(no, 'Bergen'))}
                        {getFromFacet(no, getCity(no, 'Trondheim'))}
                        {getFromFacet(no, getCity(no, 'Stavanger'))}
                        {getFromFacet(no, getCity(no, 'Kristiansand'))}
                    </div>
                    {isBigScreen && <div className='offset-2'/>}
                </div>
            </div>
        } else {
            console.log('CountryAndCity facets is undefined', facets);
            return <> </>;
        }
    } else {
        console.log('CountryAndCity [countries] is undefined', countries);
        return <> </>;
    }
}

export default CountryAndCity;
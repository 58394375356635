import React, {useState} from 'react';
import MultiSelectOptions from "../../../../../../Fields/Options/MultiSelectFeatures";
import * as LanguageUtils from '../../../../../../../../Integration/Utils/LanguageUtils';
import './css/vehicle.css';

const EditableAttributeOptions = props => {

    const [showField, setShowField] = useState(false);
    const handleKeyPress = (event) => {
        console.log(event.key);
        if (event.key === 'Enter') setShowField(!showField);
        console.log('state after enter', state);
    }
    const state = props.state;
    const title = props.title;

    let optionsInState = state['options'];
    let optionsAsArray = optionsInState;
    console.log('typeof state[options]', typeof optionsInState);
    if (typeof optionsInState === 'string') optionsAsArray = JSON.parse(optionsInState);
    // console.log('JSON.parse(state[options])',typeof JSON.parse(optionsInState));
    // console.log('state[\'options\']',state['options']);
    const copy = JSON.parse(JSON.stringify(state));
    copy['options'] = optionsAsArray;
    // console.log('copy',copy)

    const updateOptions = options => {
        console.log('options', options)
        const copy = JSON.parse(JSON.stringify(state));
        copy['options'] = options;
        props.setState(copy);
    }

    return <div className='row col-12 mx-0 px-0 d-flex justify-content-between'
                style={props.textStyle}>
        <div className='col align-items-start px-0'>
            <span className="text-left">{title}</span>
        </div>
        <div className='col-auto d-flex justify-content-end pr-0'>
            <img height='17px' width='17px' className='button ml-1 mt-1 edit-icon'
                 onClick={() => setShowField(!showField)}
            />
        </div>
        <div className='row col-12'/>
        <div className='col px-0'>
            <div className='d-flex justify-content-start px-0'>
                {!showField && <span
                    className="text-left font-weight-bold">
                {optionsAsArray.map((o, i) => {
                    const title = LanguageUtils.getTitleFromDictionary(o, props.options)
                    console.log('state[\'options\']', optionsInState)
                    if (optionsAsArray.length > 0 && i < optionsAsArray.length - 1) return title + ', '
                    else return title;
                })}
            </span>}
            </div>
            {showField && <>
                <div className='d-flex justify-content-end container multiselect'>
                    <MultiSelectOptions
                        bsClass={'mt-2 container multiselect'}
                        options={props.options}
                        state={copy}
                        style={props.textStyle}
                        updateOptions={updateOptions}
                    />
                </div>
            </>}

        </div>

    </div>
}

export default EditableAttributeOptions
import {AddCompanySellerButton} from "./AddCompanySellerButton";
import NumberOfStep from "../../../Misc/NumberOfStep/NumberOfStep";
import Heading from "../../../../Search/SearchArea/Heading";
import React from "react";
import './myaccount.css'
import _ from "lodash";

export const AddCompanySellerForm = ({localInfoFields, setLocalInfoFields, setErrorMessage, props, textStyle, t}) => {

    const handleCompanyInfoChange = (e, field) => {
        const localInfoCopy = {...localInfoFields};
        localInfoCopy[field] = e.target.value;
        setLocalInfoFields(localInfoCopy);
        // console.log('handleCompanyInfoChange localInfoFields', e.target.value, 'localInfoCopy', localInfoCopy,
        //     'localInfoFields', localInfoFields);
    }

    return <div id='field-block-company'>
        <div id='field-alias-company' className='row mt-4'>
            <NumberOfStep
                thisField={localInfoFields['name']}
                prevField={localInfoFields['juridicalType']}>
                {2}
            </NumberOfStep>
            <div className='offset-1'/>
            <div className='col-7'>
                <Heading spanStyle={textStyle}>{t('texts:sell.my-account-area.alias')}</Heading>
                <div className='ml-n3'>
                    <input type='text'
                           className='border-style'
                           onChange={e => handleCompanyInfoChange(e, 'name')}
                           value={localInfoFields['name']}
                           disabled={_.isEmpty(localInfoFields['juridicalType'])}/>
                </div>
            </div>
        </div>
        <div id='field-phone-company' className='row mt-4'>
            <NumberOfStep
                thisField={localInfoFields['phone']}
                prevField={localInfoFields['name']}>
                {3}
            </NumberOfStep>
            <div className='offset-1'/>
            <div className='col-7'>
                <Heading spanStyle={textStyle}>{t('texts:sell.my-account-area.company-phone')}</Heading>
                <div className='ml-n3'>
                    <input type='tel'
                           maxLength={18}
                           className='border-style'
                           onChange={e => handleCompanyInfoChange(e, 'phone')}
                           value={localInfoFields['phone']}
                           disabled={_.isEmpty(localInfoFields['name'])}/>
                </div>
            </div>
        </div>
        <div id='field-phone-registration-number' className='row mt-4'>
            <NumberOfStep
                thisField={localInfoFields['registrationNumber']}
                prevField={localInfoFields['phone']}>
                {4}
            </NumberOfStep>
            <div className='offset-1'/>
            <div className='col-7'>
                <Heading
                    spanStyle={textStyle}>{t('texts:sell.my-account-area.registration-number')}</Heading>
                <div className='ml-n3'>
                    <input type='text'
                           className='border-style'
                           onChange={e =>
                               handleCompanyInfoChange(e, 'registrationNumber')}
                           value={localInfoFields['registrationNumber']}
                           disabled={localInfoFields['phone'] === undefined}/>
                </div>
            </div>
        </div>
        <div id='field-phone-company-address' className='row mt-4'>
            <NumberOfStep
                thisField={localInfoFields['companyAddress']}
                prevField={localInfoFields['registrationNumber']}>
                {5}
            </NumberOfStep>
            <div className='offset-1'/>
            <div className='col-7'>
                <Heading spanStyle={textStyle}>{t('texts:sell.my-account-area.company-address')}</Heading>
                <div className='ml-n3'>
                    <input type='text'
                           className='border-style'
                           onChange={e =>
                               handleCompanyInfoChange(e, 'companyAddress')}
                           value={localInfoFields['companyAddress']}
                           disabled={localInfoFields['registrationNumber'] === undefined}/>
                </div>
            </div>
        </div>
        <div id='field-phone-company-address' className='row mt-4'>
            <NumberOfStep
                thisField={localInfoFields['website']}
                prevField={localInfoFields['companyAddress']}>
                {6}
            </NumberOfStep>
            <div className='offset-1'/>
            <div className='col-7'>
                <Heading spanStyle={textStyle}>{t('texts:sell.my-account-area.website')}</Heading>
                <div className='ml-n3'>
                    <input className='border-style'
                           maxLength={25}
                           type='text'
                           onChange={e => handleCompanyInfoChange(e, 'website')}
                           value={localInfoFields['website']}
                           disabled={localInfoFields['companyAddress'] === undefined}/>
                </div>
            </div>
        </div>
        <AddCompanySellerButton
            localInfoFields={localInfoFields}
            setLocalInfoFields={setLocalInfoFields}
            setErrorMessage={setErrorMessage}
            props={props}
        />
    </div>
}
const openModal = (updateHandler, modalName) => {
    updateHandler(modalName, true);
};


const redirect2 = (url, props, location, navigate) => {
    console.log('redirect2', url, props.handleClose, location, navigate);
    if (props.handleClose !== undefined) props.handleClose();

    // console.log('redirect props', props, 'URL', url);
    if (location.pathname === url) {
        console.log('URL is the same as location');
        return;
    }
    console.log('URL is not the same as location.pathname', url,
        location.pathname, '...redirecting');
    navigate(url);
};


export {redirect2, openModal}

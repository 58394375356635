import React, {useState} from 'react';
import Heading from "../../../Search/SearchArea/Heading";
import plus from "../svg/plus.svg";
import CarHistoryModal from "./CarHistoryModal";

const InputFieldHistory = props => {

    console.log('InputFieldHistory', props);

    const [showModal, setShowModal] = useState(false);

    let numberClass = 'row mt-2 numbered-list-item'
    if (props.state.history.length === 0
        && (props.prevField !== undefined && props.prevField.length > 0)) numberClass += ' yellow';
    else if ((props.prevField === undefined || props.prevField.length === 0)
        && props.state.history.length === 0) numberClass += ' grey'

    return <div className={numberClass} id='row-history'>
        <div className='offset-1'/>
        <div className='col-9 pr-0' style={{marginLeft: '-1em'}}>
            <div style={{marginLeft: '1em'}}>
                <Heading>{props.title}</Heading>
            </div>
            <div className='row d-flex align-items-center'>
                <div className='col-10 pr-0'>{props.message}</div>
                <div className='col-2 px-0 d-flex justify-content-center'>
                    <img src={plus}
                         height={20}
                         style={{cursor: 'pointer'}}
                         onClick={() => setShowModal(true)}/>
                </div>
            </div>
        </div>

        <CarHistoryModal show={showModal} setShowModal={setShowModal}
                         history={props.state.history}
                         title={props.title}
                         state={props.state}
                         historyDictionary={props.historyDictionary}
                         updateHistory={props.updateHistory}
        />

    </div>

}

export default InputFieldHistory;
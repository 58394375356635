import React from 'react';
import { Modal} from 'react-bootstrap';
import {deleteCar} from "../functions/infoFunctions";
import {buyButtonStyle} from "../functions/offersFunctions";
import {useMediaQuery} from "react-responsive";
import {useMsal} from "@azure/msal-react";

const DeleteModal = props => {

    const {accounts} = useMsal();

    const isSmallScreen = useMediaQuery({maxWidth: '321px'});
    const headerFont = {fontSize: '1.4rem'};
    const textStyle = {fontSize: '1rem'}
    if (isSmallScreen) {
        headerFont.fontSize = '1.25rem';
        textStyle.fontSize = '0.9rem'
    }
    return <div>
        <Modal show={props.show} onHide={props.handleClose} size='' centered>
            <Modal.Header closeButton>
                <Modal.Title className='text-capitalize font-weight-bold d-flex w-100'>
                    <div className='d-flex col justify-content-start'
                         style={headerFont}>
                        {props.title}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row d-flex col justify-content-start font-weight-bold'>
                    <span className='col text-capitalize' style={textStyle}>
                       {props.car.make} {props.car.model} {props.car.year} {props.car.vin}
                    </span>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className='row w-100'>
                    <div className='container d-flex justify-content-between px-0 w-100 mb-4'>
                        <div
                            onClick={async () => {
                                await deleteCar(props.car,accounts);
                                props.refreshCars();
                                props.handleClose()
                            }}
                            className='col-6 d-flex justify-content-center align-items-center px-0'
                            style={{
                                ...buyButtonStyle,
                                width: 'auto', maxWidth: '9rem',
                                color: 'orangered', border: '1px solid orangered',
                                ...textStyle, opacity: 0.75
                            }}>
                                    <span className=' d-flex align-items-center'>
                                {props.yes}</span>
                        </div>
                        <div
                            onClick={() => props.handleClose()}
                            className='col-5 d-flex justify-content-center align-items-center mr-0'
                            style={{
                                ...buyButtonStyle, color: '#888', border: '1px solid #888',
                                width: 'auto', maxWidth: '9rem',
                                ...textStyle
                            }}>
                                    <span className=' d-flex align-items-center'>
                                    {props.no}</span>
                        </div>
                    </div>

                    {/*<div className='col-6 d-flex justify-content-start w-100'>*/}
                    {/*    <Button className='btn-light btn-outline-danger'*/}
                    {/*            style={buttonStyle}*/}
                    {/*            onClick={*/}
                    {/*                async () => {*/}
                    {/*                    await deleteCar(props.car);*/}
                    {/*                    props.refreshCars();*/}
                    {/*                    // props.setCar(undefined); //unneeded?*/}
                    {/*                    props.handleClose();*/}
                    {/*                }}>{props.yes}</Button>*/}
                    {/*</div>*/}
                    {/*<div className='col-6 d-flex justify-content-end'>*/}
                    {/*    <Button className='btn-primary w-100'*/}
                    {/*            style={buttonStyle}*/}
                    {/*            onClick={() => props.handleClose()}>{props.no}</Button>*/}
                    {/*</div>*/}
                </div>
            </Modal.Footer>
        </Modal>
    </div>
};
const buttonStyle = {fontSize: '.92rem'};


export default DeleteModal;
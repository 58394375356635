import Select from 'react-select';
import React, {useEffect, useState} from 'react';
import {optionSelectStyle} from './optionSelectStyle';
import {useTranslation} from "react-i18next";

const OptionSelect = props => {

    console.log('OptionSelect id:' + props.id, props);

    const {t} = useTranslation(['texts']);
    const [selected, setSelected] = useState({value: '', label: '--'})

    useEffect(() => {
        // console.log('useEffect in OptionSelect', selected)
        if (props.selected !== undefined || props.selected !== '' || props.selected !== false) {
            setSelected({value: props.selected, label: props.selected});
            // console.log('useEffect OptionSelect setSelected', props.selected);
        }
    }, [props.selected]);

    return (<div className="container" id={'option-select-container-' + props.id}>
        {props.children}

        <div className="row">
            <div className='m-0 w-100'>
                <Select
                    className={props.className}
                    value={selected}
                    onChange={e => props.handler(e.value)}
                    options={props.options}
                    styles={optionSelectStyle}
                    isSearchable={false}
                    isDisabled={props.isDisabled}
                    placeholder={t('texts:common.select')}
                    // menuIsOpen
                />
            </div>
        </div>
    </div>)
};

export default OptionSelect;

import React from 'react';
import {Modal} from 'react-bootstrap';

class ModalByLocation extends React.Component {

    render() {
        return (
            <React.Fragment>
                <Modal show={this.props.show} onHide={this.props.handleClose} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Select By Location</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{
                            width: '100%', height: '44px',
                            border: 'solid 1px rgba(0,0,0,0.25)', borderRadius: '3px'
                        }}>
                            <img src="../img/magnifying-glass.svg" height="20px" alt='glass'
                                 style={{position: 'relative', margin: '12px 12px', opacity: 0.4}}
                            />
                            <input style={{
                                opacity: 0.8, fontSize: '1.125rem',
                                border: 'none', height: '42px'
                            }}
                                   placeholder=" Enter a city"/>
                        </div>
                        <div style={{
                            height: '44px', lineHeight: '42px', verticalAlign: 'middle',
                            margin: '20px 12px 10px 12px',
                            borderTop: 'solid 0.5px rgba(0,0,0,0.3)',
                            borderBottom: 'solid 0.5px rgba(0,0,0,0.3)'
                        }}
                        >
                            <img src="../img/compass.svg" height="12px" style={{background: 'white'}}
                                 alt='compass'/>
                            <span style={{fontSize: 18, marginLeft: 5}}>Current location</span>
                        </div>
                        <div className='text-center'>
                            <img src='../img/poweredbygoogle.png' height='15px' alt='googlemaps'/>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        )
    }
}

export default ModalByLocation;

import React from 'react';
import './NumberOfStep.css'
import _ from "lodash";

const NumberOfStep = props => {

    let badgeColor = 'badge-light border border-light';

    const isNonEmptyString = field => !_.isEmpty(field) && !Array.isArray(field);
    const isNonEmptyArray = field => Array.isArray(field) && field.length > 0;

    // console.log('NumberOfStep', 'this.field',props.thisField, 'prevField',props.prevField)

    if (props.badgeColor === undefined) {
        if (isNonEmptyString(props.prevField)) badgeColor = 'badge-warning border border-success';
        if (isNonEmptyArray(props.prevField)) badgeColor = 'badge-warning border border-success';
        if (isNonEmptyString(props.thisField)) badgeColor = 'badge-success';
        if (isNonEmptyArray(props.thisField)) badgeColor = 'badge-success';
    } else badgeColor = props.badgeColor;
    const classname = `square text-center d-flex justify-content-center 
        align-items-center rounded-circle ${badgeColor}`;
    return <div className='col-1 d-flex align-items-center justify-content-center pr-0'> {/*d-flex align-items-center*/}
        <div className={classname}>
            {props.children}
        </div>
    </div>
};

export default NumberOfStep;


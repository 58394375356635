export const optionSelectStyle = {
    div: {
        color: '#555',
        fontSize: '0.875rem',
        // lineHeight: '32px',
        // height: '32px',
        border: 'solid 1px rgba(0,0,0,0.2)',
        borderRadius: '4px'
    },

    indicatorSeparator: (styles) => ({display: 'none'}),

    placeholder: (base, state) => ({
        ...base,
        paddingLeft: 0,
        marginLeft: '6px'
    }),
    singleValue: (base, state) => ({
        ...base,
        marginLeft: '6px',
        minHeight: '1px',
        height: '32px',
        paddingTop: '0',
        paddingBottom: '0',
        fontSize: '0.875rem',
        color: '#555',
        textTransform: 'capitalize'


    }),
    input: (base, state) => ({
        ...base,
        marginLeft: '6px',
    }),
    control: (base, state) => ({
        ...base,
        // border: 'none',
        border: 'solid 1px rgba(0,0,0,0.2)',
        boxShadow: 'none',
        '&:hover,&:focus,&:active': {
            border: '1px solid #ddd',
        },
        background: state.isDisabled ? '#e9ecef' : "#fff",

        minHeight: 32,
        height: 32,
        // lineHeight: 32,
        paddingTop: '0',
        paddingBottom: '0',
        fontSize: '0.875rem',

    }),
    dropdownIndicator: (base) => ({
        ...base,
        paddingTop: 0,
        paddingBottom: 0,
        // display:'none'
    }),
    clearIndicator: (base) => ({
        ...base,
        paddingTop: 0,
        paddingBottom: 0,
    }),
    container: (base, state) => ({
        ...base,
    }),
    valueContainer: (provided) => ({
        ...provided,
        minHeight: '32px',
        height: '32px',
    }),
    menuList: (provided, state) => ({
        ...provided,
        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,
        color: '#555',

    }),
    menu: (provided, state) => ({
        ...provided,
        marginTop: '2px',
        marginBottom: '4px',
        zIndex: 3
    }),
    option: (styles, {data, isDisabled, isFocused, isSelected}) => {
        return {
            ...styles,
            textTransform: 'capitalize',
            paddingLeft: '15px',
            minHeight: '1px',
            height: '32px',
            paddingTop: '0',
            paddingBottom: '0',
            backgroundColor:
                isSelected
                    ? '#888'
                    : isFocused
                    ? '#ccc'
                    : null,
            color:
                isSelected
                    ? 'white'
                    : isFocused
                    ? '#333'
                    : null,
            padding: '8px 10px',
            fontSize: '0.875rem',
            cursor: isDisabled ? 'not-allowed' : 'default',
            '&:active': {
                background: '#888',
            },
        }
    },
};
import React, {useEffect, useState} from "react";
import _ from "lodash";
import Select from "react-select";
import './css/vehicle.css';

const EditableAttributeLocation = ({
                                       title, setState, state, dictionary,
                                       placeholderCity, placeholderCountry,
                                       textStyle
                                   }) => {

    // const connector = new AzureCharacteristicsRESTConnector();
    const [showField, setShowField] = useState(false);

    useEffect(() => {
        console.log('useEffect at EditableAttributeLocation!');
        // connector.retrieveCountries().then(data => {
        //     console.log('countries', data)
        console.log('countries dictionary', dictionary)
        const copy = JSON.parse(JSON.stringify(state))
        copy.countries = dictionary;
        setState(copy);
        // });
    }, []);
    // console.log('EditableAttributeLocation title', title, 'state', state)
    // console.log('state.country + \', \' + state.city', state.country + ', ' + state.city)
    console.log('EditableAttributeLocation dictionary', dictionary);
    const country = dictionary.find(el => el.Name.toLowerCase() === state.country);
    console.log('dictionary.find(state.country)', state.country, country);

    const city = country.cities.find(el => el.Name.toLowerCase() === state.city);
    console.log('country.find(el => el.Name.toLowerCase() === state.city)', city);


    const handleKeyPress = (event) => {
        console.log(event.key);
        if (event.key === 'Enter') setShowField(!showField);
        console.log('state after enter', state);
    }
    // const location = _.truncate(_.capitalize(country.title + ', ' + city.title), {
    //     'length': 24
    // })

    return <div id='editable-attribute-location'
                className="row col-12 mx-0 d-flex justify-content-between px-0"
                style={textStyle}>

        <span className="text-left">{title}</span>
        <div className='col-auto d-flex justify-content-end pr-0'>
            <img height='17px' width='17px' className='button ml-1 mt-1 edit-icon' alt=''
                 onClick={() => setShowField(!showField)}/>
        </div>
        <div className='row col-12'/>
        <div className='d-flex justify-content-start px-0'>
            {!showField && <span
                className="text-left font-weight-bold text-capitalize">
                {country.title}{', '}{city.title}
            </span>}
        </div>
        {showField && <div className="col px-0 mt-1 d-flex justify-content-between">
            <div className='col-6 d-flex justify-content-start px-0 mx-0'>
                <Select className='w-100'
                        style={textStyle}
                        key='location-country-select'
                        placeholder={placeholderCountry}
                        options={state.countries !== undefined &&
                        state.countries.map(element => {
                            console.log('editable select location element ', element);
                            return {
                                label: _.capitalize(element.title),
                                value: element,
                            }
                        })}
                        onChange={async el => {
                            console.log('el country', el);
                            const cities = _.orderBy(el.value.cities, ['Population'],
                                'desc');
                            const copy = JSON.parse(JSON.stringify(state));
                            console.log('el.value.Name', el.value.Name);
                            copy.country = _.lowerCase(el.value.Name);
                            // console.log('el copy.country', copy.country);
                            // console.log('cities', cities)
                            copy.cities = cities.map(city => city.Name);
                            setState(copy);
                            console.log('after setState in change country', state)
                        }}
                />
            </div>
            <div className='col-6 d-flex justify-content-end px-0 mr-0 ml-1'>
                {/*{console.log('second', state)}*/}
                <Select className='w-100'
                        style={textStyle}
                        key='location-city-select'
                        placeholder={placeholderCity}
                        options={state.cities.map(el => {
                            return {
                                label: country.cities.find(entry =>
                                    entry.Name.toLowerCase() === el.toLowerCase()).title,
                                value: el
                            }
                        })}
                        onChange={el => {
                            console.log('city', el);
                            const copy = JSON.parse(JSON.stringify(state));
                            copy.city = el.value.toLowerCase();
                            let cityTitle = country.cities.find(entry => entry.Name.toLowerCase() === el.value.toLowerCase()).title;
                            console.log('el.value', el.value, 'el', el,
                                'dictionary.find(el => el.Name.toLowerCase() === state.country)',
                                cityTitle);
                            setState(copy);
                            console.log('copy after city', copy)
                        }}
                        onKeyDown={evt => handleKeyPress(evt)}
                        isDisabled={state.cities.length === 0}
                />

            </div>
        </div>}
    </div>
}
export default EditableAttributeLocation;
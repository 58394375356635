import React from 'react';
import {buyButtonStyle} from "../../MyOffers/functions/offersFunctions";
import {closeButtonColor} from "../../MyOffers/Modals/EditModal/EditModal";
import {useTranslation} from "react-i18next";
import {useMsal} from "@azure/msal-react";

export const ResetButton = props => {

    console.log('ResetButton props', props);
    const {t} = useTranslation(['texts']);
    const {accounts} = useMsal();

    return <div id='info-reset-button'
                className='px-4 d-flex justify-content-center align-items-center mr-0'
                onClick={() => {
                    console.log('ResetButton clicked');
                    // props.setLocalInfoFields({});
                    props.setShowInput({});
                    props.setErrorMessage({});
                    props.fetchAndUpdate(accounts[0].username)

                }}
                style={{...buyButtonStyle, ...closeButtonColor,}}>
                                <span className='d-flex align-items-center'>
                                    {t('texts:sell.car-ad-card.edit-modal.reset')}
                                </span>
    </div>

}
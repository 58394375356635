import React, {useEffect, useState} from 'react';
import {CloseButton, Modal} from 'react-bootstrap';
import {useTranslation} from "react-i18next";
import '../../Pages/Search/CarListing/Modal/css/filters.css';
import '../../Pages/Search/CarListing/Modal/css/modal.css';
import LogotypeHelper from "../../Pages/Home/utils/LogotypeHelper";
import Button from "react-bootstrap/Button";

const ModalByMake = props => {

    // console.log('ModalByMake props', props);

    const {t} = useTranslation();
    const logotypeHelper = LogotypeHelper();
    const [makes, setMakes] = useState([]);

    useEffect(() => {
        console.log('useEffect in ModalByMake', 'makes', makes);
        if (props.makes !== undefined && props.makes.length > 0) setMakes(props.makes);
    }, [props.make, props.makes]);

    const currentlySelectedMakeLogo = logotypeHelper.get(props.make.value.toLowerCase());

    return (
        <Modal show={props.show}
            // onHide={props.handleClose}
               animation={false}
               centered
               dialogClassName={props.isMobilePortrait ? "modal-fullscreen" : "modal-height-100"}
        >
            <Modal.Header className='d-flex justify-content-between'>
                <div className='offset-2'/>
                <div className='col-8 d-flex justify-content-center align-items-center'>
                    <Modal.Title>
                        <div style={{fontSize: '.92rem', fontWeight: 'bold', textTransform: 'uppercase'}}>
                            {t('modals.select-make')}
                        </div>
                    </Modal.Title>
                </div>
                <div className='col-2 d-flex justify-content-center align-items-center'>
                    <CloseButton onClick={() => props.updateMultipleModals({
                        'make': false, 'search': false, 'model': false
                    })}/>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <ul className="list-unstyled" style={{
                        overflow: 'auto',
                        border: 'none'
                    }}>
                        {
                            makes.map(
                                make => {
                                    return (<li key={make.id}
                                                className="list-group-item hover-item"
                                                style={makeModelListStyle.li}
                                                onClick={() => {
                                                    console.log('clicked make', make);
                                                    props.handleSwitch(make);
                                                }}
                                        >
                                            <div className="d-flex row"
                                                 style={makeModelListStyle.carItem}>
                                                <div className="col-4 d-flex justify-content-center align-items-center">
                                                    {logotypeHelper.get(make.name.toLowerCase())}
                                                </div>
                                                <div className="col-8">
                                                        <span key={make.name}
                                                              className='text-capitalize'
                                                              style={makeModelListStyle.carTitle}>{make.name}
                                                        </span>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })}
                    </ul>
                </div>
            </Modal.Body>
            <Modal.Footer className='modal-footer-bar-shadow'>
                <div className='col-12 d-flex justify-content-between align-items-center'>
                    <Button variant='link' style={{color: '#222', textDecoration: 'underline'}}
                            onClick={() => props.handleClose()}>
                        {t('texts:search.search-area.back-to-filters')}
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalByMake;

export const makeModelListStyle = {
    carItem: {
        width: '100%',
        height: '55px',
        lineHeight: '52px',
    },
    carTitle: {fontSize: '1rem', marginLeft: '5px'},
    li: {border: 'none', borderBottom: '1px solid #eee', padding: '.5rem 1.25rem'},
};
import React from 'react';
import OptionSelect from "../../OptionSelect/OptionSelect";
import Heading from "../../../Search/SearchArea/Heading";

const InputFieldMake = props => {
    // console.log('InputFieldMake', props)

    let numberClass = 'row mt-2 numbered-list-item'
    if (props.state.make === undefined) numberClass += ' yellow';

    return <div className={numberClass} id='row-make'>
        <div className='offset-1'/>
        <div className='col-9 pl-0'>
            <OptionSelect
                id='select-make'
                options={props.state.makes.map(make => {
                    return {
                        label: make.name, value: make
                    }
                })}
                selected={props.state.make !== undefined && props.state.make.name }
                handler={props.updateMake}>
                <Heading>
                    {props.title}
                </Heading>
            </OptionSelect>
        </div>
    </div>
};

export default InputFieldMake;
import React from 'react';
import {CloseButton, Modal} from "react-bootstrap";
import _ from "lodash";
import orders from "./img/orders.svg";
import {useTranslation} from "react-i18next";

const OrderHistoryModal = props => {

    console.log('OrderHistoryModal', props);
    const {t} = useTranslation(['texts'])

    const getDate = dateString => {
        const date = new Date(Date.parse(dateString));
        // const month = date.toLocaleString('default', { month: 'long' });
        // console.log("getDate",date, date.getUTCMonth()+1,date.getDate(),date.toUTCString());
        return date.getDate() + '-' + (date.getUTCMonth() + 1) + '-' + date.getFullYear();
    }

    return <Modal show={props.show} onHide={props.handleClose} centered key='history-modal'>
        <Modal.Header>
            <Modal.Title className='font-weight-bold text-uppercase d-flex w-100'
                         style={{fontSize: '0.75rem', color: '#555'}}>
                <div className='d-flex col justify-content-start align-items-center pl-0'>
                    <img src={orders} height={16} style={{marginRight: '1rem'}}/>
                    <span>{t('texts:sell.car-ad-card.upgrade-modal.upgrade-history')}</span>
                </div>
                <CloseButton onClick={() => props.handleClose()}/></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{minHeight: '150px'}}>
            {!_.isEmpty(props.upgradeOrders) && props.upgradeOrders.values.map(el =>
                <div key={el.RowKey} className='d-table mb-2 border w-100'
                     style={{fontSize: '0.75rem'}}>
                    <div className='d-table-row'>
                        <div className='d-table-cell'>ID</div>
                        <div className='d-table-cell'>{el.vin}</div>
                    </div>
                    <div className='d-table-row'>
                        <div className='d-table-cell'>{t('sell.car-ad-card.upgrade-modal.status')}</div>
                        <div className='d-table-cell'>{el.advStatus}</div>
                    </div>
                    <div className='d-table-row'>
                        <div className='d-table-cell'>{t('sell.car-ad-card.upgrade-modal.end-date')}</div>
                        <div className='d-table-cell'>{getDate(el.endDate)}</div>
                    </div>
                    <div className='d-table-row'>
                        <div className='d-table-cell'>{t('sell.car-ad-card.upgrade-modal.payment-date')}</div>
                        <div className='d-table-cell'>{getDate(el.paymentDate)}</div>
                    </div>
                    <div className='d-table-row'>
                        <div className='d-table-cell'>{t('sell.car-ad-card.upgrade-modal.amount')}</div>
                        <div className='d-table-cell'>{el.amount}</div>
                    </div>
                </div>)}
        </Modal.Body>
        <Modal.Footer>
            <div className='row w-100 d-flex justify-content-start' style={{left: '1rem', opacity: '0.7'}}
                 onClick={() => {
                     if (props.modals.top) props.setModals({upgrade: false, history: false, payment: false, top: false})
                     else props.setModals({upgrade: true, history: false, payment: false, top: false})
                 }
                 }
            ><img className='' src='/img/angle-down-solid-black.svg' height='24px'
                  style={{transform: 'rotate(90deg)'}}/></div>
        </Modal.Footer>
    </Modal>

}

export default OrderHistoryModal;
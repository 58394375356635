import React from 'react';
import Select from 'react-select';
// import {fuel as data} from '../../../Data/Fuel/Fuel'

const SearchOptionSelectNew2 = props => {

    console.log('SearchOptionSelectNew2 props', props);

    // let title = props.defaultTitle;
    const data = props.data;
    const type = props.type;

    const options = Object.keys(data).map(key => {
        let title = data[key];
        title = title.title;
        // title = title.title.charAt(0).toUpperCase() + title.title.slice(1);
        return ({
            value: key, label: title
        })
    });

    let containerClass = 'container'
    if (props.bsClass !== undefined) containerClass += ' ' + props.bsClass;
    const defaultTitle = props.defaultTitle;
    console.log('defaultTitle', defaultTitle);
    console.log('props.state.options[type].value', props.state.options[type].value);
    return (
        <div id={'search-field-n-' + props.type.toLowerCase()} className={containerClass}>
            <div className="row">
                <div className='m-0 w-100'>
                    <Select
                        value={
                            props.state.options[type].value !== 'any' &&
                            props.state.options[type].value !== undefined &&
                            {label: props.state.options[type].title}}
                        onChange={(e) => {
                            props.handler(type, e.value, e.label);
                            // console.log('Select2 onchange', e);
                        }}
                        options={options}
                        styles={style}
                        placeholder={defaultTitle}
                        isSearchable={false}
                        classNamePrefix="react-select"
                    />
                </div>
            </div>
        </div>
    )
};

export const style = {
    div: {
        color: '#555',
        fontSize: '0.875rem',
        border: 'solid 1px rgba(0,0,0,0.2)',
        borderRadius: '4px'
    },

    indicatorSeparator: (styles) => ({display: 'none'}),

    placeholder: (base, state) => {
        // console.log('placeholder', state, base)
        return ({
            ...base,
            paddingLeft: 0,
            marginLeft: '6px',
            fontWeight: 600,
            color: '#212529'
        })
    },
    singleValue: (base, state) => {
        // console.log('singleValue', base, state);
        // if()
        return ({
            ...base,
            marginLeft: '6px',
            // minHeight: '1px',
            // height: '32px',
            paddingTop: '0',
            paddingBottom: '0',
            // fontSize: '0.875rem',
            fontWeight: 600,
            textTransform: 'uppercase',
            color: '#212529'
            // color: 'red',
        })
    },
    input: (base, state) => ({
        ...base,
        marginLeft: '6px',
    }),
    control: (base, state) => ({
        ...base,
        // border: 'none',
        border: 'solid 1px rgba(0,0,0,0.2)',
        boxShadow: 'none',
        '&:hover,&:focus,&:active': {
            border: '1px solid #ddd',
        },
        background: "#fff",
        minHeight: 40,
        height: 40,
        // lineHeight: 32,
        paddingTop: '0',
        paddingBottom: '0',
        fontSize: '0.875rem',
        cursor: 'pointer',
        userSelect: 'none'

    }),
    dropdownIndicator: (base) => ({
        ...base,
        paddingTop: 0,
        paddingBottom: 0,
        // display:'none'
    }),
    // clearIndicator: (base) => ({
    //     ...base,
    //     paddingTop: 0,
    //     paddingBottom: 0,
    // }),
    // container: (base, state) => ({
    //     ...base,
    // }),
    valueContainer: (provided) => ({
        ...provided,
        minHeight: '32px',
        height: '32px',
    }),
    menuList: (provided, state) => ({
        ...provided,
        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,
        color: '#555',

    }),
    menu: (provided, state) => ({
        ...provided,
        marginTop: '2px',
        marginBottom: '4px',
        zIndex: 3
    }),
    option: (styles, {data, isDisabled, isFocused, isSelected}) => {
        // const color = chroma(data.color);
        return {
            ...styles,
            paddingLeft: '15px',
            minHeight: '1px',
            height: '32px',
            paddingTop: '0',
            paddingBottom: '0',
            backgroundColor:
                isSelected
                    ? '#888'
                    : isFocused
                        ? '#aaa'
                        : null,
            color:
                isSelected
                    ? 'white'
                    : isFocused
                        ? 'white'
                        : null,
            padding: '8px 10px',
            fontSize: '0.875rem',
            cursor: isDisabled ? 'not-allowed' : 'default',
            '&:active': {
                background: '#888',
            },
        }
    },
};

export default SearchOptionSelectNew2;

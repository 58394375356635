import React, {useEffect, useState} from 'react';
import _ from "lodash";
import {validateCompanyInfo} from "../utils/utils";
import {AzureSellersRESTConnector} from "../../../../../Integration";
import {useTranslation} from "react-i18next";
import './/myaccount.css';

export const AddCompanySellerButton = ({
                                           localInfoFields, setLocalInfoFields, errorMessage,
                                           setErrorMessage, props
                                       }) => {

    console.log('AddCompanySellerButton', localInfoFields, props);

    const {t} = useTranslation(['texts']);

    const sellersRESTConnector = new AzureSellersRESTConnector();
    const [fieldsOK, setFieldsOK] = useState(false);

    useEffect(() => {
        if (!_.isEmpty(localInfoFields)) {
            setFieldsOK(areFieldsOk(localInfoFields));
            console.log('AddCompanySellerButton localInfoFields is not empty', localInfoFields, fieldsOK);
        } else {
            console.log('AddCompanySellerButton localInfoFields is empty', localInfoFields);
        }
    }, [localInfoFields]);

    const areFieldsOk = ({...localInfoFields}) => {
        const isOK =
            localInfoFields['juridicalType'] === 'company'
            && !_.isEmpty(localInfoFields['name']);
        console.log('AddCompanySellerButton areFieldsOk', isOK, localInfoFields);
        return isOK
    }

    const handleSubmitAddCompany = async (localInfoFields, setLocalInfoFields, setErrorMessage, props) => {
        const {
            juridicalType,
            name,
            registrationNumber,
            phone,
            companyAddress,
            website
        } = localInfoFields;

        if (!_.isEmpty(name) && !_.isEmpty(juridicalType)) {
            const validationMessage = validateCompanyInfo(juridicalType, name, phone, registrationNumber,
                companyAddress, website, t);
            console.log('AddCompanySellerButton validation result', validationMessage);

            setErrorMessage(validationMessage);

            if (_.isEmpty(validationMessage)) {
                const requestObject = {
                    type: juridicalType,
                    name,
                    phone,
                    registrationNumber,
                    companyAddress,
                    ...(website && {website}),
                };

                console.log('AddCompanySellerButton can proceed', validationMessage);

                const result = await sellersRESTConnector.addSeller(requestObject);
                window.sessionStorage.setItem('sellerInfo', JSON.stringify(result));
                props.updateSellerInfo(result);
                setLocalInfoFields({});
            } else {
                console.warn('!cannot proceed', validationMessage)
            }
        }
    };

    return <div id='submit-company-data' className='row mt-4'>
        <div className='col-1 d-flex align-items-center justify-content-center pr-0'/>
        <div className='offset-1'/>


        <button id='submit-company-data-button'
                className='submit-button'
                disabled={!fieldsOK && _.isEmpty(errorMessage)}
                onClick={() => handleSubmitAddCompany(
                    localInfoFields, setLocalInfoFields, setErrorMessage, props, t)}>
            <span>{t('texts:sell.my-account-area.save-data')}</span>
        </button>

    </div>
}


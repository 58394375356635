const rootApiURI = process.env.REACT_APP_ROOT_API_URI;
const apiKeyName = process.env.REACT_APP_OCP_API_NAME;
const ordersAPIkey = process.env.REACT_APP_ORDERS_API_KEY;

class AzureUpgradeOrdersRESTConnector {

    retrieveUpgradeOrders = async vin => {
        try {
                const response = await fetch(`${rootApiURI}/orders/${vin}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                        [apiKeyName]: ordersAPIkey
                    }
                });
                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                let json = await response.json();
                console.log("retrieveUpgradeOrders: " + vin, json);
                return json;
        } catch (error) {
            console.warn('retrieveUpgradeOrders error', error);
        }
    };

    createOrderPayPal = async props => {

        try {
                const response = await fetch(`${rootApiURI}/orders/paypal`, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                        [apiKeyName]: ordersAPIkey,
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    },
                    body: JSON.stringify(
                        {
                            amount: props.amount,
                            vin: props.car.vin,
                        }
                    )

                });

                const json = await response.json();
                console.log('json', json);
                return json.orderID;
        } catch (error) {
            console.warn('createOrderPayPal error:', error);
        }
    }

    approveOrderPayPal = async orderData => {
        try {
                const response = await fetch(`${rootApiURI}/orders/paypal`,
                    {
                        method: 'PUT',
                        headers: {
                            [apiKeyName]: ordersAPIkey,
                            'content-type': 'application/json',
                            Authorization: `Bearer ${localStorage.getItem('token')}`
                        },
                        body: JSON.stringify({orderID: orderData.orderID})
                    })
                const json = await response.json();
                console.log('approveOrderPayPal response', json);
                return json;
        } catch (error) {
            console.warn('approveOrderPayPal error', error);
        }
    }

    createOrderStripe = async props => {
        console.log('createOrderStripe props', props);

        try {
                const response = await fetch(`${rootApiURI}/orders/stripe`,
                    {
                        method: 'POST',
                        headers: {
                            'content-type': 'application/json',
                            [apiKeyName]: ordersAPIkey,
                            Authorization: `Bearer ${localStorage.getItem('token')}`
                        },
                        body: JSON.stringify({
                            vin: props.car.vin,
                            amount: props.amount
                        })
                    });
                const json = await response.json();
                console.log('createOrderStripe json', json);
                return json;
        } catch (error) {
            console.warn('createOrderStripe error', error);
        }
    }
}

export {AzureUpgradeOrdersRESTConnector}
import React from 'react';

const Input = props => {
    // console.log('Input props', props);

    return (
        <div>
            {props.children}
            <div style={styles.div} className='d-flex justify-content-start'>
                <input
                    type={props.type === undefined ? 'text' : props.type}
                    value={props.value || ''}
                    // name={props.key}
                    disabled={props.disabled}
                    onChange={e => props.handler(e.target.value)}
                    style={{width: '100%'}}
                    className="search-options-price-input text-left form-control"
                    // className="text-left form-control"
                    step={props.step}
                    min={props.min}
                    max={props.max}
                    maxLength={props.maxLength}
                    minLength={props.minLength}
                    // placeholder={props.placeholder !== undefined && props.placeholder}
                />
            </div>
        </div>
    )
};

export {Input};

const styles = {
    div: {
        color: '#555',
        fontSize: '0.875rem',
        lineHeight: '32px',
        height: '32px',
        // border: 'solid 0.2px rgba(0,0,0,0.2)',
        // borderRadius: '4px',
    }
};

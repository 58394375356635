import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom'

import {useTranslation} from "react-i18next";
import AzureSearchConnector from "../../Integration/Search/AzureSearchConnector";
import CarPageBigScreen from "./CarPageBigScreen";
import CarPageMobilePortrait from "./CarPageMobilePortrait";
import {useMediaQuery} from "react-responsive";
import {LoadingIndicator} from "../../Shared/FetchImage/LoadingIndicator";

const Car = props => {
    // console.log('Car', props);
    const {id} = useParams();

    console.log('Car id', id);
    const [done, setDone] = useState(false);
    const connector = new AzureSearchConnector();

    const isBigScreen = useMediaQuery({minWidth: 999})
    const isMobilePortrait = useMediaQuery({orientation: 'portrait'});
    const isMobileLandscape = useMediaQuery({orientation: 'landscape'});

    const {t} = useTranslation(['texts']);
    const fetchCar = async id => {
        const r = await connector.getPublicDocument(id);
        console.log('Car fetchCar', id, 'r', r);
        if (r.status === 200) {
            const json = await r.json();
            props.updateSelectedCar(json);
        }
    }

    useEffect(() => {
        console.log('Car useEffect', 'props.selectedCar', props.selectedCar, 'id', id, 'done', done);
        if (!done) {
            console.log('Car useEffect fetchCar', props.selectedCar);
            fetchCar(id).then(() => setDone(true));
        }
    }, [id]);

    if (props.selectedCar !== undefined) {
        console.log('props.selectedCar', props.selectedCar);

        const car = props.selectedCar;

        if (isBigScreen) {
            return <CarPageBigScreen car={car}/>
        } else if (isMobilePortrait && !isBigScreen)
            return <CarPageMobilePortrait car={car}/>
        else {
            return <CarPageMobilePortrait car={car}/>
        }

    } else if (id === undefined) return (
        <div className='d-flex column justify-content-center mt-5'>
             <span className="my-4" style={{fontSize: '1rem', fontWeight: 'normal'}}>
                    Please select a car!
            </span>
        </div>);

    else if (!done) return (

        <div className='d-flex justify-content-center mt-5'>
            {/* <span className="my-4" style={{fontSize: '1.15rem', fontWeight: 'normal'}}>*/}
            {/*        Car loading...*/}
            {/*</span>*/}
            <LoadingIndicator/>
        </div>)

    else return (
            <div className='d-flex column justify-content-center mt-5'>
             <span className="my-4 text-center" style={{fontSize: '1rem', fontWeight: 'normal'}}>
                    {t('texts:car.car-not-found')}
            </span>
            </div>)
};


export default Car;



import React from "react";
import {useTranslation} from "react-i18next";
import Select from "react-select";
import * as LanguageUtils from '../../../../Integration/Utils/LanguageUtils';

const FilterAreaBigScreen = props => {

    console.log('FilterAreaBigScreen props');

    const {t, i18n} = useTranslation(['texts', 'data-for-ui']);
    const result = t('search.car-listing.result');
    const results = t('search.car-listing.results');
    const sort_d = t('data-for-ui:sort', {returnObjects: true});
    const sortKeys = Object.keys(sort_d);
    console.log('sortKeys', sortKeys);

    return <div id='filter-area-big-screen'
                className="row d-flex justify-content-between">
        <div className="col d-flex align-items-center"
             style={{height: '2rem'}}>
            <span
                className="mr-auto p2 align-items-center text-capitalize"
                style={styles.resultsSpan}>{results}{':\u00A0'}{props.results}
            </span>

            <div className="d-flex align-items-center justify-content-end pr-0" id='filter-selector'>
                <div className="d-flex justify-content-between align-items-center pl-2"
                     id="dropdownMenuButton"
                     style={i18n.language && i18n.language.toLowerCase() === 'ru' ? {width: '275px'} : {width: '215px'}}
                     data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <Select
                        className='w-100'
                        options={sortKeys.map(k => {
                            return {label: LanguageUtils.getTitleFromDictionary(k, sort_d), value: k}
                        })}
                        isSearchable={false}
                        // menuIsOpen
                        value={{label: LanguageUtils.getTitleFromDictionary(props.sort, sort_d), value: props.sort}}
                        onChange={v => props.updateSort(v.value)}
                        styles={selectStyle}
                    />
                </div>
            </div>
        </div>
    </div>
};

export default FilterAreaBigScreen;

const selectStyle = {
    div: {
        color: '#555',
        fontSize: '0.875rem',
        border: 'solid 1px rgba(0,0,0,0.15)',
    },

    indicatorSeparator: (styles) => ({display: 'none'}),

    // placeholder: (base, state) => ({
    //     ...base,
    //     paddingLeft: 0,
    //     marginLeft: '6px'
    // }),
    singleValue: (base, state) => ({
        ...base,
        // marginRight: '6px',
        minHeight: '1px',
        // height: '24',
        paddingTop: '0',
        paddingBottom: '0',
        fontSize: '0.875rem',
        color: '#555',
        width: '215px'
    }),
    // input: (base, state) => ({
    //     ...base,
    //     marginLeft: '6px',
    // }),
    control: (base, state) => ({
        ...base,
        // border: 'none',
        border: 'solid 1px rgba(0,0,0,0.15)',
        boxShadow: 'none',
        '&:hover,&:focus,&:active': {
            border: '1px solid #ddd',
        },
        background: "#fff",
        minHeight: 32,
        height: 32,
        // lineHeight: 32,
        paddingTop: '0',
        paddingBottom: '0',
        fontSize: '0.875rem',

    }),
    dropdownIndicator: (base) => ({
        ...base,
        paddingTop: 0,
        paddingBottom: 0,
        // display:'none'
    }),
    // clearIndicator: (base) => ({
    //     ...base,
    //     paddingTop: 0,
    //     paddingBottom: 0,
    // }),
    // container: (base, state) => ({
    //     ...base
    // }),
    valueContainer: (provided) => ({
        ...provided,
        minHeight: '32px',
        height: '32px',
    }),
    menuList: (provided, state) => ({
        ...provided,
        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,
        color: '#555',

    }),
    menu: (provided, state) => ({
        ...provided,
        marginTop: '2px',
        marginBottom: '4px',
        zIndex: 3
    }),
    option: (styles, {data, isDisabled, isFocused, isSelected}) => {
        // const color = chroma(data.color);
        return {
            ...styles,
            // paddingLeft: '15px',
            // minHeight: '1px',
            height: '32px',
            // paddingTop: '0',
            // paddingBottom: '0',
            backgroundColor:
                isSelected
                    ? '#888'
                    : isFocused
                        ? '#aaa'
                        : null,
            color:
                isSelected
                    ? 'white'
                    : isFocused
                        ? 'white'
                        : null,
            padding: '5px 0 10px 12px',
            fontSize: '0.875rem',
            cursor: isDisabled ? 'not-allowed' : 'default',
            '&:active': {
                background: '#888',
            },
        }
    },
};
const styles = {
    resultsSpan: {fontSize: '0.875rem'},
    sortSpan: {fontWeight: 'bold', fontSize: '0.875rem'},
    dropdownMenu: {height: '32px', width: '230px'},
    dropdownMenuBtn: {
        width: '100%',
        fontSize: '0.875rem',
        lineHeight: '26px',
        border: 'solid 0.5px rgba(0,0,0,0.15)',
        borderRadius: '0.5rem'
    },
    dropdownMenuItem: {fontSize: '0.875rem', lineHeight: '0.875rem', width: '215px'},

};

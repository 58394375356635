import React, {useCallback, useEffect, useMemo, useState} from 'react';
import 'rc-slider/assets/index.css';
import './custom.css';
import _, {debounce} from 'lodash';
import {formatNumberWithSpaces} from "../../utils/searchUtils";
import Slider from "rc-slider";

const RangeSlider2 = ({bsClass, data, type, limits, step, title, changeRangeSlider}) => {

    const [displayValue, setDisplayValue] = useState(data[type].value); //initialize with previous values
    // console.log('RangeSlider2 ', type, ' displayValue', displayValue);

    const debouncedChangeRangeSlider = useCallback(
        debounce((value, type) => changeRangeSlider(value, type), 400),
        [changeRangeSlider]
    );

    useEffect(() => {
        console.log('RangeSlider2 useEffect', data[type]);
        if (_.isEqual(data[type].value, limits)) {
            console.log('RangeSlider2 useEffect resetting ', type, ' to ', limits);
            setDisplayValue(limits); //reset displayValue to limits: user clicked reset button
        }
    }, [data[type].value]);

    const containerClass = useMemo(() => {
        let cls = 'container';
        if (bsClass !== undefined) cls += ' ' + bsClass;
        return cls;
    }, [bsClass]);

    if (!data) return null;

    return (
        <div id={'search-range-' + type.toLowerCase()} className={containerClass}>
            <div className='row'>
                <div className="input-group">
                    <div className="input-group-prepend col px-0">
                        <span style={rangeTitleStyle}
                              className="input-group-text text-uppercase font-weight-bold col"
                              id={`range-title-${type}`}>{title}</span>
                    </div>
                    <input
                        id='range-input-1'
                        type="text"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        className="form-control col text-center p-0 "
                        aria-label={`range-input-1-${type}`}
                        onChange={e => {
                            let inputNumber = e.target.value;
                            inputNumber = inputNumber.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                            inputNumber = inputNumber.replace(/^0+/, ''); // Remove leading zeros
                            const value = inputNumber === '' ? 0 : parseInt(inputNumber); // Convert to a number

                            if (value >= 0 && value <= (type !== 'year' ? limits[1] * 5 : limits[1])) {
                                setDisplayValue([value, displayValue[1]]);
                                changeRangeSlider([value, displayValue[1]], type);
                            }
                        }}
                        value={formatNumberWithSpaces(displayValue[0], type)}
                    />
                    <input
                        id='range-input-2'
                        type="text"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        className="text-left form-control col-auto text-center p-0 "
                        aria-label={`range-input-2-${type}`}
                        onChange={e => {
                            let inputNumber = e.target.value;
                            inputNumber = inputNumber.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                            inputNumber = inputNumber.replace(/^0+/, ''); // Remove leading zeros
                            const value = inputNumber === '' ? 0 : parseInt(inputNumber); // Convert to a number

                            if (value >= 0 && value <= (type !== 'year' ? limits[1] * 12 : limits[1])) {
                                setDisplayValue([displayValue[0], value]);
                                changeRangeSlider([displayValue[0], value], type);
                            }
                        }}
                        value={formatNumberWithSpaces(displayValue[1], type)}
                    />

                    {displayValue[1] === limits[1] && type !== 'year' && (
                        <span id='range-input-2-plus' className="plus-sign-overlay">+</span>
                    )}
                </div>

            </div>
            <div className='mr-0 pr-0 mb-4 mt-1'
                 id='slider-container'>

                <Slider
                    range={true}
                    key={type}
                    defaultValue={[limits[0], limits[1]]}
                    min={limits[0]}
                    max={limits[1]}
                    value={displayValue}
                    step={step}
                    allowCross={false}
                    onChange={e => {
                        // console.log('onChange', e);
                        setDisplayValue(e);
                        debouncedChangeRangeSlider(e, type);
                    }}
                    trackStyle={[{backgroundColor: 'red'}]}
                    handleStyle={[handleStyle, handleStyle]}
                    railStyle={{backgroundColor: '#ccc'}}
                />
            </div>
        </div>
    )

};
const handleStyle = {
    backgroundColor: '#c8c8c8',
    width: '20px',
    height: '20px',
    top: 0,
    bottom: 0,
    margin: 'auto',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    boxShadow: 'rgb(0 0 0 / 20%) 4px 4px 5px'
}
let rangeTitleStyle = {fontSize: '0.875rem', color: '#212529', userSelect: 'none'};


export default RangeSlider2;
import React from 'react';
import {useTranslation} from "react-i18next";

const ResetFilterButton = props => {

    const {t} = useTranslation();

    console.log('ResetFilterButton props', props);

    return (
        <div id='reset-filter-area' className="col-12 justify-content-between py-0 px-0 d-flex">
            <span style={{fontSize: '0.75rem', color: '#aaa'}}>
                {t('search.search-area.active-search-filters')}</span>
            <span onClick={() => props.reset()}
                  style={{fontSize: '0.75rem', color: '#222', fontWeight: 300, textDecoration: 'underline',
                  cursor:'pointer'}}>
            {t('search.search-area.reset')}
        </span>
        </div>
    );
}

export default ResetFilterButton;

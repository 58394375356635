import React, {useEffect, useState} from 'react';
import {Route, useLocation, Link, Routes} from "react-router-dom";
import './footer-pages.css';
import {useTranslation} from "react-i18next";
import {useMediaQuery} from "react-responsive";
import Footer2 from "../Footer/Footer2";
import './infopages.css';
import {validateEmail, validateTextRange} from "../../Pages/Car/Components/ContactForm/helpers";
import {
    composeMessage,
    loadRecaptcha,
    recaptchaKey,
    submitSupportMessage
} from "../../Integration/Email/emailFunctions";
import {EmailSentStatus} from "./EmailSentStatus";

const Company = props => {

    console.log('Company props', props);

    const {t} = useTranslation(['support', 'texts']);
    const isSmallScreen = useMediaQuery({maxWidth: '450px'});
    let companySideMenuClass = 'col-3';
    let companyMainTextArea = 'col-8';
    let contactUsDiv = 'col-7 pl-0 mt-2';
    const mainTextStyle = {fontSize: '1rem'};

    if (isSmallScreen) {
        companySideMenuClass = 'd-none';
        companyMainTextArea = 'col-12';
        mainTextStyle.fontSize = '0.9rem';
        contactUsDiv = 'col-12 pl-0 mt-2'
    }


    const [replyEmail, setReplyEmail] = useState('');
    const [messageText, setMessageText] = useState('');

    const [fieldsAreValid, setFieldsAreValid] = useState(false);
    const [sendingDisabled, setSendingDisabled] = useState(false);


    const [sendButtonStyle, setSendButtonStyle] = useState({opacity: .5})
    const setActiveStyle = () => setSendButtonStyle({opacity: 1});
    const setInactiveStyle = () => setSendButtonStyle({opacity: .5});

    const [emailSent, setEmailSent] = useState(false);
    const [emailStatus, setEmailStatus] = useState('checking');

    let preMsg = t('texts:search.car-card.contact-form.msg-pre') + ': ' + ' (' + replyEmail + ')\n\n';
    let afterMsg = '\n' + t('texts:search.car-card.contact-form.msg-after');


    useEffect(() => {

        const emailRange = validateTextRange(replyEmail, 5, 60);
        const emailFormat = validateEmail(replyEmail);
        const textRange = validateTextRange(messageText, 5, 250);

        console.log('useEffect in Company', 'emailRange', emailRange, 'emailFormat', emailFormat, 'textRange', textRange);
        if (emailRange && emailFormat && textRange && !sendingDisabled) {
            setFieldsAreValid(true);
            setActiveStyle()
        } else {
            setFieldsAreValid(false);
            setInactiveStyle();
        }

    }, [replyEmail, messageText]);

    useEffect(() => {
        console.log('useEffect in Company, preparing to load recaptcha')
        loadRecaptcha();
    }, []);

    const location = useLocation();
    const pathname = location.pathname;
    console.log('Company pathname', pathname, location);

    return <div id='company' className='root-container-style'>
        <div className='container level-one-container'>
            <h5 style={{fontSize: '0.875rem', fontWeight: 600, textTransform: 'uppercase'}}>
                {t('texts:footer.company-info')}</h5>
            <div className='row'>
                <div className={companySideMenuClass} id='company-side-menu'>
                    <ul className='list-unstyled'>
                        {/*<li>Company info</li>*/}
                        {getMenuItem('about', t('texts:footer.about'), location)}
                        {getMenuItem('legal', t('texts:footer.legal'), location)}
                        {getMenuItem('protection', t('texts:footer.data-protection'), location)}
                        {getMenuItem('contact', t('texts:footer.contact'), location)}
                    </ul>
                </div>
                <div id='company-main'
                     className={companyMainTextArea}
                     style={{minHeight: '300px', ...mainTextStyle}}>
                    <Routes>
                        <Route path='about'
                               element={<About/>}>
                        </Route>
                        <Route path='legal'
                               element={<Legal/>}>
                        </Route>
                        <Route path={'protection'}
                               element={<Protection/>}>
                        </Route>

                        {/*TODO Refactor Contact*/}

                        <Route path={'contact'}
                               element={
                                   <div className={contactUsDiv} id='contact-us-div'>
                                       <p className='header-h1'>{t('company.contact.contact-us')}</p>
                                       <p>+372 5855 7507</p>
                                       <p>info@caruniverse.ee</p>
                                       <>
                                           {!emailSent ? <div id='write-message-form'>
                                                   <label
                                                       style={{
                                                           fontWeight: 'bold',
                                                           fontSize: '1rem',
                                                           marginBottom: '.25rem'
                                                       }}>{t('company.contact.write-a-message')}
                                                   </label>

                                                   <input className="mb-1 contact-form-input"
                                                          id="contact-us-email"
                                                          autoComplete='off'
                                                          type='email'
                                                          value={replyEmail}
                                                          maxLength={50}
                                                          placeholder={t('company.contact.your-email')}
                                                          onChange={e => {
                                                              setReplyEmail(e.target.value);
                                                              // console.log(e.target.value);
                                                          }}
                                                          style={{
                                                              width: '100%',
                                                              borderRadius: '.25rem',
                                                              border: '2px solid #ddd'
                                                          }}
                                                   />
                                                   <textarea id='textarea'
                                                             className='contact-form-input'
                                                             rows={6}
                                                             minLength={5}
                                                             maxLength={200}
                                                             style={{
                                                                 width: '100%',
                                                                 borderRadius: '.25rem',
                                                                 border: '2px solid #ddd',
                                                                 marginTop: '.25rem'
                                                             }}
                                                             placeholder={t('company.contact.your-message')}
                                                             value={messageText}
                                                             onChange={(e) => {
                                                                 setMessageText(e.target.value);
                                                                 // console.log('messageText', e.target.value);
                                                             }}
                                                   />

                                                   <div id='send-message-button'
                                                        className='d-flex justify-content-end'>
                                                       <div
                                                           className='email-button col-4 d-flex justify-content-center align-items-center'
                                                           style={{borderRadius: 0, ...sendButtonStyle}}
                                                           onClick={async () => {
                                                               if (fieldsAreValid && !sendingDisabled) {

                                                                   setSendingDisabled(true);
                                                                   setEmailSent(true);
                                                                   setInactiveStyle();

                                                                   window.grecaptcha.ready(() => {
                                                                       console.log('recaptcha ready');

                                                                       window.grecaptcha.execute(recaptchaKey, {action: 'submit'}).then(async token => {

                                                                           const message = composeMessage(preMsg, messageText, afterMsg);
                                                                           const response = await submitSupportMessage(token, replyEmail, message);

                                                                           console.log('message response...', response);

                                                                           if (response.emailStatus === 'sent') {
                                                                               setEmailStatus('sent');
                                                                               console.log('email sent');
                                                                           } else {
                                                                               setEmailStatus('not sent')
                                                                               console.log('email  not sent', response.emailStatus);
                                                                           }
                                                                           setEmailStatus(response.emailStatus);
                                                                       });

                                                                   });
                                                                   console.warn('send email');


                                                                   setMessageText('');
                                                                   setReplyEmail('');
                                                                   setTimeout(() => {
                                                                       setSendingDisabled(false)
                                                                   }, 10000);
                                                               } else {
                                                                   console.warn('message or email validation error');
                                                               }
                                                           }
                                                           }>
                                                           <span>{t('company.contact.send')}</span>
                                                       </div>
                                                   </div>
                                               </div> :
                                               <div className='px-0 container-fluid'>
                                                   <div className='d-flex justify-content-between my-3 px-0'
                                                        id='sent-support-email'>
                                                       <div
                                                           className='col-5 pl-0'>{t('texts:search.car-card.contact-form.sent-status-title')}</div>
                                                       {' '}
                                                       <div className='col-7 pr-0 text-right'>
                                                           <EmailSentStatus emailStatus={emailStatus}/>
                                                       </div>
                                                   </div>
                                               </div>}
                                       </>
                                   </div>
                               }>
                        </Route>
                    </Routes>
                </div>
            </div>
        </div>


        <Footer2/>
    </div>
}
export default Company;

const getMenuItem = (item, title, location) => {
    let style = {
        fontWeight: 300, fontSize: '0.875rem', paddingTop: '2px', paddingBottom: '2px',
        marginLeft: '0rem'
    };

    const itemPath = `/company/${item.toLowerCase()}`;
    if (location.pathname.includes(itemPath)) {
        style = {...style, fontWeight: 600};
    }

    return <li style={style}>
        <Link to={itemPath}>{title !== undefined ? title : item}</Link>
    </li>
}


export const About = () => {

    const {t} = useTranslation(['support', 'texts']);

    return <div id='about'>
        <p className='header-h1'>{t('company.about.about-us')}</p>
        <p className='mt-1 mb-3'>{t('company.about.we-are-online-car-marketplace')}</p>
        <p className='mt-2 mb-3'>{t('company.about.we-love-cars')}{' '}<span
            style={{color: 'red'}}>{'\u2665'}</span>{' '}
            {t('company.about.looking-through-buyer-eyes')}<br/><br/>
            {t('company.about.best-tech-to-express-our-passion')} </p>
        <p className='mt-2 mb-4'>{t('company.about.our-vision')}</p>
        <p className='header-h2'>{t('company.about.company-details')}</p>
        <ul className='list-unstyled'>
            <li className='my-2'>Caruniverse OÜ</li>
            <li className='my-2'>16774206</li>
            <li className='my-2'>Tartu mnt 55, Tallinn</li>
        </ul>
    </div>
}

export const Legal = () => {
    const {t} = useTranslation(['support', 'texts']);

    return <div id='legal'>
        <p className='header-h1'>{t('texts:footer.legal')}</p>
        <p>{t('company.legal.the-platform')}</p>

        <p className='header-h2 mt-4 mb-2'>{t('company.legal.definitions')}</p>
        <p>{t('company.legal.caruniverse-platform-system')}</p>
        <p>{t('company.legal.users-seek-or-add-cars')}</p>
        <p>{t('company.legal.two-categories-of-users')}</p>
        <p>{t('company.legal.datastore')}</p>
        <p>{t('company.legal.data-includes-photos-and-metadata')}</p>

        <p className='header-h2 mt-4 mb-2'>{t('company.legal.services')}</p>
        <p>{t('company.legal.user-interaction-with-system')}</p>
        <p>{t('company.legal.user-data-and-system-provided-data')}</p>
        <p>{t('company.legal.listings-are-persisted')}</p>
        <p>{t('company.legal.sellers-can-upgrade-ads')}</p>

        <p className='header-h2 mt-4 mb-2'> {t('company.legal.scope')}</p>
        <p>{t('company.legal.technical-enabler')}</p>
        <p>{t('company.legal.system-conveys-sellers-data')}</p>
        <p>{t('company.legal.not-responsible-for-buyers-misinformation')}</p>
        <p>{t('company.legal.not-responsible-for-inaccurate-specs')}</p>
        <p>{t('company.legal.not-responsible-for-data-loss')}</p>

        <p className='header-h2 mt-4 mb-2'>{t('company.legal.responsibility-of-a-user')}</p>
        <p>{t('company.legal.users-agree-not-to-misuse-the-system')}</p>
        <p>{t('company.legal.users-should-not-copy-data')}</p>
        <p>{t('company.legal.visitors-should-not-spam-sellers-via-our-messaging-system')}</p>
        <p>{t('company.legal.forbidden-to-upload-non-vehicle-images')}</p>
    </div>
}

export const Protection = () => {
    const {t} = useTranslation(['support', 'texts']);
    return <div>
        <p className='header-h1'>{t('texts:footer.data-protection')}</p>
        <p>{t('company.protection.the-platform-to-connect-buyers-and-sellers')}</p>
        <p>{t('company.protection.comfortable-and-secure-experience')}</p>
        <p>{t('company.protection.we-collect-minimum-data')}</p>

        <div className='mt-2 mb-3'>
            {t('company.protection.seller-needs-to-provide-some-data')}
            <ul className='' style={{listStyleType: 'square'}}>
                <li style={{marginTop: '10px'}}>
                    {t('company.protection.private-seller-data-requirements')}</li>
                <li style={{marginTop: '10px'}}>
                    {t('company.protection.corporate-seller-data-requirements')}
                </li>
            </ul>
        </div>
        <p> {t('company.protection.vin-is-public')}</p>

        <p className='header-h2 mt-4 mb-2'>
            {t('company.protection.username-password')}</p>
        <p>{t('company.protection.email-is-a-username')}</p>
        <p>{t('company.protection.password-security')}</p>

        <p className='header-h2 mt-4 mb-2'>
            {t('company.protection.payment-info')}
        </p>
        <p>{t('company.protection.payment-providers')}</p>
    </div>
}


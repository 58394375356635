const testState = {
    makes: [], models: [], generations: [
        {
            name: 'e-tron',
            id: 22558,
            rating: 50,
            makeID: 10,
            generationID: 108057,
            generationName: '1 generation',
            yearStart: 2018,
            yearEnd: 2020
        }
    ], trims: [
        {
            name: 'Crossover',
            id: 53763,
            modelID: 22558,
            generationID: 108057
        }
    ], modifications: [
        {
            name: '55 quattro (408 HP)',
            id: 239586,
            trimID: 53763
        },
        {
            name: 'Electric (0.0 360 HP)',
            id: 240989,
            trimID: 53763
        }
    ], characteristics: [],
    make: {
        name: 'Audi',
        id: 10
    },
    model: {
        name: 'e-tron',
        id: 22558
    },
    generation: {
        name: 'e-tron',
        id: 22558,
        rating: 50,
        makeID: 10,
        generationID: 108057,
        generationName: '1 generation',
        yearStart: 2018,
        yearEnd: 2020
    },
    trim: {
        name: 'Crossover',
        id: 53763,
        modelID: 22558,
        generationID: 108057
    },
    modification: undefined,
    year: 2020,
    mileage: 7,
    price: 7777,
    fuel: undefined,
    power: 350,
    displacement: 3883,
    options: [],
    transmission: undefined,
    drivetrain: undefined,
    color: undefined,
    vin: "45830AFSF095839A",
    files: [],
    blobPaths: [],
    bodyStyle: 'Sedan',
    loadingComplete: false,
    fullDataToggle: false,
    entryMode: undefined,//'myaccount',automatic, manual, myoffers
    comment: undefined,
    trims_and_modifications: [],
    countries: [],
    cities: [],
    history: [],
    vatIncluded: undefined //true/false
    // cars:{cars:[]}
}
const initialState = {
    makes: [],
    make: undefined,
    models: [],
    model: undefined,
    generations: [],
    generation: undefined,
    trims: [],
    trim: undefined,
    modifications: [],
    modification: undefined,
    characteristics: [],
    characteristicsError: undefined,
    year: undefined,
    mileage: undefined,
    price: undefined,
    fuel: undefined,
    power: undefined,
    displacement: undefined,
    options: [],
    transmission: undefined,
    drivetrain: undefined,
    color: undefined,
    vin: undefined,
    files: [],
    blobPaths: [],
    bodyStyle: undefined,//bodyStyle===trim
    loadingComplete: false,
    fullDataToggle: false,
    entryMode: undefined,//myaccount, automatic, manual, myoffers
    comment: undefined,
    trims_and_modifications: [],
    countries: [],
    cities: [],
    city: undefined,
    country: undefined,
    history: [],
    vatIncluded: undefined, //true/false
    errors: {}

    // steps: []
    // cars: {cars: []}
    // cars:[] //move cars to state, to drive entryModes - does not make sense due to lazy loading
    // isLoggedIn: false - App.state.logged is used
    //? do we need blobPaths ?
};
const testStateManual = {
    makes: [],
    make: {name: "Audi", id: "10"},
    models: [],
    model: {
        generationID: undefined,
        generationName: undefined,
        id: "76",
        makeID: "10",
        name: "200",
        rating: 50,
        yearEnd: undefined,
        yearStart: undefined
    },
    generations: [],
    generation: undefined,
    trims: [],
    trim: {name: "Sedan"},
    modifications: [],
    modification: {name: 'Turbo'},
    characteristics: [],
    characteristicsError: undefined,
    year: 1990,
    mileage: 120000,
    price: 4500,
    fuel: 'gasoline',
    power: 350,
    displacement: 2200,
    options: [],
    transmission: "manual",
    drivetrain: "all wheel drive",
    color: 'white',
    vin: 'WPZAAA123999888TZ',
    files: [],
    blobPaths: [],
    bodyStyle: 'Sedan',//bodyStyle===trim
    loadingComplete: false,
    fullDataToggle: false,
    entryMode: 'automatic',
    comment: undefined,
    trims_and_modifications: [],
    countries: [],
    cities: [],
    vatIncluded: undefined,//true/false

    // isLoggedIn: false - App.state.logged is used
};

export {testState, initialState, testStateManual}
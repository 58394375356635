import React, {useEffect, useState} from 'react';
import OptionSelect from "../../OptionSelect/OptionSelect";
import Heading from "../../../Search/SearchArea/Heading";

const InputFieldBodyStyle = props => {

    console.log('InputFieldBodyStyle props', props);

    const bodyStyleDictionary = props.bodyStyles;
    const keys = Object.keys(bodyStyleDictionary);

    let numberClass = 'row mt-2 numbered-list-item'
    if (props.state.bodyStyle === undefined && props.prevField !== undefined) numberClass += ' yellow';
    else if (props.prevField === undefined) numberClass += ' grey'

    const [selectedValue, setSelectedValue] = useState('');

    useEffect(() => {
        // console.log('useEffect InputFieldBodyStyle',props.state.bodyStyle);
        if (props.state.bodyStyle !== undefined) {

            const selectedBodyStyle = bodyStyleDictionary[props.state.bodyStyle];
            console.log('useEffect InputFieldBodyStyle', selectedBodyStyle);

            setSelectedValue(selectedBodyStyle.title);
        }

    }, [props.state.bodyStyle]);


    return <div className={numberClass} id='row-trim'>

        <div className='offset-1'/>
        <div className='col-9 pl-0'>
            <OptionSelect
                id='select-trim'
                options={keys.map(key => {
                    let title = bodyStyleDictionary[key].title;
                    return ({
                        value: key, label: title
                    })
                })}
                selected={selectedValue}
                handler={props.updateBodyStyle}
                isDisabled={props.prevField === undefined}
            >
                <Heading>{props.title}</Heading>
            </OptionSelect>
        </div>
    </div>
}

export default InputFieldBodyStyle;
import React from 'react';
import {Col, Container, Modal, Row} from 'react-bootstrap';
import _ from 'lodash';
import EditableImagesFromFile from "./EditableImagesFromFile";
import {useTranslation} from "react-i18next";
import {validateFiles} from "../../../../../Integration/Utils/StorageUtils";

const ShowPhotosModal = props => {

    console.log('ShowPhotos props', props)
    let files = [];
    const {t} = useTranslation(['texts']);

    const setFiles = async changed => {
        files = await Promise.all(changed.map(async (url, i) =>
            await getFileFromUrl(url, i + '.jpg')));
    }

    const closeWrapper = async () => {
        console.log('ShowPhotosModal closeWrapper');
        props.handleClose();
        props.updateFileInput(files);
        const errors = await validateFiles(files);

        console.log('ShowPhotosModal errors', errors);

        /** Clear state from file errors in case of deletion of images from modal */
        if (errors.length === 0) {
            console.log('ShowPhotosModal clear state from file error');
            const copyStateErrors = {...props.stateErrors};
            delete copyStateErrors.files;
            props.updateSellErrors(copyStateErrors);
        }

    }

    const getTitle = () => {
        let title = '';
        if (props.car.make !== undefined) title += _.capitalize(props.car.make.name + ' ');
        if (props.car.model !== undefined) title += _.capitalize(props.car.model.name + ' ');
        if (props.car.modification !== undefined) title += _.truncate(_.capitalize(props.car.modification.name + ' '), {'length': 25});
        if (props.car.year !== undefined) title += _.capitalize(props.car.year);
        return title;
    }
    const title = getTitle();

    const subHeadingStyle = {fontSize: '.75rem', color: '#555'};
    return <div id='show-photos-modal'>
        <Modal show={props.show} onHide={closeWrapper} centered>
            <Modal.Header closeButton>
                <Modal.Title className='text-capitalize font-weight-bold d-flex w-100'>
                    <div className='container'>
                        <div className='row col-12 d-flex start'>
                              <span className="vehicle-description-subtitle" style={{fontSize: '1rem'}}>
                                   View photos
                                </span>
                        </div>
                        <div className='row d-flex align-items-center' style={subHeadingStyle}>
                            <div className='col-12 justify-content-start'>
                                {title}
                            </div>
                        </div>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container fluid={true} className='px-0'>
                    <Row>
                        <Col>
                            <EditableImagesFromFile
                                files={props.car.files}
                                setFiles={setFiles}
                                showModal={closeWrapper}
                            />
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>{t('texts:sell.order-of-photos-saved-on-close')}
            </Modal.Footer>
        </Modal>
    </div>
};

export {ShowPhotosModal}

async function getFileFromUrl(url, name, defaultType = 'images/jpeg') {
    const response = await fetch(url);
    const data = await response.blob();
    return new File([data], name, {
        type: response.headers.get('content-type') || defaultType,
    });
}
import _ from 'lodash';

export const recaptchaKey = process.env.REACT_APP_MESSAGES_RECAPTCHA;
const ocpApimKey = process.env.REACT_APP_MESSAGES_API_KEY;
const messageApiURL = process.env.REACT_APP_ROOT_API_URI;
const ocpKeyName = process.env.REACT_APP_OCP_API_NAME;

/**
 * testing deployment IV
 * **/


export const loadRecaptcha = () => {
    const url = "https://www.google.com/recaptcha/api.js?render=" + recaptchaKey;
    const id = "recaptcha-key";
    const callback = () => console.log('recaptcha loaded...', recaptchaKey);

    const loadScriptByURL = (id, url, callback);
    const isScriptExist = document.getElementById(id);

    if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
            if (callback) callback();
        };
        document.body.appendChild(script);
    } else {
        console.log('recaptcha already loaded!');
    }

    if (isScriptExist && callback) callback();
    return loadScriptByURL;
}

export const submitData = async (token, vin, container, senderEmail, senderName, message) => {
    // call a backend API to verify reCAPTCHA response
    console.log('calling backend..', token);
    let json = {status: 'email not sent'}
    if (!_.isEmpty(token) && !_.isEmpty(container) && !_.isEmpty(senderEmail) && !_.isEmpty(senderName)) {

        const response = await fetch(
            messageApiURL + "/messages/seller",
            {
                method: 'post',
                headers: {
                    "Content-Type": "application/json",
                    [ocpKeyName]: ocpApimKey
                },
                body: JSON.stringify({
                    "container": container,
                    "vin": vin,
                    "senderEmail": senderEmail,
                    "senderName": senderName,
                    "message": message,
                    "token": token
                })
            });
        json = await response.json();
        console.log('response from seller backend', response, json);
    }
    return json;
}

export const submitSupportMessage = async (token, senderEmail, message) => {
    // call a backend API to verify reCAPTCHA response
    // console.log('calling backend..', token);
    let json = {status: 'email not sent'}
    if (!_.isEmpty(senderEmail) && !_.isEmpty(message)) {

        const response = await fetch(
            messageApiURL + "/messages/support",
            {
                method: 'post',
                headers: {
                    "Content-Type": "application/json",
                    [ocpKeyName]: ocpApimKey
                },
                body: JSON.stringify({
                    "replyEmail": senderEmail,
                    "message": message,
                    "token": token
                })
            });
        json = await response.json();
        console.log('response from support backend', response, json);
    }

    return json;
}

export const composeMessage = (preMsg, message, afterMsg) => {
    return preMsg + '-----------\n\n' + message + '\n\n-----------' + afterMsg;
}
import React, {useState} from 'react';
import './css/vehicle.css';

const EditableAttributeComment = props => {

    const {title, validator, setState, state, textStyle} = {...props};
    const [showField, setShowField] = useState(false);

    const updateOnChange = value => {
        console.log('updateOnChange EditableAttributeComment', value);
        const copy = JSON.parse(JSON.stringify(state));
        copy['comment'] = value;
        setState(copy);
        console.log('state after updateOnChange', copy)
    }

    const handleKeyPress = (event) => {
        console.log(event.key);
        if (event.key === 'Enter') setShowField(!showField);
        console.log('state after enter', state);
    }

    return <div className='row col-12 mx-0 pl-0 d-flex justify-content-between'
                style={textStyle}>
        <div className='col align-items-start px-0'>
            <span className="text-left">{title}</span>
        </div>
        <div className='col-auto d-flex justify-content-end'>
            <img height='17px' width='17px' className='ml-1 mt-1 edit-icon button'
                 onClick={() => setShowField(!showField)}
            />
        </div>
        <div className='row'/>
        <div className='col-12 d-flex justify-content-start px-0'>
            {!showField && <span
                style={{whiteSpace: 'pre-line'}}
                className="text-left font-weight-bold">
                {/*className="text-right font-weight-bold text-nowrap text-capitalize text-truncate">*/}
                {state['comment']}</span>}
            {showField && <textarea
                value={state['comment']}
                onChange={e => {
                    if (validator !== undefined && validator(e.target.value) === true)
                        updateOnChange(e.target.value);
                    else if (validator === undefined) updateOnChange(e.target.value)
                }}
                // onKeyPress={event => handleKeyPress(event)}
                key={title}
                rows={10}
                className="input text-left w-100"/>
            }
        </div>
    </div>;
}

export default EditableAttributeComment;
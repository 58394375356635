import React, {useState} from "react";
import './css/vehicle.css';

const EditableAttribute = props => {
    const [showField, setShowField] = useState(false);

    const field = props.field;
    const title = props.title;
    const state = props.state;
    const type = props.type;
    const setState = props.setState;
    const side = props.side;
    const validator = props.validator;
    const isSmallScreen = props.isSmallScreen;
    const textStyle = props.textStyle;

    const updateOnChange = value => {
        console.log('updateOnChange EditableAttribute', value);
        const copy = JSON.parse(JSON.stringify(state));
        copy[field] = value;
        setState(copy);
        console.log('state after updateOnChange', copy);
    }

    const handleKeyPress = (event) => {
        console.log(event.key);
        if (event.key === 'Enter') setShowField(!showField);
        console.log('state after enter', state);
    }

    // let col1style = 'col align-items-start'
    // let colImgStyle = 'col-auto d-flex justify-content-end'
    let colClass = '';
    let col2style = '';
    let rowClass = 'row col-6 mx-0 d-flex justify-content-between px-0';

    if (side === 'left') {
        // col1style += ' pl-0';
        col2style = 'col-12 mx-0 pl-0'
        // colImgStyle += ' pl-0'
        colClass = 'col-12 d-flex justify-content-between pl-0'
    } else {
        // col1style += ' pr-0';
        col2style = 'col-12 mx-0 pr-0';
        // colImgStyle += ' pr-0';
        colClass = 'col-12 d-flex justify-content-between pr-0'
    }
    if (isSmallScreen && field === 'modification') {
        rowClass = 'row col-12 mx-0 d-flex justify-content-between px-0 my-4';
        colClass = 'col-12 d-flex justify-content-between px-0'
        col2style = 'col-12 mx-0 px-0';
    }


    return <div id={`editable-attribute-${field.toLowerCase()}`}
                key={field}
                className={rowClass}
                style={textStyle}>

        <div className={colClass}>
            <span className="text-left">{title}</span>
            <img height='17px' width='17px' className='button ml-1 mt-1 edit-icon'
                 onClick={() => setShowField(!showField)}
            />
        </div>
        <div className='row col-12'/>
        <div className={col2style}>
            <div id='edit-attribute-button' className='d-flex justify-content-start col px-0'>
                {!showField && <span
                    className="text-left font-weight-bold">
                {/*className="text-right font-weight-bold text-nowrap text-capitalize text-truncate">*/}
                    {state[field]}</span>}
                {showField && (
                    (type === 'text' || type === 'number') && <input
                        style={{
                            minWidth: '100%',
                            maxWidth: '100%'
                        }}
                        value={state[field]}
                        onChange={e => {
                            if (validator !== undefined && validator(e.target.value) === true)
                                updateOnChange(e.target.value);
                            else if (validator === undefined) updateOnChange(e.target.value)
                        }}
                        onKeyPress={event => handleKeyPress(event)}
                        key={title}
                        type={type}
                        // style={type === 'text' ? {'width': '100%'} : {}}
                        maxLength={type === 'text' ? '30' : '7'}
                        className="input text-left font-weight-bold"/>)
                }
            </div>
        </div>
    </div>
};

export default EditableAttribute;
import React from 'react';
import {useTranslation} from "react-i18next";

const PaymentConfirmation = props => {

    const {t} = useTranslation(['texts']);

    return <div id='payment-confirmation-div'>
        <div className='font-weight-bold mb-2'>
            {t('sell.car-ad-card.upgrade-modal.transaction-results')}</div>
        <div>{t('sell.car-ad-card.upgrade-modal.congratulations-message')}</div>
    </div>
}

export default PaymentConfirmation;
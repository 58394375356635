import React, {useEffect, useState} from 'react';
import {Input} from "../../Input";
import Heading from "../../../Search/SearchArea/Heading";
import {AzureAdvertisementsRESTConnector} from "../../../../Integration";
import {calculateCRC32} from "../../../../Integration/Utils/TableUtils";
import {ErrorMessageBlock} from "../ErrorMessages/ErrorMessageBlock";

const InputFieldVIN = props => {

    let numberClass = 'row mt-2 numbered-list-item'
    if (props.state.vin === undefined && props.prevField !== undefined) numberClass += ' yellow';
    else if (props.prevField === undefined) numberClass += ' grey'

    const connector = new AzureAdvertisementsRESTConnector();
    const [errorMessage, setErrorMessage] = useState(undefined);

    useEffect(() => {

        console.log('InputFieldVIN useEffect props', props.state.vin);

        async function getAdByVIN() {
            if (props.state.vin && props.state.vin.length === 17) {
                //checking if vin is unique for user
                const adlerVIN = calculateCRC32(props.state.vin);
                const result = await connector.retrieveAdsByUserIDAndVIN(props.state.userID, adlerVIN)
                console.log('InputFieldVIN useEffect getAdByVIN', result);
                if (result.length > 0) {
                    //VIN is not unique
                    setErrorMessage('VIN already registered for one of your cars');
                    // console.log('InputFieldVIN useEffect getAdByVIN', props.updateErrors);
                    props.updateSellErrors({
                        'input-field-vin': 'VIN already registered for one of your cars',
                    });
                } else {
                    //VIN is unique
                    setErrorMessage(undefined);
                    const errors = props.state.errors;
                    if (props.state.errors !== undefined && props.state.errors['input-field-vin'] !== undefined) delete errors['input-field-vin'];
                    props.updateSellErrors({...errors});
                }
            } else if (props.state.vin && props.state.vin.length === 16) {
                //VIN not full
                setErrorMessage(undefined);
                const errors = props.state.errors;
                if (props.state.errors !== undefined && props.state.errors['input-field-vin'] !== undefined) delete errors['input-field-vin'];
                props.updateSellErrors({...errors});
            }
        }

        getAdByVIN();

    }, [props.state.vin && props.state.vin.length === 17]);


    return <div className={numberClass} id='row-vin'>

        <div className='offset-1'/>

        <div className='col-9 pl-0'>
            <Input
                key='input-vin'
                value={props.state.vin}
                disabled={props.prevField === undefined}
                handler={props.updateVIN}
                maxLength={17}
                minLength={17}
                placeholder={'VIN code has 17 characters'}
            >
                <div style={{marginLeft: '1em'}}>
                    <Heading>{props.title}</Heading>
                </div>
            </Input>
            <div id='error-message-div' className='row'>
                <ErrorMessageBlock errorMessage={errorMessage}/>
            </div>
        </div>
    </div>
}

export default InputFieldVIN;
import React from "react";
import * as LanguageUtils from '../../../../Integration/Utils/LanguageUtils';

const MultiSelectFeatures = props => {
    // console.log('MultiSelectFeatures', props)
    let containerClass = 'container';
    if (props.bsClass !== undefined) containerClass += ' ' + props.bsClass;
    const keys = Object.keys(props.options);
    // const keys = props.options;
    // console.log('keys', keys, props.options);
    // console.log('state.options', props.state.options);
    const set = new Set(props.state.options)
    // console.log('set', set)
    return (
        <div id='search-field-features'
            className={containerClass}>
            {props.children}

            {keys.map((key) => {

                const title = LanguageUtils.getTitleFromDictionary(key, props.options);
                // const title = props.options[key];
                // console.log('key', key, title)
                return <div className='row' key={key}>
                    <div className='d-flex justify-content-start align-items-center'>
                        {/*{console.log('each item >>>', props.options[props.section][index])}*/}
                        <input
                            checked={props.state.options.includes(key)}
                            name={key}
                            type='checkbox'
                            style={{...styles.checkbox, ...props.textStyle}}
                            onChange={(e) => {
                                console.log(e.target.name, e.target.checked, props.state);
                                if (e.target.checked) {
                                    set.add(key);
                                    // copy.push(key)
                                } else {
                                    set.delete(key);
                                    // _.remove(copy, k => k === key);
                                }
                                console.log(Array.from(set));

                                props.updateOptions(Array.from(set));
                            }
                            }/>
                        <span className='checkmark'/>
                        <label className='ml-2 my-0'
                               style={{...styles.label, ...props.textStyle}}>
                            {title}
                        </label>
                    </div>
                </div>
            })}
        </div>
    )
}

export default MultiSelectFeatures;

const styles = {
    div: {
        opacity: 0.1,
        width: '24px',
        height: '24px',
        zIndex: 1
    },
    checkbox: {
        width: '24px',
        height: '24px',
        border: '1px solid black',
        zIndex: 22
        // opacity: 0.2
    }
    ,
    label: {
        lineHeight: '32px',
        // fontSize: '13px'
        userSelect: 'none',
    }
};
import React, {useEffect, useRef, useState} from 'react';
import {
    getAttribute,
    getAttributeAsList,
    getAttributeComment,
    getAttributePrice, getAttributeVAT,
    getAttributeWide
} from './vehicleAttributes';
import {useTranslation} from "react-i18next";
import {
    getCityFromDictionary,
    getCountryFromDictionary,
    getTitleFromDictionary
} from "../../../../Integration/Utils/LanguageUtils";
import {AzureCharacteristicsRESTConnector} from "../../../../Integration";
import * as LanguageUtils from '../../../../Integration/Utils/LanguageUtils'
import {getVatByCountryAndCar} from "../../../Sell/Fields/Price/priceHelperFunctions";
import {useMediaQuery} from "react-responsive";
import '../css/carcomponents.css';

const VehicleInformation = props => {

    // console.log('VehicleInformation', props);

    const isBigScreen = useMediaQuery({minWidth: 700})

    const {t, i18n} = useTranslation();

    const characteristicsConnector = new AzureCharacteristicsRESTConnector();


    //titles
    const make = t('vehicle-information.make');
    const model = t('vehicle-information.model');
    const modification = t('vehicle-information.modification');
    const mileage = t('vehicle-information.mileage');
    const year = t('vehicle-information.year');
    const fuelType = t('vehicle-information.fuel-type');
    const power = t('vehicle-information.power');
    const color = t('vehicle-information.color');
    const drivetrain = t('vehicle-information.drivetrain');
    const location = t('vehicle-information.location');
    const bodyStyle = t('vehicle-information.body-style');
    const displacement = t('vehicle-information.displacement');
    const comment = t('vehicle-information.comment');
    const options_title = t('vehicle-information.features');
    const history_title = t('vehicle-information.history-of-ownership');
    const vin = t('vehicle-information.vin');
    const price = t('vehicle-information.price');
    const vat = t('vehicle-information.vat');

    //dictionaries
    const fuel_d = t('extended-dictionary:fuels', {returnObjects: true});
    const drivetrain_d = t('extended-dictionary:drivetrains', {returnObjects: true});
    // const fuel_d = t('data-for-ui:fuel', {returnObjects: true});
    const units_d = t('characteristics:units', {returnObjects: true});
    const values_d = t('characteristics:values', {returnObjects: true});
    const suspension_d = t('characteristics:suspension', {returnObjects: true});
    // const drivetrain_d = t('data-for-ui:drivetrain', {returnObjects: true});
    const colors_d = t('data-for-ui:extColor', {returnObjects: true});
    const locations_d = t('locations:countries', {returnObjects: true});
    const bodystyle_d = t('data-for-ui:bodystyle', {returnObjects: true});
    const options_d = t('data-for-ui:features', {returnObjects: true});
    const history_d = t('data-for-ui:history', {returnObjects: true});

    // const history_d = t('data-for-ui:history', {returnObjects: true});


    //hooks
    const [showVehicleInfo, setShowVehicleInfo] = useState(false);
    const [showFeatures, setShowFeatures] = useState(false);
    const [showCharacteristics, setShowCharacteristics] = useState(false);
    const vehicleInfoRef = useRef();

    // const netPriceFromVAT = netFromVAT(props.car);


    useEffect(() => {
        vehicleInfoRef.current.scrollIntoView({behavior: 'smooth'});
        if (!showVehicleInfo && !showFeatures && !showCharacteristics) {   //auto-scroll-to top if all closed
            window.scrollTo({left: 0, top: 45, behavior: 'instant'}); //smooth,instant
        }
    }, [showVehicleInfo, showFeatures, showCharacteristics]);

    const [characteristicsValues, setCharacteristicsValue] = useState([]);
    const [characteristicsValuesLabelStyle, setCharacteristicsValuesStyle] = useState({});

    useEffect(() => {
        // console.log('useEffect in VehicleInformation!', props.car);

        const characteristicID = props.car.characteristicID;

        const getCharacteristics = async () => {
            if (characteristicID !== undefined) {
                console.log('characteristicID found', characteristicID);
                const characteristicsValue = await characteristicsConnector.retrieveCharacteristicsByLanguage(characteristicID, i18n.language);
                // console.log('fetched characteristics', characteristicsValue);

                LanguageUtils.processUnitsByLanguage(characteristicsValue, units_d);
                LanguageUtils.processCharacteristicsValuesByLanguage(characteristicsValue, values_d, suspension_d);
                setCharacteristicsValue(characteristicsValue);
                if (characteristicsValue.length < 5) setCharacteristicsValuesStyle({opacity: 0.5});
            } else console.warn('characteristicID not found')
        };
        getCharacteristics();
    }, []);

    // const [characteristics, setCharacteristics] = useState([]);

    //setting font-size for vehicle header and main text
    let mainTextStyle = {fontSize: '1rem'};
    if (props.mainTextStyle !== undefined)
        mainTextStyle = props.mainTextStyle;

    let headerTextStyle = {fontSize: '1.2rem'};
    if (props.headerTextStyle !== undefined)
        headerTextStyle = props.headerTextStyle;


    const vatByCountry = getVatByCountryAndCar(props.car);
    const netTitle = t('texts:vehicle-information.netprice');
    const taxTitle = t('texts:vehicle-information.tax');

    return (
        <div id='vehicle-info-description' ref={vehicleInfoRef}>
            {/*Vehicle Information*/}
            <div id='vehicle-information-accordion'
                 className="my-0 center-block d-flex align-items-center cursor-pointer"
                 onClick={() => setShowVehicleInfo(!showVehicleInfo)}>
                {showVehicleInfo ?
                    <img src="img/angle-up-solid-black.svg"
                         height="25px"
                         alt='arrow up'
                         style={arrowStyle}
                         className='pl-0 ml-0'/>
                    :
                    <img src="img/angle-down-solid-black.svg"
                         height="25px" alt='arrow down'
                         style={arrowStyle}
                         className='pl-0 ml-0'
                    />}
                <span className="vehicle-description-subtitle text-uppercase"
                      style={headerTextStyle}>
                        {t('vehicle-information.vehicle-information')}
                    </span>
            </div>

            <div id='vehicle-info-container' className='container-fluid'
                 style={{display: showVehicleInfo ? 'block' : 'none', ...mainTextStyle}}
            >

                <div id='vehicle-info-make-model' className="row py-3 border-bottom">
                    {getAttribute(make, props.car.PartitionKey, 'left')}
                    {getAttribute(model, props.car.model)}
                </div>
                <div id='vehicle-info-modification' className="row py-3  border-bottom">
                    {getAttributeWide(modification, (props.car.modification.length <= 50)
                        ? props.car.modification
                        : props.car.modification.substring(0, 49) + '..')}
                </div>
                <div id='vehicle-info-price' className="row py-3 border-bottom"
                    // style={{fontSize: '1.125rem'}}
                >
                    {getAttributePrice(price, props.car.price, vatByCountry, netTitle)}
                    {/*{getAttributePrice(price, props.car.price, vatByCountry, netTitle)}*/}
                    {getAttributeVAT(vat, props.car.price, vatByCountry, taxTitle)}
                </div>

                <div id='vehicle-info-mileage-year' className='row py-3 border-bottom'>
                    {getAttribute(mileage, props.car.mileage, 'left')}
                    {getAttribute(year, props.car.year)}
                </div>
                <div id='vehicle-info-fuel-power' className="row py-3 border-bottom"
                    // style={{fontSize: '1.125rem'}}
                >
                    {getAttribute(fuelType, getTitleFromDictionary(props.car.fuel, fuel_d), 'left')}
                    {getAttribute(power, props.car.power)}
                </div>
                <div id='vehicle-info-color-drivetrain' className='row py-3 border-bottom'>
                    {getAttribute(color, getTitleFromDictionary(props.car.color, colors_d), 'left')}
                    {getAttribute(drivetrain, getTitleFromDictionary(props.car.drivetrain, drivetrain_d))}
                </div>

                <div id='vehicle-info-bodystyle-displacement' className='row py-3 border-bottom'>
                    {getAttribute(bodyStyle, getTitleFromDictionary(props.car.bodyStyle, bodystyle_d), 'left')}
                    {getAttribute(displacement, (props.car.fuel !== 'electric' && props.car.fuel !== 'electro')
                        ? props.car.displacement + '' : 'N/A')}
                </div>

                {/**
                 Normal in desktop mode
                 */}
                {!isBigScreen && <>
                    {/*<div id='vehicle-info-vin' className="row py-3 border-bottom">*/}
                    {/*    {getAttributeWide(vin, props.car.RowKey, 'left')}*/}
                    {/*</div>*/}
                    <div id='vehicle-info-location' className="row py-3 border-bottom">
                        {getAttributeWide(location,
                            getCountryFromDictionary(props.car, locations_d) + ', ' +
                            getCityFromDictionary(props.car, locations_d))}
                    </div>
                </>}

                {/**
                 Wide in mobile mode
                 */}
                {isBigScreen && <>
                    <div id='vehicle-info-vin-location' className="row py-3 border-bottom"
                        // style={{fontSize: '1.125rem'}}
                    >
                        {/*{getAttribute(vin, props.car.RowKey, 'left')}*/}
                        {getAttributeWide(location,
                            getCountryFromDictionary(props.car, locations_d) + ', ' +
                            getCityFromDictionary(props.car, locations_d))}
                    </div>
                </>}

            </div>

            {/*Features*/}
            <div id='history-and-features-accordion'
                 className="my-0 center-block d-flex align-items-center cursor-pointer"
                 onClick={() => setShowFeatures(!showFeatures)}>
                {!showFeatures ?
                    <img src="img/angle-down-solid-black.svg"
                         height="25px"
                         alt='arrow up'
                         style={arrowStyle}
                         className='pl-0 ml-0'/>
                    :
                    <img src="img/angle-up-solid-black.svg"
                         height="25px" alt='arrow down'
                         style={arrowStyle}
                         className='pl-0 ml-0'
                    />}
                <span className="vehicle-description-subtitle text-uppercase"
                      style={headerTextStyle}>
                        {t('vehicle-information.vehicle-features')}
                    </span>
            </div>

            <div id='history-and-features-container'
                 className='container-fluid px-0'
                 style={{display: showFeatures ? 'block' : 'none', ...mainTextStyle}}>
                <div className='row mx-0 my-1 py-0'>
                    {getAttributeAsList(history_title, props.car.history, history_d,
                        props.car.options, 'left', t('vehicle-information.not-provided'))}
                    {getAttributeAsList(options_title, props.car.options, options_d,
                        props.car.history, 'right', t('vehicle-information.not-provided'))}
                </div>
                <div className='row mx-0 my-1 py-0'>
                    {getAttributeComment(comment, props.car.comment)}
                </div>
            </div>

            {/*Technical Specifications*/}
            {<div id='technical-characteristics-accordion'
                  className="my-0 center-block d-flex align-items-center cursor-pointer"
                  style={characteristicsValuesLabelStyle}
                  onClick={() => setShowCharacteristics(!showCharacteristics)}
                //   onClick={() => toggleCharacteristics()}
            >
                {!showCharacteristics ?
                    <img src="img/angle-down-solid-black.svg"
                         height="25px"
                         alt='arrow up'
                         style={arrowStyle}
                         className='pl-0 ml-0'/>
                    :
                    <img src="img/angle-up-solid-black.svg"
                         height="25px" alt='arrow down'
                         style={arrowStyle}
                         className='pl-0 ml-0'
                    />}
                <span className="vehicle-description-subtitle text-uppercase"
                      style={headerTextStyle}>
                        {t('vehicle-information.technical-specification')}
                    </span>
            </div>}


            <div id='technical-characteristics-container' className='container-fluid '>
                {showCharacteristics && (
                    characteristicsValues.length > 5 ? characteristicsValues.map(el => {
                        return (
                            <div key={el.id} className='row py-3 border-bottom'>

                                <div className="col-12 d-flex justify-content-between px-0"
                                     style={mainTextStyle}>
                                    <span className="col-auto pl-0 ml-0 text-left ">{el.name}</span>
                                    <span
                                        className="col px-0 mx-0 text-right font-weight-bold">
                                        {el.value}{el.unit !== 'N/A' && ' ' + el.unit}
                                    </span>
                                </div>
                            </div>
                        )
                    }) : <div className='row pb-4 border-bottom'>
                        <div className='col-12 d-flex justify-content-start px-0'
                             style={{...mainTextStyle, opacity: '0.6'}}
                        >{t('vehicle-information.data-not-available')}
                        </div>
                    </div>)}</div>

        </div>
    );

}
export default VehicleInformation;

const arrowStyle = {
    position: 'relative',
    margin: '12px 12px',
    opacity: 0.9,
};

// const vehicleHeader = {
//     fontSize: '1.125rem'
// }
import React, {useState} from 'react';
import FilterAreaBigScreen from './FilterAreaBigScreen/FilterAreaBigScreen';
import Paginator from './BottomPagination/Paginator';
import {initialOptions} from "../../../Data/State/initialState";
import {useMediaQuery} from "react-responsive";
import CarCardMobilePortrait from "./CarCard/CarCardMobilePortrait";
import CarCardMobileLandscape from "./CarCard/CarCardMobileLandscape";
import CarCardBigScreen from "./CarCard/CarCardBigScreen";
import filterIcon from './img/filters-icon.svg' ;
import {useTranslation} from "react-i18next";
import SearchFiltersModal2 from "./Modal/SearchFiltersModal2";
import {SearchCriteria12} from "../SearchArea/SearchCriteria/SearchCriteria12";
import ResetFilterButton from "../SearchArea/ResetFiterButton";
import {getActiveFilters} from "./Modal/helpers";

const CarListing = props => {
        console.log('CarListing props', props);
        const cars = props.searchResults;

        const isBigScreen = useMediaQuery({minWidth: 1200});
        const isMobilePortrait = useMediaQuery({orientation: 'portrait'});
        const isMobileLandscape = useMediaQuery({orientation: 'landscape'});

        const {t} = useTranslation('texts');

        const results = t('search.car-listing.results');

        const [showModal, setShowModal] = useState(false);
        const activeFilters = getActiveFilters(props.options);

        const removeCompositeFilters = name => {
            // console.log('removeCompositeFilters at SearchArea', name, 'initial options at removeCompositeFilters', initialOptions);
            const makeAndModel = {make: initialOptions['make'], model: initialOptions['model']};
            // console.log('makeAndModel', makeAndModel);
            const cityAndCountry = {
                country: initialOptions['country']
                , city: initialOptions['city']
            }
            if (name === 'make') props.updateMultipleOptions(makeAndModel);
            else if (name === 'country') {
                props.updateMultipleOptions(cityAndCountry);
                props.updateCities([])
            } else props.updateOptions(name, initialOptions[name]);
        }

        let carListingDiv = 'col-8 ml-3 pr-0';
        let carListRow = 'row mt-2 pr-4'
        if (!isBigScreen) {
            carListingDiv = 'container-fluid';
            carListRow = 'mt-2'
        }

        return (
            <div id='carlisting-div' className={carListingDiv} style={{minHeight: '265px'}}>
                {isBigScreen && <div id='search-results-heading-big-screen'
                                     className='container pb-2 mr-2'
                                     style={bigScreenStyle}>

                    <FilterAreaBigScreen
                        sort={props.sort}
                        updateSort={props.updateSort}
                        results={props.searchCount}
                        resetOptions={props.resetOptions}
                        options={props.options}
                        // results={Object.keys(cars).length}
                    />
                    <div className='my-1'/>
                    {activeFilters > 0 &&
                        <>
                            <ResetFilterButton reset={props.resetOptions}/>
                            <SearchCriteria12
                                data={props.options}
                                showFiltersModal={setShowModal}
                                resetOptions={props.resetOptions}
                                remove={removeCompositeFilters}
                                updateOptions={props.updateOptions}
                                updateMultipleOptions={props.updateMultipleOptions}
                            />
                        </>}
                </div>}

                {(isMobilePortrait || isMobileLandscape && !isBigScreen) &&
                    <div id='mobile-filter-search-results'
                         className='row border justify-content-between mb-3 mb-1'
                         style={isMobileLandscape ? mobileFilterStyle : {...mobileFilterStyle, borderRadius: 0}}>

                        <div className='col-6 d-flex justify-content-start align-items-center pr-0 pl-2'>
                            <div className='d-flex justify-content-center align-items-center flex-nowrap'
                                 id='search-filters-button' onClick={() =>
                                // setShowModal(true)
                                props.updateMultipleModals({search: true})
                            }
                                 style={{
                                     background: 'rgba(255,255,255,.6)',
                                     border: '1px solid #ccc',
                                     borderRadius: '10px',
                                     padding: '3px 5px',
                                     cursor: 'pointer',
                                     whiteSpace: 'nowrap',
                                     flexShrink: 0
                                 }}>
                                    <span id='filter-settings-span'
                                          style={{
                                              fontSize: '0.8rem',
                                              fontWeight: 'bold',
                                              marginLeft: '.5rem'
                                          }}>{t('search.search-area.search-filters')}
                                    </span>
                                <img src={filterIcon} height='80%' alt='' style={{
                                    transform: 'rotate(90deg)',
                                    opacity: '75%', marginLeft: '-0.05rem', marginRight: '0.3rem'
                                }}/>
                            </div>
                        </div>

                        <div id='search-results-count-span'
                             className='col-6 d-flex justify-content-end align-items-center'
                             style={{fontSize: '0.8rem'}}>
                            <span
                                className='text-capitalize'>{results}</span><span>{':\u00A0'}</span><span>{props.searchCount}
                            </span>
                        </div>
                    </div>}

                <div id='car-listing-row' className={carListRow}>
                    <div className="mb-0 w-100">
                        {cars.map((car) => {

                            return <div className="mb-4 pb-1" key={car.RowKey}>
                                {/*{i === 0 && <div style={{padding: '5px 0'}}/>}*/}
                                {isBigScreen &&
                                    <CarCardBigScreen
                                        car={car}
                                        image={car.blobPaths[0]}
                                        selectHandler={props.updateSelectedCar}
                                    />
                                }

                                {!isBigScreen && isMobilePortrait &&
                                    <CarCardMobilePortrait
                                        // image={image}
                                        image={car.blobPaths[0]}
                                        car={car}
                                        selectHandler={props.updateSelectedCar}
                                    />}
                                {!isBigScreen && isMobileLandscape &&
                                    <CarCardMobileLandscape
                                        image={car.blobPaths[0]}
                                        car={car}
                                        selectHandler={props.updateSelectedCar}
                                    />}

                            </div>
                        })}
                    </div>
                </div>
                {/*<BottomPagination searchCount={props.searchCount}/>*/}
                <Paginator {...props}/>
                <div className='col-12 d-flex justify-content-center'>
                    <SearchFiltersModal2
                        {...props}
                        isMobilePortrait={isMobilePortrait}
                    />
                </div>
            </div>
        );
    }
;

export default CarListing;


const mobileFilterStyle = {
    boxShadow: 'rgb(221 221 221 / 30%) 1px 8px 7px -3px',
    height: '2.5rem',
    background: 'rgba(0,0,0,.05)',
    borderRadius: '0.5rem'
};
const bigScreenStyle = {
    border: '1px solid #ddd',
    // padding: '0.5rem',
    paddingTop: '0.5rem',
    paddingRight: '0.5rem',
    paddingBottom: '0.5rem',
    paddingLeft: '1em',
    borderRadius: '0.5rem',
    // width: '96.5%',
    width: '758px',
    boxShadow: 'rgb(221 221 221 / 70%) 1px 5px 4px -3px',
    marginLeft: '-0.5rem',
    borderTop: '1.5px solid rgb(221, 221, 221)',
    marginBottom: '18px'
};
import React, {useState} from 'react';
import {redirect, redirect2} from "../../../../../Shared/Modals/functions";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";

const HeroSearchButton = props => {

    const [t] = useTranslation(['texts']);
    const location = useLocation();
    const navigate = useNavigate();

    // console.log('HeroSearchButton', props);
    const backgroundNormal = {backgroundColor: 'rgba(0,0,0,.7)'}
    const backgroundHover = {backgroundColor: 'rgba(0,0,0,.82)'}
    const [backgroundStyle, setBackgroundStyle] = useState(backgroundNormal);

    return <div id='hero-search-button'
                className='text-uppercase d-flex justify-content-center align-items-center'

                onClick={() => {
                    props.props.resetOptions();
                    redirect2('/search', props, location, navigate);
                }}
                onMouseEnter={() => setBackgroundStyle(backgroundHover)}
                onMouseLeave={() => setBackgroundStyle(backgroundNormal)}

                style={{
                    height: '55px', borderRadius: '.75rem',
                    userSelect: "none",
                    cursor: 'pointer',
                    border: '2px solid white',
                    fontWeight: 'bold',
                    fontSize: '1rem'
                    , width: props.buttonWidth
                    , ...backgroundStyle
                }}>{t('home.start-searching')}</div>
}

export default HeroSearchButton;
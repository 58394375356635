import React from "react";

const NonEditableAttribute = props => {

    console.log('NonEditableAttribute', props);

    let row1style = ''
    let row2style = '';
    let colStyle = 'col-6 px-0';
    if (props.side === 'left') {
        row1style = 'row col-12 justify-content-start mx-0 pl-0';
        row2style = 'row col-12 mx-0 pl-0';
    } else if (props.side === 'right') {
        row1style = 'row col-12 justify-content-start mx-0 pr-0';
        row2style = 'row col-12 mx-0 pr-0';
    }
    if (props.isSmallScreen) {
        row1style = 'row col-12 justify-content-start mx-0 px-0';
        row2style = 'row col-12 mx-0 px-0';
        colStyle = 'col-12 px-0 border-bottom pb-3'
    }

    return <div id={`non-editable-attribute-${props.title.toLowerCase()}`}
                key={props.title}
                className={colStyle}
                style={props.textStyle}>
        <div className={row1style}>
            <span className="text-left">{props.title}</span>
        </div>
        <div className={row2style}>
            <div className='d-flex justify-content-start'>
                <span className="text-right font-weight-bold text-capitalize">
                    {props.value}
                </span>
            </div>
        </div>
    </div>;
};

export default NonEditableAttribute;
import React, {useState} from 'react';
import {Modal, Nav} from 'react-bootstrap';
import EditableVehicleInfo from "./EditableInfo/EditableVehicleInfo";
import AddImages from "./AddImages/AddImages";
import {saveImageResults, upload} from "../../functions/imageFunctions";
import _ from 'lodash';
import {saveInfoResults} from "../../functions/infoFunctions";
import {initialState} from "../../../../Data/Data";
import {useMediaQuery} from "react-responsive";
import {buyButtonStyle} from "../../functions/offersFunctions";
import './tabs.css';
import {useMsal} from "@azure/msal-react";
import {EditableImages2} from "./EditableImages/EditableImages2";

const EditModal = props => {
    // console.log('EditModal props', props);
    const car = props.car;
    const t = props.t;

    const {accounts: userAccounts} = useMsal()


    //set active tab
    const [selectedTab, setSelectedTab] = useState('add-images'); //add-images, edit-images

    //add-images
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [files, setFiles] = useState([]);
    const [inputKey, setInputKey] = useState(1);
    const [showFileInput, setShowFileInput] = useState(true);

    //edit images
    const paths = JSON.parse(car.blobPaths);
    const [images, setImages] = useState(paths)
    const [imagesUploaded, setImageUploadCompleted] = useState(undefined);
    const [uploadStatus, setUploadStatus] = useState(undefined);

    //edit info
    // console.log('joint stata', {...initialState, ...car})
    const [info, setInfo] = useState({...initialState, ...car});


    /**
     * Screen-specific styles
     * */
    const isSmallScreen = useMediaQuery({maxWidth: '400px'});
    const headerFont = {fontSize: '1rem'}
    const tabsFont = {fontSize: '.9rem'};
    if (isSmallScreen) {
        headerFont.fontSize = '0.9rem';
        tabsFont.fontSize = '.88rem';
    }

    const uploadImages = async images => {
        console.log('uploadImages', images);
        setImageUploadCompleted(false);

        const response = await upload(images, car); //upload in imageFunctions.js
        console.log('response after uploadImages', response);

        if (response.status.code === 201) {
            props.setCar(response.updatedCar);
            setInputKey(_.random(0, 100));
            setFiles([]);
            setShowFileInput(false);
            setDisableSubmit(true);
            setImages(JSON.parse(response.updatedCar.blobPaths));
            props.refreshCars();
            setImageUploadCompleted(true);
            setUploadStatus(response.status)
            console.log('uploadImages: table response === ', response.tableResponse.status, ' - success');
        } else {
            setImageUploadCompleted(true);
            setUploadStatus(response.status)
            setFiles([]);
            setShowFileInput(false);
            console.log('uploadImages response === ', response.status, ' - error');
        }
    }

    const uploadButtonColor = !disableSubmit ? {color: '#007bff', border: '1px solid #007bff'} :
        {color: '#0051ff', border: '1px solid #007bff', opacity: .6};

    const activeLinkStyle = {borderBottom: '3px solid #888'};
    return <div>
        <Modal id='edit-modal' show={props.show} onHide={props.handleClose} size='md' centered>
            <Modal.Header closeButton>
                <Modal.Title className='text-capitalize font-weight-bold d-flex row w-100' style={headerFont}>
                    <div className='container'>
                        <div className='row col-12 d-flex start'>
                              <span className="vehicle-description-subtitle" style={headerFont}>
                                    {t('vehicle-information.edit-vehicle-info')}
                                </span>
                        </div>
                        <div className='row d-flex align-items-center' style={{fontSize: '.7rem', color: '#555'}}>
                            <div className='col-12 justify-content-start'>
                                {car.make}{' '}{car.model}{' '}{car.year}
                            </div>
                        </div>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body id='edit-car-details-modal'>
                <div className='container-fluid px-0'>
                    <div className='row'>
                        <div className="mt-1 mb-2 container" id='editable-vehicle-info'>


                        </div>
                        <div className='col-12 px-0'>
                            <div className="my-2 ml-0 mr-0" id='edit-tabs-wrapper'>

                                <Nav
                                    style={tabsFont}
                                    activeKey={'key'}
                                    fill={true}
                                    onSelect={(k) => {
                                        setSelectedTab(k);
                                        setShowFileInput(true);
                                        setFiles([]);
                                    }}
                                >
                                    <Nav.Item>
                                        <Nav.Link
                                            eventKey="edit-info"
                                            className={selectedTab === 'edit-info' ? 'active' : ''}
                                            style={selectedTab === 'edit-info' ? activeLinkStyle : {}}>
                                            {t('sell.car-ad-card.edit-modal.tabs.edit-info')}
                                        </Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                        <Nav.Link
                                            eventKey="edit-images"
                                            className={selectedTab === 'edit-images' ? 'active' : ''}
                                            style={selectedTab === 'edit-images' ? activeLinkStyle : {}}

                                        >
                                            {t('sell.car-ad-card.edit-modal.tabs.edit-images')}
                                        </Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                        <Nav.Link
                                            eventKey="add-images"
                                            className={selectedTab === 'add-images' ? 'active' : ''}
                                            style={selectedTab === 'add-images' ? activeLinkStyle : {}}
                                        >
                                            {t('sell.car-ad-card.edit-modal.tabs.add-images')}
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>

                            </div>

                            {/***
                             Edit vehicle information*
                             */}

                            {selectedTab === 'edit-info' &&
                                <>
                                    <div className='w-100 container-fluid d-flex justify-content-center'
                                        // style={{maxWidth: '400px'}}
                                    >
                                        {/*<CarSlider car={car}/>*/}
                                    </div>
                                    <EditableVehicleInfo state={info} setState={setInfo}
                                                         headerFont={headerFont}
                                                         isSmallScreen={isSmallScreen}
                                    />
                                </>}

                            {/**
                             Edit images
                             * **/}

                            {selectedTab === 'edit-images' && <>
                                <EditableImages2 images={images} setImages={setImages} t={t}/>
                            </>}
                            {selectedTab === 'add-images' && <>
                                {imagesUploaded === false ?
                                    <span className='d-flex justify-content-center'>
                                        {t('texts:sell.car-ad-card.edit-images.uploading-images')}
                                    </span> :
                                    <AddImages setDisableSubmit={setDisableSubmit}
                                               files={files}
                                               setFiles={setFiles}
                                               inputKey={inputKey}
                                               showFileInput={showFileInput}
                                               setShowFileInput={setShowFileInput}
                                               setShowInfo={setSelectedTab}
                                               uploadStatus={uploadStatus}
                                    />}
                            </>}
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>

                {/**
                 Add images
                 ***/}

                {selectedTab !== 'add-images' ?
                    //save info
                    <div className='container d-flex justify-content-between px-0 w-100 mb-4'>
                        <div id='save-changes-button'
                             onClick={async () => {
                                 console.log('EditModal: save changes button clicked, selectedTab', selectedTab);
                                 if (selectedTab === 'edit-images') {
                                     console.log('EditModal: save Images button clicked', images);
                                     await saveImageResults(car, paths, images, userAccounts);
                                     await props.refreshCars();
                                 } else if (selectedTab === 'edit-info') {
                                     console.log('EditModal: save Info button clicked', info);
                                     await saveInfoResults(info);
                                     await props.refreshCars();
                                 }
                                 props.handleClose();
                             }}
                             className='col-6 d-flex justify-content-center align-items-center px-0'
                             style={{...buyButtonStyle, width: 'auto', maxWidth: '9rem'}}>
                                    <span className=' d-flex align-items-center'>
                                {t('sell.car-ad-card.edit-modal.footer.save-changes')}
                                    </span>
                        </div>
                        <div id='close-button'
                             onClick={() => props.handleClose()}
                             className='col-5 d-flex justify-content-center align-items-center mr-0'
                             style={{
                                 ...buyButtonStyle, ...closeButtonColor,
                                 width: 'auto', maxWidth: '9rem'
                             }}>
                                    <span className=' d-flex align-items-center'>
                                    Close</span>
                        </div>
                    </div>
                    :
                    <div id='add-images-button'
                         onClick={() => {
                             !disableSubmit && uploadImages(files);
                         }}
                         className='px-4 d-flex justify-content-center align-items-center mr-0'
                         style={{
                             ...buyButtonStyle, ...uploadButtonColor, width: 'auto'
                         }}>
                                    <span className='d-flex align-items-center'>
                                    {t('sell.car-ad-card.edit-modal.footer.upload-images')}
                                    </span>
                    </div>
                }
            </Modal.Footer>
        </Modal>
    </div>

};
export const closeButtonColor = {color: '#888', border: '1px solid #888'};

export default EditModal;
import React from 'react';
import upgradeImage from "./img/icon-upgrade.png";

const SponsoredLabel = props => {
    console.log('RatingLabel props', props);
    if (props.rating === 1) {
        return <img src={upgradeImage} alt='upgraded car label'
                    style={{
                        position: 'absolute', top: '.2rem', right: '0.2rem',
                        height: '15px', width: '15px', opacity: '.75'
                    }}/>
    } else return null;
}

export default SponsoredLabel;
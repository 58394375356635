import React from 'react';
import _ from 'lodash';
import Select from "react-select";
import {initialOptions} from "../../../../Data/State/initialState";
import {useTranslation} from "react-i18next";


const LocationOptionSelect2 = props => {
    // console.log('LocationOptionSelect2', props);

    const {t} = useTranslation(['locations']);

    const countries = props.countries;

    // console.log('LocationOptionSelect2 countries', countries);

    const cities = props.country.value !== 'any'
        ?
        _.orderBy(
            countries.find(co => co.Name === props.country.value).cities,
            'Population', 'desc')
        : undefined;

    // console.log('LocationOptionSelect2 cities in country', cities);

    return <div id='select-location' className='container mb-1'>
        <div className='row'>
            <Select className='col-6 px-0'
                    styles={styleCol1}
                    options={countries.map(country => {
                        return {
                            value: country.Name,
                            label: country.title,
                            code: country.IsoCode,
                            title: country.title
                        }
                    })}
                    placeholder={props.countryTitle}
                    value={props.country.value !== 'any' && {label: props.country.title}}
                    isSearchable={false}
                    onChange={async e => {
                        const cityAndCountry =
                            {
                                'country': {value: e.value, visible: true, title: e.title},
                                'city': initialOptions['city']
                            }
                        console.log('LocationOptionSelect2 country onChange', e);

                        props.updateOptions(cityAndCountry);
                        const matchingCountry = countries.find(el => el.IsoCode === e.code);
                        console.log('LocationOptionSelect2 matchingCountry', matchingCountry);
                        const cities = matchingCountry.cities.map(c => {
                            return {
                                Name: c.Name,
                                Country: c.PartitionKey,
                                Population: c.Population,
                                title: c.title
                            }
                        });
                        const sorted = _.orderBy(cities, 'Population', 'desc');
                        console.log('LocationOptionSelect2 cities', sorted);
                        props.updateCities(sorted);
                    }}
            />
            <Select className='col-6 px-0'
                    isDisabled={props.country.value === 'any'}
                    styles={styleCol2}
                    placeholder={props.cityTitle}
                    isSearchable={false}
                    value={props.city.value !== 'any' && {label: props.city.title}}
                // options={[{value: 'tallinn', label: 'Tallinn'}]}
                    options={cities !== undefined && cities.map(c => {
                        return {value: c.Name, label: c.title, title: c.title}
                    })
                    }

                    onChange={(e) => {
                        props.updateOptions({'city': {value: e.value, visible: true, title: e.title}});
                    }}
            />
        </div>
    </div>

}
const optionStyle = (styles, {data, isDisabled, isFocused, isSelected}) => {
    // const color = chroma(data.color);
    // console.log('data', data)
    return {
        ...styles,
        paddingLeft: '15px',
        minHeight: '1px',
        height: '32px',
        lineHeight: '32px',
        paddingTop: '0',
        paddingBottom: '0',
        backgroundColor:
            isSelected
                ? '#888'
                : isFocused
                    ? '#aaa'
                    : null,
        color:
            isSelected
                ? 'white'
                : isFocused
                    ? 'white'
                    : null,
        padding: '8px 10px',
        fontSize: '0.875rem',
        cursor: isDisabled ? 'not-allowed' : 'default',
        '&:active': {
            background: '#ddd',
        },
    }
}
const leftBorderRadius = '4px 0 0 4px';
const rightBorderRadius = '0 4px 4px 0';
const placeholderStyle = props => ({
    ...props,
    fontWeight: '600',
    color: '#212529',
    fontSize: '0.875rem',
    textTransform: 'uppercase'
});

const styleCol1 = {
    control: (settings) => {
        return ({
            ...settings,
            borderRadius: leftBorderRadius,
            boxShadow: 'none',
            border: 'solid 1px rgba(0,0,0,0.2)',
            '&:hover,&:focus,&:active': {
                border: '1px solid #ddd',
            },
            cursor: 'pointer',
            userSelect: 'none'
        })
    },
    placeholder: placeholderStyle,
    singleValue: placeholderStyle,
    indicatorSeparator: () => ({display: 'none'}),
    valueContainer: provided => ({
        ...provided,
        height: '32px',
        padding: '0 8px'
    }),
    menu: provided => ({
        ...provided,
        marginTop: '2px',
        // marginBottom: '4px',
        // width:'inherit'
        top: 0,
        position: 'relative'
    }),
    menuList: (provided) => ({
        ...provided,
        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,
    }),
    option: optionStyle
}
const styleCol2 = {
    control: (settings) => {
        return (
            {
                ...settings,
                borderRadius: rightBorderRadius,
                border: 'solid 1px rgba(0,0,0,0.2)',
                borderLeft: 'none',
                boxShadow: 'none',
                '&:hover,&:focus,&:active': {
                    border: '1px solid #ddd',
                    borderLeft: 'none',
                }
            })
    },
    indicatorSeparator: () => ({display: 'none'}),
    singleValue: placeholderStyle,
    placeholder: placeholderStyle,
    valueContainer: provided => ({
        ...provided,
        height: '32px',
        padding: '0 8px'
    }),
    menu: provided => ({
        ...provided,
        marginTop: '2px',
        // marginBottom: '4px',
        // width:'inherit'
        top: 0,
        position: 'relative'
    }),
    menuList: (provided) => ({
        ...provided,
        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,
    }),
    option: optionStyle
}

export default LocationOptionSelect2;
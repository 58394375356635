import React from 'react';
import MultiSelectOptions from "./MultiSelectFeatures";
import {CloseButton, Modal} from "react-bootstrap";
import car from "../svg/car-travel.svg";

const OptionalFeaturesModal = props => {

    return <Modal centered onHide={() => props.setShowModal(false)}
                  show={props.show}>
        <Modal.Header>
            <Modal.Title style={{fontSize: '0.75rem', color: '#555'}}
                         className='font-weight-bold text-uppercase d-flex w-100'>
                <div className='d-flex col justify-content-start align-items-center pl-0'>
                    <img src={car} height={20} style={{marginRight: '1rem'}} alt='optional feature'/>
                    {props.title}
                </div>
                <CloseButton onClick={() => props.setShowModal(false)}/>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className='container justify-content-start d-flex mb-2 pl-1'>
                <MultiSelectOptions
                    bsClass={'mt-2 multiselect col-12'}
                    options={props.options}
                    state={props.state}
                    updateOptions={props.updateOptions}
                >
                </MultiSelectOptions>
            </div>
        </Modal.Body>
    </Modal>
}

export default OptionalFeaturesModal;
import React, {useEffect, useState} from 'react';
import OptionSelect from "../../OptionSelect/OptionSelect";
import Heading from "../../../Search/SearchArea/Heading";

const InputFieldFuel = props => {

    const fuels = props.fuels;

    let numberClass = 'row mt-2 numbered-list-item'
    if (props.state.fuel === undefined && props.prevField !== undefined) numberClass += ' yellow';
    else if (props.prevField === undefined) numberClass += ' grey';

    const [selected, setSelected] = useState('');

    useEffect(() => {
        if (props.state.fuel !== undefined) {
            const selectedFuel = fuels[props.state.fuel];
            setSelected(selectedFuel);
        }
    }, [props.state.fuel]);

    const keys = Object.keys(fuels);
    const fuelOptions = keys.map(key => {
        return {label: fuels[key].title, value: key}
    });

    return <div className={numberClass} id='row-fuel'>

        <div className='offset-1'/>
        <div className='col-9 pl-0'>
            <OptionSelect
                id='select-fuel'
                isDisabled={props.prevField === undefined}
                handler={props.updateFuel}
                selected={selected.title}
                options={fuelOptions}>
                <Heading>{props.title}</Heading>
            </OptionSelect>
        </div>
    </div>
}

export default InputFieldFuel;
import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import jwtDecode from "jwt-decode";
import {useMsal} from "@azure/msal-react";
import {useIsAuthenticated} from "@azure/msal-react";

const LoginButton = props => {

    console.log('LoginButton init', props);
    const {t} = useTranslation(['texts']);
    const fontStyle = {fontSize: '.9rem'}

    const {instance, accounts} = useMsal();
    const isAuthenticated = useIsAuthenticated();
    console.log('LoginButton isAuthenticated', isAuthenticated, 'instance', instance, 'accounts', accounts);

    const handleLogin = async () => {
        await instance.loginRedirect({
            scopes: ['openid', 'profile', 'email'],
            prompt: 'select_account'
        });
    };

    useEffect(() => {
        console.log('LoginButton useEffect token extension');
        if (accounts) {
            try {
                const accessToken = window.localStorage.getItem('token');
                if (accessToken) {
                    const decodedToken = jwtDecode(accessToken);
                    console.log('LoginButton jwtDecode', accessToken.substring(0, 30) + '...',
                        'result', decodedToken, 'emails', decodedToken.emails);
                }
            } catch (error) {
                console.warn('LoginButton jwtDecode error', error);
            }
        }
    }, [accounts]);

    return <div id='login-button-div' className='row d-flex justify-content-end mt-2' style={props.fontStyle}>
        <div className='col-12 text-right pl-0'>
            <span style={fontStyle}>{t('sell.login-message')}</span>
        </div>
        <div className='d-flex align-items-center justify-content-center mr-3'
             style={loginStyle}
             onClick={async () => {
                 props.setIsLoading(true);
                 try {
                     await handleLogin();
                 } catch (error) {
                     console.log('LoginButton login error', error);
                 }
             }}>
            <div className='text-uppercase'
                 style={fontStyle}>
                {t('sell.login-title')}
            </div>
        </div>
    </div>
}

export default LoginButton;

const loginStyle = {
    width: 200,
    height: 40,
    // border: 'solid #aaa 0.5px',
    marginTop: 10,
    borderRadius: 4,
    userSelect: 'none',
    cursor: 'pointer',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px'
}
import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Car from './Pages/Car/';
import Search from './Pages/Search/';
import Home from './Pages/Home/';
import Top from './Shared/Header/Top/';
import Navbar from './Shared/Header/Navbar/'
import Sell2 from './Pages/Sell/Sell2';
import {initialFiltersOption, initialModals, initialOptions, initialSort}
    from './Data/State/initialState.js'
import AzureSearchConnector from './Integration/Search/AzureSearchConnector';
import {AzureModelsRESTConnector, AzureMakesRESTConnector} from './Integration'
import SearchUtils from './Integration/Utils/SearchUtils';
import './Integration/i18n/i18n';
import Company from "./Shared/Pages/Company";
import Support from "./Shared/Pages/Support";
import {silenceConsole} from "./Shared/helpers/genericHelper";
import {withTranslation} from 'react-i18next';
import {Helmet} from "react-helmet";

const searchConnector = new AzureSearchConnector();
const searchQueryUtils = new SearchUtils();

class App extends React.Component {

    modelsRESTConnector = new AzureModelsRESTConnector();
    makesRESTConnector = new AzureMakesRESTConnector();
    searchFacets = {};

    constructor(props) {
        silenceConsole();

        super(props);
        this.state = {
            options: JSON.parse(JSON.stringify(initialOptions)),
            modals: JSON.parse(JSON.stringify(initialModals)),
            filters: JSON.parse(JSON.stringify(initialFiltersOption)),
            sort: JSON.parse(JSON.stringify(initialSort)),
            updateModals: this.updateModals,
            updateMultipleModals: this.updateMultipleModals,
            updateOptions: this.updateOptions,
            resetOptions: this.resetOptions,
            updateFilters: this.updateFilters,
            updateSort: this.updateSort,
            updateSelectedCar: this.updateSelectedCar,
            updateActivePage: this.updateActivePage,
            updateSearchResults: this.updateSearchResults,
            updateMake: this.updateMake,
            updateMultipleOptions: this.updateMultipleOptions,
            updateMultipleOptionsNoAppend: this.updateMultipleOptionsNoAppend,
            updateCities: this.updateCities,
            updateCountries: this.updateCountries,
            updateToggle: this.updateToggle,
            searchResults: [],
            searchString: '',
            searchCount: 0,
            activePage: 0,
            itemsPerPage: 7,
            makes: [],
            countries: [],
            cities: [],
            token: undefined,
            toggle: undefined
        };
        console.log('App constructor', this.state);
    }


    componentDidMount = async () => {
        if (this.state.makes.length === 0) {

            const makes = await this.makesRESTConnector.retrieveMakes(55);

            this.setState({makes});
        }
        await this._getSearchResults();
        console.log('App.js componentDidMount; app state:', this.state);
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log('componentDidUpdate');
    }

    async _getSearchResults() {
        const query = searchQueryUtils.createSearchString(this.state.options);
        const top = searchQueryUtils.generateTop(this.state.itemsPerPage);
        const sort = searchQueryUtils.generateSort(this.state.sort, this.state.options.ignoreSponsored);
        const facetString = searchQueryUtils.generateFacets(query);//added facets
        const searchString = query + top + sort + facetString;
        console.log('App: \nsearch query', query, '\nsearch string', searchString);
        const searchResults = await searchConnector.fetchResults(searchString);
        console.log('SearchResults fetched:', searchResults['@odata.count'], searchString);
        // this._parseSearchResults(searchResults);
        // console.log('this.searchFacets at search', this.searchFacets);
        let cachedFacetsLength = Object.keys(this.searchFacets).length;
        if (cachedFacetsLength === 0 && searchResults['@search.facets'] !== undefined) {
            this.searchFacets = searchResults['@search.facets'];
            console.log('searchFacets renewed')
        }
        if (cachedFacetsLength > 0) {
            console.log('searchFacets from cache ', cachedFacetsLength, this.searchFacets);
        }
        this.setState({
                searchFacets: searchResults['@search.facets'] !== undefined ? searchResults['@search.facets'] : this.searchFacets,
                searchResults: searchResults.value !== undefined ? searchResults.value : [],
                searchCount: searchResults['@odata.count'] !== undefined ? searchResults['@odata.count'] : 0,
                searchString,
                activePage: 0,
            },
            // () => console.log('getSearchResults: search fetched', this.state)
        )
    }

    updateOptions = async (key, obj) => {
        console.log('updateOptions at App', key, obj);
        const options = {...this.state.options};
        options[key] = obj;
        this.setState({options: options}, async () => {
            console.log('updateOptions: after update', this.state.options);
            await this._getSearchResults();
        });
    };

    updateMultipleOptionsNoAppend = async (multipleOptions) => {
        console.log('updateMultipleOptionsNoAppend at App', multipleOptions);
        const options = {...initialOptions};
        Object.keys(multipleOptions).forEach(
            key => options[key] = multipleOptions[key]
        );

        this.setState({options}, async () => {
            console.log('updateMultipleOptionsNoAppend: after update', this.state.options);
            await this._getSearchResults();
        });
    };

    updateMultipleOptions = async (multipleOptions) => {
        console.log('updateMultipleOptions at App', multipleOptions);
        const options = {...this.state.options};
        Object.keys(multipleOptions).forEach(
            key => options[key] = multipleOptions[key]
        );
        console.log('options updated with keys', options);


        this.setState({options}, async () => {
            console.log('updateMultipleOptions: after update', this.state.options);
            await this._getSearchResults();
        });
    };


    updateMake = async (make) => {
        console.log('updateMake in App', make);
        const options = {...this.state.options};
        options.make = {value: make.name, id: make.id, visible: true, title: make.name};
        options.model = {value: 'any', id: 0, visible: false};
        const models = await this.modelsRESTConnector.retrieveModelNames(make.id);
        this.setState({options, models}, async () => {
            console.log('state.options after updateMake', this.state);
            await this._getSearchResults();
        });
        // options.model = {value: 'any', id: '0', visible: false};
    }

    updateCountries = countries => this.setState({countries}, () => console.log('update countries', this.state));
    updateCities = cities => this.setState({cities}, () => console.log('update cities', this.state));

    updateFilters = () => {
        console.log('updaterFilters', this.state,);
        this.setState({filters: !this.state.filters},
            () => {
                console.log('updateFilters: ', this.state.filters);
                if (!this.state.filters) window.scrollTo(0, 90);
            })
    };

    updateSort = value => {
        this.setState({sort: value}, async () => {
            console.log('updateSort', this.state.sort);
            await this._getSearchResults();
        });

    };
    updateSelectedCar = value => this.setState({selectedCar: value},
        () => console.log('updateSelectedCar', this.state.selectedCar));

    resetOptions = () => {
        console.log('resetOptions at App: ', this.state.options);
        this.setState({
            options: JSON.parse(JSON.stringify(initialOptions)),
            searchResults: []
        }, () => this._getSearchResults())
    };

    updateModals = async (key, val) => {
        const modals = {...this.state.modals};
        modals[key] = val;
        console.log('updateModals', key, val, 'modals[key]', modals[key], 'modals', modals);
        this.setState({modals: modals},
            () => console.log('updateModals: after update', this.state)
        )
    };
    updateMultipleModals = (modals) => {
        const m = {...this.state.modals, ...modals};
        console.log('updateMultipleModals resulting modals', m);
        this.setState({modals: m}, () =>
            console.log('setState after updateMultipleModals', this.state.modals));
    }

    updateActivePage = async activePage => {

        this.setState({activePage},
            async () => {
                console.log('update active page:', this.state.activePage);

                const skip = searchQueryUtils.generateSkip(this.state.activePage, this.state.itemsPerPage);
                // const query = queryUtils.createSearchString(props.options);
                // const top = queryUtils.generateTop(props.itemsPerPage);
                const fullQuery = this.state.searchString + skip;
                console.log('full query', fullQuery);

                const results = await searchConnector.fetchResults(fullQuery);
                console.log('search results', results);
                this.setState({
                        searchResults: results.value,
                        searchCount: results['@odata.count'],
                    },
                    () => console.log('update searchResults', this.state));
            });
    };

    updateToggle = (toggle) => this.setState({toggle}, () => console.log('updateToggle',
        this.state.toggle));

    render() {
        console.log('App started');
        const {t} = this.props;

        return (
            <Router>
                <Helmet>
                    <title>{t('texts:common.html-title')}</title>
                    <meta name="description" content={t('texts:common.html-description')}/>
                </Helmet>
                <Top/>
                <Navbar/>

                <Routes>
                    <Route path={'/'} element={<Home {...this.state}/>}/>

                    <Route path={'/search'} element={<Search{...this.state}/>}/>

                    <Route
                        path="/car/:id?"
                        element={<Car selectedCar={this.state.selectedCar}
                                      updateSelectedCar={this.updateSelectedCar}/>}
                    />

                    <Route path={'/sell'} element={<Sell2 {...this.state}/>}/>

                    <Route path={'/company/*'} element={<Company/>}/>

                    <Route path={'/support/*'} element={<Support/>}/>
                </Routes>

            </Router>
        );
    }
}

export default withTranslation()(App);

import React, {useEffect, useState} from 'react';
import './Top.css';
import logout from "../Navbar/img/logout.svg";
import {updateFontFamily} from "../../helpers/fontHelper";
import i18n from "i18next";
import {useMediaQuery} from "react-responsive";
import {useTranslation} from "react-i18next";
import {LanguageModal} from "../../Modals";
import {useLocation} from 'react-router-dom'
import {NavLink} from 'react-router-dom';
import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import {LanguageSelector} from "../../Language/LanguageSelector";

const Top = props => {

    console.log('Top props', props);

    const {instance} = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [showLanguageModal, setShowLanguageModal] = useState(false);
    const {t} = useTranslation(['texts']);
    const location = useLocation();

    const closeLanguageModal = () => setShowLanguageModal(false);
    const changeLanguage = code => {
        console.log('Top changeLanguage', code);
        i18n.changeLanguage(code);
        updateFontFamily(code);
    };

    useEffect(() => {
        setLanguageCode(i18n.language);
    }, [i18n.language]);

    const [languageCode, setLanguageCode] = useState(i18n.language);

    const isTinyScreen = useMediaQuery({maxWidth: '340px'});
    // let langTextWidth = '100px';
    let logoutMargin = '1rem'
    if (isTinyScreen) {
        logoutMargin = '.5rem'
    }
    const isTabletOrMobile = useMediaQuery({maxWidth: 1224});
    const isCarPage = location.pathname.split('/').filter(s => s === 'car').length > 0;

    const topContainer =
        isTabletOrMobile && isCarPage
            ? 'container-fluid d-flex justify-content-between align-items-center h-100'
            : 'container px-0 d-flex justify-content-between align-items-center h-100';

    const logoutContainerStyle = {
        border: '1px solid #bbb', marginLeft: logoutMargin,
        userSelect: 'none', cursor: 'pointer'
    };

    return <div id='top' className='top'>
        <div className={topContainer} id='top-container'>
            <NavLink key='logo-home' to="/"
                     className={isTinyScreen && isAuthenticated ? 'nav-link pr-0 pl-2' : 'nav-link'}>
                    <span id='logo-caruniverse' className='text-uppercase mr-0'
                          style={{
                              color: 'red', fontSize: '1rem', fontWeight: '600',
                              letterSpacing: '2px'
                          }}>
                    Caruniverse
                    </span>
            </NavLink>

            <div id='language-logout-container' className='p-2 d-flex'>

                <LanguageSelector languageCode={languageCode}
                                  setShowLanguageModal={setShowLanguageModal}
                                  isFooter={false}
                />

                {isAuthenticated && <div id='logout-container'
                                         className='d-flex align-items-center justify-content-between footer-link px-1'
                                         style={logoutContainerStyle}

                                         onClick={async () => {
                                             window.localStorage.removeItem('token');
                                             window.sessionStorage.removeItem('sellerInfo');
                                             await instance.logout();
                                         }}>
                    {!isTinyScreen &&
                        <span style={{fontSize: '.8rem', paddingRight: '.25rem', whiteSpace: 'nowrap'}}>
                        {t('sell.logout-title')}</span>
                    }
                    <img src={logout} height='17' style={{opacity: '.6', transform: 'rotate(180deg)'}}/>
                </div>}
            </div>
        </div>

        <LanguageModal show={showLanguageModal}
                       handleClose={closeLanguageModal}
                       changeLanguage={changeLanguage}/>
    </div>
}

export default Top;
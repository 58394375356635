import React, {Suspense} from 'react';
import {createRoot} from "react-dom/client";
import App from './App';
import AuthProvider from "./Pages/Sell/MsalLogin/AuthProvider";
import {LoadingIndicator} from "./Shared/FetchImage/LoadingIndicator";

const rootElement = document.getElementById("container");

const root = createRoot(rootElement);
root.render(
    <Suspense fallback={
        <div className='vh-100 d-flex justify-content-center align-items-center'>
            <LoadingIndicator/>
        </div>}>
        <AuthProvider>
                <App/>
        </AuthProvider>
    </Suspense>
);
import React, {useEffect, useState} from "react";
import Select from "react-select";
import './css/vehicle.css';

const EditableAttributeVAT = props => {

    const {
        title, field, value, state, setState,
        dictionary, placeholder, side, textStyle
    } = {...props};


    console.log('EditableAttributeVAT; title :', title, 'field: ', field, 'value: ', value,
        'type of data:', typeof dictionary);


    let initialValue = '';

    const [showField, setShowField] = useState(false);
    const [selectValue, setSelectValue] = useState(initialValue);
    const [spanValue, setSpanValue] = useState(initialValue);

    //get vat by country and
    const getVATasText = (vat, dictionary) => {
        let text = dictionary[0].label;
        if (vat === 'true') text = dictionary[1].label;
        return text;
    }


    useEffect(() => {
        setSpanValue('');
        if (value !== undefined) {
            const text = getVATasText(value, dictionary);
            console.log('EditableAttributeVAT useEffect text', text)
            setSpanValue(text);
        }
        console.log('EditableAttributeVAT useEffect; selectValue:', selectValue, 'initial value', initialValue, dictionary);
    }, [value])

    const updateOnSelect = value => {
        console.log('EditableAttributeVAT updateOnSelect', value);
        setSelectValue(value);

        const copy = JSON.parse(JSON.stringify(state));
        copy[field] = value.value;
        setState(copy);
        console.log('updateOnSelect state', copy);
        // setFieldValue(value.value);
    }

    const handleKeyPress = (event) => {
        console.log(event.key);
        if (event.key === 'Enter') setShowField(!showField);
        console.log('state after enter', state);
    }

    let colClass = 'col-12 align-items-start d-flex justify-content-between'
    let colImgStyle = 'col-12 d-flex justify-content-end'
    let col2style = 'col-12 mx-0'
    if (side === 'left') {
        colClass += ' pl-0';
        col2style += ' pl-0'
        colImgStyle += ' pl-0'
    } else {
        colClass += ' pr-0';
        col2style += ' pr-0';
        colImgStyle += ' pr-0';
    }

    return <div id={`editable-attribute-select-${title.toLowerCase()}`}
                className='row col-6 mx-0 d-flex justify-content-between px-0'
                style={textStyle}>
        <div className={colClass}>
            <span className="text-left">{title}</span>
            <img height='17px' width='17px' className='button ml-1 mt-1 edit-icon'
                 onClick={() => setShowField(!showField)}
            />
        </div>

        <div className='row col-12'/>
        <div className={col2style}>
            <div className='d-flex justify-content-start'>
                {!showField && <span id='attribute-vat-value-'
                                     className="text-left font-weight-bold text-capitalize">
                    {spanValue}
            </span>}
                {showField && (
                    <Select value={selectValue}
                            className='w-100'
                            placeholder={placeholder !== undefined && placeholder}
                            style={textStyle}
                        // menuIsOpen
                            onChange={e => updateOnSelect(e)}
                            key={field}
                            onKeyDown={evt => handleKeyPress(evt)}
                            options={dictionary}/>)
                }
            </div>
        </div>
    </div>;
};

export default EditableAttributeVAT;
import React, {useEffect} from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import './css/navbar.css';
import {useTranslation} from "react-i18next";
import {useMediaQuery} from 'react-responsive';
import {useLocation} from 'react-router-dom'

const Navbar = props => {
    const {t} = useTranslation(['texts']);
    // console.log('Navbar props', props);
    const navigate = useNavigate();
    const location = useLocation();

    const isPortrait = useMediaQuery({orientation: 'portrait'});
    const isLandscape = useMediaQuery({orientation: 'landscape'});
    const isTabletOrMobile = useMediaQuery({maxWidth: 1224})

    const isCarPage = location.pathname.split('/').filter(s => s === 'car').length > 0;

    useEffect(() => {
        console.log('Navbar useEffect');
    }, []);

    const divClass = 'h-100 d-flex align-items-center collapse navbar-collapse justify-content-end text-capitalize'
    const ulClass = 'navbar-nav d-inline-flex h-100';
    const navLinkClass = 'nav-link h-100 d-flex align-items-center pr-0';
    const activeNavLinkClass = 'active-link d-flex align-items-center';
    const liClassName = 'nav-item d-flex align-items-center pl-2 ml-2'

    const navContainer = isTabletOrMobile && isCarPage ? 'container-fluid pl-0' : 'container pl-0';

    return <div className={navContainer} id='navbar container'>

        {<nav className="navbar navbar-light navbar-custom navbar-expand py-0 pl-0 pr-0 mr-0"
              style={{height: '65px', backgroundColor: 'white'}}>
            <div className={divClass}
                 id="navbarNav">
                {(isLandscape || isPortrait) && isCarPage &&
                    <div id='back-link' className='position-absolute'
                         style={{left: '1rem', opacity: '0.7', cursor: 'pointer', padding: '1rem', marginLeft: '-1rem'}}
                         onClick={() => navigate(-1)}
                    ><img src='/img/angle-down-solid-black.svg' height='28px' style={{transform: 'rotate(90deg)'}}/>
                    </div>}
                <ul className={ulClass}>
                    <li className={liClassName} id='home-link'>
                        <NavLink
                            className={({isActive}) =>
                                isActive ? `${navLinkClass} ${activeNavLinkClass}` : navLinkClass}
                            key='key-home'
                            to="/"
                            style={{maxHeight: '0.5rem'}}>
                            <div>
                                {t('nav.home')}
                                {location.pathname === '/' && <span className='active-span'/>}
                            </div>
                            <span className="sr-only">(current)</span>
                        </NavLink>
                    </li>
                    <li className={liClassName} id='search-link'>
                        <NavLink
                            className={({isActive}) =>
                                isActive ? `${navLinkClass} ${activeNavLinkClass}` : navLinkClass}
                            key='key-search'
                            to="/search"
                            style={{maxHeight: '0.5rem'}}>
                            <div>
                                {t('nav.search')}
                                {location.pathname === '/search' && <span className='active-span'/>}
                            </div>
                            <span className="sr-only">(current)</span>
                        </NavLink>
                    </li>

                    <li className={liClassName} id='sell-link'>
                        <NavLink
                            className={({isActive}) =>
                                isActive ? `${navLinkClass} ${activeNavLinkClass}` : navLinkClass}
                            key='key-sell'
                            to="/sell"
                            style={{maxHeight: '0.5rem'}}>
                            <div>
                                {t('nav.sell')}
                                {location.pathname === '/sell' && <span className='active-span'/>}
                            </div>
                            <span className="sr-only">(current)</span>
                        </NavLink>
                    </li>
                </ul>
            </div>
        </nav>}
    </div>
}

export default Navbar;

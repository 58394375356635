import React from 'react';
import {Modal} from "react-bootstrap";

const LanguageModal = props => {

    return <Modal show={props.show}
                  onHide={props.handleClose}
                  animation={false}
                  centered
                  size='sm'
                  id='language-modal'
    >
        <Modal.Header closeButton>
            <Modal.Title>Select Language</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <div className='container'
                // style={{maxWidth: '350px'}}
            >
                <div className='row'>
                    <div className='col-12 d-flex justify-content-center'>
                        <ul className="list-unstyled list-group" style={styles.modalUl}>
                            <LanguageLI code='en' changeLanguage={props.changeLanguage}
                                        close={props.handleClose}>
                                English
                            </LanguageLI>
                            <LanguageLI code='ee' changeLanguage={props.changeLanguage}
                                        close={props.handleClose}>
                                Eesti
                            </LanguageLI>
                            <LanguageLI code='lv' changeLanguage={props.changeLanguage}
                                        close={props.handleClose}>
                                Latviešu
                            </LanguageLI>
                            <LanguageLI code='lt' changeLanguage={props.changeLanguage}
                                        close={props.handleClose}>
                                Lietuvių
                            </LanguageLI>
                            <LanguageLI code='fi' changeLanguage={props.changeLanguage}
                                        close={props.handleClose}>
                                Suomi
                            </LanguageLI>
                            <LanguageLI code='se' changeLanguage={props.changeLanguage}
                                        close={props.handleClose}>
                                Svenska
                            </LanguageLI>
                            <LanguageLI code='ru' changeLanguage={props.changeLanguage}
                                        close={props.handleClose}>
                                Русский
                            </LanguageLI>
                        </ul>
                    </div>
                </div>
            </div>
        </Modal.Body>
    </Modal>
}
export default LanguageModal;

const LanguageLI = props => {

    return <li style={{
        width: 200, height: 45, border: 'solid #ddd 2px',
        marginTop: 10, borderRadius: 22.5, cursor: 'pointer', userSelect: 'none'
    }} className='d-flex align-items-center justify-content-center'
               onClick={() => {
                   props.changeLanguage(props.code);
                   localStorage.setItem('user-selected-language', props.code);
                   props.close();
               }}
    >
        <span style={{fontFamily: 'Rubik', fontSize: '100%'}}> {props.children}</span>
    </li>
}

const styles = {
    modalBody: {
        width: '100%',
        height: '44px',
        borderBottom: 'solid 1px rgba(0,0,0,0.75)'
    },
    modalUl: {
        maxHeight: '450px',
        overflow: 'overlay',
        border: 'none'
    },
    modalInput: {
        opacity: 0.8,
        fontSize: '1.125rem',
        width: '320px',
        border: 'none',
        height: '42px',
        marginLeft: '10px'
    },
    carItem: {
        width: '100%',
        height: '55px',
        lineHeight: '52px',
        borderBottom: 'solid 1px rgba(0,0,0,0.25)'
    },
    carTitle: {fontSize: '1.125rem', marginLeft: '5px'}
};
import React from 'react';

const Heading = (props) => {

    let divClass = '';
    let spanClass = '';
    let divStyle = {};
    let spanStyle = {};
    if (props.style !== undefined && Object.keys(props.style).length !== 0) divStyle = props.style;
    if (props.spanStyle !== undefined && Object.keys(props.spanStyle).length !== 0) spanStyle = props.spanStyle;
    if (props.divClass !== undefined) divClass = props.divClass;
    if (props.spanClass !== undefined) spanClass = props.spanClass;

    return <div style={divStyle}
                className={`row p-0 d-flex align-items-center ${divClass}`}>
        <span style={{...spanStyle, userSelect: 'none'}}
              className={`text-uppercase search-options-label ${spanClass}`}>
            {props.children}
        </span>
        {props.img !== undefined ? <img className='ml-1' src={props.img}
                                        width='12px' height='12px'
                                        alt='checkbox'/> : null}
    </div>;
}

export default Heading;

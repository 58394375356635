import React, {useEffect, useState} from 'react';
import Toggle from "./Toggle/Toggle";
import AutomaticFlow from "./AutomaticFlow/AutomaticFlow";
import ManualFlow from "./ManualFlow/ManualFlow";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import {useMediaQuery} from "react-responsive";
import {AzureMakesRESTConnector} from "../../../../Integration";
import {useIsAuthenticated} from "@azure/msal-react";
import {CarAdded} from "./CarAdded/CarAdded";

const AddCar = props => {

    console.log('AddCar props:', props);
    const {t} = useTranslation(['texts']);

    const [autoComplete, setAutoComplete] = useState(true);
    const isSmallScreen = useMediaQuery({maxWidth: '450px'});

    const [textStyle, setTextStyle] = useState({fontSize: '.95rem'});
    const isAuthenticated = useIsAuthenticated();

    const [isSellerInfo, setIsSellerInfo] = useState(false);

    const makesRESTConnector = new AzureMakesRESTConnector();

    useEffect(() => {
            console.log('AddCar useEffect, [isAuthenticated]');

            if (isSmallScreen) {
                setTextStyle({fontSize: '.9rem'});
            }

            if (props.state.makes.length === 0) {
                try {
                    makesRESTConnector.retrieveMakes(55).then(makes => {
                        console.log('AddCar: Makes was empty, fetched new set:', makes);
                        props.sellFunctions.updateSellMakes(makes);
                    })
                } catch (e) {
                    console.warn('AddCar: Error fetching makes', e);
                }
            }

            const sellerInfo = window.sessionStorage.getItem('sellerInfo');
            console.log('AddCar: inside useEffect; sellerInfo', typeof sellerInfo, sellerInfo, 'isEmpty'
                , _.isEmpty(sellerInfo));
            console.log('AddCar: inside useEffect; sellerInfo', sellerInfo);
            if (!_.isEmpty(sellerInfo) && sellerInfo.length > 6) {
                setIsSellerInfo(true);
            }
        }, [isAuthenticated]
    );

    useEffect(() => {
            props.sellFunctions.updateEntryMode('automatic');
            // else props.sellFunctions.updateEntryMode('manual');
            console.log('useEffect AddCar, [entryMode]');
        },
        [isAuthenticated]);

    return <div className='container'>
        {!isSellerInfo ?
            <div className='row mt-2'>
                <span className='pl-0 ml-0' style={textStyle}>
                    {t('texts:sell.my-account-area.register-to-sell')}
                </span>
            </div> :


            <div className='row mt-2'>
                {!props.state.loadingComplete ?
                    <>
                        <div className='col-12 px-0 d-flex justify-content-left'>
                            <Toggle setAutoComplete={setAutoComplete}
                                    entryMode={props.state.entryMode}
                                    sellClear={props.sellFunctions.sellClear}
                                    autoComplete={autoComplete}
                                    updateEntryMode={props.sellFunctions.updateEntryMode}
                                    onMessage={t('sell.autocomplete-on')}
                                    offMessage={t('sell.autocomplete-off')}/>
                        </div>
                        {autoComplete &&
                            <AutomaticFlow
                                state={props.state}
                                sellFunctions={props.sellFunctions}
                            />
                        }
                        {!autoComplete &&
                            <ManualFlow state={props.state}
                                        sellFunctions={props.sellFunctions}
                            />}
                    </>
                    : <CarAdded state={props.state} sellFunctions={props.sellFunctions}/>}
            </div>}
    </div>

}

export default AddCar;
import React from 'react';
import bmw from './img/bmw.svg';
import audi from './img/audi.svg';
import mercedes from './img/mercedes.svg';
import porsche from './img/porsche.svg';
import vw from './img/volkswagen.svg';
import peugeot from './img/peugeot.svg';
import lexus from './img/lexus.svg';
import tesla from './img/tesla.svg';
import hyundai from './img/hyundai.svg';
import toyota from './img/toyota.svg';

import './css/banner.css';
import {useMediaQuery} from "react-responsive";
import {Link} from "react-router-dom";

const BannerWithMakes = props => {

        const isBigScreen = useMediaQuery({minWidth: 1000});

        let justifyContent = '';
        let bannerContainer = 'banner-container mt-0 scroll'
        if (isBigScreen) {
            justifyContent = 'center';
            bannerContainer = 'row banner-container mt-0';
        }


        return <div className=''>
            <div className={bannerContainer} style={{justifyContent: justifyContent}}>

                <div className="px-2 mx-1 d-flex align-items-center" key='audi'>
                    <Link to='/search'>
                        <img src={audi} alt='audi' height='26' style={{opacity: '50%'}}
                             onClick={() => props.updateOptions({
                                 make: {
                                     value: 'audi',
                                     visible: true,
                                     title: 'Audi'
                                 }
                             })}
                        />
                    </Link>
                </div>
                <div className="px-2 mr-1 d-flex align-items-center" key='bmw'>
                    <Link to='/search'>
                        <img src={bmw} height='50' style={{opacity: '50%'}} alt='bmw'
                             onClick={() => props.updateOptions({
                                 make: {
                                     value: 'bmw',
                                     visible: true,
                                     title: 'BMW'
                                 }
                             })}
                        />
                    </Link>
                </div>
                <div className="px-2 mr-1  d-flex align-items-center" key='mercedes'>
                    <Link to='/search'>
                        <img src={mercedes} alt='mercedes' height='50' style={{opacity: '100%'}}
                             onClick={() => props.updateOptions({
                                 make: {
                                     value: 'mercedes-benz',
                                     visible: true,
                                     title: 'Mercedes'
                                 }
                             })}
                        />
                    </Link>
                </div>
                <div className="px-2 mr-1  d-flex align-items-center" key='porsche'>
                    <Link to='/search'>
                        <img src={porsche} alt='porsche' height='50' style={{opacity: '50%'}}
                             onClick={() => props.updateOptions({
                                 make: {
                                     value: 'porsche', title: 'Porsche', visible: true
                                 }
                             })}
                        />
                    </Link>
                </div>
                <div className="px-2 mr-1  d-flex align-items-center" key='vw'>
                    <Link to='/search'>
                        <img src={vw} alt='vw' height='50' style={{opacity: '50%'}}
                             onClick={() => props.updateOptions({
                                 make: {
                                     value: 'volkswagen', title: 'VW', visible: true
                                 }
                             })}
                        />
                    </Link>
                </div>
                <div className="px-2 mr-1  d-flex align-items-center" key='peugeot'>
                    <Link to='/search'>
                        <img src={peugeot} alt='peugeot' height='55' style={{opacity: '50%'}}
                             onClick={() => props.updateOptions({
                                 make: {
                                     value: 'peugeot', title: 'Peugeot', visible: true
                                 }
                             })}/>
                    </Link>
                </div>
                <div className="px-2 mr-1  d-flex align-items-center" key='lexus'>
                    <Link to='/search'>
                        <img src={lexus} alt='lexus' height='80' style={{opacity: '50%'}}
                             onClick={() => {
                                 props.updateOptions({
                                     make: {value: 'lexus', title: 'Lexus', visible: true}
                                 })
                             }}/>
                    </Link>
                </div>
                <div className="px-2 mr-1  d-flex align-items-center" key='tesla'>
                    <Link to='/search'>
                        <img src={tesla} alt='tesla' height='50' style={{opacity: '50%'}}
                             onClick={() => props.updateOptions({
                                 make: {
                                     value: 'tesla', title: 'Tesla', visible: true
                                 }
                             })}/>
                    </Link>
                </div>
                <div className="px-2 mr-1  d-flex align-items-center" key='hyundai'>
                    <Link to='/search'>
                        <img src={hyundai} alt='hyundai' height='72' style={{opacity: '70%'}}
                             onClick={() => props.updateOptions({
                                 make: {
                                     value: 'hyundai', title: 'Hyundai', visible: true
                                 }
                             })}/>
                    </Link>
                </div>
                <div className="px-2 mr-1  d-flex align-items-center" key='toyota'>
                    <Link to='/search'>
                        <img src={toyota} alt='toyota' height='37' style={{opacity: '50%'}}
                             onClick={() => props.updateOptions({
                                 make: {value: 'toyota', title: 'Toyota', visible: true}
                             })}/>
                    </Link>
                </div>
            </div>
        </div>
    }

;

export default BannerWithMakes;

export const silenceConsole = () => {

    // dotenv.config();

    const consoleVerbosity = process.env.REACT_APP_CONSOLE;
    console.log('running in ', process.env.NODE_ENV, ' mode', '; console: ', consoleVerbosity);
    // console.log('process.env',process.env);

    if (consoleVerbosity === 'prod') {
        console.log = function () {
        };
        console.warn = function () {
        };
        console.error = function () {
        };
    }
}

import React, {useEffect, useRef, useState} from 'react';
import _ from 'lodash';
import '../MyOffers/Modals/EditModal/EditableInfo/EditableInfoAttributes/css/vehicle.css'
import OptionSelect from "../../OptionSelect/OptionSelect";
import Heading from "../../../Search/SearchArea/Heading";
import {useTranslation} from "react-i18next";
import NumberOfStep from "../../Misc/NumberOfStep/NumberOfStep";
import {validatePrivateInfo,} from "./utils/utils";
import {CompanyTypeChangeModal} from "./Modals/CompanyTypeChangeModal";
import {useMediaQuery} from "react-responsive";
import {hashUserID} from "../../../../Integration/Utils/StorageUtils";
import {AzureSellersRESTConnector} from "../../../../Integration";
import {getTitleFromDictionary} from "../../../../Integration/Utils/LanguageUtils";
import {LoadingIndicator} from "../../../../Shared/FetchImage/LoadingIndicator";
import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import {ErrorMessageBlock} from "./Components/ErrorMessageBlock";
import {ResetButton} from "./Components/ResetButton";
import {UpdateButton} from "./Components/UpdateButton";
import {AddPrivateSellerForm} from "./Components/AddPrivateSellerForm";
import {AddCompanySellerForm} from "./Components/AddCompanySellerForm";
import {FieldComponent} from "./Components/FieldComponent";
import {SellerInfoRemoveButton} from "./Components/SellerInfoRemoveButton";
import {SellerInfoEditButton} from "./Components/SellerInfoEditButton";
import {SellerInfoInput} from "./Components/SellerInfoInput";
import {SellerInfoSelect} from "./Components/SellerInfoSelect";

const sellersRESTConnector = new AzureSellersRESTConnector();

export const fetchSeller = async id => {
    console.log('fetchSeller', id);
    const ID = hashUserID(id);
    const sellerInfo = await sellersRESTConnector.retrieveSellerInfo(ID.toString());
    console.log('sellerInfo', sellerInfo);
    return sellerInfo;
};

const MyAccount = props => {

    // console.log('MyAccount props', props);
    const {accounts} = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [sellerID, setSellerID] = useState(undefined);

    const [showInput, setShowInput] = useState({});
    const {t} = useTranslation(['data-for-ui', 'texts', 'errors']);
    const juridicalStatus_d = t('data-for-ui:juridical-status', {returnObjects: true});
    const sellerInfo_d = t('texts:vehicle-information.seller-info', {returnObjects: true});

    const [errorMessage, setErrorMessage] = useState(undefined);
    const [localInfoFields, setLocalInfoFields] = useState({});

    const fetchAndUpdate = async (sellerID) => {
        console.log('MyAccount: fetchAndUpdate: empty props.sellerInfo; sellerID', sellerID);
        if (!_.isEmpty(sellerID)) {
            const info = await fetchSeller(sellerID);
            console.log('MyAccount: fetchAndUpdate response: info', info);
            props.updateSellerInfo(info);
            setLocalInfoFields({});
        } else throw new Error('sellerID is empty');
    }

    const prevSellerInfo = props.sellerInfo;
    const prevSellerID = sellerID;
    const prevLocalInfo = localInfoFields;
    const prevState = useRef({prevSellerInfo, prevLocalInfo, prevSellerID});

    const [showCompanyTypeChangeModal, setShowCompanyTypeChangeModal] = useState(false);
    const closeCompanyTypeChange = () => setShowCompanyTypeChangeModal(false);

    useEffect(() => {
        console.log('MyAccount useEffect 1, props.sellerInfo')

        setSellerID(accounts[0].username);

        prevState.current.prevLocalInfo = localInfoFields;
        prevState.current.prevSellerInfo = props.sellerInfo;
        if (prevState.current.prevLocalInfo.type === 'private'
            && prevState.current.prevSellerInfo.type === 'company') {
            console.log('change from company to private');
        }

    }, [props.sellerInfo, localInfoFields]);

    useEffect(() => {
        // const fetchAndUpdate = fetchAndUpdate();
        console.log('MyAccount useEffect 2, []');

        const fetchWrapper = async (userName) => {
            await fetchAndUpdate(userName);
        }

        if (isAuthenticated) {
            console.log('MyAccount useEffect 0, isAuthenticated', isAuthenticated, 'accounts', accounts, accounts[0].username);
            setSellerID(accounts[0].username);

            const userName = accounts[0].username;
            if (_.isEmpty(props.sellerInfo)) {
                console.log('props.sellerInfo is empty, running fetchAndUpdate...');
                fetchWrapper(userName);
            }
        }
        if (isSmallScreen) {
            setTextStyle({fontSize: '.85rem'});
        }
    }, []);

    const isSmallScreen = useMediaQuery({maxWidth: '450px'});
    const [textStyle, setTextStyle] = useState({fontSize: '.95rem'});

    const submitButtonStyle = {...originalSubmitButtonStyle};

    if (localInfoFields.juridicalType === undefined ||
        (localInfoFields['juridicalType'] === 'company' && _.isEmpty(localInfoFields['companyAddress']))) {
        submitButtonStyle.background = 'palegreen';
        submitButtonStyle.opacity = '0.5';
    }

    if (props.sellerInfo === undefined) {
        console.log('MyAccount - loading data');
        return <div className='row justify-content-center ml-2 mt-3'><LoadingIndicator/></div>
    } else if (!_.isEmpty(props.sellerInfo)) {
        console.log('MyAccount props.sellerInfo!==undefined (if 1)', props.sellerInfo);
        return <div id='my-account' className='row'>
            <>
                <div id='my-account-col-12' className='col-12' style={containerStyle}>
                    <ul className='list-unstyled container' style={textStyle}>
                        <li className='row my-3 border-bottom'>
                            <div className='col-4 text-capitalize pl-0'>userID</div>
                            <div className='col-8 font-weight-bold d-flex justify-content-end pr-0'
                                 style={shortTextStyle}>{sellerID}</div>
                        </li>

                        <div>
                            {Object.keys(props.sellerInfo).map(k => {

                                if (k === 'logo') {
                                    console.log('not showing logo')
                                } else if (k === 'sellersRank') {
                                    console.log('not showing rank')
                                } else return (
                                    <li key={k} className='row my-3 border-bottom'>
                                        <div id='seller-info-field-title'
                                             className='col-4 text-capitalize text-left pl-0'
                                             style={shortTextStyle}>
                                            {getTitleFromDictionary(k, sellerInfo_d)}
                                        </div>
                                        <div id='change-seller-type'
                                             className='col-8 font-weight-bold text-right d-flex align-items-center justify-content-end pr-0'>
                                            {showInput[k] === undefined && (localInfoFields[k] === undefined
                                            || _.isEmpty(localInfoFields[k]) ?
                                                <span id='seller-info-type-selector'>{
                                                    k === 'type' ?
                                                        getTitleFromDictionary(props.sellerInfo[k], sellerInfo_d) :
                                                        [props.sellerInfo[k]]}</span> :
                                                <span>{localInfoFields[k]}</span>)}
                                            {showInput[k] !== undefined && (
                                                k === 'type' ?
                                                    <SellerInfoSelect
                                                        k={k}
                                                        localInfoFields={localInfoFields}
                                                        setLocalInfoFields={setLocalInfoFields}
                                                        juridicalStatus_d={juridicalStatus_d}
                                                        sellerInfo={props.sellerInfo}
                                                        sellerInfo_d={sellerInfo_d}
                                                        setShowCompanyTypeChangeModal={setShowCompanyTypeChangeModal}
                                                        showInput={showInput}
                                                    />
                                                    : (<div
                                                            className='px-0 mx-0 input-group-sm d-flex justify-content-end'>
                                                            <SellerInfoInput
                                                                k={k}
                                                                borderStyle={borderStyle}
                                                                localInfoFields={localInfoFields}
                                                                setLocalInfoFields={setLocalInfoFields}
                                                                props={props}
                                                            />

                                                            {/*Remove phone or website*/}

                                                            {((k === 'phone' && props.sellerInfo.type === 'private') ||
                                                                    (k === 'website' && props.sellerInfo.type === 'company')) &&
                                                                <SellerInfoRemoveButton
                                                                    k={k}
                                                                    localInfoFields={localInfoFields}
                                                                    setLocalInfoFields={setLocalInfoFields}
                                                                    t={t}
                                                                />}
                                                        </div>
                                                    )
                                            )}
                                            <SellerInfoEditButton
                                                k={k}
                                                localInfoFields={localInfoFields}
                                                setLocalInfoFields={setLocalInfoFields}
                                                showInput={showInput}
                                                setShowInput={setShowInput}
                                                setErrorMessage={setErrorMessage}
                                                props={props}
                                            />
                                        </div>
                                        <button type="button" className="close" aria-label="Close"></button>
                                    </li>
                                );
                            })}
                        </div>

                        {/*Phone input for private*/}
                        {props.sellerInfo.type === 'private'
                            && localInfoFields.type !== 'company'
                            && _.isEmpty(props.sellerInfo.phone)
                            && <FieldComponent
                                sellerType='private'
                                fieldType='phone'
                                borderStyle={borderStyle}
                                localInfoFields={localInfoFields}
                                setLocalInfoFields={setLocalInfoFields}
                                showInput={showInput}
                                setShowInput={setShowInput}
                                sellerInfo={props.sellerInfo}
                                texts={{
                                    title: `${t('texts:vehicle-information.seller-info.phone')} ${t('texts:vehicle-information.seller-info.optional')}`
                                }}
                                inputOpenByDefault={false}
                            />
                        }

                        {/*Website input for company*/}
                        {props.sellerInfo.type === 'company'
                            && localInfoFields.type !== 'private'
                            && _.isEmpty(props.sellerInfo.website)
                            &&
                            <div className='container px-0'>
                                <FieldComponent
                                    sellerType="company"
                                    fieldType="website"
                                    borderStyle={borderStyle}
                                    localInfoFields={localInfoFields}
                                    setLocalInfoFields={setLocalInfoFields}
                                    showInput={showInput}
                                    setShowInput={setShowInput}
                                    sellerInfo={props.sellerInfo}
                                    texts={{title: t('texts:sell.my-account-area.website')}}
                                    inputOpenByDefault={true}
                                />
                            </div>
                        }
                    </ul>
                    {/**
                     *
                     Extra fields when changing type from private to company
                     *
                     */}
                    {props.sellerInfo.type === 'private' && showInput['type'] === true
                        && localInfoFields['type'] === 'company'
                        && <div className='col-12 px-0' id='extra-company-fields'>
                            <span>{t('texts:vehicle-information.seller-info.company-requires-more-fields')}</span>
                            <ul className='list-unstyled container'>
                                <li>
                                    <FieldComponent
                                        sellerType="company"
                                        fieldType="registrationNumber"
                                        borderStyle={borderStyle}
                                        localInfoFields={localInfoFields}
                                        setLocalInfoFields={setLocalInfoFields}
                                        showInput={showInput}
                                        setShowInput={setShowInput}
                                        sellerInfo={props.sellerInfo}
                                        texts={{title: t('texts:vehicle-information.seller-info.registrationNumber')}}
                                        inputOpenByDefault={true}
                                    />
                                </li>
                                {_.isEmpty(props.sellerInfo.phone) &&
                                    localInfoFields.type === 'company' && props.sellerInfo.type === 'private' &&
                                    <li>
                                        <FieldComponent
                                            sellerType="company"
                                            fieldType="phone"
                                            borderStyle={borderStyle}
                                            localInfoFields={localInfoFields}
                                            setLocalInfoFields={setLocalInfoFields}
                                            showInput={showInput}
                                            setShowInput={setShowInput}
                                            sellerInfo={props.sellerInfo}
                                            texts={{title: t('texts:vehicle-information.seller-info.phone')}}
                                            inputOpenByDefault={true}
                                        />
                                    </li>}

                                <li>
                                    <FieldComponent
                                        sellerType="company"
                                        fieldType="companyAddress"
                                        borderStyle={borderStyle}
                                        localInfoFields={localInfoFields}
                                        setLocalInfoFields={setLocalInfoFields}
                                        showInput={showInput}
                                        setShowInput={setShowInput}
                                        sellerInfo={props.sellerInfo}
                                        texts={{title: t('texts:sell.my-account-area.company-address')}}
                                        inputOpenByDefault={true}
                                    />

                                </li>
                                <li>
                                    <FieldComponent
                                        sellerType="company"
                                        fieldType="website"
                                        borderStyle={borderStyle}
                                        localInfoFields={localInfoFields}
                                        setLocalInfoFields={setLocalInfoFields}
                                        showInput={showInput}
                                        setShowInput={setShowInput}
                                        sellerInfo={props.sellerInfo}
                                        texts={{title: t('texts:sell.my-account-area.website')}}
                                        inputOpenByDefault={true}
                                    />
                                </li>
                            </ul>

                        </div>
                    }

                    {/**
                     Submit and reset buttons
                     */}

                    {Object.keys(localInfoFields).length > 0
                        && <div className='d-flex justify-content-between'>

                            <UpdateButton
                                props={props}
                                fetchAndUpdate={fetchAndUpdate}
                                sellerInfo={props.sellerInfo}
                                localInfoFields={localInfoFields}
                                setLocalInfoFields={setLocalInfoFields}
                                setShowInput={setShowInput}
                                setErrorMessage={setErrorMessage}
                            />

                            <ResetButton
                                fetchAndUpdate={fetchAndUpdate}
                                setShowInput={setShowInput}
                                setErrorMessage={setErrorMessage}
                            />

                        </div>}

                </div>
                <div className='col-5 mt-3 ml-3'>
                    {/*Change from company to private*/}

                    <CompanyTypeChangeModal
                        show={showCompanyTypeChangeModal}
                        handleClose={closeCompanyTypeChange}
                        localInfoFields={localInfoFields}
                        setLocalInfoFields={setLocalInfoFields}
                        showInput={showInput}
                        setShowInput={setShowInput}
                        sellerInfo={props.sellerInfo}
                        updateSeller={sellersRESTConnector.updateSeller}
                        validatePrivateInfo={validatePrivateInfo}
                        fetchAndUpdate={fetchAndUpdate}
                        setErrorMessage={setErrorMessage}
                        accounts={accounts}
                        // updateSeller={u}
                    />


                    {/*duplicate error message - refactor*/}
                    <div className='mx-0'>{errorMessage !== undefined && !_.isEmpty(errorMessage) &&
                        <div className="container mt-0">
                            <div className='font-weight-bold my-1 row'>
                                {t('errors:my-account.fix-errors')}
                            </div>

                            <div className='row mt-2'>
                                <ul className='text-danger col'>{Object.keys(errorMessage)
                                    .filter(k => errorMessage[k] !== 'ok').map(n => {
                                        return <li key={n}>{errorMessage[n]}</li>
                                    })}</ul>
                            </div>
                        </div>}</div>
                    {/*end of duplicate error message - refactor*/}
                </div>
            </>
        </div>
    } else if (_.isEmpty(props.sellerInfo)) {
        console.log('MyAccountArea - sellerInfo is empty; if else 2', props.sellerInfo);
        return <div id='my-account' className='row'>

            <div id='seller-info-empty' className='col-12 mt-1' style={{maxWidth: '500px', ...textStyle}}>
                <span className='pl-0 ml-0'>{t('texts:sell.my-account-area.register-to-sell')}</span>
                <div id='field-seller-type' className='row mt-3'>
                    <NumberOfStep thisField={localInfoFields['juridicalType']}>
                        {1}
                    </NumberOfStep>
                    <div className='offset-1'/>
                    <div className='col-10 pl-0' style={{maxWidth: '220px'}}>
                        <OptionSelect
                            className={'col-12 pl-0'}
                            id='seller-type'
                            options={Object.keys(juridicalStatus_d).map(key => {
                                return {value: key, label: juridicalStatus_d[key].title}
                            })}
                            selected={localInfoFields['juridicalType'] && juridicalStatus_d[localInfoFields['juridicalType']].title}
                            handler={(e) => {
                                const localInfoFieldsCopy = {...localInfoFields};
                                localInfoFieldsCopy['juridicalType'] = e;
                                setLocalInfoFields(localInfoFieldsCopy);
                                console.log('AddPrivateSeller', 'localInfoFields', localInfoFields);
                            }}
                        >
                            <Heading
                                spanStyle={textStyle}>{t('texts:sell.my-account-area.company-or-private')}</Heading>
                        </OptionSelect>
                    </div>
                </div>
                {(localInfoFields['juridicalType'] === undefined ||
                        localInfoFields['juridicalType'] === 'private') &&

                    <AddPrivateSellerForm
                        localInfoFields={localInfoFields}
                        setLocalInfoFields={setLocalInfoFields}
                        setErrorMessage={setErrorMessage}
                        props={props}
                        textStyle={textStyle}
                        t={t}
                    />
                }
                {localInfoFields['juridicalType'] === 'company' &&
                    <AddCompanySellerForm
                        localInfoFields={localInfoFields}
                        setLocalInfoFields={setLocalInfoFields}
                        setErrorMessage={setErrorMessage}
                        props={props}
                        textStyle={textStyle}
                        t={t}
                    />}

                <ErrorMessageBlock errorMessage={errorMessage}/>
            </div>
        </div>
    }
}

const borderStyle = {border: 'solid 1px rgba(0,0,0,0.4)', borderRadius: '2px'};


export default MyAccount;

const shortTextStyle = {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    // whiteSpace: 'nowrap'
}
const buttonStyle = {
    maxWidth: '120px'
}
export const coralStyle = {color: 'coral', border: '1px solid coral'};

export const containerStyle = {maxWidth: '500px'}

export const originalSubmitButtonStyle = {
    border: '1px solid green',
    minWidth: '150px',
    height: '28px',
    background: 'palegreen',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    fontSize: '0.8rem',
    padding: '0 2rem'
};

import _ from "lodash";
import {getDistinctElements} from "./utils";
import * as LanguageUtils from "../../../Integration/Utils/LanguageUtils";

const combineSellAndAppState = (appState, sellState) => {
    console.log('combine state:\nAppState', appState, '\nSellState', sellState);

    const combined = _.clone(sellState);
    combined.makes = appState.makes;
    combined.email = sellState.email;
    console.log('combine state 3', combined);
    return combined;
}

/**
 *Cleanup functions
 * */

const getEntryMode = sellState => {
    const entryMode = sellState.entryMode;
    if (entryMode === 'semi-automatic' || entryMode === 'automatic') return 'automatic'; //close semi-automatic fields
    else return 'manual';
}

const cleanUpFieldsAfterMake = sellState => {
    sellState.year = undefined;
    sellState.model = undefined;
    sellState.generation = undefined;
    sellState.trim = undefined;
    sellState.trims = [];
    sellState.modification = undefined;
    sellState.characteristics = [];
    sellState.characteristicsError = undefined;
    sellState.fuel = undefined;
    sellState.transmission = undefined;
    sellState.displacement = undefined;
    sellState.drivetrain = undefined;
    sellState.entryMode = getEntryMode(sellState);
    // sellState.entryMode = sellState.entryMode === 'semi-automatic' ? 'automatic':'manual';
    // sellState.mileage = undefined;
    // sellState.power = undefined;
    // sellState.color = undefined;
    // sellState.price = undefined;
    // sellState.vin = undefined;
    // sellState.files = [];
}

const cleanUpFieldsAfterYear = sellState => {
    sellState.model = undefined;
    sellState.generation = undefined;
    sellState.trim = undefined;
    sellState.trims = [];
    sellState.modification = undefined;
    sellState.characteristics = [];
    sellState.characteristicsError = undefined;
    sellState.fuel = undefined;
    sellState.transmission = undefined;
    sellState.displacement = undefined;
    sellState.drivetrain = undefined;
    sellState.power = undefined;
    sellState.entryMode = getEntryMode(sellState);
    // sellState.entryMode = sellState.entryMode === 'semi-automatic' ? 'automatic':'manual';
    // sellState.mileage = undefined;
    // sellState.color = undefined;
    // sellState.price = undefined;
    // sellState.vin = undefined;
    // sellState.files = [];
}

const cleanUpFieldsAfterModel = sellState => {
    sellState.generation = undefined;
    sellState.trim = undefined;
    sellState.trims = [];
    sellState.modification = undefined;
    sellState.characteristics = [];
    sellState.characteristicsError = undefined;
    sellState.fuel = undefined;
    sellState.transmission = undefined;
    sellState.displacement = undefined;
    sellState.drivetrain = undefined;
    sellState.power = undefined;
    sellState.entryMode = getEntryMode(sellState);
    // sellState.entryMode = sellState.entryMode === 'semi-automatic' ? 'automatic':'manual';
    // sellState.mileage = undefined;
    // sellState.color = undefined;
    // sellState.price = undefined;
    // sellState.vin = undefined;
    // sellState.files = [];
}

const cleanUpFieldsAfterGeneration = sellState => {
    sellState.trim = undefined;
    sellState.modification = undefined;
    sellState.characteristics = [];
    sellState.characteristicsError = undefined;
    sellState.fuel = undefined;
    sellState.transmission = undefined;
    sellState.displacement = undefined;
    sellState.drivetrain = undefined;
    sellState.power = undefined;
    sellState.entryMode = getEntryMode(sellState);
    // sellState.mileage = undefined;
    // sellState.color = undefined;
    // sellState.price = undefined;
    // sellState.vin = undefined;
    // sellState.files = [];
}

const cleanUpFieldsAfterTrim = sellState => {
    sellState.modification = undefined;
    sellState.characteristics = [];
    sellState.characteristicsError = undefined;
    sellState.fuel = undefined;
    sellState.transmission = undefined;
    sellState.displacement = undefined;
    sellState.drivetrain = undefined;
    // sellState.mileage = undefined;
    // sellState.power = undefined;
    // sellState.color = undefined;
    // sellState.price = undefined;
    // sellState.vin = undefined;
    // sellState.files = [];
}

const cleanUpFieldsAfterModification = sellState => {
    console.log('cleanUpFieldsAfterModification');
    sellState.characteristics = [];
    sellState.characteristicsError = undefined;
    sellState.fuel = undefined;
    sellState.transmission = undefined;
    sellState.displacement = undefined;
    sellState.drivetrain = undefined;
    sellState.power = undefined;
    // sellState.mileage = undefined;
    // sellState.color = undefined;
    // sellState.price = undefined;
    // sellState.vin = undefined;
    // sellState.files = [];
}

/**
 * Utils
 * *
 * */

const processTrims = (trims, generation) => {
    console.log('processTrims: trims', trims.length, 'generation', generation);
    //
    // trims.forEach(el => {
    //     console.log('el.PartitionKey', el.PartitionKey, 'generation.generationID', generation.generationID, el.PartitionKey === generation.generationID);
    // })

    const matchingTrims = trims.filter(el => el.PartitionKey === generation.generationID)
        .map(trim => {
            return {
                name: trim.SerieName,
                id: trim.SerieID,
                modelID: trim.ModelID,
                generationID: trim.PartitionKey
            }
        })
    console.log('processTrims', matchingTrims);
    return matchingTrims;
};

const updateTrims = (updatedState, generation) => {
    const trims = getDistinctElements(updatedState.trims_and_modifications, 'SerieID');
    console.log('getDistinctElements trims', trims);
    updatedState.trims = processTrims(trims, generation);
}

const processModifications = state => state.trims_and_modifications
    .filter(el => {
        return el.PartitionKey === state.generation.generationID && state.trim.id === el.SerieID
    })
    .map(modification => {
            return {
                name: modification.ModificationName,
                id: modification.RowKey,
                trimID: modification.SerieID,
            }
        }
    ).sort((a, b) => a.name.localeCompare(b.name));


const processCharacteristicsFields = (response, state, translatedAttributes) => {
    console.log('processCharacteristics', response);

    const characteristics = response.sort((a, b) =>
        a.characteristicID - b.characteristicID);

    state.fuel = characteristics.find(a => a.characteristicID === 12)
        && characteristics.find(e => e.characteristicID === 12).value;
    state.transmission = characteristics.find(e => e.characteristicID === 24) &&
        characteristics.find(el => el.characteristicID === 24).value;
    state.drivetrain = characteristics.find(el => el.characteristicID === 27) &&
        characteristics.find(e => e.characteristicID === 27).value;
    state.power = characteristics.find(el => el.characteristicID === 14) &&
        characteristics.find(el => el.characteristicID === 14).value;
    state.displacement = characteristics.find(el => el.characteristicID === 13) &&
        characteristics.find(el => el.characteristicID === 13).value;
    state.bodyStyle = characteristics.find(el => el.characteristicID === 2) &&
        characteristics.find(el => el.characteristicID === 2).value;
    console.log('found ->> ', state.bodyStyle, state.fuel, state.transmission,
        state.drivetrain, state.power, state.displacement);


    LanguageUtils.processUnitsByLanguage(characteristics, translatedAttributes.units);
    LanguageUtils.processCharacteristicsValuesByLanguage(characteristics,
        translatedAttributes.values, translatedAttributes.suspension);
    state.characteristics = characteristics;
    state.characteristicsError = undefined;
}

const processFieldsOnCharacteristicsError = updatedState => {
    updatedState.characteristics = [];
    updatedState.drivetrain = undefined;
    updatedState.fuel = undefined
    updatedState.displacement = undefined;
    updatedState.power = undefined;
    updatedState.transmission = undefined;
    updatedState.color = undefined;
    updatedState.characteristicID = undefined;
    updatedState.characteristicsError = 'We have not been able to find the data for this modification: ' + updatedState.modification.name +
        'Please enter it manually'
}

export {
    combineSellAndAppState, cleanUpFieldsAfterMake, cleanUpFieldsAfterYear,
    cleanUpFieldsAfterModel, processTrims, cleanUpFieldsAfterGeneration, updateTrims,
    cleanUpFieldsAfterTrim, processModifications, cleanUpFieldsAfterModification,
    processCharacteristicsFields, processFieldsOnCharacteristicsError
}
import React, {useState} from 'react';
import * as LanguageUtils from '../../../../../../../../Integration/Utils/LanguageUtils';
import MultiSelectHistory from "../../../../../../Fields/History/MultiSelectHistory";
import './css/vehicle.css';

const EditableAttributeHistory = props => {

    const [showField, setShowField] = useState(false);
    const handleKeyPress = (event) => {
        console.log(event.key);
        if (event.key === 'Enter') setShowField(!showField);
        console.log('state after enter', state);
    }
    const state = props.state;
    const title = props.title;

    let historyInState = state['history'];
    let historyAsArray = historyInState;
    console.log('typeof state[history]', typeof historyInState);
    if (typeof historyInState === 'string') historyAsArray = JSON.parse(historyInState);
    // console.log('JSON.parse(state[options])',typeof JSON.parse(historyInState));
    // console.log('state[\'options\']',state['options']);
    const copy = JSON.parse(JSON.stringify(state));
    copy['history'] = historyAsArray;
    console.log('state copy in history', copy)

    const updateHistory = history => {
        console.log('history', history)
        const copy = JSON.parse(JSON.stringify(state));
        copy['history'] = history;
        props.setState(copy);
    }

    return <div className='row col-12 mx-0 px-0 d-flex justify-content-between'
                style={props.textStyle}>
        <div className='col align-items-start px-0'>
            <span className="text-left">{title}</span>
        </div>
        <div className='col-auto d-flex justify-content-end pr-0'>
            <img height='17px' width='17px' className='button ml-1 mt-1 edit-icon'
                 onClick={() => setShowField(!showField)}
            />
        </div>
        <div className='row col-12'/>
        <div className='col px-0'>
            <div className='d-flex justify-content-start px-0'>
                {!showField && <span
                    className="text-left font-weight-bold">
                {historyAsArray.map((o, i) => {
                    const title = LanguageUtils.getTitleFromDictionary(o, props.history)
                    console.log('state[\'history\']', historyInState)
                    if (historyAsArray.length > 0 && i < historyAsArray.length - 1)
                        return title + ', '
                    else return title;
                })}
            </span>}
            </div>
            {showField && <>
                <div className='d-flex justify-content-end container multiselect'>
                    <MultiSelectHistory
                        bsClass={'mt-2 container multiselect'}
                        history={props.history}
                        state={copy}
                        updateHistory={updateHistory}
                        textStyle={props.textStyle}
                    /></div>
            </>}
        </div>

    </div>
}

export default EditableAttributeHistory
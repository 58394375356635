import React from 'react';
import {CloseButton, Modal} from "react-bootstrap";
import book from './../svg/book.svg';
import MultiSelectHistoryNew from "./MultiSelectHistoryNew";

const CarHistoryModal = props => {
    console.log('CarHistoryModal props', props);
    return <Modal centered size='sm'
                  onHide={() => props.setShowModal(false)}
                  show={props.show}>
        <Modal.Header>
            <Modal.Title style={{fontSize: '0.75rem', color: '#555'}}
                         className='font-weight-bold text-uppercase d-flex w-100'>
                <div className='d-flex col justify-content-start align-items-center pl-0'>
                    <img src={book} height={16} style={{marginRight: '1rem'}}/>
                    {props.title}
                </div>
                <CloseButton onClick={() => props.setShowModal(false)}/>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className='container'>
                <div className='col-12 justify-content-start d-flex mb-2'>
                    <MultiSelectHistoryNew
                        dictionary={props.historyDictionary}
                        state={props.state}
                        updateHistory={props.updateHistory}/>
                </div>
            </div>
        </Modal.Body>
    </Modal>
}

export default CarHistoryModal;
import React from 'react';
import {Input} from "../../Input";
import Heading from "../../../Search/SearchArea/Heading";

const InputFieldPower = props => {

    let numberClass = 'row mt-2 numbered-list-item'
    if (props.state.power === undefined && props.prevField !== undefined) numberClass += ' yellow';
    else if (props.prevField === undefined) numberClass += ' grey'

    return <div className={numberClass} id='row-power'>
        {/*<NumberOfStep thisField={props.state.power} prevField={props.prevField}>*/}
        {/*    {props.step}*/}
        {/*</NumberOfStep>*/}
        <div className='offset-1'/>
        <div className='col-9 pl-0'>
            <Input
                key='power'
                value={props.state.power}
                disabled={props.prevField === undefined}
                handler={props.updatePower}
                placeholder={'Type'}
                maxLength='3'
                type='number'
            >
                <div style={{marginLeft: '1em'}}>
                    <Heading>{props.title}</Heading>
                </div>
            </Input>
        </div>
        {/*<div className='offset-9'></div>*/}
    </div>

}

export default InputFieldPower;
import React from 'react';
import {SmallTypeCard} from "./index";
import affordable from "./img/affordable.jpg";
import fast from "./img/porsche-small.jpg";
import minivan from "./img/minivan1.jpg";
import sedan from "./img/sedan.jpg";
import hybrid from "./img/hybrid.jpg";
import suv from "./img/suv2.jpg";
import electric from "./img/electric.jpg";
import pickup from "./img/pickup.jpg";
import {useTranslation} from "react-i18next";
import {useMediaQuery} from "react-responsive";

const SmallTypeCardList = props => {

    const {t} = useTranslation(['texts', 'data-for-ui']);

    const isSmallScreen = useMediaQuery({maxWidth: 560})
    const isTinyScreen = useMediaQuery({maxWidth: 280})
    const isBigScreen = useMediaQuery({maxWidth: 1599})
    const isHugeScreen = useMediaQuery({minWidth: 1600})

    const pickupTitle = t('home.cards.pickup');
    const hybridTitle = t('data-for-ui:fuel.hybrid.title');
    const electricTitle = t('home.cards.electric');
    const suvOrCrossoverTitle = t('facets.suv-or-crossover');
    const partTimeOrFullWheelDrive = t('facets.part-or-full-wheel-drive');
    const minivanTitle = t('data-for-ui:bodystyle.minivan.title');
    const sedanTitle = t('data-for-ui:bodystyle.sedan.title');
    const powerTitle = t('sell.characteristics.power'); // unused?
    const priceTitle = t('sell.characteristics.price'); //unused

    console.log('powerTitle', powerTitle, 'priceTitle', priceTitle);

    return <div className='row mt-n mb-4 justify-content-center' id='small-type-cards'>
        <SmallTypeCard image={affordable}
                       to='/search' title={t('home.cards.affordable')}
                       value={{price: {value: [0, 5000], title: priceTitle, visible: true}}}
                       updateOptions={props.updateOptions}
        />
        <SmallTypeCard image={fast} to='/search' title={t('home.cards.powerful')}
                       value={{power: {value: [300, 999], title: powerTitle, visible: true}}}
                       updateOptions={props.updateOptions}
        />
        <SmallTypeCard image={sedan} to='/search' title={t('home.cards.sedans')}
                       updateOptions={props.updateOptions}
                       value={{bodyStyle: {value: 'sedan', title: sedanTitle, visible: true}}}
        />
        <SmallTypeCard image={minivan} to='/search' title={t('home.cards.vans')}
                       updateOptions={props.updateOptions}
                       value={{bodyStyle: {value: 'minivan', title: minivanTitle, visible: true}}}
        />
        <SmallTypeCard image={suv} to='/search' title={t('home.cards.suv')}
                       updateOptions={props.updateOptions}
                       value={
                           {
                               bodyStyle: {value: 'suv/crossover', title: suvOrCrossoverTitle, visible: true},
                               drivetrain: {value: 'full', title: partTimeOrFullWheelDrive, visible: true},
                           }
                       }

        />

        <SmallTypeCard image={pickup} to='/search' title={t('home.cards.pickups')}
                       updateOptions={props.updateOptions}
                       value={{bodyStyle: {value: 'pickup', title: pickupTitle, visible: true}}}
        />

        <SmallTypeCard image={hybrid} to='/search' title={t('home.cards.hybrids')}
                       updateOptions={props.updateOptions}
                       value={{fuel: {value: 'hybrid', title: hybridTitle, visible: true}}}
        />

        <SmallTypeCard image={electric} to='/search' title={t('home.cards.electric')}
                       updateOptions={props.updateOptions}
                       value={{fuel: {value: 'electric', title: electricTitle, visible: true}}}
        />
    </div>
}

export default SmallTypeCardList;
import React, {useEffect, useState} from 'react';
import OptionSelect from "../../OptionSelect/OptionSelect";
import Heading from "../../../Search/SearchArea/Heading";

const InputFieldDrivetrain = props => {

    const drivetrains = props.drivetrains;

    let numberClass = 'row mt-2 numbered-list-item'
    if (props.state.drivetrain === undefined && props.prevField !== undefined) numberClass += ' yellow';
    else if (props.prevField === undefined) numberClass += ' grey'

    const keys = Object.keys(drivetrains);

    const [selected, setSelected] = useState('');

    useEffect(() => {
        if (props.state.drivetrain !== undefined) {
            const selected = drivetrains[props.state.drivetrain];
            setSelected(selected.title);
            // console.log('useEffect drivetrain',selected);
        }
    }, [props.state.drivetrain]);


    return <div className={numberClass}>

        <div className='offset-1'/>
        <div className='col-9 pl-0'>
            <OptionSelect
                className={'Option-Select'}
                id='select-drivetrain'
                options={keys.map(key => {
                    return {value: key, label: drivetrains[key].title}
                })}
                selected={selected}
                handler={props.updateDrivetrain}
                isDisabled={props.prevField === undefined}
            >
                <Heading>{props.title}</Heading>
            </OptionSelect>
        </div>
    </div>
}

export default InputFieldDrivetrain;
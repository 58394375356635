import {prepareForInsert} from '../../../../../Integration/Utils/TableUtils'
import {
    AzureAdvertisementsRESTConnector,
    AzureStorageRESTConnector
} from "../../../../../Integration";
import _ from "lodash";
import {getFilenameFromPath, isReady} from "./editHelpers";

const storageConnector = new AzureStorageRESTConnector();
const advertisementConnector = new AzureAdvertisementsRESTConnector();

const saveImageResults = async (car, original, updated, accounts) => {

    if (accounts.length > 0) {
        const loggedIn = accounts[0].username;
        if (car.userID === loggedIn) {
            if (updated.length === 0) console.log('ERROR, must be at least 1 images!');

            const removed = _.difference(original, updated);
            console.log('saveImageResults difference', removed);
            //
            const paths = [...updated];
            console.log('saveImageResults updated paths', paths);
            const copy = _.cloneDeep(car);

            copy.blobPaths = paths;
            console.log('saveImageResults before prepare', copy)
            const prepared = prepareForInsert(copy);
            console.log('saveImageResults updated record', prepared);
            const tableResponse = await advertisementConnector.updateAdvertisementRecord(prepared.PartitionKey, prepared.RowKey, prepared);
            console.log('saveImageResults tableResponse', tableResponse);

            const removedFilenames = removed.map(f => getFilenameFromPath(f));
            removedFilenames.forEach(filename => console.log(car.containerID + '/' + car.vin, filename));
            console.log('saveImageResults car ', car);

            removedFilenames.forEach(filename => storageConnector
                .deleteBlob(car.containerID, car.vin, filename));

        } else console.error('user error');
    }
}

const upload = async (files, car) => {
    console.log('imageFunctions upload')
    if (isReady(car)) {
        const response = await storageConnector.putBlob(car.containerID, car.vin, files);
        console.log('imageFunctions response after putBlob', response);

        //if response contains objects with status 413 or 415, create an object with the first error status
        const errorMap = response.filter((error) => {
            return error.status !== undefined && (error.status.code === 413 || error.status.code === 415);
        });
        if (errorMap.length > 0) {
            console.warn('imageFunctions errorMap: Files failed validation', errorMap);
            return {
                status: errorMap[0].status
            }
        } else {
            const uploaded = response.filter(r => r.url !== undefined);

            //update table record with new paths
            if (uploaded.length === response.length) {
                const newPaths = uploaded.map(r => r.url);
                const row = await advertisementConnector.retrieveAdsByUserIDAndVIN(car.userID, car.vin);
                console.log('user record', row, Array.isArray(row));
                const copy = Array.from(row);
                const array = copy[0];
                console.log('copy', car);
                const originalPaths = JSON.parse(array.blobPaths);
                const paths = [...originalPaths, ...newPaths];
                console.log('paths', paths);
                array.blobPaths = JSON.stringify(paths);
                console.log('updated record', array);
                const tableResponse = await advertisementConnector.updateAdvertisementRecord(car.make, car.vin, array);

                console.log('tableResponse', tableResponse);
                return {
                    tableResponse: tableResponse, updatedCar: array,
                    status: {code: 201, message: 'uploaded successfully'}
                }
            } else {
                console.log('imageFunctions else - response not successful')
                return {code: 422, message: 'response not successful'};
            }
        }
    }
}


export {upload, saveImageResults}
import React, {useState} from 'react';
import smallCross from './smallCross.svg'
import {formatNumberWithSpaces} from "../../utils/searchUtils";

export const SearchCriteria12 = props => {

    const removeMultiSelect = (data, section, object) => {
        const feature = data[section];
        const find = feature.indexOf(object);
        const newArray = [...feature.slice(0, find), ...feature.slice(find + 1)];
        props.updateOptions(section, newArray);
    };

    const data = props.data;
    let keys = Object.keys(data);

    return (
        <>
            <ul id='search-criteria-ul'
                className='d-flex flex scroll'
                style={style.searchCriteriaList}>
                {/*Make and model*/}

                {/*Normal options*/}
                {keys.filter(key => data[key].visible === true)
                    .map(key => {
                        const field = data[key];
                        let value = field.value;
                        let title = field.title;
                        let prefix, suffix = '';
                        key.indexOf('price') !== -1 ? suffix = '€' : prefix = '';
                        key.indexOf('mileage') !== -1 ? suffix = 'KM' : prefix = '';
                        key.indexOf('power') !== -1 ? suffix = 'HP' : prefix = '';
                        console.log('Search Criterion data:', 'key', key, 'value', value, 'title', title);

                        if (!Array.isArray(value)) {
                            return <Li
                                key={key}
                                name={key}
                                remove={props.remove}
                                spanClass={'text-nowrap capitalize'}
                                value={title}
                            />
                        } else {
                            return <Li
                                key={key}
                                name={key}
                                remove={props.remove}
                                spanClass={'text-nowrap '}
                                value={`${prefix} 
                                ${formatNumberWithSpaces(value[0], key)} - ${formatNumberWithSpaces(value[1], key)} ${suffix}`}
                            />
                        }
                    })}

                {/*MultiSelectOptions*/}

                {Object.entries(data)
                    .filter(o => Array.isArray(o[1]))

                    .map(i => {
                        const section = i[0];
                        // console.log('first loop', i[0], i[1]);
                        return i[1].map(item => {
                            console.log('second loop', item, section)
                            return <Li
                                key={item.value}
                                name={item.value}
                                remove={() => removeMultiSelect(data, section, item)}
                                spanClass={'text-nowrap'}
                                // value={item.value}
                                value={item.title === undefined ? item.value : item.title}
                            />
                        })
                    })}
            </ul>
        </>
    )
};


const Li = props => {

    const [crossColor, setCrossColor] = useState('#888');
    const [borderColor, setBorderColor] = useState({border: '1px solid #ddd'});

    return <li
        id={'search-criterion-' + props.name}
        style={{cursor: 'pointer'}}
        onMouseEnter={() => {
            // setCrossColor('red');
            setBorderColor({border: '1px solid #888'});
        }}
        onMouseLeave={() => {
            // setCrossColor('#888');
            setBorderColor({border: '1px solid #ddd'})
        }}
        onClick={() => props.remove(props.name)}>

        <div className="mr-2 px-2 py-0 my-1 d-flex align-items-center "
             style={{...style.div, ...borderColor}}>
                                   <span className={props.spanClass}>
                                         {props.value.charAt(0).toUpperCase() + props.value.slice(1)}
                                    </span>
            <div className='d-flex align-items-center ml-1'
                 style={{color: crossColor}}>
                <img src={smallCross} style={{opacity: 0.7}}/>
            </div>
        </div>
    </li>
};

const style = {
    div: {
        background: '#eee',
        borderRadius: '2px',
        // fontSize: '13px',
        fontSize: '0.875rem',
        color: 'black',
        height: '26px',
        // border: '1px solid #ddd'
    },
    searchCriteriaList: {
        overflowX: 'auto',
        overflowY: 'hidden',
        touchAction: 'pan-x',
        listStyleType: 'none',
        padding: 0,
        margin: 0,
        width: '100%',
        userSelect: 'none'
    },
    listItem: {
        display: 'inline-block',
        flex: 'none',
        scrollbarWidth: 'thin'
    }
};


import React from 'react';


const SearchOptionMultiColor = props => {

    const updateColor = (e, section, optionsSection, updateHandler) => {
        console.log('updateColor: section', e, 'section', section, 'optionsSection', optionsSection);
        if (optionsSection !== undefined) {
            if (e.target.checked) {
                // console.log('feature', e.target.name, 'is checked');
                const newFeatures = [...optionsSection, {value: e.target.name, visible: e.target.checked}];
                updateHandler(section, newFeatures);
            } else if (!e.target.checked) {
                // console.log(`feature ${e.target.name} is unchecked`);
                const index = optionsSection.findIndex((item) => item.value === e.target.name);
                // console.log('index', index)
                if (index !== -1) {
                    const newFeatures = [...optionsSection.slice(0, index), ...optionsSection.slice(index + 1)];
                    // console.log('new features after unchecked', newFeatures);
                    updateHandler(section, newFeatures);
                }
            }
        }
    };

    // console.log('SearchOptionMultiColor props', props);

    const stateArr = props.options['color'];
    const stateKeys = stateArr.map(i => i.value);
    // console.log('stateKeys', stateKeys);
    // console.log('stateArr', stateArr);

    let unit = undefined;
    let value = {};

    let containerClass = 'multiselect';
    // let containerClass = 'container multiselect';
    if (props.bsClass !== undefined) containerClass += ' ' + props.bsClass;

    {
        // console.log('features >>>', props.options[props.section])
    }

    const keys = Object.keys(props.data)
    // console.log('props.data', props.data)
    // console.log('keys?', keys);

    return (<div className={containerClass}>
            {props.children}
            {keys.map((key, index) => {

                const title = props.data[key].title;
                const color = props.data[key].color;
                // console.log('title', title, 'color', color);
                return <div className='row' key={key}>
                    <div className='d-flex justify-content-start align-items-center'>
                        <input
                            checked={stateKeys.includes(key)}
                            name={key}
                            type='checkbox'
                            style={styles.checkbox}
                            onChange={(e) =>
                                updateColor(e, props.section, props.options[props.section],
                                    props.updateHandler)}/>
                        <span className='checkmark' style={{background: color}}/>
                        <label className='ml-2 my-0 text-capitalize'
                               style={styles.label}>{title}</label>
                    </div>
                </div>
            })}
        </div>
    );
};

export default SearchOptionMultiColor;


const styles = {
    div: {
        opacity: 0.1,
        width: '24px',
        height: '24px',
        zIndex: 1
    },
    checkbox: {
        width: '24px',
        height: '24px',
        border: '1px solid black',
        zIndex: 22
        // opacity: 0.2
    }
    ,
    label: {
        lineHeight: '32px',
        fontSize: '13px',
        userSelect: 'none'
    }
};

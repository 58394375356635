import React from 'react';
import {Link} from "react-router-dom";

const SmallTypeCard = props => {
    // console.log('SmallCardType props', props);
    return <div id={`small-type-card-${props.title}`}>
        <Link to={props.to}
              onClick={() => props.updateOptions(props.value)}>
            <div className="col" style={style.card}>
                <div className="align-items-center d-inline-flex text-center col-12"
                     style={style.span}>
                <span
                    className='text-capitalize'>
                    {props.title}
                </span>
                </div>
                <img src={props.image} style={style.img} className='' alt='car by type'/>
            </div>
        </Link>
    </div>
}

export default SmallTypeCard;

const style = {
    card: {
        position: 'relative',
        maxWidth: '120px',
        minWidth: '120px',
        minHeight: '120px',
        maxHeight: '120px',
        padding: 0,
        margin: '10px',
        // border: 'solid 1px #ddd',
        // borderRadius: '8px',
        background: '#fff',
    }, img: {
        position: 'absolute',
        bottom: 0,
        marginTop: 0,
        borderRadius: '8px',
        // border: '1px solid transparent',
        // borderRadius: '0 0 8px 8px',
        filter: 'brightness(80%)',
        // height: '100%',
        width: '100%',
        objectFit: 'contain',
        zIndex: 0,

        // borderTop: '1px solid #ccc'
    },
    span: {
        // display: 'inline-flex',
        justifyContent: 'center',
        background: 'inherit',
        color: 'black',
        position: 'absolute',
        zIndex: 22,
        // fontSize: '14px',
        fontSize: '0.75rem',
        fontWeight: 'bold',
        height: '40%',
        width: '100%',
        textAlign: 'center',
        border: 'solid 1px #ddd',
        borderRadius: '8px 8px 0 0 ',
        // backgroundColor: 'rgba(255,255,255,0.99)'
        // borderBottom: '1px solid #ddd'
        borderBottom: 0
        // paddingLeft: '65px'
    }
}
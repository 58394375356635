import React, {useEffect, useState} from 'react';
import Heading from "../../../Search/SearchArea/Heading";
import OptionSelectOld from "../../OptionSelect/OptionSelectOld";
import {ErrorMessageBlock} from "../ErrorMessages/ErrorMessageBlock";
import _ from "lodash";

const InputFieldModification = props => {

    // console.log('InputFieldModification', props);

    let numberClass = 'row mt-2 numbered-list-item'
    if (props.state.modification === undefined && props.prevField !== undefined) numberClass += ' yellow';
    else if (props.prevField === undefined) numberClass += ' grey'


    return <div className={numberClass} id='row-modification'>


        <div className='offset-1'/>
        <div className='col-9 pl-0'>
            {/*{console.log('modifications', props.state.modifications)}*/}
            <OptionSelectOld
                id='select-modifications'
                options={props.state.modifications
                    .map(modification => {
                        return {
                            label: modification.name, value: modification
                        }
                    })}
                selected={props.state.modification !== undefined && props.state.modification.name}
                handler={props.updateModification}
                isDisabled={props.prevField === undefined}
            >
                <Heading>{props.title}</Heading>
            </OptionSelectOld>
        </div>
    </div>
}

export default InputFieldModification;
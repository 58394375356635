const rootApiURI = process.env.REACT_APP_ROOT_API_URI;
const metadataApiKey = process.env.REACT_APP_METADATA_API_KEY;
const apiKeyName = process.env.REACT_APP_OCP_API_NAME;

class AzureSeriesModificationRESTConnector {

    retrieveSeriesAndModification = async generationID => {
        const response = await fetch(
            `${rootApiURI}/metadata/modifications/generations/${generationID}/`, {
                headers: {
                    [apiKeyName]: metadataApiKey
                }
            });
        const json = await response.json();
        console.log('retrieveSeriesAndModification', response.status, json);
        return json.value;
    }
}

export {AzureSeriesModificationRESTConnector}

const rootApiURI = process.env.REACT_APP_ROOT_API_URI;
const sellersApiKey = process.env.REACT_APP_SELLERS_API_KEY;
const apiKeyName = process.env.REACT_APP_OCP_API_NAME;

class AzureSellersRESTConnector {

    retrieveSellerInfo = async ID => {
        console.log('retrieveSellerInfo request', ID);
        try {
            const result = await fetch(`${rootApiURI}/sellers/${ID}`, {
                headers: {
                    [apiKeyName]: sellersApiKey
                }
            });

            if (!result.ok) {
                throw new Error(`HTTP error status: ${result.status}`);
            }
            const json = await result.json();
            // console.log('result',result);
            console.log('retrieveSellerInfo result json', json);
            return json;
        } catch (error) {
            console.warn('retrieveSellerInfo error:', error);
        }
    }

    addSeller = async requestJson => {
        console.log('addSeller', requestJson);

        try {
            const result = await fetch(`${rootApiURI}/sellers`, {
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${localStorage.getItem('token')}`,
                    [apiKeyName]: sellersApiKey,
                },
                body: JSON.stringify(requestJson),
                method: 'post'
            });
            if (!result.ok) {
                throw new Error(`HTTP error status: ${result.status}`);
            }
            const json = await result.json();
            console.log('addSeller json', json);
            return json;
        } catch (error) {
            console.warn('addSeller error:', error);
        }
    }

    updateSeller = async requestJson => {
        console.log('updateSeller', requestJson, apiKeyName, sellersApiKey);

        try {
            const result = await fetch(`${rootApiURI}/sellers`, {
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${localStorage.getItem('token')}`,
                    [apiKeyName]: sellersApiKey
                },
                body: JSON.stringify(requestJson),
                method: 'put'
            });

            if (!result.ok) {
                throw new Error(`HTTP error status: ${result.status}`);
            }
            const status = result.status;
            console.log('updated seller', status);
            return status;
        } catch (error) {
            console.warn('updateSeller error:', error);
        }
    }

}

export {AzureSellersRESTConnector}
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './css/filters.css'
import './css/modal.css';
import {useTranslation} from "react-i18next";
import _ from "lodash";
import {initialOptions} from "../../../../Data/State/initialState";
import {
    LocationOptionSelect2,
    RangeSlider2,
    SearchOptionModal2, SearchOptionMultiColor,
    SearchOptionMultiNew,
    SearchOptionSelectNew2
} from "../../SearchArea/SearchOption";
import Heading from "../../SearchArea/Heading";
import SearchOptionSingle from "../../SearchArea/SearchOption/SearchOptionSingle";
import ShowFiltersButton from "../../SearchArea/ShowFiltersButton/ShowFiltersButton";
import {getActiveFilters} from "./helpers";
import {SearchCriteria12} from "../../SearchArea/SearchCriteria/SearchCriteria12";
import {ModalByMake, ModalByModel} from "../../../../Shared/Modals";
import {CloseButton} from "react-bootstrap";

const SearchFiltersModal2 = props => {

    console.log('SearchFiltersModal2 props', props);

    const {t} = useTranslation(['texts']);
    const history = t('data-for-ui:history', {returnObjects: true});
    const transmission = t('data-for-ui:transmission', {returnObjects: true});
    const drivetrain = t('data-for-ui:drivetrain', {returnObjects: true});
    const fuel = t('data-for-ui:fuel', {returnObjects: true});
    const features = t('data-for-ui:features', {returnObjects: true});
    const bodyStyle = t('data-for-ui:bodystyle', {returnObjects: true});
    const extColor = t('data-for-ui:extColor', {returnObjects: true});
    const countries = t('locations:countries', {returnObjects: true});
    const vat_d = t('data-for-ui:vat-included', {returnObjects: true});
    const sponsored_d = t('data-for-ui:sponsored', {returnObjects: true});

    const result = t('search.car-listing.result');
    const results = t('search.car-listing.results');

    const changeRangeSlider = (e, type) => {
        // console.log('changeRangeSlider', 'type', type, 'value', e);
        props.updateOptions(type, {value: e, visible: true});
    };

    const removeCompositeFilters = name => {
        const makeAndModel = {make: initialOptions['make'], model: initialOptions['model']};
        const cityAndCountry = {
            country: initialOptions['country']
            , city: initialOptions['city']
        }
        if (name === 'make') props.updateMultipleOptions(makeAndModel);
        else if (name === 'country') {
            props.updateMultipleOptions(cityAndCountry);
            props.updateCities([])
        } else props.updateOptions(name, initialOptions[name]);
    }

    const switchMakeAndModel = async make => {
        await props.updateMake(make);
        props.updateMultipleModals({make: false, model: true});
    };

    const switchModelAndMake = () => {
        props.updateMultipleModals({make: true, model: false});
    }

    const selectHandlerNew = (type, value, title) => {
        props.updateOptions(type, {"value": value, visible: true, "title": title});
    };

    const activeFiltersCount = getActiveFilters(props.options);

    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={props.modals.search}
            dialogClassName={props.isMobilePortrait ? "modal-fullscreen" : "modal-height-100"}>

            <Modal.Header className='d-flex justify-content-between'>
                <div className='offset-2'/>
                <div className='col-8 d-flex justify-content-center align-items-center'>
                    <Modal.Title>
                        <div id='modal-header-title'
                             style={{
                                 fontSize: '.92rem', fontWeight: 'bold', textTransform: 'uppercase',
                                 userSelect: 'none'
                             }}>
                            {t('search.search-area.search-filters')}
                        </div>
                    </Modal.Title>
                </div>
                <div className='col-2 d-flex justify-content-center align-items-center'>
                    <CloseButton onClick={() => props.updateMultipleModals({search: false})}/>
                </div>
            </Modal.Header>
            {activeFiltersCount > 0
                &&
                <Modal.Header className='modal-header-bar-shadow px-2 py-1'>
                    <SearchCriteria12
                        data={props.options}
                        resetOptions={props.resetOptions}
                        showFiltersModal={props.setShowModal}
                        remove={removeCompositeFilters}
                        updateOptions={props.updateOptions}
                        updateMultipleOptions={props.updateMultipleOptions}
                    />
                </Modal.Header>
            }
            <Modal.Body className='py-0'>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingTop: '1rem'
                    // height: '80vh',
                }}>
                    <div style={{
                        overflowY: 'auto',
                        marginBottom: 'auto' // push the bottom section to the end
                    }}/>

                    <div className='container px-0'>

                        <RangeSlider2
                            type='price'
                            data={props.options}
                            limits={[...initialOptions.price.value]}
                            changeRangeSlider={changeRangeSlider}
                            step={1000}
                            bsClass={'mt-2'}
                            title={t('search.search-area.price-range')}
                            rowStyle={{marginBottom: '-15px'}}
                        />
                        <RangeSlider2
                            type='year'
                            data={props.options}
                            limits={[...initialOptions.year.value]}
                            changeRangeSlider={changeRangeSlider}
                            step={1}
                            bsClass={'mt-n1'}
                            rowStyle={{marginBottom: '-15px'}}
                            title={t('search.search-area.year-range')}
                        />
                        <SearchOptionModal2
                            type='make-model'
                            handler={() => {
                                props.updateMultipleModals({make: true, model: false});
                            }}
                            options={props.options}
                            bsClass={'mt-n1'}
                            title={t('search.search-area.make-model')}
                        />

                        <SearchOptionSelectNew2
                            type='fuel'
                            handler={selectHandlerNew}
                            state={props}
                            data={fuel}
                            bsClass={'mt-3'}
                            defaultTitle={t('search.search-area.fuel').toUpperCase()}
                        />

                        <SearchOptionMultiNew
                            options={props.options}
                            updateHandler={props.updateOptions}
                            section={'history'}
                            bsClass={'mt-2'}
                            data={history}>
                            <Heading>
                                {t('search.search-area.history')}
                            </Heading>
                        </SearchOptionMultiNew>

                        <SearchOptionSingle
                            options={props.options}
                            updateHandler={props.updateOptions}
                            section={'vatIncluded'}
                            bsClass={'mt-2'}
                            data={vat_d}>
                            <Heading>
                                {t('texts:vehicle-information.vat')}
                            </Heading>
                        </SearchOptionSingle>

                        <RangeSlider2
                            type='mileage'
                            data={props.options}
                            limits={[...initialOptions.mileage.value]}
                            changeRangeSlider={changeRangeSlider}
                            step={10000}
                            bsClass={'mt-3'}
                            title={t('search.search-area.mileage')}
                        />

                        <SearchOptionSingle
                            options={props.options}
                            updateHandler={props.updateOptions}
                            section={'ignoreSponsored'}
                            bsClass={'mt-n2 mb-3'}
                            data={sponsored_d}>
                            <Heading>
                                {t('texts:vehicle-information.sponsored')}
                            </Heading>
                        </SearchOptionSingle>

                        <ShowFiltersButton
                            show={props.filters}
                            handler={props.updateFilters}
                        >
                            {t('search.search-area.show-more-filters')}
                        </ShowFiltersButton>

                        <div id='search-filters-extra-filters-block'
                             style={{display: !props.filters && 'none'}} className='container'>

                            <div className={`row justify-content-start d-flex mb-2`}>

                                <SearchOptionSelectNew2
                                    type='transmission'
                                    handler={selectHandlerNew}
                                    state={props}
                                    data={transmission}
                                    bsClass={'mt-n1'}
                                    defaultTitle={t('search.search-area.transmission').toUpperCase()}
                                />

                                <SearchOptionSelectNew2
                                    type='drivetrain'
                                    handler={selectHandlerNew}
                                    state={props}
                                    data={drivetrain}
                                    bsClass={'mt-3'}
                                    defaultTitle={t('search.search-area.drivetrain').toUpperCase()}
                                />

                                <RangeSlider2
                                    type='power'
                                    data={props.options}
                                    limits={[...initialOptions.power.value]}
                                    changeRangeSlider={changeRangeSlider}
                                    step={25}
                                    bsClass={'mt-3'}
                                    rowStyle={{marginBottom: '-15px'}}
                                    title={t('search.search-area.power')}
                                />

                                <LocationOptionSelect2
                                    countries={countries}
                                    country={props.options.country}
                                    countryTitle={t('search.search-area.country')}
                                    updateOptions={props.updateMultipleOptions}
                                    updateCities={props.updateCities}
                                    cityTitle={t('search.search-area.city')}
                                    city={props.options.city}
                                />


                                {/**Temporarily disabling bodyStyle*/}
                                {/*<SearchOptionSelectNew2*/}
                                {/*    type='bodyStyle'*/}
                                {/*    handler={selectHandlerNew}*/}
                                {/*    state={props}*/}
                                {/*    data={bodyStyle}*/}
                                {/*    bsClass={'mt-3'}*/}
                                {/*    defaultTitle={t('search.search-area.body-style').toUpperCase()}*/}
                                {/*/>*/}

                                <SearchOptionMultiNew
                                    options={props.options}
                                    updateHandler={props.updateOptions}
                                    section={'features'}
                                    bsClass={'mt-3'}
                                    data={features}>
                                    <Heading>
                                        {t('search.search-area.features')}
                                    </Heading>
                                </SearchOptionMultiNew>

                                <div className='col-12'>
                                    <SearchOptionMultiColor
                                        options={props.options}
                                        updateHandler={props.updateOptions}
                                        section={'color'}
                                        bsClass={'mt-3'}
                                        data={extColor}
                                    >
                                        <Heading>{t('search.search-area.color')}</Heading>
                                    </SearchOptionMultiColor>
                                </div>

                                <ShowFiltersButton show={!props.filters}
                                                   handler={props.updateFilters}
                                                   id={'showFiltersButton2'}>
                                    {t('search.search-area.show-less-filters')}
                                </ShowFiltersButton>

                            </div>
                        </div>
                    </div>

                </div>
            </Modal.Body>
            <Modal.Footer className='modal-footer-bar-shadow'>
                <div className='col-12 d-flex justify-content-between align-items-center'>
                    <Button variant='link' style={{color: '#222', textDecoration: 'underline'}}
                            onClick={() => props.resetOptions()}
                    >
                        {t('search.search-area.reset')}
                    </Button>
                    <Button
                        className='d-flex justify-content-center align-items-center'
                        onClick={() => props.updateMultipleModals({search: false})}
                        style={{
                            minHeight: '32px',
                            minWidth: '40%',
                            fontSize: '.9rem',
                            background: '#222', color: 'white',
                            outline: 'none', border: 'none',
                            textDecoration: 'underline'
                        }}
                    >{_.capitalize(t('search.car-listing.results'))}:{' '}{props.searchCount}
                    </Button>
                </div>
            </Modal.Footer>

            <ModalByMake show={props.modals.make}
                         handleClose={() => props.updateMultipleModals({make: false})}
                         handleSwitch={switchMakeAndModel}
                         makes={props.makes}
                         make={props.options.make}
                         updateOptions={props.updateMultipleOptionsNoAppend}
                         updateMultipleModals={props.updateMultipleModals}
                         isMobilePortrait={props.isMobilePortrait}
            />

            <ModalByModel show={props.modals.model}
                          handleClose={() => props.updateMultipleModals({model: false})}
                          options={props.options}
                          updateHandler={props.updateMultipleOptions}
                          models={props.models}
                          model={props.model}
                          handleSwitch={switchModelAndMake}
                          updateMultipleModals={props.updateMultipleModals}
                          isMobilePortrait={props.isMobilePortrait}
            />
        </Modal>
    );
}

export default SearchFiltersModal2;
import React, {useEffect, useState} from 'react';
import {CloseButton, Modal} from 'react-bootstrap';
import {useTranslation} from "react-i18next";
import './modals.css';
import Button from "react-bootstrap/Button";
import _ from "lodash";
import {makeModelListStyle} from "./ModalByMake";

const ModalByModel = props => {

    const {t} = useTranslation();
    const [cars, setCars] = useState([]);

    useEffect(() => {
        console.log('useEffect in ModalByModel', props.models)
        if (props.models !== undefined && props.models.length > 0) {
            const sorted = _.orderBy(props.models, ['name', 'rating'], ['asc']);
            setCars(sorted);
        }

    }, [props.models, props.model]);

    const chooseAnyModel = (props) => {
        console.log('chooseAnyModel', props.options.make.value);
        props.updateHandler({
            'make': {
                value: props.options.make.value,
                visible: true,
                title: props.options.make.value
            }
        });
        props.handleClose();
    };

    if (cars !== undefined) {
        return (
            <React.Fragment>
                <Modal
                    show={props.show}
                    // onHide={props.handleClose}
                    animation={false} centered
                    dialogClassName={props.isMobilePortrait ? "modal-fullscreen" : "modal-height-100"}
                >
                    <Modal.Header className='d-flex justify-content-between modal-header-bar-shadow'>
                        <div className='offset-2'/>
                        <div className='col-8 d-flex justify-content-center align-items-center text-center'>
                            <Modal.Title>
                                <div id='modal-header-title'
                                     style={{
                                         fontSize: '.92rem', fontWeight: 'bold', textTransform:
                                             'uppercase', userSelect: 'none'
                                     }}>
                                    {t('modals.select')} {props.options.make.value}
                                </div>
                            </Modal.Title>
                        </div>
                        <div className='col-2 d-flex justify-content-center align-items-center'>
                            <CloseButton onClick={() => props.updateMultipleModals({
                                'make': false, 'search': false, 'model': false
                            })}/>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <ul className="list-unstyled list-group">
                            <li id='li-any-model' key='any-model' className="list-group-item hover-item"
                                style={makeModelListStyle.li}>
                                <div className="d-flex row mb-4"
                                     style={styles.carItem}
                                     onClick={() => chooseAnyModel(props)}>
                                    <div className="col-12">
                                        <span style={styles.carTitle}>{t('texts:search.search-area.any-model')}</span>
                                    </div>
                                </div>
                            </li>
                            {
                                cars.map(
                                    item => {
                                        return (<li key={item.name} id={'li-model-' + item.name.toLowerCase()}
                                                    className="list-group-item hover-item"
                                                    style={makeModelListStyle.li}
                                                    onClick={() => {
                                                        // console.log('make', props.options.make.value, 'item>>>', item);
                                                        props.updateHandler({
                                                            'make': {
                                                                value: props.options.make.value,
                                                                visible: true,
                                                                title: props.options.make.value
                                                            },
                                                            'model': {
                                                                value: `${item.name}`,
                                                                visible: true,
                                                                title: `${item.name}`
                                                            }
                                                        });
                                                        props.handleClose();
                                                    }
                                                    }>
                                                <div className="d-flex row"
                                                     style={makeModelListStyle.carItem}>
                                                    <div className="col-12">
                                                        <span style={makeModelListStyle.carTitle}>{item.name}</span>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })}
                        </ul>
                    </Modal.Body>
                    <Modal.Footer className='modal-footer-bar-shadow'>
                        <div className='col-12 d-flex justify-content-between align-items-center'>
                            <Button variant='link' style={{color: '#222', textDecoration: 'underline'}}
                                    onClick={() => props.handleSwitch()}>
                                {t('texts:search.search-area.back-to-select-make')}
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        );
    } else {
        return null;
    }
};

export default ModalByModel;
const styles = {
    modalBody: {
        width: '100%',
        height: '44px',
        // borderBottom: 'solid 1px rgba(0,0,0,0.75)'
    },
    modalUl: {
        maxHeight: '450px',
        overflow: 'overlay',
        border: 'none'
    }
};
import React from 'react';

export const FieldComponent = ({
                                   sellerType,
                                   fieldType,
                                   borderStyle,
                                   localInfoFields,
                                   setLocalInfoFields,
                                   showInput,
                                   setShowInput,
                                   sellerInfo,
                                   texts
                               }) => {

    const handleClick = () => {
        console.log('FieldComponent handleClick', fieldType, showInput[fieldType]);
        if (showInput[fieldType] === true) {
            const o = {...showInput};
            delete o[fieldType];
            setShowInput(o);
        } else {
            const o = {...showInput};
            o[fieldType] = true;
            setShowInput(o);
            if (localInfoFields[fieldType] === undefined)
                localInfoFields[fieldType] = sellerInfo[fieldType];
        }
    }

    const handleChange = (e) => {
        console.log('FieldComponent handleChange', e.target.value);
        const o = {...localInfoFields};
        o[fieldType] = e.target.value;
        setLocalInfoFields(o);
    }

    return (
        // sellerInfo.type === sellerType && localInfoFields.type !== (sellerType === 'private' ? 'company' : 'private')
        // && _.isEmpty(sellerInfo[fieldType]) &&
        <div className='row my-3 border-bottom'>
            <div className='col-6 text-left pl-0'>{texts && texts.title}</div>
            <div className='col-6 font-weight-bold text-right d-flex align-items-center justify-content-end pr-0'>
                {showInput[fieldType] === undefined ? <span>{localInfoFields[fieldType]}</span> :
                    <input type='text'
                           style={borderStyle}
                           onChange={handleChange}
                           value={localInfoFields[fieldType]}/>}
                <img height='17px' width='17px'
                     className='ml-3 edit-icon'
                     onClick={handleClick}
                />
            </div>
        </div>
    );
}
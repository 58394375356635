import _ from "lodash";

export const formatWebsiteURL = website => {
    if (website.startsWith('http://') || website.startsWith('https://')) {
        return website.trim();
    } else {
        return 'https://' + website.trim();
    }
}

export const formatWebsiteName = website => {
    return website.replace(/^(http:\/\/|https:\/\/)/, '').trim();
}

export const isVerified = sellersRank => {
    if (!_.isEmpty(sellersRank) && _.words(sellersRank.toLowerCase()).includes('verified')) {
        return true;
    } else
        return false;
}
import React, {useState} from 'react';
import Heading from "../../../Search/SearchArea/Heading";
import plus from "../svg/plus.svg";
import OptionalFeaturesModal from "./OptionalFeaturesModal";

const InputFieldOptions = props => {

    const [showModal, setShowModal] = useState(false);

    let numberClass = 'row my-2 numbered-list-item'
    if (props.state.options.length === 0 && props.prevField.length > 0) numberClass += ' yellow';
    else if ((props.prevField === undefined || props.prevField.length === 0)
        && props.state.options.length === 0) numberClass += ' grey'

    return <div className={numberClass} id='row-features'>
        <div className='offset-1'/>
        <div className='col-9 pr-0' style={{marginLeft: '-1em'}}>
            <div style={{marginLeft: '1em'}}>
                <Heading>{props.title}</Heading>
            </div>
            <div className='row d-flex align-items-center'>
                <div className='col-10 pr-0'>{props.message}</div>
                <div className='col-2 px-0 d-flex justify-content-center'>
                    <img src={plus} height={20} onClick={() => setShowModal(true)} style={{cursor: 'pointer'}}/>
                </div>
            </div>
        </div>

        <OptionalFeaturesModal
            title={props.title}
            show={showModal}
            setShowModal={setShowModal}
            options={props.options}
            state={props.state}
            updateOptions={props.updateOptions}
        />
    </div>
}

export default InputFieldOptions;
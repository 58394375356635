import React from 'react';
import {useTranslation} from "react-i18next";
import {useMediaQuery} from "react-responsive";
import HeroSearchButton from "./HeroMenu/Buttons/HeroSearchButton";

const Hero = props => {

    const {t} = useTranslation(['texts']);

    const isSmallScreen = useMediaQuery({maxWidth: 560})
    const isTinyScreen = useMediaQuery({maxWidth: 280})
    const isHugeScreen = useMediaQuery({minWidth: 1600})

    console.log('isSmall', isSmallScreen, 'isTiny', isTinyScreen, 'isHuge', isHugeScreen);

    let buttonWidth = '340px'
    if (isTinyScreen) buttonWidth = '220px';
    else if (isSmallScreen) buttonWidth = '250px';
    else if (isHugeScreen) buttonWidth = '400px';

    let fontSize = '2rem';
    if (isTinyScreen) fontSize = '1.5rem';
    else if (isSmallScreen) fontSize = '1.75rem';
    else if (isHugeScreen) fontSize = '2.75rem';

    let leftH1 = 0;
    if (isTinyScreen || isSmallScreen) leftH1 = '';

    let topH1 = '1.5rem';
    if (isTinyScreen || isSmallScreen) topH1 = '20%'

    return (
        <div className='container h-100'>
            <div className="row h-100 m-0 d-flex justify-content-center" style={{color: 'white'}}>
                <div className="col-12 d-flex align-items-center justify-content-center">
                    <HeroSearchButton props={props} buttonWidth={buttonWidth}/>
                    <div style={{position: 'absolute', top: topH1, left: leftH1}}>
                        <h1 style={{fontSize: fontSize}}>{t('home.find-dream-car')}</h1>
                        {/*<h1 style={{fontSize: fontSize}}>Buy and sell cars easily</h1>*/}
                    </div>
                    <div style={{position: 'absolute', bottom: '20%'}}>
                        <h3 style={{fontSize: '1rem'}}>{t('home.use-predefined-queries')}</h3>
                    </div>
                </div>
            </div>


        </div>
    );
}
export default Hero;

import React from 'react';

const SearchOptionMultiNew = props => {

    const updateFeature = (e, title, section, optionsSection, updateHandler) => {
        console.log('multi section', section, 'optionsSection', optionsSection);
        if (optionsSection !== undefined) {
            if (e.target.checked) {
                // console.log('feature', e.target.name, 'is checked');
                const newFeatures = [...optionsSection,
                    {
                        value: e.target.name,
                        visible: e.target.checked,
                        title: title !== undefined ? title : e.target.name
                    }
                ];
                updateHandler(section, newFeatures);
            } else if (!e.target.checked) {
                // console.log(`feature ${e.target.name} is unchecked`);
                const index = optionsSection.findIndex((item) => item.value === e.target.name);
                // console.log('index', index)
                if (index !== -1) {
                    const newFeatures = [...optionsSection.slice(0, index), ...optionsSection.slice(index + 1)];
                    // console.log('new features after unchecked', newFeatures);
                    updateHandler(section, newFeatures);
                }
            }
        }
    };


    // console.log('SearchOptionMultiNew props', props);

    const stateArr = props.options[props.section];
    console.log('multi stateArr', stateArr, 'typeof', typeof stateArr);
    const stateKeys = stateArr.map(i => i.value);
    console.log('multi stateKeys', stateKeys);

    let unit = undefined;
    let value = {};

    let containerClass = 'container multiselect';
    if (props.bsClass !== undefined) containerClass += ' ' + props.bsClass;

    console.log('multi features >>>', props.options[props.section])


    const keys = Object.keys(props.data)
    console.log('multi props.data', props.data)
    console.log('multi keys?', keys);

    return (<div id={'search-field-' + props.section.toLowerCase()} className={containerClass}>
            {props.children}
            {keys.map((key, index) => {

                const title = props.data[key].title;
                // console.log('unit', title)
                return <div className='row' key={key} style={{position: 'relative'}}>
                    <div className='d-flex justify-content-start align-items-center'>
                        {/*{console.log('each item >>>', props.options[props.section][index])}*/}
                        <input
                            checked={stateKeys.includes(key)}
                            name={key}
                            type='checkbox'
                            style={multiSelectStyles.checkbox}
                            onChange={(e) =>
                                updateFeature(e, title, props.section, props.options[props.section],
                                    props.updateHandler)}/>
                        <span className='checkmark' style={value}/>
                        <label className='ml-2 my-0 text-capitalize'
                               style={multiSelectStyles.label}>{title}</label>
                    </div>
                </div>
            })}
        </div>
    )
};

export default SearchOptionMultiNew;


export const multiSelectStyles = {
    div: {
        opacity: 0.1,
        width: '24px',
        height: '24px',
        zIndex: 1
    },
    checkbox: {
        width: '24px',
        height: '24px',
        border: '1px solid black',
        zIndex: 22
        // opacity: 0.2
    },
    input: {
        opacity: 0,
        cursor: 'pointer',
        height: 0,
        width: 0,
    }
    ,
    label: {
        lineHeight: '32px',
        fontSize: '13px',
        userSelect: 'none',
    }
};

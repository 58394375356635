import React, {useEffect, useState} from "react";
import {AzureStorageRESTConnector} from "../../Integration";
import {LoadingIndicator} from "./LoadingIndicator";

const FetchImage = ({src, style, className, onClick}) => {
    const [imageUrl, setImageUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        AzureStorageRESTConnector.fetchImage(src, setImageUrl)
            .then(() => setIsLoading(false));

        console.log('fetchImage', src)

    }, [src]);
    return <>
        {isLoading ?
            <div
                style={{minHeight: '210px'}}
                className='d-flex justify-content-center align-items-center'>
                <LoadingIndicator/>
            </div> :
            <img src={imageUrl}
                style={style}
                className={className}
                onClick={onClick || null}
                alt='car image'
            />}
    </>;
}

export {FetchImage}
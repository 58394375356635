const getApproximateLeasing = price => {

    let monthlyPay = ((price - (price * 0.05) - (price * 0.25)) / 60).toFixed(0);
    // console.log('getApproximateLeasing', monthlyPay);

    return monthlyPay
}


///"https://api-mgnt-2.azure-api.net/images/532cfa0a8de799d6beec1bb1489c764bb9f6aee4/TYUU889888TTUUTTU/sq8zzz6tb6s.jpg"
const getContainerIDfromPath = car => {
    const path = car.blobPaths[0];

    const url = new URL(path);

    console.log('url.pathname', url.pathname, 'url', url);
    let pathLength = url.pathname.split('/').length;
    console.log('string.split',pathLength);
    let sf;

    if(pathLength===3) {
        console.log('pathLength===3')
        const i = url.pathname.lastIndexOf('/');
        const s = url.pathname.substring(0, i);
        const i2 = s.lastIndexOf('/');
        // console.log('i3', i3);
        sf = s.substring(i2 + 1, s.length);
    }
    if(pathLength===4 || pathLength===5) {
        console.log('pathLength===4 || 5')
        const i = url.pathname.lastIndexOf('/');
        const s = url.pathname.substring(0, i);
        // console.log('s', s)
        const i2 = s.lastIndexOf('/');
        const s2 = s.substring(0, i2);
        // console.log('s2', s2);
        const i3 = s2.lastIndexOf('/');
        // console.log('i3', i3);
        sf = s2.substring(i3 + 1, s2.length);
        console.log('sf', sf);
    }
    return sf;
}

export {getApproximateLeasing, getContainerIDfromPath}
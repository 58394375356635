import globe from "../Header/Navbar/img/globe.svg";
import React, {useState} from "react";

export const LanguageSelector = ({setShowLanguageModal, languageCode, isFooter}) => {

    const initialLanguageContainerStyle = {
        borderRadius: '14px', height: '28px', width: '56px',
        fontSize: '0.82rem', opacity: '.7',
        userSelect: 'none', cursor: 'pointer',
        color: '#222', border: 'solid 2px #999'
    };
    const initialLanguageContainerStyleFooter = {
        ...initialLanguageContainerStyle,
        color: '#ddd', border: 'solid 2px #ddd', opacity: '.8'
    };

    const initialGlobeStyle = {opacity: '.7', marginRight: '-5px'};
    const initialGlobeStyleFooter = {opacity: '1', marginRight: '-5px', filter: 'invert(1)'};

    const [languageStyle, setLanguageStyle] = useState(
        isFooter
            ? initialLanguageContainerStyleFooter
            : initialLanguageContainerStyle
    );
    const [globeStyle, setGlobeStyle] = useState(
        isFooter ? initialGlobeStyleFooter : initialGlobeStyle);

    return <div id='language-container'
                className='p-2 d-flex align-items-center justify-content-between'
                onClick={() => setShowLanguageModal(true)}
                onMouseEnter={() => {
                    let updatedStyle = {
                        ...initialLanguageContainerStyle,
                        opacity: '.9'
                    };
                    let globeStyle = {...initialGlobeStyle, opacity: '.8'};

                    if (isFooter) updatedStyle = {...updatedStyle, color: '#ccc', border: 'solid 2px #ccc', opacity: 1};
                    if (isFooter) globeStyle = {...globeStyle, opacity: '1', filter: 'invert(1)'};

                    setLanguageStyle(updatedStyle)
                    setGlobeStyle(globeStyle);
                }}
                onMouseLeave={() => {
                    setLanguageStyle(isFooter ? initialLanguageContainerStyleFooter : initialLanguageContainerStyle);
                    setGlobeStyle(isFooter ? initialGlobeStyleFooter : initialGlobeStyle);
                }}
                style={languageStyle}>
                    <span className='text-center text-uppercase' style={{width: '20px', marginLeft: '-1px'}}>
                        {languageCode}
                    </span>
        <img src={globe} height='20' style={globeStyle} alt='language-switch'/>
    </div>
}

export const documentsOcpApimKey = process.env.REACT_APP_DOCUMENTS_API_KEY;
const ocpApiKeyName = process.env.REACT_APP_OCP_API_NAME;
const rootApiKey = process.env.REACT_APP_ROOT_API_URI;

export default class AzureSearchConnector {

    constructor() {
        console.log('AzureSearchConnector instance created')
    }

    ping() {
        console.log('ping')
    }

    fetchResults = async (options) => {
        // console.log('fetch results from', baseUrl);
        let searchQuery = `${rootApiKey}/docs?`;

        if (options !== undefined) searchQuery = searchQuery + options;
        console.log('searchQuery at AzureSearchConnector', searchQuery);
        const response = await fetch(searchQuery,
            {
                headers: {
                    [ocpApiKeyName]: documentsOcpApimKey
                }
            });
        const json = await response.json();
        // console.log('json from search', json)
        return json;

    };

    publishDocument = async (body) => {

        const token = localStorage.getItem('token');
        if (token === undefined) {
            console.log('token is undefined');
        } else {
            try {
                console.log('token in localStorage', token)
                const url = `${rootApiKey}/docs/add-document-with-check`;
                body['@search.action'] = 'upload';

                console.log('publish body', body);
                if (body.RowKey === undefined && body.vin !== undefined) body.RowKey = body.vin;
                if (body.PartitionKey === undefined && body.make !== undefined) body.PartitionKey = body.make;
                if (body.options.length > 0) {
                    let step1 = JSON.parse(body.options);
                    if (Array.isArray(step1)) {
                        body.options = step1;
                        console.log('body.options parsing step1', step1);
                    } else {
                        let step2 = JSON.parse(step1);
                        body.options = step2;
                    }
                }
                if (body.history.length > 0) {
                    let step1 = JSON.parse(body.history);
                    if (Array.isArray(step1)) {
                        body.history = step1;
                        console.log('body.history parsing step1', step1);
                    } else {
                        let step2 = JSON.parse(step1);
                        body.history = step2;
                    }
                }
                delete body.vin;
                delete body.originalVIN;
                delete body.make;
                delete body.containerID;
                delete body.userID;
                delete body.entryMode;
                delete body.cars;
                delete body.sellerInfo;
                // delete body.characteristicID;
                delete body.email;

                const val = {value: [body]};

                console.log('publishDocument val', JSON.stringify(val));
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        [ocpApiKeyName]: documentsOcpApimKey,
                        'Authorization': 'Bearer ' + token
                    },
                    body: JSON.stringify(val)
                });

                const json = await response.json();
                return json;
            } catch (e) {
                console.log('AzureSearchConnector.publishDocument error', e);
            }
        }
    }

    unpublishDocument = async body => {
        const token = localStorage.getItem('token');
        if (token === undefined) {
            console.log('token is undefined');
        } else {
            try {
                console.log('token in localStorage', token);
                const url = `${rootApiKey}/docs/deleteDocument`;

                const response = await fetch(url,
                    {
                        method: 'POST',
                        headers: {
                            [ocpApiKeyName]: documentsOcpApimKey,
                            'Authorization': 'Bearer ' + token
                        },
                        body: JSON.stringify({
                            value: [
                                {
                                    "@search.action": "delete",
                                    "RowKey": body.vin
                                }
                            ]
                        })
                    });
                console.log('response after unpublish', response);
                const json = await response.json();
                return json;
            } catch (e) {
                console.log('AzureSearchConnector.unpublishDocument error', e);
            }
        }
    }

    getDocument = async vin => {

        try {
            const response = await fetch(`${rootApiKey}/docs/${vin}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    [ocpApiKeyName]: documentsOcpApimKey
                }
            });

            console.log('AzureSearchConnector.getDocument', vin, 'response.code:', response.status);

            if (response.status !== 200 && response.status !== 204) {
                console.warn('AzureSearchConnector.getDocument code!==200/204', response.status);
            }
            return response;
        } catch (e) {
            console.log('AzureSearchConnector.getDocument error', e);
        }
    }

    getPublicDocument = async vin => {
        // console.log('public document', vin)
        const response = await fetch(`${rootApiKey}/docs/public/${vin}`, {
            headers: {
                [ocpApiKeyName]: documentsOcpApimKey
            }
        });
        return response;
    }
}
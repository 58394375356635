import React, {useEffect} from 'react';
import {
    InputFieldColor,
    InputFieldComment,
    InputFieldOptions,
    InputFieldGeneration,
    InputFieldImages,
    InputFieldLocation,
    InputFieldMake,
    InputFieldMileage,
    InputFieldModel,
    InputFieldModification,
    InputFieldPrice,
    InputFieldsSubmit,
    InputFieldTrim,
    InputFieldVIN,
    InputFieldYear
} from "../../../Fields";
import SemiAutomaticFlow from './SemiAutomaticFlow/SemiAutomaticFlow'
import {useTranslation} from "react-i18next";
import InputFieldHistory from "../../../Fields/History/InputFieldHistory";

const AutomaticFlow = props => {

    // console.log('AutomaticFlow props', props);

    const {t} = useTranslation(["texts", "data-for-ui", "locations"]);
    const color_d = t('data-for-ui:extColor', {returnObjects: true});
    const countries_d = t('locations:countries', {returnObjects: true});
    const common_d = t('data-for-ui:common', {returnObjects: true});
    const features_d = t('data-for-ui:features', {returnObjects: true});
    const history_d = t('data-for-ui:history', {returnObjects: true});

    useEffect(() => {
        console.log('AutomaticFlow useEffect: setEntryMode once []');
        props.sellFunctions.updateEntryMode('automatic');
    }, []);

    return <div id='automatic-flow'
                className='col-12 pr-0 numbered-list'
                style={{maxWidth: '400px'}}>
        <InputFieldMake
            key={'make'}
            title={t('sell.input-fields.make')}
            updateMake={props.sellFunctions.updateSellMake}
            state={props.state}/>

        <InputFieldYear
            key={'year'}
            title={t('sell.input-fields.year')}
            prevField={props.state.make}
            updateYear={props.sellFunctions.updateSellYear}
            state={props.state}
        />

        <InputFieldModel
            key={'model'}
            title={t('sell.input-fields.model')}
            prevField={props.state.year}
            updateModel={props.sellFunctions.updateSellModel}
            state={props.state}/>


        <InputFieldGeneration
            title={t('sell.input-fields.generation')}
            prevField={props.state.model}
            updateGeneration={props.sellFunctions.updateSellGeneration}
            state={props.state}
            generation={common_d}
        />


        <InputFieldTrim
            title={t('sell.input-fields.body-style')}
            prevField={props.state.generation}
            updateTrim={props.sellFunctions.updateSellTrim}
            state={props.state}/>


        <InputFieldModification
            title={t('sell.input-fields.modification')}
            prevField={props.state.trim}
            updateModification={props.sellFunctions.updateSellModification}
            state={props.state}/>


        {(props.state.characteristicsError !== undefined || props.state.entryMode === 'semi-automatic') &&
            <SemiAutomaticFlow state={props.state} sellFunctions={props.sellFunctions}/>}

        <InputFieldColor
            title={t('sell.input-fields.color')}
            prevField={
                props.state.characteristicsError === undefined
                    ? props.state.modification //modification
                    : (props.state.fuel === 'electric'
                            ? props.state.drivetrain
                            : props.state.displacement
                    )}
            updateColor={props.sellFunctions.updateSellColor}
            state={props.state}
            colors={color_d}
            placeholder={t('common.select')}
        />


        <InputFieldMileage
            title={t('sell.input-fields.mileage')}
            prevField={props.state.color} //color
            updateMileage={props.sellFunctions.updateSellMileage}
            state={props.state}
        />

        <InputFieldVIN
            title={t('sell.input-fields.vin')}
            prevField={props.state.make}
            // prevField={props.state.mileage}
            updateVIN={props.sellFunctions.updateSellVIN}
            updateSellErrors={props.sellFunctions.updateSellErrors}
            state={props.state}/>


        <InputFieldLocation
            countryTitle={t('sell.input-fields.country')}
            cityTitle={t('sell.input-fields.city')}
            prevField={props.state.vin} //vin
            updateCountry={props.sellFunctions.updateSellCountry}
            updateCity={props.sellFunctions.updateSellCity}
            state={props.state}
            countries={countries_d}
        />

        <InputFieldPrice
            title={t('sell.input-fields.price')}
            prevField={props.state.city} //city
            updatePrice={props.sellFunctions.updateSellPrice}
            updateHistory={props.sellFunctions.updateSellHistory}
            updateVatIncluded={props.sellFunctions.updateVatIncluded}
            historyDictionary={history_d}
            state={props.state}/>

        <InputFieldImages
            title={t('sell.input-fields.photos')}
            message={t('sell.input-fields.photos-message')}
            prevField={props.state.price}
            updateFileInput={props.sellFunctions.updateSellFileInput}
            state={props.state}
            updateSellErrors={props.sellFunctions.updateSellErrors}
        />


        <InputFieldHistory
            title={t('sell.input-fields.history')}
            message={t('sell.input-fields.history-message')}
            state={props.state}
            prevField={props.state.files}
            updateHistory={props.sellFunctions.updateSellHistory}
            historyDictionary={history_d}
            addButtonText={t('vehicle-information.add')}
        />

        <InputFieldOptions
            message={t('sell.input-fields.features-message')}
            title={t('sell.input-fields.extra-features')}
            addButtonText={t('vehicle-information.add')}
            state={props.state}
            prevField={props.state.files}
            updateOptions={props.sellFunctions.updateSellOptions}
            options={features_d}
        />

        <InputFieldComment
            title={t('sell.input-fields.comment')}
            message={t('sell.input-fields.comment-message')}
            addButtonText={t('vehicle-information.add')}
            prevField={props.state.files}
            updateComment={props.sellFunctions.updateSellComment}
            state={props.state}
        />


        <InputFieldsSubmit state={props.state}
                           updateBlobPaths={props.sellFunctions.updateSellBlobPaths}
                           updateFileInput={props.sellFunctions.updateSellFileInput}
                           updateLoadingComplete={props.sellFunctions.updateSellLoadingComplete}
                           updateSellErrors={props.sellFunctions.updateSellErrors}
                           clear={props.sellFunctions.sellClear}
                           email={props.state.email}
                           clearFieldsText={t('sell.input-fields.clear-fields')}
                           uploadText={t('sell.input-fields.upload')}/>
    </div>
}

export default AutomaticFlow;

const style = {
    "numbered-list": {
        "counter-reset": "css-counter 0",
        // "color":"red"
    },
    "numbered-list div": {
        "counter-increment": "css-counter 1",
        "color": "red"
    },
    "numbered-list div:before": {
        "content": "counter(css-counter) \".\"" /* Apply counter before children's content. */
    }
}
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {buyButtonStyle} from "../../MyOffers/functions/offersFunctions";

const CarAdded = props => {

    const {t} = useTranslation();

    useEffect(() => {
        console.log('useEffect in CarAdded');
        props.sellFunctions.refreshCars();
    }, []);

    return <div id='car-added' className='border rounded-0 p-2' style={{maxWidth: '500px'}}>
        <div className='row col-12'>
            <div className='my-2'>
                {t('sell.car-added.car')}{' '}
                {props.state.vin}{' '}
                {t('sell.car-added.has-been-added')}
            </div>
        </div>
        <div className='row'>
            <div className='col-12 d-flex justify-content-start align-items-center'>
                <button id='add-another-car-button'
                        style={{
                            ...buyButtonStyle, width: '10rem', background: 'limegreen', color: 'white',
                            border: 'none'
                        }}
                        className='d-flex justify-content-center align-items-center mr-2'
                        onClick={() => props.sellFunctions.sellClear()}>
                    {t('sell.car-added.add-another')}
                </button>
                <button id='check-it-in-my-offers-button'
                        style={{
                            ...buyButtonStyle, width: '10rem', background: 'rgba(221,221,221,0.58)', color: '#888',
                            border: '#ccc 1px solid'
                        }}
                        className='d-flex justify-content-center align-items-center mr-2'
                        onClick={() => props.sellFunctions.setSellSelectedPage({page: 'myOffers'})}>
                    {t('sell.car-added.check-my-offers')}</button>
            </div>
        </div>
    </div>
}

export {CarAdded};
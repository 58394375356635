import React from 'react';
import InputFieldMake from "../../../Fields/Make/InputFieldMake";
import InputFieldYear from "../../../Fields/Year/InputFieldYear";
import InputFieldModel from "../../../Fields/Model/InputFieldModel";
import InputFieldModificationSimple from "../../../Fields/Modification/InputFieldModificationSimple";
import InputFieldBodyStyle from "../../../Fields/BodyStyle/InputFieldBodyStyle";
import InputFieldFuel from "../../../Fields/Fuel/InputFieldFuel";
import InputFieldPower from "../../../Fields/Power/InputFieldPower";
import InputFieldTransmission from "../../../Fields/Transmission/InputFieldTransmission";
import InputFieldDisplacement from "../../../Fields/Displacement/InputFieldDisplacement";
import InputFieldDrivetrain from "../../../Fields/Drivetrain/InputFieldDrivetrain";
import InputFieldColor from "../../../Fields/Color/InputFieldColor";
import InputFieldMileage from "../../../Fields/Mileage/InputFieldMileage";
import InputFieldPrice from "../../../Fields/Price/InputFieldPrice";
import InputFieldVIN from "../../../Fields/Vin/InputFieldVin";
import InputFieldLocation from "../../../Fields/Location/InputFieldLocation";
import {
    InputFieldComment,
    InputFieldHistory,
    InputFieldImages,
    InputFieldOptions,
    InputFieldsSubmit
} from "../../../Fields";
import {useTranslation} from "react-i18next";

const ManualFlow = props => {

    // console.log('ManualFlow props', props);

    const {t} = useTranslation(["texts", "data-for-ui", "locations"]);
    const bodyStyle_d = t('data-for-ui:bodystyle', {returnObjects: true});
    const fuel_d = t('data-for-ui:fuel', {returnObjects: true});
    const transmission_d = t('data-for-ui:transmission', {returnObjects: true});
    const drivetrain_d = t('data-for-ui:drivetrain', {returnObjects: true});
    const color_d = t('data-for-ui:extColor', {returnObjects: true});
    const countries_d = t('locations:countries', {returnObjects: true});
    const features_d = t('data-for-ui:features', {returnObjects: true});
    const history_d = t('data-for-ui:history', {returnObjects: true});

    console.log('locations.countries in ManualFlow', countries_d);

    return <div id='manual-flow'
                className='col-12 numbered-list pr-0'
                style={{maxWidth: '400px'}}>

        <InputFieldMake
            key={'make'}
            title={t('sell.input-fields.make')}
            updateMake={props.sellFunctions.updateSellMake}
            state={props.state}/>

        <InputFieldYear
            key={'year'}
            title={t('sell.input-fields.year')}
            prevField={props.state.make}
            updateYear={props.sellFunctions.updateSellYearSimple}
            state={props.state}/>

        <InputFieldModel
            title={t('sell.input-fields.model')}
            prevField={props.state.year}
            updateModel={props.sellFunctions.updateSellModel}
            state={props.state}/>


        <InputFieldModificationSimple
            title={t('sell.input-fields.modification')}
            prevField={props.state.model}
            updateModification={props.sellFunctions.updateSellModificationSimple}
            state={props.state}
        />


        <InputFieldBodyStyle
            bodyStyles={bodyStyle_d}
            title={t('sell.input-fields.body-style')}
            prevField={props.state.modification} //modification
            updateBodyStyle={props.sellFunctions.updateSellBodyStyle}
            state={props.state}
        />


        <InputFieldFuel
            fuels={fuel_d}
            title={t('sell.input-fields.fuel')}
            prevField={props.state.bodyStyle}
            updateFuel={props.sellFunctions.updateSellFuel}
            state={props.state}
        />


        <InputFieldPower
            title={t('sell.input-fields.power')}
            prevField={props.state.fuel}
            updatePower={props.sellFunctions.updateSellPower}
            state={props.state}
        />


        <InputFieldTransmission
            title={t('sell.input-fields.transmission')}
            transmissions={transmission_d}
            prevField={props.state.power}
            updateTransmission={props.sellFunctions.updateSellTransmission}
            state={props.state}
        />


        <InputFieldDisplacement
            title={t('sell.input-fields.displacement')}
            prevField={props.state.transmission}
            updateDisplacement={props.sellFunctions.updateSellDisplacement}
            state={props.state}
        />


        <InputFieldDrivetrain
            title={t('sell.input-fields.drivetrain')}
            prevField={props.state.displacement} //displacement
            updateDrivetrain={props.sellFunctions.updateSellDrivetrain}
            state={props.state}
            drivetrains={drivetrain_d}
        />


        <InputFieldColor
            title={t('sell.input-fields.color')}
            prevField={props.state.drivetrain} //drivetrain
            updateColor={props.sellFunctions.updateSellColor}
            state={props.state}
            colors={color_d}
            placeholder={t('common.select')}
        />


        <InputFieldMileage
            title={t('sell.input-fields.mileage')}
            prevField={props.state.color}
            updateMileage={props.sellFunctions.updateSellMileage}
            state={props.state}
        />

        <InputFieldVIN
            title={t('sell.input-fields.vin')}
            prevField={props.state.mileage}
            updateVIN={props.sellFunctions.updateSellVIN}
            state={props.state}
            updateSellErrors={props.sellFunctions.updateSellErrors}
        />


        <InputFieldLocation
            countryTitle={t('sell.input-fields.country')}
            cityTitle={t('sell.input-fields.city')}
            prevField={props.state.vin} //vin
            updateCountry={props.sellFunctions.updateSellCountry}
            updateCity={props.sellFunctions.updateSellCity}
            state={props.state}
            countries={countries_d}
        />

        <InputFieldPrice
            title={t('sell.input-fields.price')}
            prevField={props.state.city} //city
            updatePrice={props.sellFunctions.updateSellPrice}
            updateHistory={props.sellFunctions.updateSellHistory}
            updateVatIncluded={props.sellFunctions.updateVatIncluded}
            historyDictionary={history_d}
            state={props.state}
        />

        <InputFieldImages
            title={t('sell.input-fields.photos')}
            message={t('sell.input-fields.photos-message')}
            prevField={props.state.price}
            updateFileInput={props.sellFunctions.updateSellFileInput}
            state={props.state}
            updateSellErrors={props.sellFunctions.updateSellErrors}
        />

        <InputFieldHistory
            title={t('sell.input-fields.history')}
            message={t('sell.input-fields.history-message')}
            state={props.state}
            prevField={props.state.files}
            updateHistory={props.sellFunctions.updateSellHistory}
            historyDictionary={history_d}
            addButtonText={t('vehicle-information.add')}
        />

        <InputFieldOptions
            message={t('sell.input-fields.features-message')}
            title={t('sell.input-fields.extra-features')}
            addButtonText={t('vehicle-information.add')}
            state={props.state}
            prevField={props.state.files}
            updateOptions={props.sellFunctions.updateSellOptions}
            options={features_d}
        />

        <InputFieldComment
            title={t('sell.input-fields.comment')}
            message={t('sell.input-fields.comment-message')}
            addButtonText={t('vehicle-information.add')}
            prevField={props.state.files}
            updateComment={props.sellFunctions.updateSellComment}
            state={props.state}
        />

        <InputFieldsSubmit state={props.state}
                           updateBlobPaths={props.sellFunctions.updateSellBlobPaths}
                           updateFileInput={props.sellFunctions.updateSellFileInput}
                           updateLoadingComplete={props.sellFunctions.updateSellLoadingComplete}
                           updateSellErrors={props.sellFunctions.updateSellErrors}
                           clear={props.sellFunctions.sellClear}
                           email={props.state.email}
                           clearFieldsText={t('sell.input-fields.clear-fields')}
                           uploadText={t('sell.input-fields.upload')}/>
    </div>
}

export default ManualFlow;
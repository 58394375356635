import React, {useEffect} from 'react';
import './paginator.css';
import ReactPagination from 'react-paginate';
import {useTranslation} from "react-i18next";
import {useMediaQuery} from "react-responsive";

const Paginator = props => {

    const {t} = useTranslation();

    const isPortraitMobile = useMediaQuery({orientation: 'portrait'});

    console.log('paginator props', props);

    useEffect(()=>{
        console.log('useEffect in Paginator')
        window.scrollTo(0,0);
    },[props.activePage])


    let pageCount = Math.ceil(props.searchCount / props.itemsPerPage);
    let previous = '< ' + t('search.car-listing.previous');
    let next = t('search.car-listing.next') + ' >';
    if (isPortraitMobile) {
        previous = '<';
        next = '>'
    }

    if (pageCount > 1) {
        return <div className={'react-paginate'}>
            <ReactPagination
                previousLabel={previous}
                nextLabel={next}
                breakLabel={'...'}
                forcePage={props.activePage}
                pageCount={pageCount}
                marginPagesDisplayed={1}
                pageRangeDisplayed={3}
                onPageChange={evt => props.updateActivePage(evt.selected)}

                subContainerClassName={'pages-pagination'}
                breakClassName={'page-item'}
                breakLinkClassName={'page-link'}
                containerClassName={'pagination justify-content-center'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                activeClassName={'active'}
            />
        </div>
    } else return <div></div>

};

export default Paginator;


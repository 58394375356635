import React from 'react';
import Heading from "../../../Search/SearchArea/Heading";
import {useTranslation} from "react-i18next";
import OptionSelectOld from "../../OptionSelect/OptionSelectOld";


const getTitleFromJSON = (json, key) => {
    // console.log('getTitleFromJSON', key, json)
    if (key !== undefined && json[key.toLowerCase()] !== undefined) {
        let title = json[key.toLowerCase()].title;
        // console.log('hit, return ', title)
        return title;
    } else {
        // console.log('miss, return ', key)
        return key;
    }
}

const InputFieldTrim = props => {

    // console.log('InputFieldTrim; props.state.trims', props.state.trims)

    const {t} = useTranslation(['data-for-ui']);
    const bodystyle = t('data-for-ui:bodystyle', {returnObjects: true});

    let numberClass = 'row mt-2 numbered-list-item'
    if (props.state.trim === undefined && props.prevField !== undefined) numberClass += ' yellow';
    else if (props.prevField === undefined) numberClass += ' grey'

    return <div className={numberClass} id='row-trim'>
        {/*<NumberOfStep thisField={props.state.trim} prevField={props.prevField}>*/}
        {/*    {props.step}*/}
        {/*</NumberOfStep>*/}

        <div className='offset-1'/>
        <div className='col-9 pl-0'>
            {/*{console.log('trims', props.state.trims)}*/}
            <OptionSelectOld
                id='select-trim'
                options={props.state.trims.map(trim => {
                    // console.log('trim', trim, ' getTitleFromJSON', getTitleFromJSON(bodystyle, trim.name));
                    return {
                        // label: getTitleFromJSON(bodystyle, trim.name), value: trim
                        label: getTitleFromJSON(bodystyle, trim.name), value: trim
                    }
                })}
                selected={props.state.trim !== undefined && getTitleFromJSON(bodystyle, props.state.trim.name)}
                // selected={props.state.trim !== undefined && getTitleFromJSON(bodystyle, props.state.trim.name)}
                handler={props.updateTrim}
                isDisabled={props.prevField === undefined}
            >
                <Heading>{props.title}</Heading>
            </OptionSelectOld>
        </div>
    </div>
}

export default InputFieldTrim;
import React, {useEffect, useState} from 'react';
import {Input} from "../../Input";
import Heading from "../../../Search/SearchArea/Heading";
import Select from "react-select";
import {optionSelectStyle} from "../../OptionSelect/optionSelectStyle";
import {calculatePriceWithoutVat, calculateVatFromGross} from "./priceHelperFunctions";
import {useTranslation} from "react-i18next";
import {useMsal} from "@azure/msal-react";


const InputFieldPrice = props => {

    const {accounts} = useMsal();

    const {t} = useTranslation(['data-for-ui']);
    const countriesVatDictionary = t('data-for-ui:vat', {returnObjects: true});
    console.log('InputFieldPrice', props, countriesVatDictionary);

    let numberClass = 'row mt-2 numbered-list-item'
    if (props.state.price === undefined && props.prevField !== undefined) numberClass += ' yellow';
    else if (props.prevField === undefined) numberClass += ' grey'

    // const [sellerInfo, setSellerInfo] = useState(undefined); //stored in sessionStorage
    const [vat, setVat] = useState(undefined);
    const [vatDictionary, setVatDictionary] = useState([]);

    const country = props.state.country !== undefined ? props.state.country.toLowerCase() : 'estonia';

    useEffect(() => {
            console.log('InputFieldPrice useEffect, [accounts,props.state.country]', accounts, props.state.country);
            if (accounts.length > 0) {

                const sellerInfoStr = window.sessionStorage.getItem('sellerInfo');
                if (sellerInfoStr !== undefined) {
                    const sellerInfo = JSON.parse(sellerInfoStr);
                    // setSellerInfo(sellerInfo);

                    console.log('useEffect InputFieldPrice', countriesVatDictionary[country], sellerInfo)
                    if (props.state.country !== undefined) {
                        const dictionary = countriesVatDictionary[country];
                        setVatDictionary(dictionary);

                        if (sellerInfo.type === 'company' && props.state.vatIncluded === undefined
                            || props.state.vatIncluded === 'true') {
                            const defaultValue = countriesVatDictionary[country][1];
                            setVat(defaultValue);
                            if (props.state.vatIncluded === undefined) props.updateVatIncluded('true');
                            // console.log('useEffect InputFieldPrice type is company, VAT', defaultValue, props.state);

                        } else if (sellerInfo.type === 'private' && props.state.vatIncluded === undefined
                            || props.state.vatIncluded === 'false') {
                            const defaultValue = countriesVatDictionary[country][0];
                            setVat(defaultValue);
                            if (props.state.vatIncluded === undefined) props.updateVatIncluded('false');
                            // console.log('useEffect InputFieldPrice type is private, VAT', defaultValue, props.state);
                        }
                    }
                }
            }
        },
        [accounts, props.state.country]
    );

    return <div className={numberClass} id='row-price'>

        <div className='offset-1'/>
        <div className='col-9 pl-0'>
            <Input
                key='input-price'
                value={props.state.price}
                disabled={props.prevField === undefined}
                handler={props.updatePrice}
                // placeholder={'Type'}
                maxLength='7'
                type='number'
                step={1000}
            >
                <div style={{marginLeft: '1em'}}>
                    <Heading>{props.title}</Heading>
                </div>
            </Input>
            <div className='col-12 px-0'>
                <div className="container">
                    <Heading>VAT</Heading>
                    <div className="row">
                        <div className='m-0 w-100'>
                            <Select
                                className='Option-Select'
                                value={vat}
                                onChange={e => {
                                    console.log('onChange', e)
                                    setVat(e);
                                    if (e.value === 0) {
                                        console.log('Sell.state.updateVAT as false');
                                        props.updateVatIncluded("false");
                                    } else {
                                        console.log('Sell.state.updateVAT as true')
                                        props.updateVatIncluded("true");
                                    }
                                }}
                                options={vatDictionary}
                                styles={optionSelectStyle}
                                isSearchable={false}
                                isDisabled={props.isDisabled}
                                placeholder=''
                                // menuIsOpen
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div style={{fontSize: '0.82rem'}}>
                {vat !== undefined ? <span>{t('texts:vehicle-information.price-without-vat')}{': '}
                    {calculatePriceWithoutVat(props.state.price, vat.value)}
                    </span> : null}
                {vat !== undefined ? <span className='ml-2'>{t('texts:vehicle-information.vat')}{': '}{
                    calculateVatFromGross(props.state.price, vat.value)}
                    </span> : null}
            </div>
        </div>
    </div>
};

export default InputFieldPrice;




import React, {useState} from 'react';
import _ from 'lodash';
import {getApproximateLeasing, getContainerIDfromPath} from '../../Utils/utils';
import HorizontalBorder from "../../../Search/CarListing/HorizontalBorder/HorizontalBorder";
import ContactForm from "../ContactForm/ContactForm";
import {useTranslation} from "react-i18next";
import {arrowStyle} from "../../Utils/commonStyles";
import * as languageUtils from '../../../../Integration/Utils/LanguageUtils';
import {AzureSellersRESTConnector} from "../../../../Integration";
import SellerInfo from "../SellerInfo/SellerInfo";
import {calculatePriceWithoutVat, getCompanyVatByCountry} from "../../../Sell/Fields/Price/priceHelperFunctions";

const BriefInfoPanel = ({car, mainTextStyle, headerStyle}) => {

    console.log('BriefInfoPanel', car);
    const [leaseStyle, setLeaseStyle] = useState({
        background: 'none'
    });
    const [showContactForm, setShowContactForm] = useState('none');
    const [showSellerInfo, setShowSellerInfo] = useState(false);
    const [sellerInfo, setSellerInfo] = useState({})

    const {t} = useTranslation(['texts', 'data-for-ui', 'locations', 'extended-dictionary']);

    //dictionaries
    const countries_d = t('locations:countries', {returnObjects: true});
    const fuel_d = t('extended-dictionary:fuels', {returnObjects: true});
    const transmission_d = t('extended-dictionary:transmissions', {returnObjects: true});
    const drivetrain_d = t('extended-dictionary:drivetrains', {returnObjects: true});
    // const fuel_d = t('data-for-ui:fuel', {returnObjects: true});
    // const transmission_d = t('data-for-ui:transmission', {returnObjects: true});
    // const drivetrain_d = t('data-for-ui:drivetrain', {returnObjects: true});
    const history_d = t('data-for-ui:history', {returnObjects: true});
    //connector
    const connector = new AzureSellersRESTConnector();

    let historyString;
    if (car.history.length > 0) {
        historyString = languageUtils.getTitleFromDictionary(car.history[0], history_d);
        if (car.history.length > 1) {
            historyString += ', ';
            historyString += languageUtils.getTitleFromDictionary(car.history[1], history_d).substring(0, 5) + '...';
        }
    }

    const vatByCountry = car => {
        if (car.vatIncluded === "true") {
            return getCompanyVatByCountry(car.country) + '%';
        }
        // else if (car.vatIncluded === "false") {   //should we show 0% VAT?
        //     return t('vehicle-information.vat') + ' 0%'
        // }
    }

    const netFromVAT = car => {
        let vatString = '';
        if (car.country !== undefined && car.vatIncluded !== undefined) {
            if (car.vatIncluded === "true") {
                const companyVat = getCompanyVatByCountry(car.country);
                const netPrice = parseInt(calculatePriceWithoutVat(car.price, companyVat)).toLocaleString('ru-Ru');
                vatString = netPrice.toLocaleString('ru-Ru');
            }
        }
        return vatString;
    }

    const getNetPrice = netFromVat => {
        if (netFromVat.length > 0) {
            return ' (' + t('texts:vehicle-information.netprice') + ' ' + netFromVat + ')';
        }
        return '';
    }

    const vatString = vatByCountry(car);
    const netFromVat = netFromVAT(car);
    const netPriceString = getNetPrice(netFromVat);


    let mainTextStl = {fontSize: '1rem'};
    if (mainTextStyle !== undefined) mainTextStl = mainTextStyle;
    let vehicleHeader = {fontSize: '1.5rem'};
    if (headerStyle !== undefined) vehicleHeader = headerStyle;

    return <div id='brief-info-panel-div' className='mt-2' style={{...mainTextStl}}>
        <div className='row text-capitalize font-weight-bold' style={{fontWeight: 'bold', ...vehicleHeader}}>
            <span className='col-8'>{
                car.PartitionKey}{' '}{car.model}
                {/*{' '}{_.truncate(car.modification, {'length': 30})}*/}
            </span>
            <span className='col-4 d-flex justify-content-end'>{car.year}</span>
        </div>
        <div className='row text-capitalize'>
            <span className='col' style={{color: 'rgba(0,0,0,.5)'}}>
                {car.modification}
            </span>
        </div>

        <div className='row mt-4'>
            <div className='col-7'>
                <ul className='list-unstyled'>

                    <li className='mb-4'>
                        <span className='text-capitalize'>{t('vehicle-information.mileage')}</span>
                        <div className='d-block mt-0 pt-0 font-weight-bold'>
                    <span className=''>
                        {car.mileage.toLocaleString('ru')}
                    </span>
                            <span>{' '}{t('common.km')}</span>
                        </div>
                        <div className='border-bottom' style={{width: '7em'}}/>
                    </li>


                    <li className='mb-4'>
                        <span>{t('vehicle-information.engine')}</span>
                        <div className='d-block mt-0 pt-0 font-weight-bold'>
                    <span className='text-capitalize'>
                        {languageUtils.getTitleFromDictionary(car.fuel, fuel_d)}</span><span>{', '}
                            {car.power}{' '}{t('sell.characteristics.hp')}
                            {/*{car.displacement !== undefined && ', '}*/}
                            {/*{car.displacement}{car.displacement !== undefined && ' cm3'}*/}
                    </span>
                        </div>
                        <div className='border-bottom' style={{width: '7em'}}/>
                    </li>


                    <li className='mb-4'>
                        <span>{t('vehicle-information.location')}</span>
                        <div className='d-block mt-0 pt-0 font-weight-bold '>
                            <span className='text-capitalize'>
                                {languageUtils.getCityFromDictionary(car, countries_d)}
                                {', '}{languageUtils.getCountryFromDictionary(car, countries_d)}
                            </span>
                        </div>
                        <div className='border-bottom' style={{width: '7em'}}/>
                    </li>

                </ul>
            </div>


            <div className='col-5'>
                <ul className='list-unstyled'>
                    <li className='mb-4'>
                        <span>{t('vehicle-information.transmission')}</span>
                        <div className='d-block mt-0 pt-0 font-weight-bold '>
                            <span className='text-capitalize'>
                                {languageUtils.getTitleFromDictionary(car.transmission, transmission_d)}</span>
                        </div>
                        <div className='border-bottom' style={{width: '7em'}}/>
                    </li>


                    <li className='mb-4'>
                        <span>{t('vehicle-information.drivetrain')}</span>
                        <div className='d-block mt-0 pt-0 font-weight-bold '>
                            <span className='text-capitalize'>
                                {languageUtils.getTitleFromDictionary(car.drivetrain, drivetrain_d)}</span>
                        </div>
                        <div className='border-bottom' style={{width: '7em'}}/>
                    </li>


                    {!_.isEmpty(historyString) && <li className='mb-4'>
                        <span>{t('vehicle-information.history-of-ownership')}</span>
                        <div className='d-block mt-0 pt-0 font-weight-bold '>
                            <span className=''>
                                 {historyString}
                            </span>
                        </div>
                        <div className='border-bottom' style={{width: '7em'}}/>
                    </li>}
                    {/*<li className='mb-4'>*/}
                    {/*    <span>{t('vehicle-information.location')}</span>*/}
                    {/*    <div className='d-block mt-0 pt-0 font-weight-bold '>*/}
                    {/*        <span className='text-capitalize'>*/}
                    {/*            {languageUtils.getCityFromDictionary(car, countries_d)}*/}
                    {/*            {', '}{languageUtils.getCountryFromDictionary(car, countries_d)}*/}
                    {/*        </span>*/}
                    {/*    </div>*/}
                    {/*    <div className='border-bottom' style={{width: '7em'}}/>*/}
                    {/*</li>*/}

                </ul>
            </div>
        </div>
        {/*</div>*/}

        <div id='price-block' className='row mt-3'>
            <div className='offset-6'/>
            {/*<div className='col-6 d-flex align-items-end justify-content-start'>*/}
            {/*    <span className='d-flex border rounded-pill px-2'*/}
            {/*          style={leaseStyle}*/}
            {/*          onMouseLeave={() => setLeaseStyle({background: 'none'})}*/}
            {/*          onMouseEnter={() => setLeaseStyle({background: '#f2f2f2'})}*/}
            {/*    >*/}
            {/*        {t('search.car-card.est')}{' '}{getApproximateLeasing(car.price).toLocaleString('ru')}/{*/}
            {/*        t('search.car-card.mo')*/}
            {/*    }*/}
            {/*    </span>*/}
            {/*    /!*<i className="fas fa-angle-down"></i>*!/*/}
            {/*</div>*/}
            <div className='col-6 d-flex justify-content-end' style={vehicleHeader}><span
                className='font-weight-bold'>{car.price.toLocaleString('ru')}
                {' '}{t('common.eur')}</span>
            </div>
        </div>
        <div id='brief-info-vat' className='row mt-0'>
            <div className='offset-6'/>
            <div className='col-6 d-flex justify-content-end' style={{...leaseStyle, color: '#888'}}><span
                className='font-weight-normal'>{vatString}{netPriceString}</span>
            </div>
        </div>

        <HorizontalBorder top={24} bottom={24}/>

        {/*SELLER MESSAGE BUTTON*/}
        <div id='button-message-to-seller' className='row mt-n2 cursor-pointer'>
            <div className='col-12'>
                <div className="w-100 d-flex justify-content-start"
                     style={{
                         height: '50px',
                         fontWeight: 'bold',
                         background: 'palegreen',
                         border: '1px solid yellowgreen'
                         // color: 'white'
                     }}
                     onClick={() => setShowContactForm(showContactForm === 'none' ? 'block' : 'none')}
                >
                    <div className='col-11 d-flex justify-content-center'>
                        <span className="d-flex align-items-center">
                            {t('vehicle-information.seller-info.ask-question')}
                        </span>
                    </div>
                    <div className='d-flex justify-content-center position-absolute'
                         style={{right: '1.5em'}}
                    >
                        {showContactForm === 'none' ? <img src="img/angle-down-solid-black.svg"
                                                           height="30px" alt='arrow down'
                                                           style={arrowStyle}
                        /> : <img src="img/angle-up-solid-black.svg"
                                  height="30px" alt='arrow up'
                                  style={arrowStyle}
                        />}
                    </div>
                </div>
            </div>
        </div>

        {/*CONTACT FORM*/}
        <div id='contact-form' className='' style={{display: showContactForm}}>
            <ContactForm car={car}/>
        </div>


        {/*SELLER INFO BUTTON */}
        <div id='button-seller-info' className='row my-3 cursor-pointer'>
            <div className='col'>
                <div className="bg-lightgrey w-100 d-flex justify-content-center"
                     style={{height: '50px', fontWeight: 'bold', border: '1px solid #ddd'}}
                     onClick={async () => {
                         setShowSellerInfo(!showSellerInfo);
                         const id = getContainerIDfromPath(car);
                         const sellerInfo = await connector.retrieveSellerInfo(id);

                         console.log('sellerInfo after rest', sellerInfo);
                         // console.log('JSON.parse(sellerInfo)',JSON.parse(sellerInfo));
                         setSellerInfo(sellerInfo);
                     }}
                >
                    <div className='col-11 d-flex justify-content-center'>
                        <span className="d-flex align-items-center">
                            {t('vehicle-information.seller-info.seller-details')}
                        </span>
                    </div>
                    <div className='d-flex justify-content-center position-absolute'
                         style={{right: '1.5em'}}
                    >
                        {!showSellerInfo ?
                            <img src="img/angle-down-solid-black.svg"
                                 height="30px" alt='arrow down'
                                 style={arrowStyle}
                            /> : <img src="img/angle-up-solid-black.svg"
                                      height="30px" alt='arrow up'
                                      style={arrowStyle}
                            />}
                    </div>
                </div>
            </div>
        </div>

        {/*SELLER INFO*/}
        {showSellerInfo && sellerInfo &&
            <SellerInfo sellerInfo={sellerInfo}/>}

    </div>
}

export default BriefInfoPanel;


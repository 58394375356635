import React, {useState} from 'react';
import {CloseButton, Modal} from "react-bootstrap";
import bank from "../img/bank.svg";
import {getPrice} from "../../functions/offersFunctions";
import paypal from '../img/PayPal.svg';
import stripe from '../img/stripe_logo.svg';
import {useMediaQuery} from "react-responsive";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import StripeCheckoutForm2 from "./StripeCheckoutForm/StripeCheckoutForm2";
import Paypal from "./PayPalButton/Paypal";
import {useTranslation} from "react-i18next";

const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const stripePromise = loadStripe(stripePublicKey);

const PaymentModal = props => {

    console.log('PaymentModal', props, 'stripe_pk:', stripePublicKey);

    const {t} = useTranslation(['texts']);

    const isSmallScreen = useMediaQuery({maxWidth: '321px'});
    let paypalButtonHeight = 40;
    if (isSmallScreen) paypalButtonHeight = 30;

    const [showPaymentOption, setShowPaymentOption] = useState(undefined);


    // useEffect(() => {
    // const loadPaypal = async () => {
    //     payPalButton = await loadScript({"client-id": "ASa4VhT5KxskpbIeeJwZGLHPqPq580Yv1t8mCxJ7rPWCbVXbCB-KbdybmqXp5DF-r7FEB_oePCHWu7E0"});
    // }
    // loadPaypal();

    // }, []);

    const amount = getPrice(props.upgradePeriod);
    // const stripe = getURL(amount);

    const appearance = {
        theme: 'stripe',
    };
    const options = {
        appearance
    };


    return <Modal show={props.show} onHide={props.handleClose} centered id='payment-modal'>
        <Modal.Header>
            <Modal.Title className='font-weight-bold text-uppercase d-flex w-100'
                         style={{fontSize: '0.75rem', color: '#555'}}>
                <div className='d-flex col justify-content-start align-items-center pl-0'>
                    <img src={bank} height={16} style={{marginRight: '1rem'}}/>
                    <span>{t('sell.car-ad-card.payment-modal.payment-options')}</span>
                </div>
                <CloseButton onClick={() => {
                    props.handleClose();
                    setShowPaymentOption(undefined);
                }}/>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{minHeight: '150px'}}
                    bsPrefix='modal-body d-flex align-items-center justify-content-center'>
            <div className='row w-100' style={{minHeight: '40px'}}>


                <div className='col-12 d-flex justify-content-center'>
                    {!showPaymentOption &&
                        <img className='col-6' src={stripe} height={45} style={{cursor: 'pointer'}}
                             onClick={() => setShowPaymentOption('stripe')}/>}
                    {!showPaymentOption &&
                        <img className='col-6' src={paypal} height={25} style={{marginTop: '13px', cursor: 'pointer'}}
                             onClick={() => setShowPaymentOption('paypal')}/>}
                </div>
                {showPaymentOption === 'stripe' && <div className='col-12 pl-1' style={{display: 'block'}}>
                    <Elements stripe={stripePromise} options={options}>
                        <StripeCheckoutForm2 amount={amount} car={props.car}/>
                    </Elements>
                </div>}

                {/**
                 *
                 * PayPal
                 *
                 * */}

                {showPaymentOption === 'paypal' && <Paypal amount={amount} car={props.car}/>}

            </div>
        </Modal.Body>
        <Modal.Footer>
            <div className='row w-100 d-flex justify-content-start'
                 style={{left: '1rem', opacity: '0.7'}}>
                <img className=''
                     onClick={() => {
                         props.setModals({upgrade: true, history: false, payment: false, top: false});
                         setShowPaymentOption(undefined);
                     }}
                     src='/img/angle-down-solid-black.svg'
                     height='24px'
                     style={{transform: 'rotate(90deg)'}}/></div>
        </Modal.Footer>
    </Modal>

}

export default PaymentModal;
import React from 'react';
import OptionSelect from "../../OptionSelect/OptionSelect";
import Heading from "../../../Search/SearchArea/Heading";
import {getDistinctElements} from "../../Utils/utils";

const InputFieldModel = props => {

    let numberClass = 'row mt-2 numbered-list-item'
    if (props.state.model === undefined && props.prevField !== undefined) numberClass += ' yellow';
    else if (props.prevField === undefined) numberClass += ' grey'

    return <div className={numberClass} id='row-model'>
        <div className='offset-1'/>
        <div className='col-9 pl-0'>
            {/*{console.log('models', props.state.models)}*/}
            <OptionSelect
                id='select-model'
                options={props.state.year !== undefined
                    && getDistinctElements(props.state.models, 'id')
                        .map(model => {
                            return {
                                label: model.name, value: model
                            }
                        })}
                selected={props.state.model !== undefined && props.state.model.name}
                handler={props.updateModel}
                isDisabled={props.prevField === undefined}
            >
                <div className='row d-flex align-items-center'>
                    <div className='col-auto'><Heading>{props.title}</Heading></div>
                    {/*<div className='col-auto ml-2'><InfoButton/></div>*/}
                </div>
            </OptionSelect>
        </div>
    </div>
}

export default InputFieldModel;
import React from 'react';

const HorizontalBorder = props => {
    // console.log('HorizontalBorder', props)
    let top, bottom;
    if (props.top !== undefined && props.top !== 0) top = props.top; else top = 0;
    if (props.bottom !== undefined && props.bottom !== 0) bottom = props.bottom; else bottom = 0;
    return <div id='horizontal-border'
                style={{
                    ...{
                        borderBottom: 'solid #eee 1px',
                        width: '100%',
                        marginBottom: `${bottom}px`,
                        marginTop: `${top}px`
                    },...props.style,

                }}/>
};

export default HorizontalBorder;

const getDistinctElements = (elements, attributeName) => {
    // console.log('getDistinctElements', elements, '; attributeName', attributeName);

    const map = elements.map(a => a[attributeName]);
    // console.log('first map', map);
    const set = new Set(map);
    // console.log('second set', set);
    const array = Array.from(set);
    // console.log('third array', array);
    const map2 = array.map(id => elements.find(a => a[attributeName] === id));
    // console.log('map2', map2);
    return map2;
};

const chooseStepNumber = (ok, nok, errorVariable) => errorVariable === undefined ? ok : nok;

const checkIfNotEmpty = (data, datasetName) => {
    console.log(`checkIfFetchedResults; typeof dataset '${datasetName}':`);
    if (data === undefined || data.length === 0) {
        console.warn(`Error fetching results from ${datasetName}`);
        return false;
    }
    return true;
}

const capitalize = modification => modification.charAt(0).toUpperCase()
    + modification.slice(1);


export {getDistinctElements, chooseStepNumber, checkIfNotEmpty, capitalize};
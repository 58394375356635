import React, {useState} from 'react';
import {CarSlider, VehicleInformation} from "./Components";
import {carHeader, vatStyle} from "../Search/CarListing/CarCard/CarCardMobilePortrait";
import {
    getCityFromDictionary,
    getCountryFromDictionary,
    getTitleFromDictionary
} from "../../Integration/Utils/LanguageUtils";
import {useTranslation} from "react-i18next";
import ContactSellerMobileBar from "./Components/ContactForm/ContactSellerMobileBar";
import ContactSellerModal from "./Components/ContactSellerModal/ContactSellerModal";
import {highlightStyle} from "../Search/CarListing/CarCard/HistoryLabel/HistoryLabel";
import {truncateWordsToLength} from "../Search/CarListing/CarCard/utils/stringUtils";

const CarPageMobilePortrait = props => {

    console.log('CarPageMobilePortrait', props);

    const {t} = useTranslation(['texts']);
    const fuel_d = t('extended-dictionary:fuels', {returnObjects: true});
    // const fuel_d = t('data-for-ui:fuel', {returnObjects: true});
    const transmission_d = t('data-for-ui:transmission', {returnObjects: true});
    const locations = t('locations:countries', {returnObjects: true});

    const carLocation = getCityFromDictionary(props.car, locations) + ', ' +
        getCountryFromDictionary(props.car, locations);

    const [showModal, setShowModal] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false);

    let vatElement = '';
    if (props.car.vatIncluded !== null) {
        // console.log('vatIncluded not empty', props.car.vatIncluded);
        if (props.car.vatIncluded === "true") vatElement = <span id='vat-span' style={vatStyle}>
            {t('texts:vehicle-information.vat')}</span>
    }

    const truncatedModification = truncateWordsToLength(props.car.modification, 33);

    return <div id='car-page-mobile-portrait' className='container-fluid px-0'>
        <CarSlider car={props.car}/>
        <div id='car-card-description-block-1' className='col-12 d-table pr-0 mb-2 pb-0'>
            <div className='d-table-row'>
                <div className='col-12 justify-content-between row align-items-start pr-0 mt-0'>
                    <div className='col-7 font-weight-bold mt-2 mb-0 px-0 d-flex align-items-center' style={carHeader}>
                        <span className="text-capitalize mr-1">{props.car.year}</span>
                        <span className="text-capitalize mr-0">{props.car.make}</span>
                        <span className="text-capitalize mr-2">{props.car.model}</span>
                    </div>
                    <div className='col-5 font-weight-bold mt-2 mb-n1 px-0 text-right d-flex align-items-center
                    justify-content-end' style={carHeader}>
                        €{props.car.price.toLocaleString('ru-RU')}{' '}{vatElement}</div>
                </div>
            </div>
            <div className='d-table-row'>
                <div className='justify-content-between row col-12 pr-0 mt-0'>
                    <div className='col-12 px-0 text-uppercase'>
                        <span style={{fontSize: '.82rem', color: '#555'}}>{truncatedModification}</span>
                    </div>
                </div>
            </div>
            <div className='d-table-row' style={{fontSize: '.88rem'}}>
                <div className='justify-content-between row col-12 pr-0 my-1'>
                    <div>
                        <span>{props.car.mileage.toLocaleString('ru-RU')}{' '}{t('search.car-card.km')}</span>
                    </div>
                    <div className=''><span
                        className='text-capitalize'>{getTitleFromDictionary(props.car.fuel, fuel_d)}</span>
                        <span
                            className='text-lowercase'>{', '}{props.car.power}{' '}
                            {t('sell.characteristics.hp')}</span>
                        {/*<span>{getTitleFromDictionary(props.car.transmission, transmission_d)}</span>*/}
                    </div>
                </div>
            </div>

            <div className='d-table-row' style={{fontSize: '.88rem'}}>
                <div className='justify-content-between row col-12 pr-0 align-items-center'>
                    <div className='text-capitalize'>
                        <span className='justify-content-start'>{carLocation.toString()}</span>
                    </div>

                    <div>    {props.car.history !== undefined && props.car.history.length > 0 ?
                        <div style={{fontWeight: '300'}} className='mt-0 mb-1'>
                            {props.car.history.indexOf('no-accident') !== -1
                                ? <span style={highlightStyle}>{t('search.search-area.no-accident')}</span>
                                : <span style={highlightStyle}>{t('search.search-area.history')}</span>}
                        </div> : null}</div>
                </div>
            </div>
        </div>
        <div className='col-12 d-table' style={{marginBottom: '39px'}}>
            <VehicleInformation car={props.car} mainTextStyle={{fontSize: '.88rem'}}
                                headerTextStyle={{fontSize: '1rem'}}/>
        </div>
        <div className=''>
            <ContactSellerMobileBar setShow={setShowModal}/>
        </div>

        <ContactSellerModal showModal={showModal} setShowModal={setShowModal} car={props.car}/>

    </div>
}

export default CarPageMobilePortrait;
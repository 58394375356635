import _ from "lodash";

const getActiveFilters = options => {
    if (_.isEmpty(options)) return 0;
    else {
        const activeFilters = Object.values(options).filter(e => e !== undefined && e.visible === true).length
            + Object.values(options).filter(e => Array.isArray(e) && e.length > 0).length;
        console.log('activeFilters', activeFilters);
        return activeFilters;
    }
}

export {getActiveFilters}
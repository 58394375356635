const initialOptions = { //options=search criteria
    "price": {"value": [0, 90000], "visible": false},
    "year": {"value": [1980, 2023], "visible": false},
    "bodyStyle": {"value": "any", "visible": false},
    "make": {"value": "any", "id": 0, "visible": false},
    "model": {"value": "any", "id": 0, "visible": false},
    "modification": {"value": "any", "id": '0', "visible": false},
    "fuel": {"value": "any", "visible": false},
    "features": [],
    "history": [],
    "drivetrain": [],
    "mileage": {"value": [0, 300000], "visible": false},
    "transmission": {"value": "any", "visible": false},
    "power": {"value": [50, 500], "visible": false},
    "color": [],
    "country": {"value": "any", "visible": false},
    "city": {"value": "any", "visible": false},
    "searchResults": [],
    "vatIncluded": {"value": "any", "visible": false},
    // "ignoreSponsored": {"value": "any", "visible": false}
    "ignoreSponsored": {"value": "false", "visible": false}
};

const initialModals = {
    "location": false,
    "make": false,
    "bodyStyle": false,
    "model": false,
    "search": false
};

const initialFiltersOption = false;

const initialSort = "price-desc";
// const initialSort = "price-desc";
// const initialSort = "undefined";
const selectedCar = "";

export {initialModals, initialOptions, initialFiltersOption, initialSort, selectedCar};
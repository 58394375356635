import _ from 'lodash';

const privateKeys = ['name', 'type', 'phone'];
const companyKeys = ['name', 'type', 'registrationNumber', 'companyAddress', 'phone', 'website'];

// const isLetter = character => {
//     let b = /[a-zA-Z]/i.test(character);
//     console.log('isLetter', character, b);
//     return b
// }

// const validatePhone = phone => {
//     if (typeof phone !== "undefined") {
//
//         const pattern = new RegExp(/^[0-9\b]+$/);
//         if (!pattern.test(phone)) {
//             return "Please enter only numbers";
//         } else if (phone.length > 12 || phone.length < 5) {
//             return "Please enter valid phone number.";
//         }
//     }
// }

const validatePrivateInfo = (type, name, phone, t) => {
    console.log('validatePrivateInfo', name, phone, type, t);

    const errorObject = {};
    if (_.isEmpty(t)) throw new Error('t is empty');

    if (_.isEmpty(name) || name.length < 3 || name.length > 15) {
        errorObject['nameStatus'] = t('errors:my-account.name-not-ok');
        //'name too short';
    }

    const regExp = /[a-zA-Zа-яА-Я]/i;
    if (!_.isEmpty(phone)) {
        console.log('regex', regExp.test(phone))
        if (regExp.test(phone) || (phone.length < 5 || phone.length > 18)) {
            errorObject['phoneStatus'] = t('errors:my-account.phone-not-ok');
            //'Phone number out of range or contains letters';
        }
    }

    if (_.isEmpty(type) || type !== 'private') {
        errorObject['typeStatus'] = t('errors:my-account.seller-type-not-private');
        //'Seller type not private';
    }
    return errorObject;
}

const validateCompanyInfo = (type, name, phone, regNumber, address, url, translate) => {
    console.log('validateCompanyInfo', 'name', name, 'phone', phone, 'type', type,
        'regNum', regNumber, 'address', address, 'url', url);

    const urlRegex = new RegExp('[-a-zA-Z0-9@:%._\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)');
    console.log('isInteger', regNumber, _.isInteger(regNumber), 'isNumber', _.isNumber(regNumber))

    const errorObject = {};

    if (name.length < 3 || name.length > 15) {
        errorObject['nameStatus'] = translate('errors:my-account.name-not-ok')//'Name too short or too long
    }

    const regExp = /[a-zA-Zа-яА-Я]/i;
    if (!_.isEmpty(phone)) {
        console.log('regex', regExp.test(phone))
        if (regExp.test(phone) || (phone.length < 5 || phone.length > 18)) {
            errorObject['phoneStatus'] = translate('errors:my-account.phone-not-ok')// 'Phone number out of range or contains letters';
        }
    } else {
        errorObject['phoneStatus'] = translate('errors:my-account.phone-empty') //'Phone empty'
    }

    if (type !== 'company') {
        errorObject['typeStatus'] = translate('errors:my-account.seller-type-not-company')//'Seller type is not company';
    }

    if (_.isEmpty(address) || address.length < 3 || address.length > 80) {
        errorObject['companyAddress'] = translate('errors:my-account.company-address-not-ok')
        // '//'Company address is empty or not within a range';
    }

    if (_.isEmpty(regNumber) || regExp.test(regNumber) || regNumber.length > 20 || regNumber.length < 5) {
        errorObject['registrationNumber'] = translate('errors:my-account.company-reg-number-not-ok')
        // 'Company registration number is not a number or not within a range'
    }

    if (!_.isEmpty(url) && (url.length > 40 || !urlRegex.test(url))) {
        console.log('isURL', url, urlRegex.test(url));

        errorObject['website'] = translate('errors:my-account.company-website-not-ok');// 'Website is not matching a url pattern'
    }
    return errorObject;
}

function populateInfoWithUpdatedValues(localInfoFields, sellerInfo, keys) {
    const n = Object.keys(localInfoFields)
        .filter(k => localInfoFields[k] !== undefined || !_.isEmpty(localInfoFields[k]))
        .map(k => {
            return {[k]: localInfoFields[k]}
        });
    console.log('populate with updated values\nnew', n);
    const nn = Object.assign({}, ...n);
    console.log('flat n', nn);
    console.log('Object.keys(sellerInfo)', Object.keys(sellerInfo));
    const final = keys.map(k => {
        if (nn[k] !== undefined) return {[k]: nn[k]};
        return {[k]: sellerInfo[k]};
    });
    console.log('final', final);
    const ff = Object.assign({}, ...final);
    console.log('ff', ff);
    return ff;
}

// let submitButtonStyle = {
//     border: '1px solid green',
//     width: '150px',
//     height: '28px',
//     background: 'lawngreen',
//     alignItems: 'center',
//     justifyContent: 'center',
//     display: 'flex',
//     fontSize: '0.8rem'
// };

export {
    validatePrivateInfo, validateCompanyInfo, populateInfoWithUpdatedValues,
    privateKeys, companyKeys
};
import React from 'react';
import {useTranslation} from "react-i18next";

const HistoryLabel = props => {
    const {t} = useTranslation(['texts']);

    return <div id='history-label'> {props.history !== undefined && props.history.length > 0 ?
        <div style={{fontWeight: 300}}>
            {props.history.indexOf('no-accident') !== -1
                ? <span style={highlightStyle}>{t('search.search-area.no-accident')}</span>
                : <span style={highlightStyle}>{t('search.search-area.history')}</span>}
        </div> : null}
    </div>
}

export default HistoryLabel;

export const highlightStyle = {
    background: 'rgba(238,238,238,.4)',
    borderRadius: '1px',
    fontSize: '13px',
    color: '#555',
    height: '20px',
    border: '1px solid #ccc',
    padding: '0 10px',
    whiteSpace: 'nowrap'
}
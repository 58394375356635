import React from 'react';
import ShowFiltersButton from './ShowFiltersButton/ShowFiltersButton';
import Heading from './Heading';
import {
    SearchOptionMultiNew, SearchOptionMultiColor, LocationOptionSelect2, RangeSlider2,
    SearchOptionModal2, SearchOptionSelectNew2
} from './SearchOption';
import {initialOptions} from '../../../Data/State/initialState.js';
import {ModalByMake, ModalByModel} from '../../../Shared/Modals';
import {useTranslation} from "react-i18next";
import SearchOptionSingle from "./SearchOption/SearchOptionSingle";

const SearchArea = props => {
    console.log('SearchArea props', props);

    const {t} = useTranslation(["texts", "data-for-ui", "locations"]);
    const fuel = t('data-for-ui:fuel', {returnObjects: true});
    const transmission = t('data-for-ui:transmission', {returnObjects: true});
    const drivetrain = t('data-for-ui:drivetrain', {returnObjects: true});
    const features = t('data-for-ui:features', {returnObjects: true});
    const bodyStyle = t('data-for-ui:bodystyle', {returnObjects: true});
    const extColor = t('data-for-ui:extColor', {returnObjects: true});
    const countries = t('locations:countries', {returnObjects: true});
    const history = t('data-for-ui:history', {returnObjects: true});
    const vat_d = t('data-for-ui:vat-included', {returnObjects: true});
    const sponsored_d = t('data-for-ui:sponsored', {returnObjects: true});
    console.log('SearchArea dictionaries:\nfuel', fuel, 'transmission', transmission, 'drivetrain',
        drivetrain, 'features', features, 'extColor', extColor, 'countries', countries, 'history',
        history, 'vat_d', vat_d, 'sponsored_d', sponsored_d);


    const selectHandlerNew = (type, value, title) => {
        props.updateOptions(type, {"value": value, visible: true, "title": title});
    };

    const openModal = modalName => props.updateModals(modalName, true);
    const closeModal = modalName => props.updateModals(modalName, false);

    const switchMakeAndModel = async make => {
        await props.updateMake(make);
        props.updateMultipleModals({make: false, model: true});
    };
    const switchModelAndMake = () => {
        props.updateMultipleModals({make: true, model: false});
    }

    const changeRangeSlider = (e, type) => {
        props.updateOptions(type, {value: e, visible: true});
    };

    return (
        <div id='search-area-div' className='col-4 mr-2' style={styles.columnDiv}>
            <div className='row justify-content-start d-flex mb-0' style={styles.headingRow}>

                <RangeSlider2
                    type='price'
                    data={props.options}
                    limits={[...initialOptions.price.value]}
                    changeRangeSlider={changeRangeSlider}
                    step={1000}
                    bsClass={'mt-2'}
                    title={t('search.search-area.price-range')}
                    rowStyle={{marginBottom: '-15px'}}
                />
                <RangeSlider2
                    type='year'
                    data={props.options}
                    limits={[...initialOptions.year.value]}
                    changeRangeSlider={changeRangeSlider}
                    step={1}
                    bsClass={'mt-n1'}
                    rowStyle={{marginBottom: '-15px'}}
                    title={t('search.search-area.year-range')}
                />
                <SearchOptionModal2
                    type='make-model'
                    handler={() => openModal('make')}
                    options={props.options}
                    bsClass={'mt-n1'}
                    title={t('search.search-area.make-model')}
                />
                <SearchOptionSelectNew2
                    type='fuel'
                    handler={selectHandlerNew}
                    state={props}
                    data={fuel}
                    bsClass={'mt-3'}
                    defaultTitle={t('search.search-area.fuel').toUpperCase()}
                />
                <SearchOptionMultiNew
                    options={props.options}
                    updateHandler={props.updateOptions}
                    section={'history'}
                    bsClass={'mt-2'}
                    data={history}>
                    <Heading>
                        {t('search.search-area.history')}
                    </Heading>
                </SearchOptionMultiNew>
                <SearchOptionSingle
                    options={props.options}
                    updateHandler={props.updateOptions}
                    section={'vatIncluded'}
                    bsClass={'mt-2'}
                    data={vat_d}>
                    <Heading>
                        {t('texts:vehicle-information.vat')}
                    </Heading>
                </SearchOptionSingle>

                <RangeSlider2
                    type='mileage'
                    data={props.options}
                    limits={[...initialOptions.mileage.value]}
                    changeRangeSlider={changeRangeSlider}
                    step={10000}
                    bsClass={'mt-3'}
                    title={t('search.search-area.mileage')}
                />

                <SearchOptionSingle
                    options={props.options}
                    updateHandler={props.updateOptions}
                    section={'ignoreSponsored'}
                    bsClass={'mt-n2 mb-3'}
                    data={sponsored_d}>
                    <Heading>
                        {t('texts:vehicle-information.sponsored')}
                    </Heading>
                </SearchOptionSingle>


                <ShowFiltersButton
                    id={'showFilterButton1'}
                    show={props.filters}
                    handler={props.updateFilters}
                >
                    {t('search.search-area.show-more-filters')}
                </ShowFiltersButton>
            </div>
            <div id='extra-filters'
                 style={{display: !props.filters && 'none'}}>
                <div className={`row justify-content-start d-flex mb-2`}>


                    <SearchOptionSelectNew2
                        type='transmission'
                        handler={selectHandlerNew}
                        state={props}
                        data={transmission}
                        bsClass={'mt-n1'}
                        defaultTitle={t('search.search-area.transmission').toUpperCase()}
                    />

                    <SearchOptionSelectNew2
                        type='drivetrain'
                        handler={selectHandlerNew}
                        state={props}
                        data={drivetrain}
                        bsClass={'mt-3'}
                        defaultTitle={t('search.search-area.drivetrain').toUpperCase()}
                    />

                    <RangeSlider2
                        type='power'
                        data={props.options}
                        limits={[...initialOptions.power.value]}
                        changeRangeSlider={changeRangeSlider}
                        step={25}
                        bsClass={'mt-3'}
                        rowStyle={{marginBottom: '-15px'}}
                        title={t('search.search-area.power')}
                    />

                    <LocationOptionSelect2
                        countries={countries}
                        country={props.options.country}
                        countryTitle={t('search.search-area.country')}
                        updateOptions={props.updateMultipleOptions}
                        updateCities={props.updateCities}
                        cityTitle={t('search.search-area.city')}
                        city={props.options.city}
                    />

                    {/**Temporarily disabling bodyStyle*/}

                    {/*<SearchOptionSelectNew2*/}
                    {/*    type='bodyStyle'*/}
                    {/*    handler={selectHandlerNew}*/}
                    {/*    state={props}*/}
                    {/*    data={bodyStyle}*/}
                    {/*    bsClass={'mt-3'}*/}
                    {/*    defaultTitle={t('search.search-area.body-style').toUpperCase()}*/}
                    {/*/>*/}

                    <SearchOptionMultiNew
                        options={props.options}
                        updateHandler={props.updateOptions}
                        section={'features'}
                        bsClass={'mt-3'}
                        data={features}>
                        <Heading>
                            {t('search.search-area.features')}
                        </Heading>
                    </SearchOptionMultiNew>

                    <SearchOptionMultiColor
                        options={props.options}
                        updateHandler={props.updateOptions}
                        section={'color'}
                        bsClass={'mt-3 container'}
                        data={extColor}
                    >
                        <Heading>{t('search.search-area.color')}</Heading>
                    </SearchOptionMultiColor>

                    <ShowFiltersButton show={!props.filters}
                                       handler={props.updateFilters}
                                       id={'showFiltersButton2'}
                    >
                        {t('search.search-area.show-less-filters')}
                    </ShowFiltersButton>
                </div>
            </div>

            <ModalByMake show={props.modals.make}
                         handleClose={() => props.updateMultipleModals({make: false})}
                         handleSwitch={switchMakeAndModel}
                         makes={props.makes}
                         make={props.options.make}
                         updateOptions={props.updateMultipleOptionsNoAppend}
                         updateMultipleModals={props.updateMultipleModals}
                         isMobilePortrait={props.isMobilePortrait}
            />
            <ModalByModel show={props.modals.model}
                          handleClose={() => props.updateMultipleModals({model: false})}
                          options={props.options}
                          updateHandler={props.updateMultipleOptions}
                          models={props.models}
                          model={props.model}
                          handleSwitch={switchModelAndMake}
                          updateMultipleModals={props.updateMultipleModals}
                          isMobilePortrait={props.isMobilePortrait}
            />
        </div>
    );
};


const styles = {
    columnDiv: {background: 'none', maxWidth: '335px'},
    headingRow: {lineHeight: '30px', verticalAlign: 'middle'},
};

export default SearchArea

import React, {useEffect} from 'react';
import InputFieldFuel from "../../../../Fields/Fuel/InputFieldFuel";
import InputFieldPower from "../../../../Fields/Power/InputFieldPower";
import InputFieldTransmission from "../../../../Fields/Transmission/InputFieldTransmission";
import InputFieldDrivetrain from "../../../../Fields/Drivetrain/InputFieldDrivetrain";
import InputFieldDisplacement from "../../../../Fields/Displacement/InputFieldDisplacement";
import {useTranslation} from "react-i18next";
import _ from "lodash";

const SemiAutomaticFlow = props => {
    // console.log('SemiAutomaticFlow props', props);

    const {t} = useTranslation(["data-for-ui", "texts"]);
    const drivetrain = t('data-for-ui:drivetrain', {returnObjects: true});
    const fuel = t('data-for-ui:fuel', {returnObjects: true});
    const transmission = t('data-for-ui:transmission', {returnObjects: true});


    useEffect(() => {
        console.log('SemiAutomaticFlow useEffect [props]', 'props.state.fuel || props.state.drivetrain || props.state.transmission]',
            props.state.fuel || props.state.drivetrain || props.state.transmission, 'entrymode', props.state.entryMode);

        if (!_.isEmpty(props.state.fuel) && !_.isEmpty(props.state.drivetrain) &&
            !_.isEmpty(props.state.transmission) && !_.isEmpty(props.state.power) && !_.isEmpty(props.state.displacement)
            && !_.isEmpty(props.state.characteristicsError)) {
            //clearing characteristics error
            console.log('SemiAutomaticFlow clear characteristics error');
            props.sellFunctions.updateCharacteristicsError(undefined);
        }
        if (props.state.entryMode === 'automatic') {
            console.log('SemiAutomaticFlow useEffect [props]', 'props.state.entryMode === automatic');
            props.sellFunctions.updateEntryMode('semi-automatic');
        }

    }, [props.state.fuel, props.state.drivetrain, props.state.transmission, props.state.displacement, props.state.power]);


    return <div id='semi-automatic-flow'>
        <InputFieldFuel
            fuels={fuel}
            title={t('texts:sell.input-fields.fuel')}
            prevField={props.state.modification}
            updateFuel={props.sellFunctions.updateSellFuel}
            state={props.state}/>


        <InputFieldPower
            title={t('texts:sell.input-fields.power')}
            prevField={props.state.fuel}
            updatePower={props.sellFunctions.updateSellPower}
            state={props.state}/>


        <InputFieldTransmission
            transmissions={transmission}
            title={t('texts:sell.input-fields.transmission')}
            prevField={props.state.power}
            updateTransmission={props.sellFunctions.updateSellTransmission}
            state={props.state}/>


        <InputFieldDrivetrain
            title={t('texts:sell.input-fields.drivetrain')}
            prevField={props.state.transmission}
            state={props.state}
            updateDrivetrain={props.sellFunctions.updateSellDrivetrain}
            drivetrains={drivetrain}
        />


        <InputFieldDisplacement
            title={t('texts:sell.input-fields.displacement')}
            prevField={props.state.drivetrain}
            updateDisplacement={props.sellFunctions.updateSellDisplacement}
            state={props.state}/>
    </div>
}
export default SemiAutomaticFlow;
import React from 'react';
import {CloseButton, Modal} from "react-bootstrap";
import upgradeImage from '../../../../../Search/CarListing/CarCard/SponsoredLabel/img/icon-upgrade.png';
import {useTranslation} from "react-i18next";
import {useMediaQuery} from "react-responsive";
import {buyButtonStyle, getMainFontStyle, getPrice} from "../../functions/offersFunctions";
import Slider from 'rc-slider';

const UpgradeModalNew = props => {

    const {t} = useTranslation(['texts']);
    const isBigScreen = useMediaQuery({minWidth: '600px'});
    const mainFontStyle = getMainFontStyle(isBigScreen);


    const periods = [0, 28];
    const marks = {
        7: '1w',
        14: '2w',
        21: '3w',
        28: '4w',
    };

    const price = getPrice(props.upgradePeriod);


    return <Modal key='upgrade-modal' show={props.show}
                  id='upgrade-modal'
                  onHide={() => props.handleClose()} centered>
        <Modal.Header>
            <Modal.Title
                className='font-weight-bold text-uppercase d-flex w-100'
                style={{fontSize: '0.75rem', color: '#555'}}>
                <div className='d-flex col justify-content-start align-items-center pl-0'>
                    <img src={upgradeImage} height={22} style={{marginRight: '.5rem'}}/>
                    {/*<img src={podium} height={16} style={{marginRight: '1rem'}}/>*/}
                    {t('sell.car-ad-card.upgrade-modal.upgrade-offering')}
                </div>
                <CloseButton onClick={() => props.handleClose()}/>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className='container-fluid px-0'>
                <div className='row' style={mainFontStyle}>
                    <div className='col-12'>
                        <p>
                            {t('sell.car-ad-card.upgrade-modal.want-to-sell-faster')}
                        </p>
                    </div>
                </div>
                <div className='row mt-2 mb-3' style={mainFontStyle}>
                    <div className='col-12'>
                        <p>{t('sell.car-ad-card.upgrade-modal.choose-promotional-period')}
                            <span className='text-capitalize font-weight-bold'>
                            {' '}{props.car.make} {props.car.model}</span>
                        </p>
                        <div className='d-flex justify-content-between'>
                            <div className=''>
                                <b>{t('sell.car-ad-card.upgrade-modal.upgrade-period')} {props.upgradePeriod}
                                    {t('sell.car-ad-card.upgrade-modal.days')}</b>
                            </div>
                            <div className={props.upgradePeriod === 0 ? 'd-none' : ' text-capitalize'}>
                                <b>{t('common.price')}: {price}</b>
                            </div>
                        </div>

                        <div className='col-12 pl-0 mr-1'>
                            <Slider
                                min={periods[0]}
                                max={periods[1]}
                                defaultValue={7}
                                step={7}
                                trackStyle={{backgroundColor: '#FF8C00'}}
                                railStyle={{backgroundColor: '#ccc'}}
                                marks={marks}
                                value={props.upgradePeriod}
                                onChange={props.setUpgradePeriod}
                            />
                        </div>
                    </div>

                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            {/**
             *Toggle: Buy and History
             **/}
            <div className='d-flex justify-content-between px-0 w-100'>
                <div
                    onClick={() => props.setModals({upgrade: false, history: false, payment: true, top: false})}
                    className=' d-flex justify-content-center align-items-center'
                    style={buyButtonStyle}>
                <span className=' d-flex align-items-center'>
                    {t('sell.car-ad-card.upgrade-modal.pay')}</span>
                </div>
                <div onClick={() => {
                    props.setModals({upgrade: false, history: true, payment: false, top: false});
                    props.refreshUpgradeOrders();
                }}
                     className=' d-flex justify-content-center align-items-center mr-0'
                     style={{...buyButtonStyle, color: '#888', border: '1px solid #888'}}>
                <span className=' d-flex align-items-center'>
                    {t('sell.car-ad-card.upgrade-modal.history')}</span>
                </div>
            </div>
        </Modal.Footer>


    </Modal>
}

export default UpgradeModalNew;
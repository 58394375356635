import React, {useEffect, useState} from 'react';
import './css/footer.css';
import {useTranslation} from "react-i18next";
import {updateFontFamily} from "../helpers/fontHelper";
import {Link} from "react-router-dom";
import {useMediaQuery} from "react-responsive";
import {LanguageModal} from "../Modals";
import {LanguageSelector} from "../Language/LanguageSelector";

const Footer2 = props => {

    const {t, i18n} = useTranslation(['support', 'texts']);
    const [showLanguageModal, setShowLanguageModal] = useState(false);
    const closeLanguageModal = () => setShowLanguageModal(false);
    const [languageCode, setLanguageCode] = useState(i18n.language);
    const changeLanguage = code => {
        console.log('Footer2 changeLanguage', code);
        i18n.changeLanguage(code);
        updateFontFamily(code);
    };

    useEffect(() => {
        setLanguageCode(i18n.language);
    }, [i18n.language]);

    const isSmallScreen = useMediaQuery({maxWidth: '535px'});

    let visionDivClass = 'col-5';
    let containerClass = 'container pt-2 pb-0';
    if (isSmallScreen) {
        visionDivClass = 'col-auto';
        containerClass = 'container pt-2 pb-0 px-0'
    }
    let footerContainerClass = 'container-fluid mt-5';

    console.log('Footer props', props);

    return <div id='footer'
                className={footerContainerClass}
                style={{
                    color: 'white',
                    minHeight: '260px',
                    fontSize: '0.75rem',
                    backgroundColor: '#333',
                    flex: 1 //added for sticking of footer to the bottom
                }}>
        <div className={containerClass}>
            <div className='row mt-4'>
                <div className='col mr-3 '>
                <span className='text-uppercase font-weight-bold'>
                    {t('texts:footer.company')}
                </span>
                    <ul className='list-unstyled mt-2'>

                        <li className='footer-link'>
                            <Link to='/company/about'>{t('texts:footer.about')}</Link>
                        </li>
                        <li className='footer-link'>
                            <Link to='/company/legal'>{t('texts:footer.legal')}</Link>
                        </li>
                        <li className='footer-link'>
                            <Link to='/company/protection'>{t('texts:footer.data-protection')}</Link>
                        </li>
                        <li className='footer-link'>
                            <Link to='/company/contact'>{t('texts:footer.contact')}</Link>
                        </li>
                    </ul>
                </div>
                <div className='col mr-3 '>
                <span className='text-uppercase font-weight-bold'>
                    {t('support.support')}
                </span>
                    <ul className='list-unstyled mt-2 text-firstletter'>
                        <li className='footer-link'>
                            <Link to={'/support/how-to-search'}>
                                {t('support.search.how-to-search')}
                            </Link>
                        </li>
                        <li className='footer-link'>
                            <Link to={'/support/how-to-sell'}> {t('support.sell.how-to-sell')}</Link>
                        </li>
                        <li className='footer-link'>
                            <Link to={'/support/how-to-partner'}>{t('support.sell.how-to-partner')}</Link>
                        </li>
                        <li className='footer-link'>
                            <Link to={'/support/sitemap'}>{t('support.sell.sitemap')}</Link>
                        </li>
                    </ul>
                </div>
                <div className='col mr-3 '>
                <span className='text-uppercase font-weight-bold'>
                    {t('texts:footer.social-media')}
                </span>
                    <ul className='list-unstyled mt-2'>
                        <li className='footer-link'>
                            <a href="https://www.facebook.com/caruniverse.ee" target="_blank"
                               rel="noopener noreferrer">
                                Facebook
                            </a>
                        </li>

                        <li className='footer-link'>
                            <a href="https://www.instagram.com/caruniverse.ee/" target="_blank"
                               rel="noopener noreferrer">
                                Instagram
                            </a></li>
                    </ul>
                </div>
                <div className={visionDivClass}>
                <span className='text-uppercase font-weight-bold'>
                    {t('texts:footer.vision')}
                </span>
                    <ul className='list-unstyled mt-2'>
                        <li>{t('texts:footer.vision-text')}</li>
                    </ul>
                </div>
            </div>
            <LanguageSelector
                setShowLanguageModal={setShowLanguageModal}
                languageCode={languageCode}
                isFooter={true}
            />
            <div className='pb-2'/>

            <div className='offset-10'/>
        </div>

        <LanguageModal show={showLanguageModal}
                       handleClose={closeLanguageModal}
                       changeLanguage={changeLanguage}/>
    </div>
}
export default Footer2;
import _ from 'lodash';

export const truncateWordsToLength = (string, length) => {

    let words = string.split(' ');
    // if (words.length > 0) { //in case there is more than one word
    while (words.join(' ').length > length && words.length > 0) {
        words = _.dropRight(words);
    }

    console.log('truncateWordsToLength', string, 'actual length', length, words);
    return words.join(' ');
}
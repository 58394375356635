import React, {useCallback, useEffect, useState} from 'react';
import {DndProvider, useDrag, useDrop} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {Button, ButtonGroup, ButtonToolbar, Col, Container, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {FetchImage} from "../../../../../../../Shared/FetchImage/FetchImage";
import {translateErrorMessage, validateFiles} from "../../../../../../../Integration/Utils/StorageUtils";
import {ErrorMessageBlock} from "../../../../MyAccount/Components/ErrorMessageBlock";
import _ from "lodash";

export const EditableImages2 = ({images: initialImages, setImages: initialSetImages}) => {
    const [images, setImages] = useState(initialImages || []);

    const moveItem = useCallback((dragIndex, hoverIndex) => {
        const newImages = [...images];
        const dragItem = newImages[dragIndex];
        newImages.splice(dragIndex, 1);
        newImages.splice(hoverIndex, 0, dragItem);
        setImages(newImages);
        initialSetImages(newImages); //update the parent state
    }, [images, setImages, initialSetImages]);

    const deleteItem = useCallback(index => {
        const newImages = [...images];
        newImages.splice(index, 1);
        setImages(newImages);
        initialSetImages(newImages); //update the parent state
    }, [images, setImages]);

    return (
        <DndProvider backend={HTML5Backend}>
            <div id='editable-images-list-container'
                 className='container-fluid px-0'>
                <ul className='list-unstyled'>
                    {images.map((image, i) => (
                        <DraggableItem
                            key={'image-' + i}
                            index={i}
                            id={'image-' + i}
                            value={image}
                            moveItem={moveItem}
                            deleteItem={deleteItem}
                            buttonStyle={{fontSize: '0.9rem'}}
                        />
                    ))}
                </ul>
            </div>
        </DndProvider>
    );
};

const DraggableItem = ({id, index, moveItem, deleteItem, value, file, buttonStyle}) => {

    const {t} = useTranslation(['texts', 'errors']);

    const upText = t('sell.car-ad-card.edit-images.move-up');
    const downText = t('sell.car-ad-card.edit-images.move-down');
    const deleteText = t('sell.car-ad-card.edit-images.delete');

    const [fileSize, setFileSize] = useState(0);
    const [errors, setErrors] = useState('');

    const [{isDragging}, drag] = useDrag({
        type: "image",
        item: {id, index},
        collect: monitor => ({
            isDragging: monitor.isDragging()
        })
    });

    const [, drop] = useDrop({
        accept: "image",
        hover(item, monitor) {
            if (item.index !== index) {
                moveItem(item.index, index);
                item.index = index;
            }
        }
    });

    useEffect(() => {
        console.log('useEffect in EditableImages2: file changed');

        const runValidateFiles = async () => {
            const errors = await validateFiles([file]);
            console.log('useEffect in EditableImages2, runValidateFiles: ', errors);

            const translatedErrors = _.map(errors, fileError => {
                const tr = translateErrorMessage(fileError.status, t);
                console.log('useEffect in EditableImages2, fileError.status: ', tr);
                return tr;
            })
            setErrors(translatedErrors);
            // console.log('useEffect in EditableImages2, translatedErrors: ', tr2);

        }

        if (file !== undefined && file.size !== undefined) {
            console.log('useEffect in EditableImages2, file not empty: ', file);

            setFileSize(file.size);

            runValidateFiles();
        }

    }, [file]);

    /**Handing new file upload*/

    return (
        <Container
            key={id}
            className='my-3 px-0'
            fluid={true}
            ref={node => drag(drop(node))}
            style={{
                opacity: isDragging ? 0 : 1,
                borderBottom: '1px solid #ddd',
                borderTop: '1px solid #ddd'
            }}>
            <Container>
                <div id='editable-image-row' className='row'>
                    <div className='col-8'>
                        <div style={{maxHeight: '250px', maxWidth: '250px'}}>
                            {/*<div style={{height: '200px', overflow: 'hidden'}}>*/}
                            <FetchImage src={value} className='w-100'
                                        style={{
                                            objectFit: 'cover',
                                            objectPosition: 'center',
                                            height: '100%',
                                            width: '100%',
                                            maxHeight: '250px',
                                            cursor: 'grab'
                                        }}/>
                        </div>
                    </div>
                    <div className='col-4 w-100 d-flex justify-content-end align-items-center pl-0'>
                        <ButtonToolbar aria-label="Toolbar with button groups">
                            <ButtonGroup className="mr-2 d-flex flex-column" aria-label="image control" size="sm">
                                <Button variant='link mt-0 mb-1 text-left pt-0'
                                        style={buttonStyle}
                                        onClick={() => {
                                            moveItem(index, index - 1);
                                        }}>
                                    {upText}
                                    {/*<img src={upArrow}/>*/}
                                </Button>
                                <Button variant='link mb-1 text-left' style={buttonStyle}
                                        onClick={() => {
                                            moveItem(index, index + 1);
                                        }}>
                                    {downText}
                                    {/*<img src={downArrow}/>*/}
                                </Button>
                                <Button variant='link mb-1 text-left' style={buttonStyle}
                                        onClick={() => {
                                            deleteItem(index);
                                        }}>{deleteText}
                                </Button>
                            </ButtonGroup>
                        </ButtonToolbar>
                    </div>
                </div>

                {/**Handing new file upload*/}

                {errors.length > 0 && <Row className='mt-n4 pt-0' style={{fontSize: '12px', color: 'red'}}>
                    <ErrorMessageBlock errorMessage={errors}/>
                </Row>}
                {fileSize > 0 &&
                    <Row className='pb-1' style={{fontSize: '12px'}}>
                        <Col className='text-left'>
                            <div>
                                {/*<div style={{fontSize: '12px', color: errors ? 'red' : 'inherit'}}>*/}
                                <span
                                    className='font-weight'>{t('texts:sell.car-ad-card.edit-images.file-size')}
                                    {Math.round(fileSize / 1024)} KB</span>
                            </div>
                        </Col>
                    </Row>
                }
            </Container>
        </Container>
    );
};
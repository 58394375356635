import React from 'react';

export const SellerInfoEditButton = ({
                                         k,
                                         localInfoFields,
                                         showInput,
                                         setShowInput,
                                         props,
                                         setErrorMessage,
                                         setLocalInfoFields,
                                     }) => {


    console.log('SellerInfoEditButton k', k, 'localInfoFields', localInfoFields, 'props', props);

    const handleEdit = () => {

        // console.log('SellerInfoEditButton handleEdit k', k, 'localInfoFields', localInfoFields, 'props', props, 'k', k);

        if (showInput[k] === true) {
            const copy = {...showInput};
            delete copy[k];
            if (k === 'type') {
                delete localInfoFields[k];
                // setFontStyle({}); // reset font color
            }
            console.log('SellerInfoEditButton close:', k, copy)
            setShowInput(copy);
            setErrorMessage({}); // reset error message
            // setLocalInfoFields({}); // reset localInfoFields
        } else {
            const copy = {...showInput};
            copy[k] = true;
            setShowInput(copy);
            console.log('SellerInfoEditButton open', k, copy);
        }
    };

    return (
        <img
            id={'edit-button-' + k}
            height='17px'
            width='17px'
            className='ml-3 edit-icon'
            onClick={handleEdit}
        />
    );
};
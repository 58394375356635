import React from 'react';

const ShowFiltersButton = props => {
    // console.log('ShowFiltersButton props', props);
    let className = 'container justify-content-end mx-0 d-flex';
    if (props.show) className = 'd-none';
    return (
        <div id='show-extra-filters-button' className={className}>
            {/*<span className='text-capitalize'*/}
            <span
                style={spanStyle}
                onClick={() => props.handler()}>
                        {props.children}
                    </span>
        </div>
    )
};
const spanStyle = {color: 'red', fontWeight: '600', fontSize: '1rem', userSelect: 'none', cursor: 'pointer'};

export default ShowFiltersButton;

const rootApiURI = process.env.REACT_APP_ROOT_API_URI;
const metadataApiKey = process.env.REACT_APP_METADATA_API_KEY;
const apiKeyName = process.env.REACT_APP_OCP_API_NAME;

class AzureCharacteristicsRESTConnector {

    retrieveCharacteristicsByLanguage = async (modificationID, language) => {
        const names = await _retrieveCharacteristicsNamesByLanguage(language);
        const processedNames = _processCharacteristicsNamesByLanguage(names);
        // console.log('names', processedNames);
        const values = await _retrieveCharacteristicsValues(modificationID);
        const processedValues = _processCharacteristicsValues(values);
        const characteristics = _processCharacteristics(processedNames, processedValues);
        // console.log('final characteristics', characteristics);
        return characteristics;
    }
}

const _retrieveCharacteristicsNamesByLanguage = async language => {
    const response = await fetch(`${rootApiURI}/metadata/characteristicnames?lang=${language}`, {
        headers: {[apiKeyName]: metadataApiKey}
    });
    const json = await response.json();
    // console.log('_retrieveCharacteristicsNamesByLanguage response: ', json);
    return json.value;
}

const _retrieveCharacteristicsValues = async modificationID => {
    const response = await fetch(`${rootApiURI}/metadata/characteristics/${modificationID}`, {
        headers: {[apiKeyName]: metadataApiKey}
    });
    const json = await response.json();
    console.log('Characteristic values response', response.status);
    return json.value;
}

const _processCharacteristics = (names, values) => {
    console.log('_processCharacteristics', names, values);
    return values.map(el => {
        return {
            name: names.find(en => en.characteristicID === el.characteristicID).name,
            value: el.value,
            unit: el.unit,
            modificationID: el.modificationID,
            characteristicID: el.characteristicID,
            id: el.id
        }
    })
}

const _processCharacteristicsNamesByLanguage = names => {
    // console.log('_processCharacteristicsNamesByLanguage', names);
    return names.map(el => {
        const keys = Object.keys(el);
        const n = keys.find(k => k.toLocaleLowerCase().includes('name'));
        // console.log('n', n);
        return {
            name: el[n],
            characteristicID: Number.parseInt(el.RowKey)
        }
    })
}

const _processCharacteristicsValues = characteristics => {
    // console.log('process characteristics values', characteristics);
    return characteristics.map(el => {
        return {
            value: el.Value,
            unit: el.Unit,
            modificationID: el.PartitionKey,
            characteristicID: el.CharacteristicID,
            id: el.RowKey
        }
    })
}

export {AzureCharacteristicsRESTConnector};
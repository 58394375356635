import _ from "lodash";
import {prepareForInsert} from "../../../../../Integration/Utils/TableUtils";
import AzureSearchConnector from "../../../../../Integration/Search/AzureSearchConnector";
import {AzureUpgradeOrdersRESTConnector} from "../../../../../Integration";

const searchConnector = new AzureSearchConnector();
const upgradeOrdersConnector = new AzureUpgradeOrdersRESTConnector();

/**
 * Upgrade helper functions
 * */

export const getMainFontStyle = isBigScreen => {
    const mainFontStyle = {fontSize: '.88rem'};
    if (isBigScreen) mainFontStyle.fontSize = '0.92rem';
    return mainFontStyle;
}

export const getPrice = (period) => {
    let price = 0;
    if (period !== undefined) {
        const prices = {
            7: "7eur",
            14: "14eur",
            21: "20eur",
            28: "25eur"
        };
        price = prices[period];
        console.log('getPrice', period, price);
    }
    return price;
}

export const getURL = amount => {
    const urls = {
        '7': 'https://buy.stripe.com/test_eVa3e6flVdg6fqU6oo',
        '14': 'https://buy.stripe.com/test_7sI4ia7Ttb7Y6Uo145',
        '20': 'https://buy.stripe.com/test_3cscOGb5F0tk5QkbIL',
        '25': 'https://buy.stripe.com/test_3cs9CugpZ7VM1A47su'
    };
    return urls[amount];
}

export const buyButtonStyle = {
    background: 'white',
    color: '#FF8C00',
    border: '1px solid #FF8C00',
    bottom: 0, right: 0,
    // borderRadius: '4px',
    height: '28px',
    width: '33%',
    fontSize: '0.8rem',
    marginRight: '1rem',
    userSelect: 'none', cursor: 'pointer'
}


/**
 * Publishing functions
 * */

export const checkIfPublished = async (car, setIsPublished) => {
    console.log('checkIfPublished', car.vin);
    try {
        const response = await searchConnector.getDocument(car.vin);
        console.log('response status', response, car.vin);
        if (response !== undefined) {
            if (response.status === 200) {
                setIsPublished(true);
            } else if (response.status === 204) {
                setIsPublished(false)
            }
        } else {
            console.log('checkIfPublished response undefined');
        }
    } catch (error) {
        console.warn('checkIfPublished error', error);
    }
}

export const publishCarToSearch = async (car, setIsPublished) => {
    console.log('publishCarToSearch');
    const paths = JSON.parse(car.blobPaths);
    console.log('paths:', 'typeof ', car.blobPaths,
        'typeof paths', typeof paths, paths);

    const copy = _.cloneDeep(car);
    copy.blobPaths = paths;
    console.log('after cloneDeep', copy);
    const prepared = prepareForInsert(copy);
    console.log('after prepare', prepared);
    prepared.blobPaths = paths;
    console.log('copy after preparation with paths', prepared);
    const response = await searchConnector.publishDocument(prepared);
    console.log('response from publish', response);
    setIsPublished(true);
}

export const unpublishCarFromSearch = async (car, setIsPublished) => {
    console.log('unpublishCarFromSearch');
    const response = await searchConnector.unpublishDocument(car);
    console.log('response after unpublish', response);
    setIsPublished(false);
}

/**
 * Orders
 * */
export const fetchUpgradeOrders = async (vin, setUpgradeStatus) => {
        const orders = await upgradeOrdersConnector.retrieveUpgradeOrders(vin);
        if (!_.isEmpty(orders)) {
            const cars = orders.values;
            let sortedCarOrders = _.orderBy(cars, car => Date.parse(car.endDate), 'desc');
            console.log('sortedCarOrders', sortedCarOrders);
            const sortedOrders = {values: sortedCarOrders};
            console.log('sortedOrders', sortedOrders);
            setUpgradeStatus(sortedOrders);
            console.log('fetchUpgradeOrders')
        }
        return orders;
    }
;

/**
 * Parsing string to objects (navigate to Car page)
 * ***/

export const updateRowKeyAndVin = car => {
    car.RowKey = car.vin;
    car.PartitionKey = car.make;
    return car;
}

export const parseHistoryOptionsPaths = car => {
    car.options = JSON.parse(car.options);
    car.history = JSON.parse(car.history);
    car.blobPaths = JSON.parse(car.blobPaths);
    return car;

}

/**
 * EDIT & UPGRADE Button style
 ***/

export const getEditButtonStyle = isPublished => {
    const style = {color: '#888'};
    if (!isPublished) style.color = '#007bff';
    return style;
}

export const getUpgradeButtonStyle = isPublished => {
    const style = {color: '#888'};
    if (isPublished) {
        style.borderBottom = '3px solid #ffd700';
        style.color = '#FF8C00';
    }
    return style;
}

export const getUpgradedBadgeStyle = order => {
    const style = {
        top: '2px', left: '2px',
        background: 'white',
        border: '1px solid #ffd700',
        cursor: 'pointer',
    };
    let isRunning = false;
    if (order !== undefined) {
        isRunning = order.values.filter(el => el.advStatus === 'STARTED');
        console.log('getUpgradedBadgeStyle', isRunning.length > 0);
        if (isRunning.length === 0) {
            style.filter = 'grayscale(1.0)';
            style.opacity = .7;
        }
    }
    return style;
}

/**
 * Active listings *
 * **/

export const getActiveListingsStyle = isPublished => {
    const style = {
        right: '2px', top: '2px', fontSize: '0.625rem', background: 'lightgreen',
        height: '14px', border: '1px solid #5BDF9D', color: '#555'
    }
    if (!isPublished) {
        style.border = '1px solid #7A427A';
        style.background = 'lightgray'
    }
    return style;
}



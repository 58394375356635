import React, {useEffect, useState} from 'react';
import {Button, ProgressBar} from "react-bootstrap";
import {sellCardBorder} from "../../../SellCarCards/SellCarCardMobile";
import {useTranslation} from "react-i18next";
import {translateErrorMessage} from "../../../../../../../Integration/Utils/StorageUtils";
import {handleFileChangeAndResize} from "../../../../../Input/FileInput/fileUtils";
import _ from "lodash";

const AddImages = props => {
    console.log('AddImages props', props);
    const {t} = useTranslation(['texts']);
    const [progress, setProgress] = useState(0); // for tracking progress

    // if (props.files.length === 0) props.setDisableSubmit(true);

    useEffect(() => {
        console.log('AddImages useEffect props.files:', props.files);
        if (props.files.length === 0) props.setDisableSubmit(true);
        else props.setDisableSubmit(false);
    }, [props.files]);

    const translatedErrorMessage = translateErrorMessage(props.uploadStatus, t);
    console.log('AddImages translateErrorMessage', translatedErrorMessage);

    return <div id='add-images' className='col-12'>
        {props.showFileInput ? <>

                {/*show file input*/}
                <div id='add-image-file-input' className='my-3'>
                    <input type='file'
                           accept='image/*'
                           multiple
                           key={props.inputKey || 0}
                           onChange={async event => {
                               const resizedFiles = await handleFileChangeAndResize(event, setProgress);
                               props.setFiles(resizedFiles);
                               console.log('resized files:', resizedFiles);
                               props.setDisableSubmit(false);
                           }}
                    />
                </div>

                {/*show the list of images for  upload*/}
                <div id='add-image-list' className='col-12 px-0'>
                    <div id='progress indicator' className='my-2'>
                        {!_.isEmpty(props.files) && progress !== 100 &&
                            <ProgressBar now={progress}
                                         label={`${progress}%`}
                                         variant='success'/>
                        }
                    </div>
                    <ul style={{listStyle: 'none', padding: 0}}>
                        {props.files.map((f, index) =>
                            <li key={index} className='d-flex justify-content-center mb-4'>
                                <img style={{
                                    width: '100%',
                                    objectFit: 'contain',
                                    ...sellCardBorder
                                }}
                                     src={URL.createObjectURL(f)}/>
                            </li>)}
                    </ul>
                </div>
            </> :

            //show message if images were uploaded
            <div id='add-image-uploaded'>
                {props.uploadStatus !== undefined && props.uploadStatus.code === 201 ? (
                    <div id='images-uploaded'>
                        <span>{t('texts:sell.car-ad-card.edit-images.images-were-uploaded')}</span>
                        <Button variant='link'
                                onClick={() => {
                                    props.setShowInfo('edit-images');
                                    props.setShowFileInput(true);
                                    props.setDisableSubmit(false);
                                }}>
                            <span id='title-edit-images'>{t('texts:sell.car-ad-card.edit-images.edit-images')}</span>
                        </Button>
                    </div>
                ) : <div id='image-error-message' style={{color: 'red'}}>{translatedErrorMessage}</div>
                }
            </div>
        }
    </div>
}

export default AddImages;
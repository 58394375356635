import React, {useEffect, useState} from 'react';
import SellCarCardMobile from "./SellCarCards/SellCarCardMobile";
import {useMediaQuery} from "react-responsive";
import _ from "lodash";
import {useTranslation} from "react-i18next";

const MyOffers = props => {

    // console.log('MyOffers props', props);

    const {t} = useTranslation(['texts'])

    const isMobilePortrait = useMediaQuery({orientation: 'portrait'});
    const isSmallScreen = useMediaQuery({maxWidth: '450px'});

    const [textStyle, setTextStyle] = useState({fontSize: '.95rem'});

    useEffect(() => {
        console.log('MyOffers useEffect, []');
        if (isSmallScreen) {
            setTextStyle({fontSize: '.9rem'});
        }
    }, []);

    const [sortedCars, setSortedCars] = useState(props.cars);

    useEffect(() => {
        console.log('MyOffers useEffect, [props.cars]', props.cars);
        if (!_.isEmpty(sortedCars)) {
            const sortedCars = _.sortBy(props.cars, car => {
                const date = new Date(car.Timestamp.replace('Z', ''));
                // console.log('MyOffers date', date);
                return date
            }).reverse();
            console.log('MyOffers sortedCars', sortedCars);
            setSortedCars(sortedCars);
        } else {
            console.log('MyOffers props.cars is empty', props.cars);
        }
    }, [props.cars]);

    return <div id='myoffers-container' className='container'>{
        _.isEmpty(sortedCars)
            ? <div id='offers-list-empty' className='row mt-2'><span
                style={textStyle}
                className='pl-0 ml-0'>{t('texts:sell.my-offers-area.no-cars-yet')}</span>
            </div>
            : sortedCars.map(car => {
                return <div className='mb-4' key={car.vin}>
                    <SellCarCardMobile
                        isMobilePortrait={isMobilePortrait}
                        sellFunctions={props.sellFunctions}
                        car={car}
                        updateSelectedCar={props.updateSelectedCar}
                    />
                </div>;
            })
    }
    </div>
}

export default MyOffers;
import checkmark from "../../Pages/Car/Components/ContactForm/checkmark.svg";
import gif from "../../Pages/Car/Components/ContactForm/gears.gif";
import React from "react";
import {useTranslation} from "react-i18next";

export const EmailSentStatus = ({emailStatus}) => {

    const {t} = useTranslation(['texts']);

    if (emailStatus === 'sent') {
        return <div id='email-sent'>
            <b>{t('texts:search.car-card.contact-form.sent-status-sent')}</b>
            <img src={checkmark} height='18px' style={{marginLeft: '0.25rem'}} alt='email sent'/>
        </div>
    } else if (emailStatus === 'checking') {
        return <div id='email-checking'>
            <b>{t('texts:search.car-card.contact-form.sent-status-checking')}</b>
            <img src={gif} height='18px' alt='checking email status'/>
        </div>
    } else
        return <div id='email-not-sent'>{t('texts:search.car-card.contact-form.sent-status-not-sent')}</div>
}
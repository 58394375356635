import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {
    getCityFromDictionary,
    getCountryFromDictionary,
    getTitleFromDictionary
} from "../../../../../Integration/Utils/LanguageUtils";
import {
    Button, ButtonGroup, ButtonToolbar,
} from "react-bootstrap";
import {carHeader, getVATBaloon} from "../../../../Search/CarListing/CarCard/CarCardMobilePortrait";
import {
    checkIfPublished,
    fetchUpgradeOrders,
    getActiveListingsStyle,
    getEditButtonStyle,
    getUpgradeButtonStyle,
    getUpgradedBadgeStyle,
    parseHistoryOptionsPaths,
    publishCarToSearch,
    unpublishCarFromSearch,
    updateRowKeyAndVin
} from "../functions/offersFunctions";
import {useNavigate} from "react-router-dom";
import ToastModalPublish from "../Modals/ToastModalPublish";
import ToastModalUpgrade from "../Modals/ToastModalUpgrade";
import OrderHistoryModal from "../Modals/OrderHistoryModal";
import UpgradeModalNew from "../Modals/UpgradeModal/UpgradeModalNew";
import upgradeImage from '../../../../Search/CarListing/CarCard/SponsoredLabel/img/icon-upgrade.png';
import PaymentModal from "../Modals/PaymentModal/PaymentModal";
import EditModal from "../Modals/EditModal/EditModal";
import DeleteModal from "../Modals/DeleteModal";
import {highlightStyle} from "../../../../Search/CarListing/CarCard/HistoryLabel/HistoryLabel";
import {FetchImage} from "../../../../../Shared/FetchImage/FetchImage";
import _ from "lodash";

const SellCarCardMobile = props => {

        const navigate = useNavigate();

        const {t} = useTranslation(['texts']);

        const fuel_d = t('extended-dictionary:fuels', {returnObjects: true});
        const transmission_d = t('extended-dictionary:transmissions', {returnObjects: true});
        // const fuel_d = t('data-for-ui:fuel', {returnObjects: true});
        // const transmission_d = t('data-for-ui:transmission', {returnObjects: true});

        const locations = t('locations:countries', {returnObjects: true});
        const carLocation = getCityFromDictionary(props.car, locations) + ', ' +
            getCountryFromDictionary(props.car, locations);

        // const truncatedModification = truncateWordsToLength(props.car.modification, 33);


        const [car, setCar] = useState(props.car);
        const [isPublished, setIsPublished] = useState(undefined);
        const [upgradeOrders, setUpgradeOrders] = useState(undefined);

        const [showEditModal, setShowEditModal] = useState(false);
        const [showDeleteModal, setShowDeleteModal] = useState(false);
        const [showToast, setShowToast] = useState(false);
        const [showToastUpgrade, setShowToastUpgrade] = useState(false);

        const getTranslatedActiveText = isPublished => {
            let text = t('sell.my-offers-area.publish-checking');
            if (isPublished) text = t('sell.my-offers-area.publish-active');
            else if (isPublished === false) text = t('sell.my-offers-area.publish-inactive');
            console.log('getTranslatedText', text);
            return text;
        }

        const editButtonStyle = getEditButtonStyle(isPublished);
        const upgradeButtonStyle = getUpgradeButtonStyle(isPublished);
        const upgradedBadgeStyle = getUpgradedBadgeStyle(upgradeOrders);
        const activeStyle = getActiveListingsStyle(isPublished);
        const activeListingText = getTranslatedActiveText(isPublished);

        /**
         * Modals: upgrade, history, payment
         * */
        const [modals, setModals] = useState(
            {upgrade: false, history: false, payment: false, top: false});
        const showUpgradeModal = modals.upgrade;

        const setShowUpgradeModal = value => {
            modals.upgrade = value;
            setModals({...modals});
        }

        const showOrderHistoryModal = modals.history;
        const setShowOrderHistoryModal = value => {
            modals.history = value;
            setModals({...modals});
        }

        const showPaymentModal = modals.payment;
        const setShowPaymentModal = value => {
            modals.payment = value;
            setModals({...modals});
        };

        useEffect(() => {
            console.log('useEffect inside of SellCarCardPortrait', props.car.vin);
            checkIfPublished(props.car, setIsPublished);
            fetchUpgradeOrders(props.car.vin, setUpgradeOrders);
        }, [props.car])

        /**
         * upgrade modal: setter and property moved from modal for propagation to payment
         * ***/
        const [upgradePeriod, setUpgradePeriod] = useState(7);

        const refreshUpgradeOrders = async () => {
            const refreshedOrders = await fetchUpgradeOrders(props.car.vin, setUpgradeOrders);
            console.log('refreshUpgradeOrders', 'oldOrders: ', upgradeOrders, 'refreshedOrders: ', refreshedOrders);
        }

        console.log('SellCarCardMobile props', props.car.vin, upgradeOrders);

        const vatElement = getVATBaloon(t('texts:vehicle-information.vat'), props.car.vatIncluded);
        const originalVIN = props.car.originalVIN !== undefined ? props.car.originalVIN : props.car.vin;

        return <div className='row' style={{...sellCardBorder, ...{maxWidth: '775px'}}} id='sell-car-card-mobile'>
            {props.isMobilePortrait ? <>
                {/**
                 Portrait
                 **/}
                <div className='col-12 px-0'>
                    {upgradeOrders && <div className='p-1 position-absolute'
                                           id='upgrade-badge'
                                           style={upgradedBadgeStyle}>
                        <img src={upgradeImage} height={24} alt='upgrade order'
                             onClick={() => {
                                 setModals({upgrade: false, history: true, payment: false, top: true});
                             }}
                        />
                    </div>}
                    <div className='p-1 position-absolute d-flex align-items-center'
                         style={activeStyle}>
                        <span>{activeListingText}</span>
                    </div>
                    <FetchImage
                        style={{...thumbnail, borderBottom: '1px solid #ddd'}}
                        src={JSON.parse(props.car.blobPaths)[0]} alt='car image'
                        onClick={() => {
                            console.log('SellCarCard clicked', props);
                            const carUpd = updateRowKeyAndVin(props.car);
                            const copy = parseHistoryOptionsPaths(carUpd);
                            props.updateSelectedCar(copy);
                            navigate('/car/' + props.car.vin);
                        }}
                    />
                </div>
                <div className='col-12 d-table pr-0 mb-2'>
                    <div className='d-table-row'>
                        <div className='col-12 row justify-content-between align-items-start pr-0 mt-0'>
                            <div className='font-weight-bold mt-2 mb-0 col-7 px-0 d-flex align-items-center
                            justify-content-start' style={carHeader}>
                                <span className="text-capitalize mr-1">
                                    {props.car.year}{'\u00A0'}
                                    {props.car.make}
                                    {props.car.make.length > 9 ? <br/> : '\u00A0'}
                                    {_.capitalize(props.car.model)}
                                </span>
                            </div>
                            <div className='col-5 font-weight-bold mt-2 mb-n1 px-0 text-right d-flex align-items-center
            justify-content-end' style={carHeader}>
                                €{props.car.price.toLocaleString('ru-RU')}{' '}{vatElement}</div>
                        </div>
                    </div>

                    {/*<div className='d-table-row'>*/}
                    {/*    <div className='justify-content-between row col-12 pr-0 mt-0'>*/}
                    {/*        <div className='col-12 px-0 text-uppercase'>*/}
                    {/*            <span style={{fontSize: '.82rem', color: '#555'}}>{truncatedModification}</span>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className='d-table-row' style={{fontSize: '.88rem'}}>
                        <div className='justify-content-between row col-12 pr-0 my-1'>
                            <div>
                                <span className="text-nowrap text-capitalize mr-1">{props.car.year},</span>
                                <span>{props.car.mileage.toLocaleString('ru-RU')}{' '}{t('search.car-card.km')}</span>
                            </div>
                            <div className=''><span
                                className='text-capitalize'>{getTitleFromDictionary(props.car.fuel, fuel_d)}</span>
                                <span
                                    className='text-lowercase'>{', '}{props.car.power}{' '}
                                    {t('sell.characteristics.hp')}</span>
                                {/*<span>{getTitleFromDictionary(props.car.transmission, transmission_d)}</span>*/}
                            </div>
                        </div>
                    </div>

                    <div className='d-table-row' style={{fontSize: '.88rem'}}>
                        <div className='justify-content-between row col-12 pr-0 align-items-center'>
                            <div className='text-capitalize'>
                                <span className='justify-content-start'>{carLocation.toString()}</span>
                            </div>

                            <div>    {props.car.history !== undefined && props.car.history.length > 0 ?
                                <div style={{fontWeight: '300'}} className='mt-0 mb-1'>
                                    {props.car.history.indexOf('no-accident') !== -1
                                        ? <span style={highlightStyle}>{t('search.search-area.no-accident')}</span>
                                        : <span style={highlightStyle}>{t('search.search-area.history')}</span>}
                                </div> : null}</div>
                        </div>
                    </div>
                    <div className='d-table-row' style={{fontSize: '.7rem'}}>
                        <div className='justify-content-between row col-12 pr-0 align-items-center'>
                            <div className='text-capitalize'>
                                <span className='justify-content-start'>VIN:{originalVIN}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </> : <>
                {/**
                 Landscape
                 **/}

                <div className='col-6 px-0' style={{borderRight: '1px solid #eee'}}>
                    {upgradeOrders && <div className='p-1 position-absolute'
                                           id='upgrade-badge'
                                           style={upgradedBadgeStyle}>
                        <img src={upgradeImage} height={24} alt='upgrade order'
                             onClick={() => {
                                 setModals({upgrade: false, history: true, payment: false, top: true});
                                 refreshUpgradeOrders();
                             }}
                        />
                    </div>}
                    <div className='p-1 position-absolute d-flex align-items-center'
                         style={activeStyle}>
                        <span>{activeListingText}</span>
                    </div>
                    <FetchImage
                        style={thumbnail}
                        src={JSON.parse(props.car.blobPaths)[0]} alt='car image'
                        onClick={() => {
                            console.log('SellCarCard clicked', props);
                            const carUpd = updateRowKeyAndVin(props.car);
                            const copy = parseHistoryOptionsPaths(carUpd);
                            props.updateSelectedCar(copy);
                            navigate('/car/' + props.car.vin);
                        }}
                    />
                </div>
                <div className='col-6 d-table'>
                    <div className='d-table-row'>
                        <div className='font-weight-bold mt-2 mb-n1' style={carHeaderLandscape}>
                            <span className="text-nowrap text-capitalize">{props.car.year}{' '}</span>
                            <span className="text-nowrap text-capitalize">{props.car.PartitionKey}{' '}</span>
                            <span className="text-nowrap text-capitalize">{props.car.model}</span>
                        </div>
                        <div>
                  <span className="text-uppercase"
                        style={{fontSize: '0.75rem', fontWeight: 600, color: 'rgba(0,0,0,1)'}}>
                            {props.car.modification.replace(/ *\([^)]*\) */g, '')}
                        </span>
                        </div>
                    </div>
                    {/*<div className='row'>*/}
                    <div className='d-table-row' style={{fontSize: '.92rem'}}>
                        <div>
                            <span>{props.car.mileage.toLocaleString('ru-RU')}{' '}{t('search.car-card.km')}</span>
                        </div>
                        <div className=''><span
                            className='text-capitalize'>{getTitleFromDictionary(props.car.fuel, fuel_d)}</span>
                            <span
                                className='text-lowercase'>{', '}{props.car.power}{' '}{t('sell.characteristics.hp')}</span>
                        </div>
                        <div className='text-capitalize'>
                            <span>{getTitleFromDictionary(props.car.transmission, transmission_d)}</span>
                        </div>
                    </div>
                    <div className='d-table-row mr-2' style={{bottom: '0.4rem'}}>
                        <div className='justify-content-between row col-12 align-items-end mb-n1'>
                            <div>    {props.car.history !== undefined && props.car.history.length > 0 ?
                                <div style={{fontWeight: '300'}}>
                                    {props.car.history.indexOf('no-accident') !== -1
                                        ? <span style={highlightStyle}>{t('search.search-area.no-accident')}</span>
                                        : <span style={highlightStyle}>{t('search.search-area.history')}</span>}
                                </div> : null}</div>

                        </div>
                        <div className='justify-content-between row col-12 align-items-end'>
                            <div className='text-capitalize text-left mt-1'
                                 style={{fontSize: '0.75rem', fontStyle: 'normal'}}>
                                <span className='justify-content-start'>{carLocation.toString()}</span>
                            </div>
                            <div>
                        <span style={carHeaderLandscape} className='d-block text-right font-weight-bold'>
                                {props.car.price.toLocaleString('ru-RU')}
                            </span>
                            </div>
                        </div>
                    </div>


                </div>


            </>}


            {/**
             *
             * Toggle
             *
             * **/
            }

            <div className='col-12 border-top px-0'>
                <ButtonToolbar
                    bsPrefix={props.isMobilePortrait ? 'btn-toolbar w-100 d-flex justify-content-between'
                        : 'btn-toolbar w-100 d-flex justify-content-center'}
                >
                    <ButtonGroup className="mr-2" aria-label="First group" size="sm">
                        {isPublished && <Button variant='link'
                                                style={{...buttonsStyle}}
                                                onClick={() => unpublishCarFromSearch(props.car, setIsPublished)}>
                            {t('sell.car-ad-card.unpublish')}
                        </Button>}
                        {!isPublished && <Button variant='link'
                                                 disabled={isPublished === undefined}
                                                 style={{...buttonsStyle}}
                                                 onClick={
                                                     () => publishCarToSearch(props.car, setIsPublished)
                                                 }
                        >{t('sell.car-ad-card.publish')}</Button>}
                    </ButtonGroup>


                    <ButtonGroup className="mr-2" size="sm"
                                 variant={'link btn-outline-secondary'}>

                        <Button variant='link'
                                disabled={isPublished === undefined}
                                style={editButtonStyle}
                                onClick={() => {
                                    if (!isPublished && isPublished !== undefined)
                                        setShowEditModal(true);
                                    if (isPublished) setShowToast(true);
                                }}>
                            {t('sell.car-ad-card.edit')}
                        </Button>

                    </ButtonGroup>


                    <ButtonGroup className="mr-2" size="sm"
                                 variant={'link btn-outline-secondary'}>
                        <Button variant='link'
                                disabled={isPublished === undefined}
                                style={editButtonStyle}
                                onClick={() => {
                                    if (!isPublished && isPublished !== undefined)
                                        setShowDeleteModal(true);
                                    if (isPublished) setShowToast(true);
                                }}>
                            {t('sell.car-ad-card.delete')}
                        </Button>
                    </ButtonGroup>

                    <ButtonGroup aria-label="Third group" size="sm">
                        <Button variant='link'
                                style={{...upgradeButtonStyle, ...buttonsStyle}}
                                onClick={() => {
                                    if (isPublished) setShowUpgradeModal(true);
                                    else if (!isPublished) setShowToastUpgrade(true)
                                    // handleUpgradeOrderStatus(car.vin).then(response =>
                                    //     console.log('return from handleUpgradeOrderStatus', response));
                                }}
                        >{t('sell.car-ad-card.upgrade')}</Button>
                    </ButtonGroup>
                </ButtonToolbar>

            </div>


            <EditModal car={car} setCar={setCar} show={showEditModal}
                       handleClose={() => setShowEditModal(false)}
                       refreshCars={props.sellFunctions.refreshCars}
                       t={t}
            />

            <DeleteModal
                show={showDeleteModal} handleClose={() => setShowDeleteModal(false)}
                // sellFunctions={props.sellFunctions}
                car={car} setCar={setCar}
                refreshCars={props.sellFunctions.refreshCars}
                title={t('sell.car-ad-card.delete-modal.are-you-sure')}
                yes={t('sell.car-ad-card.delete-modal.yes-delete')}
                no={t('sell.car-ad-card.delete-modal.no')}
            />

            <ToastModalPublish show={showToast} handleClose={setShowToast}/>
            <ToastModalUpgrade show={showToastUpgrade} handleClose={setShowToastUpgrade}/>

            <UpgradeModalNew
                show={showUpgradeModal}
                handleClose={() => setShowUpgradeModal(false)}
                car={car}
                upgradeStatus={upgradeOrders}
                setModals={setModals}
                upgradePeriod={upgradePeriod}
                setUpgradePeriod={setUpgradePeriod}
                refreshUpgradeOrders={refreshUpgradeOrders}
            />

            <OrderHistoryModal
                show={showOrderHistoryModal}
                handleClose={() => setShowOrderHistoryModal(false)}
                upgradeOrders={upgradeOrders}
                car={car}
                modals={modals}
                setModals={setModals}
                refreshUpgradeOrders={refreshUpgradeOrders}
                // orderPaid={props.orderPaid}
            />

            <PaymentModal
                show={showPaymentModal}
                handleClose={() => setShowPaymentModal(false)}
                upgradeOrders={props.upgradeOrders}
                car={props.car}
                setModals={setModals}
                upgradePeriod={upgradePeriod}
                // refreshCars={props.sellFunctions.refreshCars}
                refreshUpgradeOrders={refreshUpgradeOrders}
                // setOrderPaid={props.setOrderPaid}
            />
        </div>
    }
;

export default SellCarCardMobile;

const thumbnail = {
    width: '100%',
    // maxWidth:'400px',
    height: 'auto',
    // borderRadius: '1rem 0 0 1rem',
}
export const sellCardBorder = {
    border: '1.5px solid #ddd',
    boxShadow: '1px 8px 7px -3px rgba(221,221,221,0.7)',
    borderBottom: 0
}

const carHeaderLandscape = {
    fontSize: '1.1rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '230px'
}

export const buttonsStyle = {fontSize: '.9rem'}
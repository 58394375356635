import React from 'react';
import {MsalProvider} from '@azure/msal-react';
import {PublicClientApplication} from '@azure/msal-browser';
import _ from "lodash";

const generateRedirectURI2 = () => {
    const location = window.location.href;

    if (!_.isEmpty(process.env.REACT_APP_MSAL_REDIRECT_URIS)) {
        const URIs = process.env.REACT_APP_MSAL_REDIRECT_URIS.split(',');

        const foundURI = URIs.find(uri => location.includes(uri));
        let redirectUri = 'https://caruniverse.ee';  // Default value

        if (!_.isEmpty(foundURI)) {
            redirectUri = foundURI.includes('localhost') ? `http://${foundURI}` : `https://${foundURI}`;
        }

        // console.log('generateRedirectURI', redirectUri, URIs);
        return redirectUri;
    } else {
        console.log('isEmpty(process.env.REACT_APP_MSAL_REDIRECT_URIS)', process.env);
        return "https://caruniverse.ee";
    }
}

const msalConfig = {


    auth: {
        clientId: process.env.REACT_APP_MSAL_CLIENT_ID,
        authority: process.env.REACT_APP_MSAL_AUTHORITY,
        redirectUri: generateRedirectURI2(),
        knownAuthorities: [process.env.REACT_APP_MSAL_KNOWN_AUTHORITIES]
    }
};

const AuthProvider = ({children}) => {

    const msalInstance = new PublicClientApplication(msalConfig);
    // console.log('AuthProvider', msalInstance);

    return (
        <MsalProvider instance={msalInstance}>
            {children}
        </MsalProvider>
    );
};

export default AuthProvider;
import React, {useState} from 'react';
import {Carousel} from 'react-bootstrap';
import {FetchImage} from "../../../../Shared/FetchImage/FetchImage";
import './carslider.css';

const CarSlider = props => {

    let blobPaths = props.car.blobPaths;
    if (typeof props.car.blobPaths === 'string') blobPaths = JSON.parse(props.car.blobPaths);
    // console.log('blobPath type', typeof blobPaths)

    const [currentIndex, setCurrentIndex] = useState(0);

    return (
        <div>
            <Carousel slide={false} interval={null} controls={true}
                      activeIndex={currentIndex}
                      onSelect={(index) => setCurrentIndex(index)}>

                {blobPaths.map((image, index) => (
                    <Carousel.Item key={props.car.RowKey + '_' + index}
                                   activeindex={index}
                                   role='button'>

                        <FetchImage
                            className="d-block w-100"
                            src={image}
                            alt={props.car.make}
                            style={{border: '1px solid #dee2e6'}}

                        />
                    </Carousel.Item>

                ))}
            </Carousel>
        </div>
    );
};

export default CarSlider;
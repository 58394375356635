import _ from "lodash";

export const handleFileChangeAndResize = async (event, setProgress) => {
    setProgress(0); // Reset progress before processing
    const files = Array.from(event.target.files);
    const totalFiles = files.length;
    const resizedFiles = [];


    console.log('handleFileChange files', files);
    files.sort((a, b) => a.lastModified - b.lastModified);

    for (let i = 0; i < totalFiles; i++) {
        const blob = await resizeImage2(files[i]);
        const newFile = new File([blob], files[i].name, {type: 'image/jpeg'});
        resizedFiles.push(newFile);

        console.log(`handleFileChange: processed file: ${newFile.name}, Size: ${newFile.size / 1024} KB`); // Log file name and size

        // Calculate the progress as a percentage
        setProgress((i + 1) / totalFiles * 100);
    }
    event.target.value = null;
    return resizedFiles;
};

const resizeImage2 = (file) => {
    const MAX_WIDTH = 1920;
    const MAX_SIZE = 350 * 1024; // 350 KB
    let quality = 0.8; // Start with this quality level

    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
            const img = new Image();
            img.src = event.target.result;
            img.onload = () => {
                const aspectRatio = img.width / img.height;
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                canvas.width = MAX_WIDTH;
                canvas.height = MAX_WIDTH / aspectRatio;

                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                const reduceQualityAndResolve = () => {
                    canvas.toBlob((blob) => {
                        if (blob.size > MAX_SIZE && quality > 0.4) {
                            quality -= 0.05; // Reduce quality
                            console.log(`HandleFileChange: quality reduced to: ${quality}`);
                            reduceQualityAndResolve(); // Recursively call until size or quality condition is met
                        } else {
                            resolve(blob); // Size is okay or quality limit reached, resolve the promise
                        }
                    }, 'image/jpeg', quality);
                };

                reduceQualityAndResolve(); // Start the iterative quality reduction process
            };
        };
        reader.onerror = (error) => reject(error);
    });
};

import React, {useEffect, useState} from 'react';
import OptionSelect from "../../OptionSelect/OptionSelect";
import Heading from "../../../Search/SearchArea/Heading";

const InputFieldTransmission = props => {

    const transmissions = props.transmissions;

    let numberClass = 'row mt-2 numbered-list-item'
    if (props.state.transmission === undefined && props.prevField !== undefined) numberClass += ' yellow';
    else if (props.prevField === undefined) numberClass += ' grey'

    const keys = Object.keys(transmissions);

    const [selected, setSelected] = useState('');

    useEffect(() => {
        if (props.state.transmission !== undefined) {
            const selected = transmissions[props.state.transmission];
            setSelected(selected.title);
        }
    }, [props.state.transmission]);


    return <div className={numberClass} id='row-transmission'>

        <div className='offset-1'/>
        <div className='col-9 pl-0'>
            <OptionSelect
                id='select-transmission'
                options={keys.map(key => {
                    return {value: key, label: transmissions[key].title}
                })}
                selected={selected}
                handler={props.updateTransmission}
                isDisabled={props.prevField === undefined}
            >
                <Heading>{props.title}</Heading>
            </OptionSelect>
        </div>
    </div>
}

export default InputFieldTransmission;
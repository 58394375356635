import React, {useState} from 'react';

export const SellerInfoInput = ({k, borderStyle, localInfoFields, setLocalInfoFields, props}) => {

    const [inputValue, setInputValue] = useState(props.sellerInfo[k]);

    const handleChange = (e) => {
        // console.log('SellerInfoInput onChange', e.target.value, 'k', k, 'localInfoFields', localInfoFields);
        const copy = {...localInfoFields};
        copy[k] = e.target.value;
        setLocalInfoFields(copy); //added to save the value as part of the localInfoFields
        setInputValue(e.target.value); //added to display the selected value
    };

    return (
        <input
            key={k}
            type='text'
            style={borderStyle}
            onChange={e => handleChange(e)}
            value={inputValue}
        />
    );
};
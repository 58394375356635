import React from 'react';

export const SellerInfoRemoveButton = ({k, localInfoFields, setLocalInfoFields, t}) => {
    const handleRemove = () => {
        console.log('remove', k, 'localInfoFields', localInfoFields)
        const o = {...localInfoFields};
        // delete o[k];
        o[k] = "";
        setLocalInfoFields(o);
        console.log('o', o, 'localInfoFields', localInfoFields)
    };

    return (
        <div className="input-group-append">
            <button
                className="btn btn-outline-dark"
                type="button"
                style={{border: '1px solid rgba(0, 0, 0, 0.4)'}}
                onClick={handleRemove}
            >
                {t('texts:sell.car-ad-card.edit-modal.remove')}
            </button>
        </div>
    );
};
import React from 'react';

import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import _ from 'lodash';

const LargeTypeCard = props => {

    // console.log('LargeTypeCard', props);
    const {t} = useTranslation('texts');
    const priceLabel = t('common.price');
    // const bodyStyleLabel = t('search.search-area.body-style');
    const strings = _.split(props.bodystyle, '/');

    let categoryTitle = '';
    if (typeof props.categoryTitle === "object") {
        categoryTitle = props.categoryTitle.map((e, i) => {
            if (i === strings.length - 1) return _.capitalize(e);
            return _.capitalize(e) + ', '
        })
    } else {
        categoryTitle = _.capitalize(props.categoryTitle);
    }

    return <div className='col' style={style.card}>
        <div className='container-fluid'
            // className="align-items-center d-inline-flex text-center col-12"
             style={style.span}>
            <div className='h-25 d-flex align-items-center
            justify-content-center font-weight-bold'>
                <span className='text-capitalize'>{props.title}</span>
            </div>
            <div className='h-75 d-table w-100' style={{fontSize: '0.75rem'}}>
                <div className='d-table-row'>
                    <div className='row'>
                        <div className='col-6'>
                            <Link to={props.to1}>
                                <span onClick={() => {
                                    props.updateOptions({
                                        'price': {value: props.value1, title: priceLabel, visible: true},
                                        'bodyStyle': {
                                            value: props.bodystyle,
                                            title: categoryTitle, visible: true
                                        }
                                    })
                                }}>{props.title1}</span>
                            </Link>
                        </div>
                        <div className='col-6'><Link to={props.to4}><span
                            onClick={() => {
                                props.updateOptions({
                                    'price': {value: props.value4, title: priceLabel, visible: true},
                                    'bodyStyle': {
                                        value: props.bodystyle,
                                        title: categoryTitle, visible: true
                                    }
                                })
                            }}
                        >{props.title4}</span></Link></div>
                    </div>
                </div>
                <div className='d-table-row'>
                    <div className='row'>
                        <div className='col-6'><Link to={props.to2}><span
                            onClick={() => {
                                props.updateOptions({
                                    'price': {value: props.value2, title: priceLabel, visible: true},
                                    'bodyStyle': {
                                        value: props.bodystyle,
                                        title: categoryTitle, visible: true
                                    }
                                })
                            }}
                        >{props.title2}</span></Link></div>
                        <div className='col-6'><Link to={props.to5}><span
                            onClick={() => {
                                props.updateOptions({
                                    'price': {value: props.value5, title: priceLabel, visible: true},
                                    'bodyStyle': {
                                        value: props.bodystyle,
                                        title: categoryTitle, visible: true
                                    }
                                })
                            }}
                        >{props.title5}</span></Link></div>
                    </div>
                </div>
                <div className='d-table-row'>
                    <div className='row'>
                        <div className='col-6'><Link to={props.to3}><span
                            onClick={() => {
                                props.updateOptions({
                                    'price': {value: props.value3, title: priceLabel, visible: true},
                                    'bodyStyle': {
                                        value: props.bodystyle,
                                        title: categoryTitle, visible: true
                                    }
                                })
                            }}
                        >{props.title3}</span></Link></div>
                        <div className='col-6'><Link to={props.to6}><span
                            onClick={() => {
                                props.updateOptions({
                                    'price': {value: props.value6, title: priceLabel, visible: true},
                                    'bodyStyle': {
                                        value: props.bodystyle,
                                        title: categoryTitle, visible: true
                                    }
                                })
                            }}
                        >{props.title6}</span></Link></div>
                    </div>
                </div>
            </div>
        </div>
        {/*<Link to={props.to}>*/}
        <img src={props.image} style={style.img} alt='search by type of car'/>
        {/*</Link>*/}
    </div>

}

export default LargeTypeCard;

const style = {
    card: {
        position: 'relative',
        maxWidth: '240px',
        minWidth: '240px',
        height: '240px',
        padding: 0,
        margin: '10px',
        // border: 'solid 1px #ddd',
        // borderRadius: '8px',
        background: '#fff',
    }, img: {
        position: 'absolute',
        bottom: 0,
        marginTop: 0,
        // borderRadius: '8px',
        borderRadius: '0 0 8px 8px',
        filter: 'brightness(60%)',
        // height: '100%',
        width: '100%',
        objectFit: 'contain',
        zIndex: 0,

        // borderTop: '1px solid #ccc'
    },
    span: {
        border: 'solid 1px #ddd',
        // borderRadius: '8px',
        // display: 'inline-flex',
        justifyContent: 'center',
        background: 'inherit',
        color: 'black',
        position: 'absolute',
        zIndex: 22,
        fontSize: '0.75rem',
        // fontWeight: 'bold',
        height: '60%',
        width: '100%',
        textAlign: 'center',
        borderRadius: '8px 8px 0 0 ',
        // backgroundColor: 'rgba(255,255,255,0.99)'
        // borderBottom: '1px solid #ddd'
        // paddingLeft: '65px'
    }
}
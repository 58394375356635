import React from 'react';
import './HR.css';

const HR = (props) => {
    let mt = 'mt-4'
    if (props.mt !== undefined) mt = props.mt;
    let styleName = 'hr-divider text-uppercase text-left ml-0 pl-0 ' + mt;
    // if (props.className) styleName = `hr-divider text-center text-uppercase ${props.className}`;
    return (
        <div>
            <div className={styleName} style={{paddingTop: '0px'}}>
                {/*<hr/>*/}
                <span>{props.children}</span>
            </div>
        </div>
    )
};

export default HR;

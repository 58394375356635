import React from 'react';
import {useTranslation} from "react-i18next";

const ContactSellerMobileBar = props => {

    const {t} = useTranslation(['texts']);

    return <div id='contact-seller-bar'
        // className='d-flex justify-content-center align-items-center '
                className='position-fixed w-100 border-top d-flex justify-content-end
                align-items-center'
                style={{
                    height: '48px', bottom: 0,
                    background: 'white',
                    // background: '#f2f2f2',
                    boxShadow: '1px -3px 16px -4px rgb(0 0 0 / 14%)'
                }}

    >
        <div className=' d-flex justify-content-center align-items-center'
             style={contactSellerStyle}
             onClick={async () => {
                 props.setShow(true);
             }}
        >
            <span className=' d-flex align-items-center'>{t('vehicle-information.seller-info.contact-seller')}</span>
        </div>
    </div>
}
export default ContactSellerMobileBar;

export const contactSellerStyle = {
    // background: 'white',
    background: 'palegreen',
    // color: 'red',
    border: '1px solid yellowgreen',
    bottom: 0, right: 0,
    height: '28px',
    width: '33%',
    fontSize: '0.8rem',
    marginRight: '1rem',
    cursor: 'pointer'
}
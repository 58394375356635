import {CloseButton, Form, Modal} from "react-bootstrap";
import React from "react";
import {buyButtonStyle} from "../../Pages/MyOffers/functions/offersFunctions";
import comment from "../svg/car-comment.svg";
import {useTranslation} from "react-i18next";

const CommentModal = props => {

    const {t} = useTranslation(['texts']);

    return <Modal centered size='sm'
                  onHide={() => props.setShowModal(false)}
                  show={props.show}>
        <Modal.Header>
            <Modal.Title style={{fontSize: '0.75rem', color: '#555'}}
                         className='font-weight-bold text-uppercase d-flex w-100'>
                <div className='d-flex col justify-content-start align-items-center pl-0'>
                    <img src={comment} height={16} style={{marginRight: '1rem'}}/>
                    <span>{t('sell.add-comment')}</span>
                </div>
                <CloseButton onClick={() => props.setShowModal(false)}/>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <><Form className='w-100'>
                <Form.Group controlId="input-field-comment">
                    <Form.Control
                        as="textarea"
                        rows="4"
                        onChange={e => props.updateComment(e.target.value)}
                        value={props.comment}
                    />
                </Form.Group>
            </Form>
                <div className='d-flex justify-content-between px-0 w-100'>
                    <div
                        onClick={() => props.setShowModal(false)}
                        className=' d-flex justify-content-center align-items-center'
                        style={buyButtonStyle}>
                        <span className=' d-flex align-items-center'>{t('sell.save')}</span>
                    </div>
                    <div onClick={() => {
                        props.updateComment(undefined);
                        props.setShowModal(false);
                    }}
                         className=' d-flex justify-content-center align-items-center mr-0'
                         style={{...buyButtonStyle, color: '#888', border: '1px solid #888'}}>
                        <span className=' d-flex align-items-center'>{t('sell.clear')}</span>
                    </div>
                </div>
            </>
        </Modal.Body>
    </Modal>
}

export default CommentModal;
import React, {useEffect, useState} from 'react';
import {CloseButton, Modal} from "react-bootstrap";
import ContactForm from "../ContactForm/ContactForm";
import SellerInfo from "../SellerInfo/SellerInfo";
import {getContainerIDfromPath} from "../../Utils/utils";
import {AzureSellersRESTConnector} from "../../../../Integration";
import {useMediaQuery} from "react-responsive";
import shop from './shop.svg';
import {useTranslation} from "react-i18next";

const connector = new AzureSellersRESTConnector();

const ContactSellerModal = props => {

    // useState
    console.log('ContactSellerModal', props);

    const isMobileScreen = useMediaQuery({maxWidth: 560})
    const style = {};
    if (isMobileScreen) style.fontSize = '.88rem';

    const [sellerInfo, setSellerInfo] = useState({});
    const {t} = useTranslation(['texts']);

    useEffect(() => {
        const getSellerInfo = async () => {
            console.log('useEffect of ContactSellerModal ', props.car);
            const id = getContainerIDfromPath(props.car);
            const seller = await connector.retrieveSellerInfo(id);
            setSellerInfo(seller);
            // console.log('sellerInfo', sellerInfo);
        };
        getSellerInfo();
    }, [props.car])

    return <Modal show={props.showModal}
                  id='ContactSellerModal'
                  fullscreen="true"
                  onHide={() => props.setShowModal(false)}>
        <Modal.Header>
            <Modal.Title className='font-weight-bold text-uppercase d-flex w-100'
                         style={{fontSize: '0.75rem', color: '#555'}}>
                <div className='d-flex col justify-content-start align-items-center pl-0'>
                    <img src={shop} height={16} style={{marginRight: '1rem'}}/>
                    <span>{t('vehicle-information.seller-info.contact-seller')}</span>
                </div>
                <CloseButton onClick={() => {
                    props.setShowModal(false);
                }}/>
            </Modal.Title>

        </Modal.Header>

        <Modal.Body className='pt-0' id='contact-seller-modal-body'>

            <ContactForm showDisclaimer={false} car={props.car} style={style}/>
            <SellerInfo sellerInfo={sellerInfo} style={style}/>

        </Modal.Body>
    </Modal>
}

export default ContactSellerModal;
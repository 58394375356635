import React, {useEffect, useState} from 'react';
import {
    AzureAdvertisementsRESTConnector,
    AzureStorageRESTConnector,
} from "../../../../Integration";
import {
    calculateCRC32,
    clearFields,
    flatten,
    lowerCase,
    prepareForInsert,
    verifyBlobPaths,
    verifyMandatoryFields
} from '../../../../Integration/Utils/TableUtils';
import {hashUserID, translateErrorMessage, validateFiles} from "../../../../Integration/Utils/StorageUtils";
import {buyButtonStyle} from "../../Pages/MyOffers/functions/offersFunctions";
import {useTranslation} from "react-i18next";
import {useMsal} from "@azure/msal-react";
import {LoadingIndicator} from "../../../../Shared/FetchImage/LoadingIndicator";
import {ErrorMessageBlock} from "../ErrorMessages/ErrorMessageBlock";
import _ from "lodash";

const blobsConnector = new AzureStorageRESTConnector();
const advertisementRESTConnector = new AzureAdvertisementsRESTConnector();

const InputFieldsSubmit = props => {

    console.log('InputFieldsSubmit props', props);

    const {t} = useTranslation('texts');
    const {accounts} = useMsal();
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [email, setEmail] = useState(undefined);
    const [isUploading, setIsUploading] = useState(false);

    useEffect(() => {
        console.log('InputFieldsSubmit: useEffect [props]', 'accounts', accounts, 'props', props);
        setErrorMessage(undefined);
        if (accounts.length > 0) setEmail(accounts[0].username);
    }, [props]);


    const uploadFiles = async () => {
        if (email && props.state.files.length > 0) {
            console.log('InputFieldsSubmit: uploadFiles props', props);
            const state = clearFields(props.state);
            const containerID = hashUserID(email);
            state['containerID'] = containerID;
            console.log('InputFieldsSubmit: uploadFiles state after userID and containerID', email, state);

            const vinAsCRC = calculateCRC32(state.vin);  //test if it works
            console.log('uploadFiles calculateCRC32', vinAsCRC);

            /**File check 2 (1st is made in InputFile2) */
            const filesErrorMap = await validateFiles(props.state.files);
            console.log('uploadFiles errorMap', filesErrorMap);

            if (filesErrorMap.length > 0) {
                const translatedErrorMessage = translateErrorMessage(filesErrorMap[0].status, t);
                console.log('translatedErrorMessage', translatedErrorMessage);
                setErrorMessage(translatedErrorMessage);
                props.updateSellErrors({files: filesErrorMap[0].status.message})
            } else {
                const fields = {...state, userID: email};
                const fieldsErrorArray = [];
                if (verifyMandatoryFields(fields, fieldsErrorArray) && fieldsErrorArray.length === 0) {
                    let canUpload = false;

                    const fetchStatus = await blobsConnector.containerExists(containerID);
                    if (fetchStatus.status === 200) canUpload = true;
                    else if (fetchStatus.status === 404) {
                        const createStatus = await blobsConnector.createContainer(containerID);
                        if (createStatus.status === 201) canUpload = true;
                        else setErrorMessage(createStatus.text);
                    }
                    console.log('can upload?:', canUpload);

                    if (canUpload) {
                        // const response = await blobsConnector.putBlob(containerID, state.vin, props.state.files);
                        const response =
                            await blobsConnector.putBlob(containerID, vinAsCRC, props.state.files);
                        console.log('response after putBlob', response);
                        const text = response.map(r => <li
                            key={r.fileName}>{<a target='_blank'
                                                 href={r.url}>{r.fileName + ": " + r.statusText}</a>}</li>);
                        const urls = response.map(r => r.url);
                        if (urls.length > 0) {
                            setErrorMessage(text);
                            const json = clearFields(state);
                            json.blobPaths = urls;
                            console.log('json for table', json);
                            props.updateFileInput([]);
                            if (verifyBlobPaths(json)) {
                                json.vin = calculateCRC32(props.state.vin); //generateUUID();
                                json.originalVIN = state.vin;
                                // json.vin = state.vin;
                                const prepared = prepareForInsert(json);

                                console.log('mandatory fields ok, prepare for insert', prepared);
                                const flattened = flatten(json);
                                const lowered = lowerCase(flattened);
                                console.log('flattened', flattened, 'lowered', lowered);
                                const response = await advertisementRESTConnector.createAdvertisementRecord(lowered);
                                if (response.status === 201) {
                                    console.log('status success', response.status);
                                    props.updateLoadingComplete(true);
                                }
                                console.log('after insertIntoTable', response, 'state', props.state);
                            } else console.log('blobPaths failed: cannot continue');
                            setErrorMessage('blobPaths failed: cannot continue');
                        } else {
                            setErrorMessage('upload error');
                        }
                    }

                } else {
                    console.log('Mandatory fields failed or files not acceptable: cannot continue', fieldsErrorArray);
                    setErrorMessage('Mandatory fields failed or files not acceptable: missing ' +
                        _.toString(fieldsErrorArray));
                }
            }
        } else {
            setErrorMessage('Cannot continue: validation failed');
        }
    }

    //initial style of upload button
    const uploadStyle = {color: '#aaa', border: '1px solid #aaa', background: 'none'};

    //setting the style of active upload button
    if (props.state.files.length > 0 && _.isEmpty(props.state.errors)) {
        uploadStyle.color = 'white';
        uploadStyle.border = '1px solid #28a745';
        uploadStyle.background = '#28a745';
    }

    //setting the style of clear button
    const clearFieldsStyle = {color: '#888', border: '1px solid #888', background: 'none'};

    return <div className='container mt-4 mb-5' id='input-fields-submit-clear'>
        <div id='error-message-submit' className='row'>
            <ErrorMessageBlock errorMessage={errorMessage}/>
        </div>
        <div id='upload-clear-buttons' className='row'>
            <div id='upload-button'
                 className='col-12 px-0 h-100 d-flex align-items-center justify-content-between'>

                {/**Upload Button*/}
                <div onClick={async () => {
                    if (props.state.files.length > 0 && _.isEmpty(props.state.errors)) {
                        setIsUploading(true);
                        await uploadFiles();
                        setIsUploading(false);
                    }
                }}
                     className='d-flex justify-content-center align-items-center mr-1'
                     style={{...buyButtonStyle, width: '10rem', ...uploadStyle}}>
                <span className=' d-flex align-items-center'>
                    {props.uploadText}
                </span>
                </div>

                {isUploading && <LoadingIndicator/>}

                {/**Clear Button*/}
                <div id='clear-button'
                     onClick={() => props.clear()}
                     className='d-flex justify-content-center align-items-center mr-0'
                     style={{
                         ...buyButtonStyle, width: '8rem', ...clearFieldsStyle
                     }}>
                <span className=' d-flex align-items-center'>
                    {t('texts:sell.clear')}</span>
                </div>
            </div>
        </div>
    </div>
};

export default InputFieldsSubmit;
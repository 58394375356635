import Select from 'react-select';
import React from 'react';
import {optionSelectStyle} from './optionSelectStyle';
import {useTranslation} from "react-i18next";

const OptionSelectOld = props => {

    const {t} = useTranslation(['texts']);

    let selected = props.selected;
    // console.log('selected', props.id, props.selected);
    if (selected === undefined) selected = '--';
    else selected = {value: props.selected, label: props.selected};

    return (<div className="container">
        {props.children}

        {/*{console.log(props.options)}*/}
        <div className="row">
            <div className='m-0 w-100'>
                {/*{console.log('typeof selected.value', typeof selected.value, selected)}*/}
                <Select
                    className={props.className}
                    value={selected}
                    onChange={(e) => {

                        typeof (e) === 'object' ? props.handler(e.value) : props.handler(e);
                        console.log('onchange', e)
                    }}
                    options={props.options}
                    styles={optionSelectStyle}
                    isSearchable={false}
                    isDisabled={props.isDisabled}
                    placeholder={t('texts:common.select')}
                    // menuIsOpen
                />
            </div>
        </div>
    </div>)
};

export default OptionSelectOld;
import {AddPrivateSellerButton} from "./AddPrivateSellerButton";
import NumberOfStep from "../../../Misc/NumberOfStep/NumberOfStep";
import Heading from "../../../../Search/SearchArea/Heading";
import React from "react";
import './myaccount.css'

export const AddPrivateSellerForm = ({localInfoFields, setLocalInfoFields, setErrorMessage, props, textStyle, t}) => {

    console.log('PrivateSellerForm localInfoFields', localInfoFields, 'props', props, 'textStyle', textStyle);

    function handleNameAndPhoneChange(e, field) {
        const localFieldsCopy = {...localInfoFields};
        localFieldsCopy[field] = e.target.value;
        setLocalInfoFields(localFieldsCopy);
    }

    return <div id='field-block-private'>
        <div id='field-alias' className='row mt-4'>
            <NumberOfStep thisField={localInfoFields['name']}
                          prevField={localInfoFields['juridicalType']}>
                {2}
            </NumberOfStep>
            <div className='offset-1'/>
            <div className='col-9' style={{maxWidth: '220px'}}>
                <Heading style={textStyle}>{t('texts:sell.my-account-area.alias')}</Heading>
                <div className='ml-n3'>
                    <input type='text'
                           className='border-style'
                           onChange={e =>
                               handleNameAndPhoneChange(e, 'name')}
                           value={localInfoFields['name']}
                           disabled={localInfoFields['juridicalType'] === undefined}
                    />
                </div>
            </div>
        </div>
        <div id='field-phone' className='row mt-4'>
            <NumberOfStep thisField={localInfoFields['phone']}
                          prevField={localInfoFields['name']}>
                {3}
            </NumberOfStep>
            <div className='offset-1'/>
            <div className='col-9' style={{maxWidth: '220px'}}>
                <Heading
                    spanStyle={textStyle}>{t('texts:sell.my-account-area.phone-optional')}</Heading>
                <div className='ml-n3'>
                    <input type='tel'
                           pattern={/[a-zA-Z]/g}
                           maxLength={18}
                           className='border-style'
                           onChange={e => {
                               handleNameAndPhoneChange(e, 'phone');
                           }}
                           value={localInfoFields['phone']}
                           disabled={localInfoFields['name'] === undefined}/>
                </div>
            </div>
        </div>
        <AddPrivateSellerButton
            localInfoFields={localInfoFields}
            setLocalInfoFields={setLocalInfoFields}
            setErrorMessage={setErrorMessage}
            props={props}
        />
    </div>
}
import React, {useState} from "react";
import Select from "react-select";
import _ from "lodash";
import './css/vehicle.css';

const EditableAttributeColor = props => {
    const {
        title, value, state, setState, dictionary,
        placeholder, side, textStyle
    } = {...props};
    console.log('EditableAttributeColor', 'value', value, 'dictionary', dictionary);

    const [showField, setShowField] = useState(false);
    const [selectValue, setSelectValue] = useState(value);

    const updateOnSelect = value => {
        console.log('updateSelect', value);
        setSelectValue(value.value);
        const copy = JSON.parse(JSON.stringify(state));
        copy.color = value;
        console.log('update color', value, value.value, copy);
        setState(copy);
    }
    const handleKeyPress = (event) => {
        console.log(event.key);
        if (event.key === 'Enter') setShowField(!showField);
        console.log('state after enter', state);
    }

    let colClass = '';
    let col2style = 'col-12 mx-0'
    if (side === 'left') {
        colClass = 'col-12 d-flex justify-content-between pl-0'
        col2style += ' pl-0'
    } else {
        colClass = 'col-12 d-flex justify-content-between pr-0';
        col2style += ' pr-0';
    }

    return <div className='row col-6 mx-0 px-0 d-flex justify-content-between'
                id='editable-attribute-color'
                style={textStyle}>

        <div className={colClass}>
            <span className="text-left">{title}</span>
            <img height='17px' width='17px' className='button ml-1 mt-1 edit-icon'
                 onClick={() => setShowField(!showField)}
            />
        </div>
        <div className='row col-12'/>
        <div className={col2style}>
            <div className='d-flex justify-content-start pl-0'>
                {!showField && <span
                    className="text-right font-weight-bold text-nowrap text-capitalize">
                {dictionary !== undefined ? dictionary[state.color].title : state.color}
            </span>}
                {showField &&
                    <Select value={selectValue}
                            className='w-100'
                            style={textStyle}
                        // menuIsOpen
                            key='color-select'
                            placeholder={placeholder !== undefined && placeholder}
                            onChange={e => updateOnSelect(e.value)}
                            onKeyDown={e => handleKeyPress(e)}
                            options={Object.keys(dictionary).map(element => {
                                return {
                                    label: <div className='d-inline-flex'>
                                        <div style={{
                                            width: '24px',
                                            height: '24px',
                                            border: '1px solid #888',
                                            zIndex: 22,
                                            borderRadius: '4px',
                                            background: dictionary[element].color,
                                        }}/>
                                        <span
                                            style={{marginLeft: '5px', ...textStyle}}>
                                        {_.capitalize(dictionary[element].title)}</span>
                                    </div>, value: element
                                }
                            })}
                    />}

            </div>
        </div>
    </div>;
}

export default EditableAttributeColor;
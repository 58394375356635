import React from 'react';

import bmw from '../Banner/img/bmw.svg';
import mercedes from "../Banner/img/mercedes.svg";
import audi from "../Banner/img/audi.svg";
import porsche from "../Banner/img/porsche.svg";
import peugeot from "../Banner/img/peugeot.svg";
import toyota from "../Banner/img/toyota.svg";
import tesla from "../Banner/img/tesla.svg";
import lexus from "../Banner/img/lexus.svg";
import hyundai from "../Banner/img/hyundai.svg";
import vw from "../Banner/img/volkswagen.svg";
import mini from "../Banner/img/mini.svg";
import bentley from "../Banner/img/bentley.svg";
import smart from "../Banner/img/smart.svg";
import skoda from "../Banner/img/skoda.svg";
import opel from "../Banner/img/opel.svg";
import volvo from "../Banner/img/volvo.svg";
import ford from "../Banner/img/ford.svg";
import lincoln from "../Banner/img/lincoln.svg";
import mazda from "../Banner/img/mazda.svg";
import mitsubishi from "../Banner/img/mitsubishi.svg";
import nissan from "../Banner/img/nissan.svg";
import rover from "../Banner/img/rover.svg";
import saab from "../Banner/img/saab.svg";
import subaru from "../Banner/img/subaru.svg";
import alfa from "../Banner/img/alfa.svg";
import chevy from "../Banner/img/chevrolet.svg";
import chrysler from "../Banner/img/chrysler.svg";
import dacia from "../Banner/img/dacia.svg";
import daihatsu from "../Banner/img/daihatsu.svg";
import fiat from "../Banner/img/fiat.svg";
import gmc from "../Banner/img/gmc.svg";
import honda from "../Banner/img/honda.svg";
import isuzu from "../Banner/img/isuzu.svg";
import jaguar from "../Banner/img/jaguar.svg";
import jeep from "../Banner/img/jeep.svg";
import kia from "../Banner/img/kia.svg";
import lancia from "../Banner/img/lancia.svg";
import land from "../Banner/img/land.svg";
import lotus from "../Banner/img/lotus.svg";
import ferrari from "../Banner/img/ferrari.svg";
import aston from "../Banner/img/aston.svg";
import renault from "../Banner/img/renault.svg";
import pontiac from "../Banner/img/pontiac.svg";
import seat from "../Banner/img/seat.svg";
import ssangyong from "../Banner/img/ssangyong.svg";
import suzuki from "../Banner/img/suzuki.svg";
import lada from "../Banner/img/lada.svg";
import buick from "../Banner/img/buick.svg";
import cadillac from "../Banner/img/cadillac.svg";
import citroen from "../Banner/img/citroen.svg";
import abarth from "../Banner/img/abarth.svg";
import daewoo from "../Banner/img/daewoo.svg";
import dodge from "../Banner/img/dodge.svg";
import hummer from "../Banner/img/hummer.svg";
import infinity from "../Banner/img/infinity.svg";
import maserati from "../Banner/img/maserati.svg";
import maybach from "../Banner/img/maybach.svg";
import royce from "../Banner/img/rolls-royce.svg";
import bugatti from "../Banner/img/bugatti.svg";
import lamborghini from "../Banner/img/lamborghini.svg";
import greatwall from "../Banner/img/great-wall.svg";
import geely from "../Banner/img/geely.svg";
import ram from "../Banner/img/ram.svg";
// import polestar from "../Banner/img/polestar.svg";
import polestar from "../Banner/img/ram.svg";
import mclaren from "../Banner/img/mclaren.svg";
import koenigsegg from "../Banner/img/koenigsegg.svg";
// import pagani from "../Banner/img/pagani.svg";
import pagani from "../Banner/img/mclaren.svg";

const LogotypeHelper = () => {

    const map = new Map();
    map.set('audi', <img src={audi} key='audi' alt='audi-image' height='16' style={{opacity: '40%'}}/>)
    map.set('porsche', <img src={porsche} key='porsche' alt='porsche-image' height='30' style={{opacity: '40%'}}/>)
    map.set('bmw', <img src={bmw} key='bmw' alt='bmw-image' height='30' style={{opacity: '40%'}}/>)
    map.set('mercedes-benz', <img src={mercedes} key='mercedes-benz' alt='mercedes-image'
                                  height='30' style={{opacity: '100%'}}/>)
    map.set('peugeot', <img src={peugeot} key='peugeot' alt='peugeot-image' height='30' style={{opacity: '40%'}}/>)
    map.set('toyota', <img src={toyota} key='toyota' alt='toyota-image' height='20' style={{opacity: '40%'}}/>)
    map.set('tesla', <img src={tesla} key='tesla' alt='tesla-image' height='35' style={{opacity: '40%'}}/>)
    map.set('hyundai', <img src={hyundai} key='hyundai' alt='hyundai-image' height='44'
                            style={{opacity: '55%', filter: 'grayscale(1)'}}/>)
    map.set('lexus', <img src={lexus} key='lexus' alt='lexus-image' height='44'
                          style={{opacity: '45%', filter: 'grayscale(1)'}}/>)
    map.set('bentley', <img src={bentley} key='bentley' alt='bentley-image' height='58'
                            style={{opacity: '55%', filter: 'grayscale(1)', position: 'absolute', bottom: '1px'}}/>)
    map.set('volkswagen', <img src={vw} key='vw' alt='vw-image' height='30' style={{opacity: '40%'}}/>)
    map.set('mini', <img src={mini} key='mini' alt='mini-image' height='24' style={{opacity: '40%'}}/>)
    map.set('opel', <img src={opel} key='opel' alt='opel-image' height='32' style={{opacity: '40%'}}/>)
    map.set('skoda', <img src={skoda} key='skoda' alt='skoda-image' height='38' style={{opacity: '45%'}}/>)
    map.set('smart', <img src={smart} key='smart' alt='smart-image' height='58' style={{
        opacity: '100%',
        filter: 'grayscale(1)', position: 'absolute', bottom: '1px'
    }}/>)
    map.set('volvo', <img src={volvo} key='volvo' alt='volvo-image' height='58'
                          style={{opacity: '40%', filter: 'grayscale(1)', position: 'absolute', bottom: '1px'}}/>)
    map.set('ford', <img src={ford} key='ford' alt='ford-image' height='44'
                         style={{opacity: '40%', filter: 'grayscale(1)'}}/>)
    map.set('lincoln', <img src={lincoln} key='lincoln' alt='lincoln-image' height='58'
                            style={{opacity: '30%', position: 'absolute', bottom: '0px'}}/>)
    map.set('mazda', <img src={mazda} key='mazda' alt='mazda-image' height='62'
                          style={{opacity: '40%', position: 'absolute', bottom: '1.5rem', filter: 'grayscale(1)'}}/>)
    map.set('mitsubishi', <img src={mitsubishi} key='mitsubishi' alt='mitsubishi-image' height='50'
                               style={{
                                   opacity: '35%',
                                   position: 'absolute',
                                   bottom: '0.2rem',
                                   filter: 'grayscale(1)'
                               }}/>)
    map.set('nissan', <img src={nissan} key='nissan' alt='nissan-image' height='34'
                           style={{opacity: '45%', filter: 'grayscale(1)'}}/>)
    map.set('rover', <img src={rover} key='rover' alt='rover-image' height='48' style={{opacity: '40%'}}/>)
    map.set('saab', <img src={saab} key='saab' alt='saab-image' height='30'
                         style={{opacity: '40%', filter: 'grayscale(1)'}}/>)
    map.set('subaru', <img src={subaru} key='subaru' alt='subaru-image' height='68' style={{
        opacity: '40%', filter: 'grayscale(1)',
        position: 'absolute', bottom: '-.4rem'
    }}/>)
    map.set('alfa romeo', <img src={alfa} key='alfa-romeo' alt='alfa-image' height='34'
                               style={{opacity: '40%', filter: 'grayscale(1)'}}/>)
    map.set('chevrolet', <img src={chevy} key='chevrolet' alt='chevrolet-image' height='58'
                              style={{opacity: '40%', filter: 'grayscale(1)'}}/>)
    map.set('chrysler', <img src={chrysler} key='chrysler' alt='chrysler-image' height='68'
                             style={{opacity: '40%', filter: 'grayscale(1)', position: 'absolute', bottom: '1px'}}/>)
    map.set('dacia', <img src={dacia} key='dacia' alt='dacia-image' height='60'
                          style={{
                              opacity: '55%', filter: 'grayscale(1)', position: 'absolute', bottom: '1px'
                          }}/>);
    // map.set('dacia', <img src={dacia} alt='dacia-image' height='60'
    //                       style={{
    //                           opacity: '55%', filter: 'grayscale(1)', position: 'absolute', bottom: '1px'
    //                       }}/>)
    map.set('daihatsu', <img src={daihatsu} key='daihatsu' alt='daihatsu-image' height='40'
                             style={{opacity: '40%', filter: 'grayscale(1)'}}/>)
    map.set('fiat', <img src={fiat} key='fiat' alt='fiat-image' height='25'
                         style={{opacity: '40%', filter: 'grayscale(1)'}}/>)
    map.set('gmc', <img src={gmc} key='gmc' alt='gmc-image' height='44'
                        style={{opacity: '40%', filter: 'grayscale(1)'}}/>)

    map.set('honda', <img src={honda} key='honda' alt='honda-image' height='62'
                          style={{opacity: '40%', filter: 'grayscale(1)', position: 'absolute', bottom: 0}}/>)
    map.set('isuzu', <img src={isuzu} key='isuzu' alt='isuzu-image' height='44'
                          style={{opacity: '40%', filter: 'grayscale(1)', position: 'absolute', bottom: 0}}/>)
    map.set('jaguar', <img src={jaguar} key='jaguar' alt='jaguar-image' height='44'
                           style={{opacity: '55%', filter: 'grayscale(1)'}}/>)
    map.set('jeep', <img src={jeep} key='jeep' alt='jeep-image' height='18'
                         style={{opacity: '45%', filter: 'grayscale(1)'}}/>)
    map.set('kia', <img src={kia} key='kia' alt='kia-image' height='48'
                        style={{opacity: '45%', filter: 'grayscale(1)'}}/>)
    map.set('lancia', <img src={lancia} key='lancia' alt='lancia-image' height='40'
                           style={{opacity: '40%', filter: 'grayscale(1)'}}/>)
    map.set('land rover', <img src={land} key='land rover' alt='land rover-image' height='52'
                               style={{opacity: '55%', filter: 'grayscale(1)'}}/>)
    map.set('lotus', <img src={lotus} key='lotus' alt='lotus-image' height='38'
                          style={{opacity: '58%', filter: 'grayscale(1)'}}/>)
    map.set('ferrari', <img src={ferrari} key='ferrari' alt='ferrari-image' height='30'
                            style={{opacity: '40%', filter: 'grayscale(1)'}}/>)
    map.set('aston martin', <img src={aston} key='aston martin' alt='aston martin-image' height='26'
                                 style={{opacity: '55%', filter: 'grayscale(1)'}}/>)
    map.set('renault', <img src={renault} key='renault' alt='renault-image' height='30'
                            style={{opacity: '55%', filter: 'grayscale(1)'}}/>)
    map.set('pontiac', <img src={pontiac} key='pontiac' alt='pontiac-image' height='44'
                            style={{opacity: '40%', filter: 'grayscale(1)', position: 'absolute', bottom: '0.5rem'}}/>)
    map.set('seat', <img src={seat} key='seat' alt='seat-image' height='44'
                         style={{opacity: '40%', filter: 'grayscale(1)'}}/>)
    map.set('ssangyong', <img src={ssangyong} key='ssang yong' alt='ssang yong-image' height='52'
                              style={{opacity: '40%', filter: 'grayscale(1)'}}/>)
    map.set('suzuki', <img src={suzuki} key='suzuki' alt='suzuki-image' height='34'
                           style={{opacity: '40%', filter: 'grayscale(1)', position: 'absolute', bottom: '0.8rem'}}/>)

    map.set('lada', <img src={lada} key='lada' alt='lada-image' height='28'
                         style={{
                             opacity: '40%', filter: 'grayscale(1)',
                             position: 'absolute', bottom: '1rem'
                         }}/>)
    map.set('buick', <img src={buick} key='buick' alt='buick-image' height='32'
                          style={{
                              opacity: '30%', filter: 'grayscale(1)',
                              position: 'absolute', bottom: '1rem'
                          }}/>)
    map.set('cadillac', <img src={cadillac} key='cadillac' alt='cadillac-image' height='38'
                             style={{
                                 opacity: '40%', filter: 'grayscale(1)',
                             }}/>)
    map.set('citroen', <img src={citroen} key='citroen' alt='citroen-image' height='34'
                            style={{
                                opacity: '60%', filter: 'grayscale(1)',
                            }}/>)
    map.set('abarth', <img src={abarth} key='abarth' alt='abarth-image' height='36'
                           style={{
                               opacity: '52%', filter: 'grayscale(1)',
                           }}/>)
    map.set('daewoo', <img src={daewoo} key='daewoo' alt='daewoo-image' height='72'
                           style={{
                               opacity: '32%', filter: 'grayscale(1)', position: 'absolute'
                           }}/>)
    // map.set('daewoo', <img src={daewoo} alt='daewoo-image' height='72'
    //                        style={{
    //                            opacity: '32%', filter: 'grayscale(1)', position: 'absolute'
    //                        }}/>)

    map.set('dodge', <img src={dodge} key='dodge' alt='dodge-image' height='34'
                          style={{
                              opacity: '40%', filter: 'grayscale(1)',
                          }}/>)

    map.set('hummer', <img src={hummer} key='hummer' alt='hummer-image' height='7'
                           style={{
                               opacity: '28%', filter: 'grayscale(1)', position: 'absolute'
                           }}/>)
    map.set('infiniti', <img src={infinity} key='infiniti' alt='infinity-image' height='24'
                             style={{
                                 opacity: '40%', filter: 'grayscale(1)',
                             }}/>)
    map.set('maserati', <img src={maserati} key='maserati' alt='maserati-image' height='36'
                             style={{
                                 opacity: '35%', filter: 'grayscale(1)', position: 'absolute'
                             }}/>)
    map.set('maybach', <img src={maybach} key='maybach' alt='maybach-image' height='34'
                            style={{
                                opacity: '45%', filter: 'grayscale(1)', position: 'absolute'
                            }}/>)
    map.set('rolls-royce', <img src={royce} key='rolls-royce' alt='rolls-royce-image' height='40'
                                style={{
                                    opacity: '45%', filter: 'grayscale(1)', position: 'absolute'
                                }}/>)
    map.set('bugatti', <img src={bugatti} key='bugatti' alt='bugatti-image' height='32'
                            style={{
                                opacity: '40%', filter: 'grayscale(1)', position: 'absolute'
                            }}/>)
    map.set('lamborghini', <img src={lamborghini} key='lamborghini' alt='lamborghini-image' height='40'
                                style={{
                                    opacity: '35%', filter: 'grayscale(1)', position: 'absolute', bottom: '.8rem'
                                }}/>)
    map.set('great wall', <img src={greatwall} key='great wall' alt='great wall-image' height='36'
                               style={{
                                   opacity: '35%', filter: 'grayscale(1)', position: 'absolute', bottom: '.8rem'
                               }}/>)
    map.set('geely', <img src={geely} key='geely' alt='geely-image' height='10'
                          style={{
                              opacity: '35%', filter: 'grayscale(1)', position: 'absolute'
                          }}/>);
    map.set('ram', <img src={ram} key='ram' alt='ram-image' height='30'
                        style={{
                            opacity: '30%', filter: 'grayscale(1)', position: 'absolute'
                        }}/>);
    map.set('polestar', <img src={polestar} key='polestar' alt='polestar-image' height='32'
                             style={{
                                 opacity: '35%', filter: 'grayscale(1)', position: 'absolute'
                             }}/>);
    map.set('mclaren', <img src={mclaren} key='mclaren' alt='mclaren-image' height='75'
                            style={{
                                opacity: '50%', filter: 'grayscale(1)', position: 'absolute'
                            }}/>);
    map.set('koenigsegg', <img src={koenigsegg} key='koenigsegg' alt='koenigsegg-image' height='30'
                               style={{
                                   opacity: '50%', filter: 'grayscale(1)', position: 'absolute'
                               }}/>);
    map.set('pagani', <img src={pagani} key='pagani' alt='pagani-image' height='9'
                           style={{
                               opacity: '50%', filter: 'grayscale(1)', position: 'absolute'
                           }}/>)
    return map;
}
export default LogotypeHelper;

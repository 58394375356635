import React, {useState} from "react";
import * as LanguageUtils from "../../../../../../../../Integration/Utils/LanguageUtils";
import Select from "react-select";
import './css/vehicle.css';
import _ from "lodash";

const isDataASimpleArray = data => Array.isArray(data) && data.length > 0 && typeof data[0] !== 'object';

const EditableAttributeSelect = props => {

    const {
        title, field, value, state, setState,
        dictionary, placeholder, side, textStyle
    } = {...props};


    console.log('EditableAttributeSelect; title :', title, 'field: ', field, 'value: ', value,
        'type of data:', typeof dictionary, 'isDataSimpleArray', isDataASimpleArray(dictionary))

    const [showField, setShowField] = useState(false);
    const [fieldValue, setFieldValue] = useState(value);
    const [selectValue, setSelectValue] = useState(value);

    const updateOnSelect = value => {
        console.log('updateOnSelect', value);
        setSelectValue(value);
        const copy = JSON.parse(JSON.stringify(state));
        copy[field] = value.value;
        setState(copy);
        // console.log('updateOnSelect state',copy);
        // setFieldValue(value.value);
    }

    const handleKeyPress = (event) => {
        console.log(event.key);
        if (event.key === 'Enter') setShowField(!showField);
        console.log('state after enter', state);
    }

    let colClass = 'col-12 align-items-start d-flex justify-content-between'
    let colImgStyle = 'col-12 d-flex justify-content-end'
    let col2style = 'col-12 mx-0'
    if (side === 'left') {
        colClass += ' pl-0';
        col2style += ' pl-0'
        colImgStyle += ' pl-0'
    } else {
        colClass += ' pr-0';
        col2style += ' pr-0';
        colImgStyle += ' pr-0';
    }

    return <div id={`editable-attribute-select-${title.toLowerCase()}`}
                className='row col-6 mx-0 d-flex justify-content-between px-0'
                style={textStyle}>
        <div className={colClass}>
            <span className="text-left">{title}</span>
            <img height='17px' width='17px' className='button ml-1 mt-1 edit-icon'
                 onClick={() => setShowField(!showField)}
            />
        </div>

        <div className='row col-12'/>
        <div className={col2style}>
            <div className='d-flex justify-content-start'>
                {!showField && <span className="text-left font-weight-bold text-capitalize">
                {LanguageUtils.getTitleFromDictionary(state[field], dictionary)}
                    {/*{dictionary !== undefined ? dictionary[state[field]].title : state[field]}*/}
            </span>}
                {showField && (
                    <Select value={selectValue}
                            className='w-100'
                            placeholder={placeholder !== undefined && placeholder}
                            style={textStyle}
                        // menuIsOpen
                            onChange={e => updateOnSelect(e)}
                            key={field}
                            onKeyDown={evt => handleKeyPress(evt)}
                            options={isDataASimpleArray(dictionary)
                                ? dictionary.map(element => {
                                    return {label: _.capitalize(element), value: element}
                                })
                                : Object.keys(dictionary).map(element => {
                                    const label = LanguageUtils.getTitleFromDictionary(element, dictionary)
                                    return {label: _.capitalize(label), value: element}
                                })}/>)
                }

                {/*<img height='17px' width='17px' className='ml-1 mt-1 edit-icon'*/}
                {/*     onClick={() => setShowField(!showField)}*/}
                {/*/>*/}
            </div>
        </div>
    </div>;
};

export default EditableAttributeSelect;
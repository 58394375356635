import React from 'react';
import {Input} from "../../Input";
import Heading from "../../../Search/SearchArea/Heading";

const InputFieldMileage = props => {
    let numberClass = 'row mt-2 numbered-list-item'
    if (props.state.mileage === undefined && props.prevField !== undefined) numberClass += ' yellow';
    else if (props.prevField === undefined) numberClass += ' grey'

    return <div className={numberClass} id='row-mileage'>

        <div className='offset-1'/>
        <div className='col-9 pl-0'>
            <Input key='input-mileage'
                   value={props.state.mileage}
                   disabled={props.prevField === undefined}
                   handler={props.updateMileage}
                   // placeholder={'Type'}
                   step={2500}
                   maxLength='7'
                   type='number'
                   >
                <div style={{marginLeft: '1em'}}>
                    <Heading>{props.title}</Heading>
                </div>
            </Input>
        </div>
    </div>
};

export default InputFieldMileage;
import React from 'react';
import {BriefInfoPanel, CarSlider, VehicleInformation} from "./Components";
import Footer2 from "../../Shared/Footer/Footer2";

const CarPageBigScreen = ({car}) => {
    return <div id='car-page-big-screen'>
        <div className='container-lg'>
            <div className='row'>
                <div className='col-7 pl-0'>
                    <CarSlider car={car}/>

                    <div className="w-100 py-2 " style={{color: '#333', fontSize: '1.125rem'}}/>

                    <VehicleInformation car={car}/>

                </div>
                <div className='col-5 border'>
                    <BriefInfoPanel car={car}/>
                </div>
            </div>
        </div>
        <Footer2/></div>
}

export default CarPageBigScreen;
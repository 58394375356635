import React from 'react';
import {multiSelectStyles} from "../../../Search/SearchArea/SearchOption/SearchOptionMultiNew";

const MultiSelectHistoryNew = props => {

    console.log('MultiSelectHistoryNew', props);

    const keys = Object.keys(props.dictionary)
    const set = new Set(props.state.history);

    return <div className='multiselect'>
        {props.children}
        {keys.map((key, index) => {

            const title = props.dictionary[key].title;
            console.log('history key.title:', title)
            return <div className='row' key={key} style={{position: 'relative'}}>
                <div className='d-flex justify-content-start align-items-center'>
                    {/*{console.log('each item >>>', props.options[props.section][index])}*/}
                    <input
                        checked={props.state.history.includes(key)}
                        name={key}
                        type='checkbox'
                        style={multiSelectStyles.checkbox}
                        onChange={(e) => {
                            if (e.target.checked) {
                                set.add(key);
                            } else {
                                set.delete(key);
                            }
                            props.updateHistory(Array.from(set));
                        }}/>
                    <span className='checkmark' style={{}}/>
                    <label className='ml-2 my-0 text-capitalize'
                           style={multiSelectStyles.label}>{title}</label>
                </div>
            </div>
        })}
    </div>
}

export default MultiSelectHistoryNew;
import React from 'react';
import {
    isCommentInRange,
    isDisplacementWithinRange,
    isMileageWithinRange,
    isModificationSimpleWithinRange,
    isOptionsWithinRange,
    isPowerWithinRange,
    isPriceWithinRange,
    isYearWithinRange
} from "../../../../../Utils/inputvalidators";
import {useTranslation} from "react-i18next";
import {
    EditableAttributeHistory,
    EditableAttributeLocation,
    EditableAttributeOptions,
    EditableAttributeComment,
    EditableAttributeSelect,
    EditableAttributeColor,
    EditableAttribute, NonEditableAttribute
} from "./EditableInfoAttributes";
import EditableAttributeVAT from "./EditableInfoAttributes/EditableAttributeVAT";

const EditableVehicleInfo = props => {

    // const jointState = {...initialState, ...props.car}
    // const [state, setState] = useState(jointState);

    const {t} = useTranslation(["texts", "data-for-ui", "locations"]);
    let bodyStyle = t('data-for-ui:bodystyle', {returnObjects: true});
    let fuel = t('data-for-ui:fuel', {returnObjects: true});
    let transmission = t('data-for-ui:transmission', {returnObjects: true});
    let drivetrain = t('data-for-ui:drivetrain', {returnObjects: true});
    let color = t('data-for-ui:extColor', {returnObjects: true});
    let locations = t('locations:countries', {returnObjects: true});
    let options_d = t('data-for-ui:features', {returnObjects: true});
    let history_d = t('data-for-ui:history', {returnObjects: true});
    let vat_d = t('data-for-ui:vat', {returnObjects: true});
    // let features = t('data-for-ui:features', {returnObjects: true});


    const vatPerCountry = vat_d[props.state.country].map((vat) => {
        let booleanValue = "false"; //vatIncluded can be false(0%) or true(20%)
        if (vat.value !== 0) booleanValue = "true";
        const v = {"value": booleanValue, "label": vat.label};
        console.log('vatPerCountry', v);
        return v;
    });

    console.log('EditableVehicleInfo props', props);

    console.log('bodyStyle in EditableVehicleInfo', bodyStyle);

    const updateOptions = async options => {

        // let obj = {options: options};
        // console.log('typeof options',typeof options);
        // if(
        //     const obj = {options: JSON.parse(options)}
        props.state['options'] = options;
        console.log('obj', options);
        console.log('props.state', props.state);
        // }

        props.setState(props.state);
    };

    const headerFont = {fontSize: '1rem'};
    const textStyle = {fontSize: '.9rem'}
    if (props.isSmallScreen) {
        headerFont.fontSize = '1rem';
        textStyle.fontSize = '0.88rem'
    }

    return <div>
        {/*<img src={gear} width='32' height='32'/>*/}
        <div id='basic'>
            <div className="row mx-3 px-0 my-3 py-3 border-bottom d-flex align-items-center">
                <NonEditableAttribute
                    value={props.state.make}
                    title={t('vehicle-information.make')}
                    side={'left'}
                    textStyle={textStyle}
                />
                <NonEditableAttribute
                    value={props.state.model}
                    title={t('vehicle-information.model')}
                    side={'right'}
                    textStyle={textStyle}
                />
            </div>
            <div className="row mx-3 px-0 my-3 pb-3 border-bottom" style={textStyle}>

                <NonEditableAttribute
                    value={props.state.originalVIN ? props.state.originalVIN : props.state.vin}
                    title={'VIN'}
                    side={'left'}
                    isSmallScreen={props.isSmallScreen}
                    textStyle={textStyle}
                />
                <EditableAttribute
                    value={props.state.modification}
                    title={t('vehicle-information.modification')}
                    type='text'
                    side={'right'}
                    state={props.state}
                    setState={props.setState}
                    field='modification'
                    validator={isModificationSimpleWithinRange}
                    isSmallScreen={props.isSmallScreen}
                    textStyle={textStyle}
                />
            </div>
            <div className='row mx-3 px-0 my-3 pb-3 border-bottom'>
                <EditableAttribute
                    value={props.state.year}
                    title={t('vehicle-information.year')}
                    side={'left'}
                    type='number'
                    field='year'
                    validator={isYearWithinRange}
                    state={props.state}
                    setState={props.setState}
                    textStyle={textStyle}
                />

                <EditableAttribute
                    title={t('vehicle-information.mileage')}
                    value={props.state.mileage}
                    side={'right'}
                    type='number'
                    state={props.state}
                    setState={props.setState}
                    field='mileage'
                    textStyle={textStyle}
                    validator={isMileageWithinRange}/>
            </div>
            <div className="row mx-3 px-0 my-3 pb-3 border-bottom" style={textStyle}>
                <EditableAttribute
                    value={props.state.price}
                    title={t('vehicle-information.price')}
                    side={'left'}
                    type='number'
                    state={props.state}
                    setState={props.setState}
                    validator={isPriceWithinRange}
                    textStyle={textStyle}
                    field='price'/>

                <EditableAttributeVAT
                    value={props.state.vatIncluded}
                    title='VAT'
                    state={props.state}
                    setState={props.setState}
                    dictionary={vatPerCountry}
                    field='vatIncluded'
                    side={'right'}
                    placeholder={t('vehicle-information.placeholder')}
                    textStyle={textStyle}
                />
            </div>

            <div className='row mx-3 px-0 my-3 pb-3 border-bottom'>

                <EditableAttributeSelect
                    value={props.state.fuel}
                    title={t('vehicle-information.fuel-type')}
                    state={props.state}
                    setState={props.setState}
                    dictionary={fuel}
                    data={fuel}
                    field='fuel'
                    side={'left'}
                    placeholder={t('vehicle-information.placeholder')}
                    textStyle={textStyle}
                />

                <EditableAttributeSelect
                    dictionary={bodyStyle}
                    field={'bodyStyle'}
                    title={t('vehicle-information.body-style')}
                    state={props.state}
                    setState={props.setState}
                    side='right'
                    placeholder={t('vehicle-information.placeholder')}
                    textStyle={textStyle}/>
            </div>
            <div className="row mx-3 px-0 my-3 pb-3 border-bottom" style={{fontSize: '1.125rem'}}>
                <EditableAttributeSelect
                    value={props.state.transmission}
                    title={t('vehicle-information.transmission')}
                    setState={props.setState}
                    state={props.state}
                    dictionary={transmission}
                    field='transmission'
                    data={transmission}
                    side='left'
                    placeholder={t('vehicle-information.placeholder')}
                    textStyle={textStyle}
                />
                <EditableAttributeSelect
                    value={props.state.drivetrain}
                    title={t('vehicle-information.drivetrain')}
                    data={drivetrain}
                    dictionary={drivetrain}
                    state={props.state}
                    setState={props.setState}
                    field='drivetrain'
                    side='right'
                    placeholder={t('vehicle-information.placeholder')}
                    textStyle={textStyle}
                />
            </div>
            <div className='row mx-3 px-0 my-3 pb-3 border-bottom'>
                <EditableAttribute
                    field='power'
                    validator={isPowerWithinRange}
                    title={t('vehicle-information.power')}
                    side={'left'}
                    type='number'
                    state={props.state}
                    setState={props.setState}
                    textStyle={textStyle}
                />
                <EditableAttribute
                    field='displacement'
                    validator={isDisplacementWithinRange}
                    title={t('vehicle-information.displacement')}
                    side={'right'}
                    type='number'
                    state={props.state}
                    setState={props.setState}
                    textStyle={textStyle}/>
            </div>
            <div className='row mx-3 px-0 my-3 pb-3 border-bottom'>
                <EditableAttributeColor
                    value={props.state.color}
                    dictionary={color}
                    type='color'
                    state={props.state}
                    title={t('vehicle-information.color')}
                    setState={props.setState}
                    side='left'
                    placeholder={t('vehicle-information.placeholder')}
                    textStyle={textStyle}
                />

            </div>

            <div className="row mx-3 px-0 my-3 pb-3 border-bottom" style={{fontSize: '1.125rem'}}>
                <EditableAttributeLocation
                    dictionary={locations}
                    cities={props.state.cities}
                    title={t('vehicle-information.location')}
                    setState={props.setState}
                    state={props.state}
                    placeholderCity={t('sell.input-fields.select-city')}
                    placeholderCountry={t('sell.input-fields.select-country')}
                    textStyle={textStyle}/>
            </div>

            <div className="row mx-3 px-0 my-3 pb-3 border-bottom" style={textStyle}>
                <EditableAttributeComment
                    title={t('vehicle-information.comment')}
                    state={props.state}
                    setState={props.setState}
                    validator={isCommentInRange}
                    textStyle={textStyle}/>
            </div>

            <div className="row mx-3 px-0 my-3 pb-3 border-bottom" style={{fontSize: '1.125rem'}}>
                <EditableAttributeOptions
                    options={options_d}
                    title={t('vehicle-information.options')}
                    state={props.state}
                    setState={props.setState}
                    validator={isOptionsWithinRange}
                    textStyle={textStyle}/>
            </div>
            <div className="row mx-3 px-0 mt-4 mb-1" style={{fontSize: '1.125rem'}}>
                <EditableAttributeHistory
                    title={t('vehicle-information.history-of-ownership')}
                    state={props.state}
                    history={history_d}
                    setState={props.setState}
                    validator={isOptionsWithinRange}
                    textStyle={textStyle}
                />
            </div>
        </div>

        {/*<ExtendedInfo car={props.state} showExtended={props.state.showExtended}/>*/}

        {/*<div id='footer' className='row border-bottom row pb-5'/>*/}

    </div>

}

export default EditableVehicleInfo;